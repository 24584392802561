import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeApiCall } from "src/api/apiRequests";
import Select, { SingleValue } from "react-select";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import PATHS from "src/routes/Paths";
import ValidationMessage from "src/helpers/ValidationMessage";

const Register = (): JSX.Element => {
  const { t } = useTranslation("");
  const { token } = useParams();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<number>();

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState<string>("");
  const [postalCode, setPostalCode] = useState("");
  const [address, setAddress] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [gender, setGender] =
    useState<SingleValue<{ value: string; label: string }>>(null);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});
  const [userShowBtnLoading, setUserShowBtnLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: any = await makeApiCall<ResponseType>(
          API_PATHS.acceptInvitation(token),
          "GET",
          API_HEADERS.unauthenticated
        );

        if (response.user.role_id === 4) {
          setEmail(response.user.email);
        } else {
          setFirstName(response.user.first_name);
          setLastName(response.user.last_name);
        }

        setRole(response.user.role_id);
      } catch (error: any) {
        navigate(PATHS.login);
      }
    };

    fetchData();
  }, []);

  const registerUser = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setUserShowBtnLoading(true);

    let formData = {
      first_name: firstName,
      last_name: lastName,
      phone: phone,
      city: city,
      postal_code: postalCode,
      address: address,
      password: password,
      password_confirmation: confirmPassword,
      gender: gender ? gender.value : "",
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.completeRegistration(token),
        "POST",
        API_HEADERS.unauthenticated,
        formData
      );

      setValidations({});
      FLASH_MESSAGES.successMsg(response.success);
      navigate(PATHS.login);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
        // } else {
        //   FLASH_MESSAGES.errorMsg(error.error);
        //   // setValidations({});
      }
    } finally {
      setUserShowBtnLoading(false);
    }
  };

  return (
    <>
      <h3 className="auth-title">
        {t("auth_register_title")}{" "}
        {role === 4 ? email : `${firstName} ${lastName}`}!
      </h3>
      <div className="auth-card">
        <p className="auth-note">{t("auth_register_note")}</p>
        <form className="w-100" onSubmit={(e) => registerUser(e)}>
          <div className="form-block">
            <label className="required">
              {t("auth_register_first_name_label")}
            </label>
            <input
              type="text"
              name="first_name"
              value={firstName}
              placeholder={t("auth_register_first_name_placeholder")}
              className={
                "form-input" + (validations.first_name ? " input-error" : "")
              }
              onChange={(e) => setFirstName(e.target.value)}
            />
            {validations.first_name && (
              <ValidationMessage message={validations.first_name[0]} />
            )}
          </div>
          <div className="form-block">
            <label className="required">
              {t("auth_register_last_name_label")}
            </label>
            <input
              type="text"
              name="last_name"
              value={lastName}
              placeholder={t("auth_register_last_name_placeholder")}
              className={
                "form-input" + (validations.last_name ? " input-error" : "")
              }
              onChange={(e) => setLastName(e.target.value)}
            />
            {validations.last_name && (
              <ValidationMessage message={validations.last_name[0]} />
            )}
          </div>
          <div className="form-block">
            <label className="required">
              {t("auth_register_gender_label")}
            </label>
            <Select
              placeholder={t("auth_register_gender_placeholder")}
              className={
                "form-react-select" + (validations.gender ? " input-error" : "")
              }
              classNamePrefix="select_input"
              name="gender"
              onChange={(e) => (e ? setGender(e) : setGender(null))}
              value={gender}
              isClearable={true}
              isSearchable={false}
              options={[
                { value: "male", label: t("auth_register_gender_male") },
                { value: "female", label: t("auth_register_gender_female") },
              ]}
            />
            {validations.gender && (
              <ValidationMessage message={validations.gender[0]} />
            )}
          </div>
          <div className="form-block">
            <label className="required">{t("auth_register_phone_label")}</label>
            <input
              type="number"
              name="phone"
              min={0}
              value={phone}
              placeholder={t("auth_register_phone_placeholder")}
              className={
                "form-input" + (validations.phone ? " input-error" : "")
              }
              onChange={(e) => setPhone(e.target.value)}
            />
            {validations.phone && (
              <ValidationMessage message={validations.phone[0]} />
            )}
          </div>
          <div className="form-block">
            <label className="required">{t("auth_register_city_label")}</label>
            <input
              type="text"
              name="city"
              value={city}
              placeholder={t("auth_register_city_placeholder")}
              className={
                "form-input" + (validations.city ? " input-error" : "")
              }
              onChange={(e) => setCity(e.target.value)}
            />
            {validations.city && (
              <ValidationMessage message={validations.city[0]} />
            )}
          </div>
          <div className="form-block">
            <label className="required">
              {t("auth_register_postal_code_label")}
            </label>
            <input
              type="number"
              name="postal_code"
              min={0}
              value={postalCode}
              placeholder={t("auth_register_postal_code_placeholder")}
              className={
                "form-input" + (validations.postal_code ? " input-error" : "")
              }
              onChange={(e) => setPostalCode(e.target.value)}
            />
            {validations.postal_code && (
              <ValidationMessage message={validations.postal_code[0]} />
            )}
          </div>
          <div className="form-block">
            <label className="required">
              {t("auth_register_address_label")}
            </label>
            <input
              type="text"
              name="address"
              value={address}
              placeholder={t("auth_register_address_placeholder")}
              className={
                "form-input" + (validations.address ? " input-error" : "")
              }
              onChange={(e) => setAddress(e.target.value)}
            />
            {validations.address && (
              <ValidationMessage message={validations.address[0]} />
            )}
          </div>
          <div className="form-block">
            <label className="required">
              {t("auth_register_password_label")}
            </label>
            <div className="password-input">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={password}
                placeholder={t("auth_register_passwonrd_placeholder")}
                className={
                  "form-input" + (validations.password ? " input-error" : "")
                }
                onChange={(e) => setPassword(e.target.value)}
              />
              <div
                className="show-hide"
                onClick={(e) => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <i className="icon show-password-icon" />
                ) : (
                  <i className="icon hide-password-icon" />
                )}
              </div>
            </div>
            {validations.password && (
              <ValidationMessage message={validations.password[0]} />
            )}
          </div>
          <div className="form-block">
            <label className="required">
              {t("auth_register_confirm_password_label")}
            </label>
            <div className="password-input">
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="password_confirmation"
                value={confirmPassword}
                placeholder={t("auth_register_confirm_password_placeholder")}
                className={
                  "form-input" +
                  (validations.password_confirmation ? " input-error" : "")
                }
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <div
                className="show-hide"
                onClick={(e) => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <i className="icon show-password-icon" />
                ) : (
                  <i className="icon hide-password-icon" />
                )}
              </div>
            </div>
            {validations.password_confirmation && (
              <ValidationMessage
                message={validations.password_confirmation[0]}
              />
            )}
          </div>

          {userShowBtnLoading ? (
            <button type="button" className="button-blue w-100 has-icon">
              <i className="spinner-icon" />
              {t("create_new_account_registering_button")}
            </button>
          ) : (
            <button type="submit" className="button-blue w-100">
              {t("create_new_account_register_button")}
            </button>
          )}
        </form>
      </div>
    </>
  );
};

export default Register;
