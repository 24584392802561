import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select, { MultiValue, SingleValue } from "react-select";
import { makeApiCall } from "src/api/apiRequests";
import { useNavigate, useParams } from "react-router-dom";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import ValidationMessage from "src/helpers/ValidationMessage";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import ListResponse from "src/helpers/ListResponse";
import CustomPagination from "src/helpers/CustomPaginate";
import SearchFilter from "src/helpers/SearchFilter";
import Loading from "src/helpers/Loading";
import moment from "moment";
import PATHS from "src/routes/Paths";
import useSearchSubmit from "src/hooks/useSearchSubmit";

interface TeachersObject {
  value: number;
  label: string;
}

const EditGroup: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { groupId } = useParams();

  // Fetched data
  const [teachersList, setTeachersList] = useState<any[]>([]);
  const [ageGroupsList, setAgeGroupsList] = useState<any[]>([]);
  const [childrenList, setChildrenList] = useState<any>([]);

  // New data
  const [name, setName] = useState<string>("");
  const [selectedTeachers, setSelectedTeachers] = useState<
    TeachersObject[] | null
  >(null);
  const [age, setAge] = useState<any>();
  const [selectedChildren, setSelectedChildren] = useState<any[]>([]);

  // Search
  const {
    searchInputs,
    searchBtnLoading,
    page,
    setSearchBtnLoading,
    handleSubmit,
    setPage,
  } = useSearchSubmit();
  const [searchChild, setSearchChild] = useState<string>("");
  const [searchAgeGroup, setSearchAgeGroup] =
    useState<SingleValue<{ value: string; label: string }>>(null);

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const fetchGroupDetails = async (): Promise<void> => {
    var searchParams: any = searchInputs;
    let request: any = API_PATHS.getKindergartenGroupDetails;


    if (groupId) {
      searchParams.kindergarten_group_id = groupId;
    }

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      const {
        age_group,
        children,
        teachersList,
        kindergarten_group,
        selected_children,
      } = response;

      setTeachersList(
        teachersList.map((teacher: any) => ({
          value: teacher.id,
          label: `${teacher.first_name} ${teacher.last_name}`,
        }))
      );
      setName(kindergarten_group.name);
      setAge(kindergarten_group.age);
      setAgeGroupsList(
        age_group.map((ageGroup: any) => ({
          value: ageGroup.id,
          label: `${ageGroup.from}-${ageGroup.to} ${t(
            "add_group_search_age_group_months"
          )}`,
        }))
      );
      setSelectedTeachers(
        kindergarten_group.teachers.map((teacher: any) => ({
          value: teacher.id,
          label: `${teacher.first_name} ${teacher.last_name}`,
        }))
      );
      setChildrenList(children);

      page === 0 && selectedChildren.length === 0 &&
        setSelectedChildren(
          selected_children.map((child: any) => ({
            id: child.id,
            child_name: `${child.first_name} ${child.last_name}`,
          }))
        );
      setLoading(false);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        navigate(-1);
      }
    } finally {
      setSearchBtnLoading(false);
    }
  };

  useEffect(() => {
    fetchGroupDetails();
  }, [searchInputs]);

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    data: any
  ) => {
    if (
      data.kindergarten_group === null ||
      data.kindergarten_group.id == groupId
    ) {
      let childData = {
        id: data.id,
        child_name: data.first_name + " " + data.last_name,
      };
      if (e.target.checked) {
        setSelectedChildren((current: any) => [...current, childData]);
      } else {
        setSelectedChildren((current: any) =>
          current.filter((element: any) => {
            return element.id !== childData.id;
          })
        );
      }
    }
  };

  const handleGroupSave = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setLoadingButton(true);

    let formData = {
      name: name,
      age: age,
      children: selectedChildren.map((child: any) => child.id),
      teachers: selectedTeachers
        ? selectedTeachers.map((teacher: any) => teacher.value)
        : [],
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.updateKindergartenGroup(groupId),
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);

      navigate(PATHS.groupsList);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        navigate(-1);
      }
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <div className="add-group">
      {loading ? (
        <div className="text-center">
          <Loading />
        </div>
      ) : (
        <div className="card second">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-lg-4 mb-4">
                <span className="title">{t("add_group_main_data")}</span>
                <div className="row mt-3">
                  <div className="form-block col-12 col-xxl-6">
                    <label className="required">
                      {t("add_group_group_name_label")}
                    </label>
                    <input
                      type="text"
                      name="name"
                      placeholder={t("add_group_group_name_placeholder")}
                      defaultValue={name}
                      className={
                        "form-input" + (validations.name ? " input-error" : "")
                      }
                      autoComplete="off"
                      onChange={(e) => setName(e.target.value)}
                    />
                    {validations.name && (
                      <ValidationMessage message={validations.name[0]} />
                    )}
                  </div>
                  <div className="form-block col-12 col-xxl-6">
                    <label>{t("add_group_teacher_label")}</label>
                    <Select
                      placeholder={t("add_group_teacher_placeholder")}
                      className={
                        "form-react-select" +
                        (validations.teachers ? " input-error" : "")
                      }
                      classNamePrefix="select_input"
                      name="kindergarten_group_id"
                      onChange={(e: MultiValue<TeachersObject>) =>
                        setSelectedTeachers(e as TeachersObject[])
                      }
                      isSearchable={true}
                      options={teachersList}
                      value={selectedTeachers}
                      isMulti
                    />
                    {validations.teachers && (
                      <ValidationMessage message={validations.teachers[0]} />
                    )}
                  </div>
                  <div className="form-block col-12 col-xxl-6">
                    <label className="required">
                      {t("add_group_group_age_label")}
                    </label>
                    <input
                      type="number"
                      name="age"
                      placeholder={t("add_group_group_age_placeholder")}
                      defaultValue={age}
                      className={
                        "form-input" + (validations.age ? " input-error" : "")
                      }
                      autoComplete="off"
                      onChange={(e) => setAge(e.target.value)}
                    />
                    {validations.age && (
                      <ValidationMessage message={validations.age[0]} />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 mb-4">
                <span className="title">{t("add_group_teachers_group")}</span>
                <div className="labels mt-3">
                  {selectedTeachers && selectedTeachers.length > 0 ? (
                    selectedTeachers.map((teacher: any) => (
                      <span key={teacher.value} className="teacher-label">
                        {teacher.label}
                      </span>
                    ))
                  ) : (
                    <span className="text-no-selected">
                      {t("add_group_no_teacher_selected")}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-4 mb-4">
                <span className="title">{t("add_group_children_group")}</span>
                <div className="labels mt-3">
                  {selectedChildren && selectedChildren.length > 0 ? (
                    selectedChildren.map((child: any) => (
                      <span key={child.id} className="child-label">
                        {child.child_name}
                      </span>
                    ))
                  ) : (
                    <span className="text-no-selected">
                      {t("add_group_no_child_selected")}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <span className="title">{t("add_group_select_children")}</span>
              </div>
              <div className="col-12 col-xl-6 col-xxl-6">
                <form onSubmit={(e) =>
                  handleSubmit(e, {
                    ...(searchChild && { child_name: searchChild }),
                    ...(searchAgeGroup && { age_group_id: searchAgeGroup.value }),
                  })
                } className="row mt-3">
                  <div className="form-block col-12 col-lg-4">
                    <label>{t("add_group_search_child_name_label")}</label>
                    <input
                      type="text"
                      name="child_name"
                      placeholder={t("add_group_search_child_name_placeholder")}
                      defaultValue={searchChild}
                      className="form-input"
                      autoComplete="off"
                      onChange={(e) => setSearchChild(e.target.value)}
                    />
                  </div>
                  <div className="form-block col-12 col-lg-4">
                    <label>{t("add_group_search_age_group_label")}</label>
                    <Select
                      placeholder={t("add_group_search_age_group_placeholder")}
                      className="form-react-select"
                      classNamePrefix="select_input"
                      name="age_group_id"
                      onChange={(e) => setSearchAgeGroup(e)}
                      isSearchable={true}
                      isClearable
                      options={ageGroupsList}
                      value={searchAgeGroup}
                    />
                  </div>

                  <div className="col-12 col-xl-6 col-xxl-4">
                    {searchBtnLoading ? (
                      <button
                        type="button"
                        className="mt-4 button-orange has-icon"
                      >
                        <i className="icon spinner-icon" />
                        {t("education_list_searching_button")}
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="mt-4 button-orange has-icon"
                      >
                        <i className="icon search-icon" />
                        {t("education_list_search_button")}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
            <div className="table-list mb-3">
              <table role="table" className="w-100">
                <thead>
                  <tr role="row">
                    <th
                      style={{ width: "20%" }}
                      role="columnheader"
                      className="text-left"
                    >
                      <i className="icon child-icon" />
                      {t("add_group_table_child")}
                    </th>
                    <th
                      style={{ width: "20%" }}
                      role="columnheader"
                      className="text-left"
                    >
                      <i className="icon icon guardian-icon" />
                      {t("add_group_table_guardian")}
                    </th>
                    <th
                      style={{ width: "20%" }}
                      role="columnheader"
                      className="text-left"
                    >
                      <i className="icon calender-icon" />
                      {t("add_group_table_birthday")}
                    </th>
                    <th
                      style={{ width: "20%" }}
                      role="columnheader"
                      className="text-left"
                    >
                      <i className="icon group-icon" />
                      {t("add_group_table_group")}
                    </th>
                    <th
                      style={{ width: "20%" }}
                      role="columnheader"
                      className="text-center"
                    >
                      <i className="icon actions-icon" />
                      {t("add_group_actions")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {childrenList?.data.map((child: any) => (
                    <tr
                      key={child.id}
                      className={
                        child.kindergarten_group &&
                          child.kindergarten_group.id != groupId
                          ? ""
                          : "available"
                      }
                    >
                      <td
                        role="cell"
                        className="text-left bold-text"
                        data-label={t("add_group_table_child")}
                      >
                        {child.first_name + " " + child.last_name}
                      </td>
                      <td
                        role="cell"
                        className="text-left bold-text"
                        data-label={t("add_group_table_guardian")}
                      >
                        {child.guardian.first_name +
                          " " +
                          child.guardian.last_name}
                      </td>
                      <td
                        role="cell"
                        className="text-left"
                        data-label={t("add_group_table_birthday")}
                      >
                        {moment(child.date_of_birth).format("DD-MM-YYYY")}
                      </td>
                      <td
                        role="cell"
                        className="text-left"
                        data-label={t("add_group_table_group")}
                      >
                        {child.kindergarten_group
                          ? child.kindergarten_group.name
                          : "-"}
                      </td>
                      <td
                        role="cell"
                        className="text-center"
                        data-label={t("add_group_actions")}
                      >
                        <div className="actions">
                          <div className="form-block">
                            <input
                              type="checkbox"
                              className="form-checkbox"
                              id={`checkbox_${child.id}`}
                              disabled={
                                child.kindergarten_group &&
                                  child.kindergarten_group.id != groupId
                                  ? true
                                  : false
                              }
                              onChange={(e) => handleCheckboxChange(e, child)}
                              defaultChecked={selectedChildren.some(
                                (item: any) => item.id === child.id
                              )}
                            />
                            <label htmlFor={`checkbox_${child.id}`}></label>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {validations.children && (
                <div className="form-block px-4 py-2">
                  <ValidationMessage message={validations.children[0]} />
                </div>
              )}
              <ListResponse
                totalItems={childrenList?.data?.length}
                loading={loading}
              />
            </div>
            <div className="end-row">
              <CustomPagination
                data={childrenList}
                setActivePage={(e) => setPage(e)}
              />

              {loadingButton ? (
                <button type="button" className="button-blue has-icon ms-auto">
                  <i className="icon spinner-icon" />
                  {t("add_group_saving_button")}
                </button>
              ) : (
                <button
                  type="submit"
                  className="button-blue has-icon ms-auto"
                  onClick={(e) => handleGroupSave(e)}
                >
                  {t("add_group_save_button")}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditGroup;
