import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { makeApiCall } from "src/api/apiRequests";
import Select, { SingleValue } from "react-select";
import { StaffRoles } from "./StaffRoles";
import AddStaff from "./AddStaff";
import EditStaff from "./EditStaff";
import ShowStaff from "./ShowStaff";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import SearchFilter from "src/helpers/SearchFilter";
import ListResponse from "src/helpers/ListResponse";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import CustomPagination from "src/helpers/CustomPaginate";
import Loading from "src/helpers/Loading";
import useSearchSubmit from "src/hooks/useSearchSubmit";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";

type SelectedInstitutionsProps = {
  value: string;
  label: string;
};

const Staff: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Staff list
  const [staffList, setStaffList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // Search
  const {
    searchInputs,
    searchBtnLoading,
    searched,
    page,
    setSearchBtnLoading,
    handleSubmit,
    setPage,
  } = useSearchSubmit();
  const [institutionsList, setInstitutionsList] = useState<any>([]);
  const [role, setRole] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [institutions, setInstitutions] = useState<SelectedInstitutionsProps[]>(
    []
  );

  // Modals
  const [addStaffModal, setAddStaffModal] = useState(false);
  const [editStaffModal, setEditStaffModal] = useState(false);
  const [showStaffModal, setShowStaffModal] = useState(false);
  const [modalId, setModalId] = useState<number | null>(null);

  const getStaff = async (): Promise<void> => {
    let searchParams: any = searchInputs;
    let request: any = API_PATHS.staffList;

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      setStaffList(response.staff_members);
      page === 0 && !searched && getInstitutions();
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    } finally {
      setSearchBtnLoading(false);
    }
  };

  const getInstitutions = async (): Promise<void> => {
    let request: any = API_PATHS.kindergartensDropdownList;
    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      let { kindergartens } = response;
      setInstitutionsList(
        kindergartens.map((institution: any) => ({
          value: institution.id,
          label: institution.name,
        }))
      );
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  useEffect(() => {
    getStaff();
  }, [searchInputs]);

  const deleteStaff = (staffId: number, staffTitle: string) => {
    ConfirmPopup.deletePopup(
      t("delete_staff_title"),
      t("delete_staff_type"),
      staffTitle
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.deleteStaff(staffId),
            "DELETE",
            API_HEADERS.authenticated
          );
          FLASH_MESSAGES.successMsg(response.success);
          if (staffList.data.length === 1 && page > 1) {
            setPage(page - 1);
          } else {
            getStaff();
          }
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  return (
    <>
      <form
        onSubmit={(e) =>
          handleSubmit(e, {
            ...(role && { role_id: role.value }),
            ...(institutions.length > 0 && {
              "kindergarten_ids[]": institutions.map(
                (institution: any) => institution.value
              ),
            }),
          })
        }
        className="row"
      >
        <div className="form-block col-12 col-sm-6 col-md-3 col-xxl-2">
          <label>{t("staff_role_label")}</label>
          <Select
            placeholder={t("staff_role_placeholder")}
            className="form-react-select"
            classNamePrefix="select_input"
            name="role_id"
            onChange={(e) => setRole(e)}
            isClearable
            isSearchable={false}
            options={StaffRoles}
            value={role}
          />
        </div>
        <div className="form-block col-12 col-sm-6 col-md-3 col-xxl-2">
          <label>{t("staff_institution_label")}</label>
          <Select
            placeholder={t("staff_institution_placeholder")}
            className="form-react-select"
            classNamePrefix="select_input"
            name="kindergarten_ids"
            onChange={(e) => setInstitutions(e as SelectedInstitutionsProps[])}
            isClearable
            isSearchable
            isMulti
            options={institutionsList}
            value={institutions}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-3 col-xxl-2 mb-1">
          {searchBtnLoading ? (
            <button type="button" className="mt-4 button-orange has-icon">
              <i className="icon spinner-icon" />
              {t("staff_searching_btn")}
            </button>
          ) : (
            <button type="submit" className="mt-4 button-orange has-icon">
              <i className="icon search-icon" />
              {t("staff_search_btn")}
            </button>
          )}
        </div>
      </form>
      <div className="input-items">
        <div className="d-flex justify-content-start justify-content-md-end my-5 mt-md-0">
          <button
            type="button"
            className="button-blue has-icon"
            onClick={() => setAddStaffModal(true)}
          >
            <i className="icon plus-icon" />
            {t("staff_add_staff")}
          </button>
          {addStaffModal && (
            <AddStaff
              onShowModal={addStaffModal}
              setShowModal={setAddStaffModal}
              setModified={() => getStaff()}
            />
          )}
        </div>
      </div>
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon staff-number-icon" />
                {t("staff_name_lastname")}
              </th>
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon role-icon" />
                {t("staff_role")}
              </th>
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon kindergarten-table-icon" />
                {t("staff_institution")}
              </th>
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon status-icon" />
                {t("staff_status")}
              </th>
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon actions-icon" />
                {t("staff_actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={5}>
                  <Loading />
                </td>
              </tr>
            ) : (
              staffList?.data.map((staff: any) => (
                <tr key={staff.id}>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("staff_name_lastname")}
                  >
                    {(staff.first_name ? staff.first_name : "-") +
                      " " +
                      (staff.last_name ? staff.last_name : "-")}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("staff_role")}
                  >
                    {staff.role ? staff.role : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("staff_institution")}
                  >
                    {staff.kindergarten?.length > 0
                      ? staff.kindergarten.map(
                          (kindergarten: any, index: number) => (
                            <span className="staff-kindergarten" key={index}>
                              {kindergarten.name
                                ? kindergarten.name + " "
                                : "-"}
                            </span>
                          )
                        )
                      : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("staff_status")}
                  >
                    {staff.status === "active" ? (
                      <span>
                        <i className="green icon dot-icon my-2 mx-3 me-2" />
                        {t("staff_active")}
                      </span>
                    ) : staff.status === "inactive" ? (
                      <span>
                        <i className="red icon dot-icon my-2 mx-3 me-2" />
                        {t("staff_pasive")}
                      </span>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("staff_actions")}
                  >
                    <div className="actions">
                      <button
                        type="button"
                        className="button-unstyled-icon"
                        onClick={(e) => {
                          setEditStaffModal(true);
                          setModalId(staff.id);
                          e.stopPropagation();
                        }}
                      >
                        <i className="icon edit-icon" />
                      </button>
                      {editStaffModal && modalId === staff.id && (
                        <EditStaff
                          onShowModal={editStaffModal}
                          setShowModal={(e) => {
                            setEditStaffModal(e);
                            setModalId(null);
                          }}
                          staffId={staff.id}
                          setModified={() => getStaff()}
                        />
                      )}
                      <button
                        type="button"
                        className="button-unstyled-icon"
                        onClick={(e) => {
                          setShowStaffModal(true);
                          setModalId(staff.id);
                          e.stopPropagation();
                        }}
                      >
                        <i className="icon show-icon" />
                      </button>
                      {showStaffModal && modalId === staff.id && (
                        <ShowStaff
                          onShowModal={showStaffModal}
                          setModalVisible={(e) => {
                            setShowStaffModal(e);
                            setModalId(null);
                          }}
                          staffId={staff.id}
                          setShowModal={setEditStaffModal}
                        />
                      )}
                      <button
                        className="button-unstyled-icon"
                        key={staff.id}
                        onClick={(e) => {
                          deleteStaff(
                            staff.id,
                            staff.first_name + " " + staff.last_name
                          );
                        }}
                      >
                        <i className="icon delete-icon" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse totalItems={staffList?.data?.length} loading={loading} />
      </div>
      <CustomPagination data={staffList} setActivePage={(e) => setPage(e)} />
    </>
  );
};

export default Staff;
