import { useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Select, { SingleValue } from "react-select";
import { makeApiCall } from "src/api/apiRequests";
import { evaluations } from "./data";
import DatePicker from "react-datepicker";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import ValidationMessage from "src/helpers/ValidationMessage";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import moment from "moment";

interface AddNewEvaluationProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  setModified: (visible: boolean) => void;
  dateOfEvaluation: Date;
  teacherId: number;
}

const AddNewEvaluation: React.FC<AddNewEvaluationProps> = ({
  onShowModal,
  setShowModal,
  setModified,
  dateOfEvaluation,
  teacherId,
}) => {
  const { t } = useTranslation();

  const [teacher, setTeacher] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [evaluationDate, setEvaluationDate] = useState<Date | null>(null);
  const [evaluation, setEvaluation] =
    useState<SingleValue<{ value: number; text?: string; icon: JSX.Element }>>(
      null
    );
  const [comment, setComment] = useState<string>("");

  const [loadingButton, setLoadingButton] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const onClose = () => {
    setShowModal(false);
  };

  const addEvaluation = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setLoadingButton(true);

    let formData = {
      teacher_id: teacherId,
      evaluation_date: moment(dateOfEvaluation).format("YYYY-MM-DD"),
      evaluation_comment: comment,
      evaluation_score: evaluation ? evaluation.value : "",
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.teacherPerformanceStore,
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);

      setModified(true);

      onClose();

      setLoadingButton(false);
    } catch (error: any) {
      if (error.response.status === 422) {
        if (error.response.data.evaluation_date) {
          FLASH_MESSAGES.errorMsg(error.response.data.evaluation_date);
          onClose();
        } else {
          setValidations(error.response.data);
        }
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        onClose();
      }
      setLoadingButton(false);
    }
  };

  return (
    <Modal isOpen={onShowModal} toggle={() => onClose()} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("performance_add_new_evaluation_title")}
      </ModalHeader>
      <form onSubmit={(e) => addEvaluation(e)}>
        <ModalBody>
          <div className="modal-note">
            {t("performance_add_new_evaluation_desc")}
          </div>
          <div className="row">
            <div className="form-block col-12 col-sm-6">
              <label className="required">
                {t("performance_add_new_evaluation_evaluation_label")}
              </label>
              <Select
                placeholder={t(
                  "performance_add_new_evaluation_evaluation_placeholder"
                )}
                className={
                  "form-react-select" +
                  (validations.evaluation_score ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="evaluation"
                onChange={(e) => setEvaluation(e)}
                isClearable={true}
                isSearchable={false}
                options={evaluations}
                value={evaluation}
                getOptionLabel={(e: any) => e.icon}
              />
              {validations.evaluation_score && (
                <ValidationMessage message={validations.evaluation_score[0]} />
              )}
            </div>
            <div className="form-block col-12">
              <label>{t("performance_add_new_evaluation_comment_label")}</label>
              <textarea
                name="comment"
                className={
                  "form-input" +
                  (validations.evaluation_comment ? " input-error" : "")
                }
                value={comment}
                placeholder={t(
                  "performance_add_new_evaluation_comment_placeholder"
                )}
                rows={3}
                onChange={(e) => setComment(e.target.value)}
              />
              {validations.evaluation_comment && (
                <ValidationMessage
                  message={validations.evaluation_comment[0]}
                />
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("performance_add_new_evaluation_cancel_button")}
          </button>

          {loadingButton ? (
            <button type="button" className="button-blue has-icon">
              <i className="spinner-icon" />
              {t("performance_add_new_evaluation_adding_button")}
            </button>
          ) : (
            <button type="submit" className="button-blue has-icon">
              <i className="icon add-icon" />
              {t("performance_add_new_evaluation_add_button")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddNewEvaluation;
