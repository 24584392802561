import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Sidebar from "src/components/layouts/sidebar/Sidebar";
import Breadcrumb from "src/components/layouts/Common/Breadcrumb";
import Notifications from "src/components/panel/notifications/Notifications";

const NotificationsView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("notifications_view")}</title>
      </Helmet>
      <Sidebar>
        <Breadcrumb title={t("notifications_title")} />
        <Notifications />
      </Sidebar>
    </> 
  );
};

export default NotificationsView;
