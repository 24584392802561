import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useUserdata } from "src/store/UserData";
import ChangePassword from "./ChangePassword";
import PATHS from "src/routes/Paths";
import CheckGender from "src/helpers/Checkgender";

const Profile = (): JSX.Element => {
  const { t } = useTranslation("");
  const navigate = useNavigate();

  const userData = useUserdata((state: any) => state.userData);

  const [visibility, setVisibility] = useState(false);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-12">
              <button
                type="button"
                className="button-blue has-icon ms-auto"
                onClick={() => navigate(PATHS.editProfile)}
              >
                <i className="icon edit-icon" />
                {t("profile_edit_button")}
              </button>
            </div>
          </div>
          <div className="profile-info">
            <div className="profile-initials">
              <div className="avatar-violet size-xl">
                <span className="circle">{`${
                  userData.first_name ? userData.first_name.charAt(0) : ""
                }${
                  userData.last_name ? userData.last_name.charAt(0) : ""
                }`}</span>
              </div>
            </div>
            <div className="profile-data">
              <div className="info">
                <div>
                  <span className="label">
                    {t("profile_name") + " " + t("profile_lastname")}
                  </span>
                  <span className="text">
                    {userData
                      ? userData.first_name + " " + userData.last_name
                      : "-"}
                  </span>
                </div>
                <div>
                  <span className="label">{t("profile_email")}</span>
                  <span className="email">
                    {userData ? userData.email : "-"}
                  </span>
                </div>
                <div>
                  <span className="label">{t("profile_phone")}</span>
                  <span className="text">
                    {userData && userData.phone ? userData.phone : "-"}
                  </span>
                </div>
                <div>
                  <span className="label">{t("profile_gender")}</span>
                  <span className="text">
                    {userData && userData.gender
                      ? CheckGender(userData.gender)
                      : "-"}
                  </span>
                </div>
                <div>
                  <span className="label">{t("profile_city")}</span>
                  <span className="text">
                    {userData && userData.city ? userData.city : "-"}
                  </span>
                </div>
                <div>
                  <span className="label">{t("profile_address")}</span>
                  <span className="text">
                    {userData && userData.address ? userData.address : "-"}
                  </span>
                </div>
                <div>
                  <span className="label">{t("profile_role")}</span>
                  <span className="text">
                    {userData?.role ? userData.role_translated : "-"}
                  </span>
                </div>
              </div>
              <div className="change-password">
                <button
                  type="button"
                  className="button-transparent has-icon"
                  onClick={() => setVisibility(true)}
                >
                  <i className="icon lock-icon" />
                  {t("profile_change_password_button")}
                </button>
                {visibility && (
                  <ChangePassword
                    onShowModal={visibility}
                    setSiteVisible={(e) => setVisibility(e)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
