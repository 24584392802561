import { useTranslation } from "react-i18next";

const CheckGender = (gender: string) => {
  const { t } = useTranslation();

  switch (gender) {
    case "male":
      return t("gender_male");
    case "female":
      return t("gender_female");
    default:
      return "-";
  }
};

export default CheckGender;
