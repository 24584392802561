import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { accessData } from "src/helpers/AppConfig";
import Billing from "./tabs/Billing/Billing";
import AllergicGroups from "./tabs/Allergies/AllergicGroups";
import ResponsiblePersons from "./tabs/Resposible_persons/ResponsiblePersons";
import Participation from "./tabs/Participation/Participation";
import Food from "./tabs/Food/Food";
import EvaluationList from "./tabs/Evaluation/EvaluationList";
import { RoleCheck } from "src/helpers/RoleCheck";

const ChildTabs: React.FC = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<number>(0);

  const roleId = accessData("roleId");

  useEffect(() => {
    if (RoleCheck("1") || RoleCheck("2") || RoleCheck("4")) {
      setActiveTab(1);
    } else if (RoleCheck("8")) {
      setActiveTab(4);
    } else {
      setActiveTab(5);
    }
  }, []);

  const handleTabClick = (tab: number) => {
    setActiveTab(tab);
  };

  const allTabs = [
    {
      id: 1,
      name: t("show_child_responsible_persons_tab"),
      role: [1, 2, 4],
      component: <ResponsiblePersons />,
    },
    {
      id: 2,
      name: t("show_child_billing_tab"),
      role: [1, 2, 4],
      component: <Billing />,
    },
    {
      id: 3,
      name: t("show_child_participation_tab"),
      role: [1, 2, 4],
      component: <Participation />,
    },
    {
      id: 4,
      name: t("show_child_food_tab"),
      role: [1, 2, 4, 8],
      component: <Food />,
    },
    {
      id: 5,
      name: t("show_child_allergy_tab"),
      role: [1, 2],
      component: <AllergicGroups />,
    },
    {
      id: 6,
      name: t("show_child_evaluation_tab"),
      role: [1, 2],
      component: <EvaluationList />,
    },
  ];

  return (
    <div className="customized-tabs">
      <div className="blue-tabs mt-4">
        <div className="tab-buttons mb-4">
          {allTabs.map((tab) =>
            tab.role.includes(Number(roleId)) ? (
              <button
                key={tab.id}
                className={`tab-button${activeTab === tab.id ? " active" : ""}`}
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.name}
              </button>
            ) : (
              ""
            )
          )}
        </div>

        <div className="tab-content">
          {allTabs.map(
            (tab) =>
              activeTab === tab.id && <div key={tab.id}>{tab.component}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChildTabs;
