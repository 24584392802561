import { useTranslation } from "react-i18next";

interface ListResponseProps {
  totalItems?: number;
  loading?: boolean;
  searched?: boolean;
}

const ListResponse: React.FC<ListResponseProps> = ({
  totalItems,
  loading,
  searched,
}) => {
  const { t } = useTranslation("");
  if (totalItems === 0 && !loading && searched) {
    return <div className="table-list-results">{t("no_results_found")}</div>;
  }
  if (totalItems === 0 && !loading && !searched) {
    return <div className="table-list-results">{t("nothing_to_show")}</div>;
  }

  return null;
};

export default ListResponse;
