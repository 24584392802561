import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AddEducationProgram } from "src/store/education_program/AddEducationProgram";
import { EducationProgramData } from "src/store/education_program/EducationProgramData";
import parse from "html-react-parser";

export enum EditorType {
  Section = "section",
  Activity = "activity",
  WorkTools = "work_tools",
}

interface DaysContentProps {
  week: number;
  selectedDay: number;
}

const DaysContent: React.FC<DaysContentProps> = ({ week, selectedDay }) => {
  const { t } = useTranslation();

  const educationProgramData = EducationProgramData(
    (state: any) => state.educationProgramData
  );
  const {
    educationProgramEdit,
    addEducationProgramEdit,
    resetEducationProgram,
  } = AddEducationProgram((state: any) => ({
    educationProgramEdit: state.educationProgramEdit,
    addEducationProgramEdit: state.addEducationProgramEdit,
    resetEducationProgram: state.resetEducationProgram,
  }));

  useEffect(() => {
    resetEducationProgram();

    const { weekly_curriculum_schedules } = educationProgramData;

    if (weekly_curriculum_schedules.length > 0) {
      const weekData = weekly_curriculum_schedules.find(
        (item: any) => item.week_nr === `${week}`
      );

      if (weekData) {
        const { id, topic, daily_curriculum_schedules } = weekData;

        const educationProgramEditData = {
          week_id: id,
          topic: topic || "",
          day_id: "",
          date: "",
          section: "",
          activity: "",
          work_tools: "",
        };

        if (daily_curriculum_schedules.length > 0) {
          const dayData = daily_curriculum_schedules.find(
            (item: any) => item.day === `${selectedDay}`
          );

          if (dayData) {
            const {
              id: dayId,
              date,
              topic: section,
              activity,
              work_tools,
            } = dayData;

            educationProgramEditData.day_id = dayId ? dayId : "";
            educationProgramEditData.date = date;
            educationProgramEditData.section = section || "";
            educationProgramEditData.activity = activity || "";
            educationProgramEditData.work_tools = work_tools || "";
          }
        }

        addEducationProgramEdit(educationProgramEditData);
      }
    }
  }, [week, selectedDay]);

  return (
    <div className="days-content mt-4">
      {!educationProgramEdit.date &&
        !educationProgramEdit.section &&
        !educationProgramEdit.activity &&
        !educationProgramEdit.work_tools && (
          <div className="education-info">
            {t("education_program_view_no_information_day")}
          </div>
        )}

      {educationProgramEdit.date && (
        <div className="row mb-4">
          <div className="form-block col-12 col-sm-3">
            <label htmlFor="checkbox2">
              {t("education_program_view_day_" + selectedDay)}
            </label>
            <div className="education-info">
              {moment(educationProgramEdit.date).format("DD-MM-YYYY")}
            </div>
          </div>
        </div>
      )}
      <div className="accordion custom-accordion">
        {Object.values(EditorType).map((editorType, index) =>
          educationProgramEdit[editorType] ? (
            <div className="accordion-custom-item" key={editorType}>
              <button
                className={`accordion-custom-button ${index === 0 ? "aqua" : index === 1 ? "green" : "violet"
                  } ${editorType} mb-3`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={"#" + editorType}
                aria-expanded="true"
                aria-controls={editorType}
              >
                {t(`education_program_view_accordion_${editorType}`)}
                <i className="icon arrow-down-icon" />
              </button>
              <div
                id={editorType}
                className={`accordion-collapse collapse ${index === 0 ? "show" : ""
                  } mb-5`}
              >
                {parse(educationProgramEdit[editorType])}
              </div>
            </div>
          ) : (
            ""
          )
        )}
      </div>
    </div>
  );
};

export default DaysContent;
