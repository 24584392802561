import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import CustomPagination from "src/helpers/CustomPaginate";
import PATHS from "src/routes/Paths";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import SearchFilter from "src/helpers/SearchFilter";
import ListResponse from "src/helpers/ListResponse";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import Loading from "src/helpers/Loading";
import useSearchSubmit from "src/hooks/useSearchSubmit";

const KindergartenNurseryList: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Kindergartens
  const [kindergartens, setKindergartens] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);

  // Search
  const {
    searchInputs,
    searchBtnLoading,
    setSearchBtnLoading,
    handleSubmit,
    setPage,
  } = useSearchSubmit();
  const [filter, setFilter] = useState("newest_first");
  const [text, setText] = useState("");
  const [specification, setSpecification] = useState("");


  const getKindergartens = async (): Promise<void> => {
    var searchParams: any = searchInputs;

    searchInputs.pagination = "yes";
    searchInputs.filter = filter;

    let request: any = API_PATHS.listKindergartens;

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setKindergartens(response.kindergartens);
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    } finally {
      setSearchBtnLoading(false);
    }
  };

  useEffect(() => {
    getKindergartens();
  }, [searchInputs, filter]);

  return (
    <>
      <div className="input-items">
        <form onSubmit={(e) =>
          handleSubmit(e, {
            ...(text && { text: text }),
            ...(specification && { specification: specification }),
          })
        } className="row mb-4">
          <div className="form-block col-12 col-sm-6 col-md-3">
            <label>{t("kindergarten_nursery_search_by_term")}</label>
            <input
              placeholder={t("kindergarten_nursery_search")}
              className="form-input"
              name="text"
              type="text"
              onChange={(e) => setText(e.target.value)}
            />
          </div>
          <div className="form-block col-12 col-sm-6 col-md-3">
            <label>{t("kindergarten_nursery_choose_option")}</label>
            <Select
              placeholder={t("kindergarten_nursery_choose")}
              className="form-react-select"
              classNamePrefix="select_input"
              name="specification"
              onChange={(e) =>
                e ? setSpecification(e.value) : setSpecification("")
              }
              isClearable={true}
              isSearchable={false}
              options={[
                { value: "address", label: t("kindergarten_nursery_address") },
                { value: "name", label: t("kindergarten_nursery_name") },
              ]}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-3 mb-1 mt-4">
            {searchBtnLoading ? (
              <button type="button" className="button-orange has-icon">
                <i className="icon spinner-icon" />
                {t("kindergarten_searching")}
              </button>
            ) : (
              <button type="submit" className="button-orange has-icon">
                <i className="icon search-icon" />
                {t("kindergarten_nursery_search")}
              </button>
            )}
          </div>
        </form>
      </div>
      <div className="input-items">
        <label>{t("kindergarten_nursery_filter")}</label>
        <div className="row">
          <div className="form-block col-12 col-sm-6 col-md-3 pb-4">
            <Select
              className={"select-sort"}
              classNamePrefix="select_input"
              value={
                filter
                  ? {
                    value: filter,
                    label: `Sort By: ${filter === "newest_first" ? "Newest" : "Oldest"
                      } First`,
                  }
                  : null
              }
              options={[
                {
                  value: "newest_first",
                  label: t("kindergarten_nursery_sort_by_newest_first"),
                },
                {
                  value: "oldest_first",
                  label: t("kindergarten_nursery_sort_by_oldest_first"),
                },
              ]}
              onChange={(e) =>
                e ? setFilter(e.value) : setFilter("newest_first")
              }
            />
          </div>
        </div>
      </div>
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                style={{ width: "15%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon kindergarten-table-icon" />
                {t("kindergarten_nursery_name")}
              </th>
              <th
                style={{ width: "17%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon address-icon" />
                {t("kindergarten_nursery_address")}
              </th>
              <th
                style={{ width: "17%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon telephone-icon" />
                {t("kindergarten_nursery_mobile")}
              </th>
              <th
                style={{ width: "17%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon email-icon" />
                {t("kindergarten_nursery_email")}
              </th>
              <th
                style={{ width: "17%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon website-icon" />
                {t("kindergarten_nursery_website")}
              </th>
              <th
                style={{ width: "17%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon actions-icon" />
                {t("kindergarten_nursery_actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={6}>
                  <Loading />
                </td>
              </tr>
            ) : (
              kindergartens.data.map((kindergarten: any) => (
                <tr key={kindergarten.id}>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("kindergarten_nursery_name")}
                  >
                    <div className="kindergarten-info">
                      <span className="kindergarten-number">
                        {kindergarten.number ? kindergarten.number : "-"}
                      </span>
                      <span>{kindergarten.name ? kindergarten.name : "-"}</span>
                    </div>
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("kindergarten_address")}
                  >
                    {kindergarten.address ? kindergarten.address : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("kindergarten_mobile")}
                  >
                    {kindergarten.phone ? kindergarten.phone : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-center blue-text"
                    data-label={t("kindergarten_email")}
                  >
                    {kindergarten.email ? kindergarten.email : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("kindergarten_website")}
                  >
                    {kindergarten.website ? (
                      <a
                        href={kindergarten.website}
                        className="text-decoration-none"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {kindergarten.website}
                      </a>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("kindergarten_actions")}
                  >
                    <div className="actions">
                      <button
                        type="button"
                        className="button-unstyled-icon"
                        onClick={() =>
                          navigate(
                            PATHS.showKindergartenNursery + kindergarten.id
                          )
                        }
                      >
                        <i className="icon show-icon" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={kindergartens?.data?.length}
          loading={loading}
        />
      </div>
      <CustomPagination
        data={kindergartens}
        setActivePage={(e) => setPage(e)}
      />
    </>
  );
};

export default KindergartenNurseryList;
