import React, { useEffect, useState } from "react";
import { makeApiCall } from "src/api/apiRequests";
import { useParams } from "react-router-dom";
import FoodNav from "./FoodNav";
import FoodList from "./FoodList";
import FoodNotes from "./FoodNotes";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import moment from "moment";
import SearchFilter from "src/helpers/SearchFilter";

const Food: React.FC = () => {
  const { childId } = useParams();

  const [assortments, setAssortments] = useState({});
  const [name, setName] = useState<string | null>(null);
  const [foodNote, setFoodNote] = useState<any>("");
  const [modified, setModified] = useState(false);

  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const fetchFood = async (): Promise<void> => {
    setModified(false);
    setLoading(true);
    let searchParams: any = {
      date: moment(selectedDate).format("YYYY-MM-DD"),
    };
    let request: any = API_PATHS.getChildFood(childId);

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      const { daily_child_food } = response;

      if (daily_child_food) {
        setFoodNote(
          daily_child_food.food_notes ? daily_child_food.food_notes : ""
        );
        setName(daily_child_food.name);
        setAssortments(daily_child_food.assortments);
      }

      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
    }
  };

  useEffect(() => {
    if (selectedDate) {
      fetchFood();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (modified) {
      fetchFood();
    }
  }, [modified]);

  return (
    <div className="child-food">
      <FoodNav
        setSelectedDate={(e) => setSelectedDate(e)}
        date={selectedDate}
      />
      <div className="child-food-content row">
        <FoodList assortments={assortments} name={name} loading={loading} />
        <FoodNotes
          foodNote={foodNote}
          assortmentsLength={Object.values(assortments).length}
          loading={loading}
          setModified={(e) => setModified(e)}
          date={selectedDate}
        />
      </div>
    </div>
  );
};

export default Food;
