import { useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import ValidationMessage from "src/helpers/ValidationMessage";
import { useParams } from "react-router-dom";
import moment from "moment";

interface AddNoteProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  setModified: (visible: boolean) => void;
  date: Date;
}

const AddNote: React.FC<AddNoteProps> = ({
  onShowModal,
  setShowModal,
  setModified,
  date,
}) => {
  const { t } = useTranslation();
  const { childId } = useParams();

  const [notes, setNotes] = useState<string>("");
  const [loadingButton, setLoadingButton] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const onClose = () => {
    setShowModal(false);
  };

  const addNote = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setLoadingButton(true);

    let formData = {
      child_id: childId,
      comment: notes,
      comment_date: moment(date).format("YYYY-MM-DD"),
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.storeChildFoodNote,
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);

      setModified(true);

      onClose();
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        onClose();
      }
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <Modal isOpen={onShowModal} toggle={() => onClose()} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("child_food_note_add_modal_title")}
      </ModalHeader>
      <form onSubmit={(e) => addNote(e)}>
        <ModalBody>
          <div className="modal-note">
            {t("child_food_note_add_modal_desc")}
          </div>
          <div className="form-block">
            <label className="required">
              {t("child_food_note_add_modal_comment_label")}
            </label>
            <textarea
              name="comment"
              rows={6}
              placeholder={t("child_food_note_add_modal_comment_placeholder")}
              value={notes}
              className={
                "form-input" +
                (validations.comment || validations.comment_date
                  ? " input-error"
                  : "")
              }
              autoComplete="off"
              onChange={(e) => setNotes(e.target.value)}
            />
            {(validations.comment || validations.comment_date) && (
              <ValidationMessage
                message={
                  (validations.comment && validations.comment[0]) ||
                  (validations.comment_date && validations.comment_date)
                }
              />
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("child_food_note_add_modal_close_button")}
          </button>

          {loadingButton ? (
            <button type="button" className="button-blue has-icon">
              <i className="spinner-icon" />
              {t("child_food_note_add_modal_saving_button")}
            </button>
          ) : (
            <button type="submit" className="button-blue has-icon">
              <i className="icon edit-icon" />
              {t("child_food_note_add_modal_save_button")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddNote;
