import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { EducationProgramData } from "src/store/education_program/EducationProgramData";
import Breadcrumb from "src/components/layouts/Common/Breadcrumb";
import Sidebar from "src/components/layouts/sidebar/Sidebar";
import EditEducationProgram from "src/components/panel/education_programs/list/education-program/edit-education-program/EditEducationProgram";

const EducationProgramEditView: React.FC = () => {
  const { t } = useTranslation();

  const educationProgramData = EducationProgramData(
    (state: any) => state.educationProgramData
  );

  return (
    <>
      <Helmet>
        <title>
          {educationProgramData.name
            ? educationProgramData.name
            : t("education_program_edit_tab")}
        </title>
      </Helmet>
      <Sidebar>
        <Breadcrumb
          title={educationProgramData.name ? educationProgramData.name : ""}
          subtitle={
            educationProgramData.topic ? educationProgramData.topic : ""
          }
        />
        <EditEducationProgram />
      </Sidebar>
    </>
  );
};

export default EducationProgramEditView;
