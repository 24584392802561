import { useState, useRef, FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Select, { SingleValue } from "react-select";

import PATHS from "../../../routes/Paths";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import ValidationMessage from "src/helpers/ValidationMessage";
import ValidationMessageKindergarten from "src/helpers/ValidationMessageKindergarten";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import { useUserdata } from "src/store/UserData";

interface StoreAlbumPopup {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  setModified: (visible: boolean) => void;
}

const StoreAlbumPopup: React.FC<StoreAlbumPopup> = ({
  onShowModal,
  setShowModal,
  setModified,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation("");
  const userData = useUserdata((state: any) => state.userData);

  const [name, setName] = useState("");
  const [kindergartenId, setKindergartenId] = useState(
    userData.kindergarten.id
  );
  const [kindergartenGroupId, setKindergartenGroupId] = useState("");
  const [images, setImages] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [kindergartenGroup, setKindergartenGroup] = useState<
    Record<string, any>[]
  >([]);

  // const [modified, setModified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const storeAlbum = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setBtnLoading(true);

    const formData = new FormData();
    formData.append("name", name);
    formData.append("kindergarten_id", kindergartenId);
    formData.append("kindergarten_group_id", kindergartenGroupId);
    images.forEach((image, index) => {
      formData.append(`images[]`, image);
    });

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.storeAlbum,
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);
      navigate(PATHS.gallery);
      onClose();
      setModified(true);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data.errors);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
      }
      setBtnLoading(false);
    }
  };

  const handleUploadButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const getKindergartenGroup = async (): Promise<void> => {
    let request: any =
      API_PATHS.kindergartenGrouplist + "?/kindergarten_id=" + kindergartenId;
    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setValidations({});
      setKindergartenGroup(response.kindergartensgroups);
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      setBtnLoading(false);
      navigate(-1);
    }
  };

  useEffect(() => {
    getKindergartenGroup();
  }, []);

  const onClose = () => {
    setShowModal(false);
    setModified(false);
  };

  const handleRemoveImage = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  return (
    <Modal isOpen={onShowModal} toggle={() => onClose()} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("store_album_add_album")}
      </ModalHeader>
      <form onSubmit={(e) => storeAlbum(e)}>
        <ModalBody>
          <div className="modal-note">{t("store_album_description")}</div>
          <p className="popup_title mb-4">
            {t("store_album_popup_description")}
          </p>
          <div className="row">
            <div className="form-block col-12 col-md-6 col-sm-6">
              <label className="required">{t("store_album_name")}</label>
              <input
                name="comment"
                type="text"
                className={
                  "form-input" + (validations.name ? " input-error" : "")
                }
                value={name}
                placeholder={t("store_album_name")}
                onChange={(e) => setName(e.target.value)}
              />
              {validations.name && (
                <ValidationMessage message={validations.name[0]} />
              )}
            </div>
            <div className="form-block col-12 col-md-6 col-sm-6">
              <label className="required">{t("store_album_group")}</label>
              <Select
                placeholder={t("store_album_group")}
                className={
                  "form-react-select" +
                  (validations.kindergarten_group_id ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="specification"
                onChange={(e) =>
                  e
                    ? setKindergartenGroupId(e.value)
                    : setKindergartenGroupId("")
                }
                isClearable={true}
                isSearchable={false}
                options={kindergartenGroup.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
              {validations.kindergarten_group_id && (
                <ValidationMessage
                  message={validations.kindergarten_group_id[0]}
                />
              )}
            </div>
          </div>
          <p className="popup_title mb-4">{t("store_album_images")}</p>
          <div className="form-block col-12 col-md-12 col-sm-6">
            <label>{t("store_album_images")}</label>
            <div className="upload-file-box">
              <div
                className={
                  "form-input" + (validations.images ? " input-error" : "")
                }
              >
                <>
                  <input
                    className="upload-input"
                    onChange={(e) => {
                      const selectedFiles = e.target.files;
                      if (selectedFiles && selectedFiles.length > 0) {
                        setImages((prevImages) => [
                          ...prevImages,
                          ...Array.from(selectedFiles),
                        ]);
                      }
                    }}
                    accept="image/*"
                    name="image"
                    type="file"
                    multiple
                  />

                  <div className="upload-input-placeholder">
                    <i className="icon upload-icon" />
                  </div>
                  <span className="popup_title">
                    {t("create_kindergarten_upload_instructions")}
                  </span>
                  <span className="popup_description">
                    {t("create_kindergarten_limitations")}
                  </span>
                </>
              </div>
              {validations.images && (
                <ValidationMessageKindergarten
                  message={validations["images"][0]}
                />
              )}
              <div className="sort-upload row mt-4">
                {images.length > 0 &&
                  images.map((image, index) => (
                    <>
                      <div className="upload-area">
                      <button
                            className="delete-image-button"
                            onClick={(e) => {
                              e.preventDefault();
                              handleRemoveImage(index);
                            }}
                          >
                            <i className="icon close-icon" />
                          </button>
                        <img
                          key={index}
                          src={URL.createObjectURL(image)}
                          alt={`Image ${index}`}
                        />
                      </div>
                    </>
                  ))}
                <>
                  <div className="upload-area">
                    <input
                      className="upload-input"
                      onChange={(e) => {
                        const selectedFiles = e.target.files;
                        if (selectedFiles && selectedFiles.length > 0) {
                          setImages((prevImages) => [
                            ...prevImages,
                            ...Array.from(selectedFiles),
                          ]);
                        }
                      }}
                      accept="image/*"
                      name="image"
                      type="file"
                      multiple
                    />
                    <span>
                      <i className="icon upload-plus-icon" />
                    </span>
                    <span>{t("create_kindergarten_upload")}</span>
                  </div>
                </>
              </div>
              <div className="upload-buttons mt-4">
                <button
                  className="has-icon button-red delete mb-1 mt-1"
                  onClick={(e) => {
                    e.preventDefault();
                    setImages([]);
                  }}
                >
                  <i className="icon delete-icon" />
                  {t("create_kindergarten_delete")}
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("store_album_close")}
          </button>
          {btnLoading ? (
            <button className="button-blue has-icon" type="button">
              <i className="icon spinner-icon" />
              {t("store_album_saving")}
            </button>
          ) : (
            <button className="button-blue has-icon" type="submit">
              <i className="icon plus-icon" />
              {t("store_album_save")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default StoreAlbumPopup;
