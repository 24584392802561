import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { SingleValue } from "react-select";
import { makeApiCall } from "src/api/apiRequests";
import { useNavigate } from "react-router-dom";
import { evaluations } from "./data";
import { LanguageDate } from "src/helpers/CheckLanguageDate";
import DatePicker from "react-datepicker";
import API_PATHS from "src/api/apiPaths";
import SearchFilter from "src/helpers/SearchFilter";
import API_HEADERS from "src/api/apiConfig";
import CustomPagination from "src/helpers/CustomPaginate";
import ListResponse from "src/helpers/ListResponse";
import Loading from "src/helpers/Loading";
import AddNewEvaluation from "./AddNewEvaluation";
import EditEvaluation from "./EditEvaluation";
import ViewPerformance from "./ViewPerformance";
import EvaluationStars from "./EvaluationStars";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import moment from "moment";
import DatePickerButton from "src/components/common/DatePickerButton";
import ValidationMessage from "src/helpers/ValidationMessage";
import useSearchSubmit from "src/hooks/useSearchSubmit";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";

const DirectorPsychologistPerformance: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Teachers
  const [teachersList, setTeachersList] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  // Search
  const {
    searchInputs,
    searchBtnLoading,
    setSearchBtnLoading,
    handleSubmit,
    setPage,
  } = useSearchSubmit();
  const [searchTeacher, setSearchTeacher] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [searchEvaluation, setSearchEvaluation] =
    useState<SingleValue<{ value: number; text?: string; icon: JSX.Element }>>(
      null
    );
  const [searchDate, setSearchDate] = useState<any>(new Date());
  const [validations, setValidations] = useState<Record<string, string>>({});

  // Modals
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showViewModal, setShowViewModal] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number | null>(null);

  const [teachers, setTeachers] = useState<any[]>([]);

  const fetchEvaluations = async (

  ): Promise<void> => {
    const searchParams: any = searchInputs;
    searchParams.date_of_evaluation = moment(searchDate).format("yyyy-MM-DD");

    const request: any = SearchFilter(
      searchParams,
      API_PATHS.evaluationTeachersList
    );

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      setTeachers(
        response.teacher_options.map((teacher: any) => ({
          value: teacher.id,
          label: `${teacher.first_name} ${teacher.last_name}`,
        }))
      );
      setTeachersList(response.teachers);
      setValidations({});
      setLoading(false);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
        setLoading(true);
        setTeachersList([]);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        navigate(-1);
      }
    } finally {
      setSearchBtnLoading(false);
    }
  };

  useEffect(() => {
    fetchEvaluations();
  }, [searchInputs]);

  const deletePerformance = (performanceId: number, performanceTitle: string) => {
    ConfirmPopup.deletePopup(
      t("performance_evaluation_delete_title"),
      t("performance_evaluation_delete_type"),
      performanceTitle
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.teacherPerformanceDelete(performanceId),
            "DELETE",
            API_HEADERS.authenticated
          );
          fetchEvaluations();
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  return (
    <>
      <form onSubmit={(e) =>
        handleSubmit(e, {
          ...(searchTeacher && { teacher_id: searchTeacher.value }),
          ...(searchEvaluation && { score: searchEvaluation.value }),
        })
      } className="row mb-4">
        <div className="form-block col-12 col-sm-6 col-md-3 col-xxl-2">
          <label>{t("performance_search_teacher_label")}</label>
          <Select
            placeholder={t("performance_search_teacher_placeholder")}
            className="form-react-select"
            classNamePrefix="select_input"
            name="month"
            onChange={(e) => setSearchTeacher(e)}
            isClearable={true}
            isSearchable={false}
            options={teachers}
            value={searchTeacher}
          />
        </div>
        <div className="form-block col-12 col-sm-6 col-md-3 col-xxl-2">
          <label>{t("performance_search_evalation_label")}</label>
          <Select
            placeholder={t("performance_search_evalation_placeholder")}
            className="form-react-select"
            classNamePrefix="select_input"
            isClearable={true}
            isSearchable={false}
            value={searchEvaluation}
            options={evaluations}
            onChange={(e) => setSearchEvaluation(e)}
            getOptionLabel={(e: any) => e.icon}
          />
        </div>
        <div className="form-block col-12 col-sm-6 col-md-3 col-xxl-2">
          <label>{t("evaluations_list_search_date_label")}</label>
          <DatePicker
            selected={searchDate}
            onChange={(e) => setSearchDate(e)}
            showMonthDropdown
            showYearDropdown
            dateFormat="dd MMM, yyyy"
            isClearable
            maxDate={new Date()}
            customInput={
              <DatePickerButton
                otherClass="simple-input"
                errorClass={validations.date_of_evaluation}
              />
            }
            placeholderText={t("evaluations_list_search_date_placeholder")}
            className={
              "form-datepicker" +
              (validations.date_of_evaluation ? " input-error" : "")
            }
            locale={LanguageDate.datepicker}
            autoComplete="off"
            name="date_of_evaluation"
          />
          {validations.date_of_evaluation && (
            <ValidationMessage message={validations.date_of_evaluation[0]} />
          )}
        </div>
        <div className="col-12 col-sm-6 col-md-3 col-xxl-2 mb-1">
          {searchBtnLoading ? (
            <button type="button" className="mt-4 button-orange has-icon">
              <i className="icon spinner-icon" />
              {t("performance_search_button_searching")}
            </button>
          ) : (
            <button type="submit" className="mt-4 button-orange has-icon">
              <i className="icon search-icon" />
              {t("performance_search_button_search")}
            </button>
          )}
        </div>
      </form>
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                style={{ width: "25%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon teacher-icon" />
                {t("performance_table_fistname_lastname")}
              </th>
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon telephone-icon" />
                {t("performance_table_contact")}
              </th>
              <th
                style={{ width: "25%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon comment-icon" />
                {t("performance_table_comments")}
              </th>
              <th
                style={{ width: "15%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon evaluation-icon" />
                {t("performance_table_evaluations")}
              </th>
              <th
                style={{ width: "15%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon actions-icon" />
                {t("performance_table_actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={5}>
                  <Loading />
                </td>
              </tr>
            ) : (
              teachersList?.data?.map((teacher: any) => (
                <tr key={teacher.id}>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("performance_table_fistname_lastname")}
                  >
                    {`${teacher.first_name} ${teacher.last_name}`}
                  </td>
                  <td
                    role="cell"
                    className="text-left"
                    data-label={t("performance_table_contact")}
                  >
                    <div className="d-flex flex-column">
                      <span>{teacher.phone}</span>
                      <span className="blue-text">{teacher.email}</span>
                    </div>
                  </td>
                  <td
                    role="cell"
                    className="text-left"
                    data-label={t("performance_table_comments")}
                  >
                    {teacher.evaluation && teacher.evaluation.evaluation_comment
                      ? teacher.evaluation.evaluation_comment.length > 100
                        ? teacher.evaluation.evaluation_comment.substring(
                          0,
                          100
                        ) + "..."
                        : teacher.evaluation.evaluation_comment
                      : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-left"
                    data-label={t("performance_table_evaluations")}
                  >
                    {teacher.evaluation ? (
                      <EvaluationStars
                        evaluationNr={teacher.evaluation.evaluation_score}
                      />
                    ) : (
                      "-"
                    )}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("performance_table_actions")}
                  >
                    <div className="actions">
                      {teacher.evaluation ? (
                        <>
                          <button
                            className="button-unstyled-icon"
                            onClick={() => {
                              setShowEditModal(true);
                              setModalId(teacher.id);
                            }}
                          >
                            <i className="icon edit-icon" />
                          </button>
                          {showEditModal && modalId === teacher.id && (
                            <EditEvaluation
                              onShowModal={showEditModal}
                              setShowModal={(e) => {
                                setShowEditModal(e);
                                setModalId(null);
                              }}
                              performanceId={teacher.evaluation.id}
                              setModified={() => fetchEvaluations()}
                              teachers={teachers}
                            />
                          )}
                          <button
                            className="button-unstyled-icon"
                            onClick={() => {
                              setShowViewModal(true);
                              setModalId(teacher.id);
                            }}
                          >
                            <i className="icon show-icon" />
                          </button>
                          {showViewModal && modalId === teacher.id && (
                            <ViewPerformance
                              onShowModal={showViewModal}
                              setShowModal={(e) => {
                                setShowViewModal(e);
                                setModalId(null);
                              }}
                              performanceId={teacher.evaluation.id}
                            />
                          )}
                          <button
                            className="button-unstyled-icon"
                            onClick={() =>
                              deletePerformance(
                                teacher.evaluation.id,
                                teacher.title
                              )
                            }
                          >
                            <i className="icon delete-icon" />
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="button-unstyled-icon"
                            onClick={() => {
                              setShowAddModal(true);
                              setModalId(teacher.id);
                            }}
                          >
                            <i className="icon plus-bordered-icon"></i>
                          </button>
                          {showAddModal && modalId === teacher.id && (
                            <AddNewEvaluation
                              onShowModal={showAddModal}
                              setShowModal={(e) => {
                                setShowAddModal(e);
                                setModalId(null);
                              }}
                              setModified={() => fetchEvaluations()}
                              dateOfEvaluation={searchDate}
                              teacherId={teacher.id}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={teachersList?.data?.length}
          loading={loading}
        />
      </div>
      <CustomPagination
        data={teachersList}
        setActivePage={(e) => setPage(e)}
      />
    </>
  );
};

export default DirectorPsychologistPerformance;
