import { t } from "i18next";

export const ageGroups = [
  { value: "6-8months", label: t("meal_data_age_group_6_8_months") },
  { value: "8-10months", label: t("meal_data_age_group_8_10_months") },
  { value: "10-12months", label: t("meal_data_age_group_10_12_months") },
  { value: "1-3years", label: t("meal_data_age_group_1_3_years") },
  { value: "3-6years", label: t("meal_data_age_group_3_6_years") },
];

export const days = [
  { value: "monday", label: t("meal_data_day_monday") },
  { value: "tuesday", label: t("meal_data_day_tuesday") },
  { value: "wednesday", label: t("meal_data_day_wednesday") },
  { value: "thursday", label: t("meal_data_day_thursday") },
  { value: "friday", label: t("meal_data_day_friday") },
];

export const meals = [
  { value: "breakfast", label: t("meal_data_breakfast") },
  { value: "lunch_1", label: t("meal_data_lunch1") },
  { value: "lunch_2", label: t("meal_data_lunch2") },
  { value: "afternoon_snack", label: t("meal_data_afternoon_snack") },
];

export const seasons = [
  { value: "spring-summer", label: t("meal_data_season_spring_summer") },
  { value: "autum-winter", label: t("meal_data_season_autum_winter") },
  { value: "winter-summer", label: t("meal_data_season_winter_summer") },
];
