import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeApiCall } from "src/api/apiRequests";
import { useNavigate } from "react-router-dom";
import { RoleCheck } from "src/helpers/RoleCheck";
import ReactChart from "src/components/common/ReactChart";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import Loading from "src/helpers/Loading";

const GuardianDashboard: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<number>(0);

  const [childrenData, setChildrenData] = useState<any[]>([]);
  const [dashboardLoading, setDashboardLoading] = useState<boolean>(true);

  const fetchReport = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.guardianDashboard,
        "GET",
        API_HEADERS.authenticated
      );
      response.children_data.length > 0 &&
        setActiveTab(response.children_data[0].id);
      setChildrenData(response.children_data);
      setDashboardLoading(false);
    } catch (error: any) {
      if (error.response.status === 403) {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        navigate(-1);
      }
    }
  };

  const findMonth = (month: string) => {
    switch (month) {
      case "01":
        return t("dashboard_guardian_absences_january");
      case "02":
        return t("dashboard_guardian_absences_february");
      case "03":
        return t("dashboard_guardian_absences_march");
      case "04":
        return t("dashboard_guardian_absences_april");
      case "05":
        return t("dashboard_guardian_absences_may");
      case "06":
        return t("dashboard_guardian_absences_june");
      case "07":
        return t("dashboard_guardian_absences_july");
      case "08":
        return t("dashboard_guardian_absences_august");
      case "09":
        return t("dashboard_guardian_absences_september");
      case "10":
        return t("dashboard_guardian_absences_october");
      case "11":
        return t("dashboard_guardian_absences_november");
      case "12":
        return t("dashboard_guardian_absences_december");
    }
  };

  useEffect(() => {
    RoleCheck("1") && fetchReport();
  }, []);

  const selectedChild = childrenData.find((child) => child.id === activeTab);

  return (
    <>
      {dashboardLoading ? (
        <div className="text-center">
          <Loading />
        </div>
      ) : childrenData.length > 0 ? (
        <div className="customized-tabs">
          <div className="blue-tabs mt-4">
            <div className="tab-buttons mb-4">
              {childrenData.map((child) => (
                <button
                  key={child.id}
                  className={`tab-button${
                    activeTab === child.id ? " active" : ""
                  }`}
                  onClick={() => setActiveTab(child.id)}
                >
                  {`${child.first_name} ${child.last_name}`}
                </button>
              ))}
            </div>

            <div className="tab-content">
              <div className="row">
                <div className="col-12 col-sm-3">
                  <div className="card second mb-4">
                    <div className="card-body">
                      <div className="general-info">
                        <span className="gen-title">
                          {t("dashboard_guardian_kindergarten_or_nursery")}
                        </span>
                        <span className="info">
                          {selectedChild.kindergarten.kindergarten_name}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-3">
                  <div className="card second mb-4">
                    <div className="card-body">
                      <div className="general-info">
                        <span className="gen-title">
                          {t("dashboard_guardian_type")}
                        </span>
                        <span className="info">
                          {selectedChild.kindergarten.kindergarten_type ===
                          "kopesht"
                            ? t("dashboard_guardian_type_kindergarten")
                            : t("dashboard_guardian_type_nursery")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-3">
                  <div className="card second mb-4">
                    <div className="card-body">
                      <div className="general-info">
                        <span className="gen-title">
                          {t("dashboard_guardian_total_children")}
                        </span>
                        <span className="info">
                          {selectedChild.kindergarten.total_children}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-3">
                  <div className="card second mb-4">
                    <div className="card-body">
                      <div className="general-info">
                        <span className="gen-title">
                          {t("dashboard_guardian_max_children")}
                        </span>
                        <span className="info">
                          {selectedChild.kindergarten.max_children_nr}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-4">
                  <div className="card second mb-4">
                    <div className="card-body">
                      <div className="card-title">
                        {t("dashboard_guardian_children_gender_pie")}
                      </div>
                      <ReactChart
                        type="donut"
                        series={[
                          selectedChild.kindergarten.male_children,
                          selectedChild.kindergarten.female_children,
                        ]}
                        otherOptions={{
                          legend: {
                            position: "bottom",
                          },
                          labels: [
                            t("dashboard_guardian_boys"),
                            t("dashboard_guardian_girls"),
                          ],
                          colors: ["#3d6de0", "#ab56d4"],
                          chart: {
                            height: 450,
                            id: "basic-bar",
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-8">
                  <div className="card second mb-4">
                    <div className="card-body">
                      <div className="card-title">
                        {t("dashboard_guardian_absences_nr")}
                        <ReactChart
                          type="bar"
                          series={[
                            {
                              name: "Total",
                              color: "#ffb020",
                              data: [
                                selectedChild.absences[0].total_absences,
                                selectedChild.absences[1].total_absences,
                                selectedChild.absences[2].total_absences,
                                selectedChild.absences[3].total_absences,
                                selectedChild.absences[4].total_absences,
                                selectedChild.absences[5].total_absences,
                              ],
                            },
                          ]}
                          otherOptions={{
                            chart: {
                              id: "basic-bar",
                              zoom: {
                                enabled: false,
                              },
                            },
                            dataLabels: {
                              enabled: false,
                            },
                            legend: {
                              show: false,
                            },
                            xaxis: {
                              categories: [
                                findMonth(selectedChild.absences[0].month),
                                findMonth(selectedChild.absences[1].month),
                                findMonth(selectedChild.absences[2].month),
                                findMonth(selectedChild.absences[3].month),
                                findMonth(selectedChild.absences[4].month),
                                findMonth(selectedChild.absences[5].month),
                              ],
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        "No children data"
      )}
    </>
  );
};

export default GuardianDashboard;
