import { useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import ValidationMessage from "src/helpers/ValidationMessage";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

interface UpdateAbsenceProps {
  onShowModal: boolean;
  absenceId: string;
  reason: string | undefined;
  setShowModal: (visible: boolean) => void;
  setModified: (visible: boolean) => void;
}

const UpdateAbsence: React.FC<UpdateAbsenceProps> = ({
  onShowModal,
  absenceId,
  reason,
  setShowModal,
  setModified,
}) => {
  const { t } = useTranslation();
  const [note, setNote] = useState(reason);

  const [loadingButton, setLoadingButton] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const onClose = () => {
    setShowModal(false);
  };

  const updateAbsence = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setLoadingButton(true);

    let formData = {
      absence_reason: note,
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.updateAbsence(absenceId),
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);

      setModified(true);
      onClose();
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        onClose();
      }
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <Modal isOpen={onShowModal} toggle={() => onClose()} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("child_participation_modal_edit_absence_title")}
      </ModalHeader>
      <form onSubmit={(e) => updateAbsence(e)}>
        <ModalBody>
          <div className="modal-note">
            {t("child_participation_modal_edit_absence_desc")}
          </div>
          <div className="row">
            <div className="form-block col-12">
              <label>
                {t("child_participation_modal_edit_absence_reason_label")}
              </label>
              <textarea
                placeholder={t(
                  "child_participation_modal_edit_absence_reason_placeholder"
                )}
                className={
                  "form-input" +
                  (validations.absence_reason ? " input-error" : "")
                }
                value={note}
                name="absence_reason"
                onChange={(e) => setNote(e.target.value)}
              />
              {validations.absence_reason && (
                <ValidationMessage message={validations.absence_reason[0]} />
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("child_participation_modal_edit_absence_close_button")}
          </button>

          {loadingButton ? (
            <button type="button" className="button-blue has-icon">
              <i className="spinner-icon" />
              {t("child_participation_modal_edit_absence_editing_button")}
            </button>
          ) : (
            <button type="submit" className="button-blue has-icon">
              {t("child_participation_modal_edit_absence_edit_button")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default UpdateAbsence;
