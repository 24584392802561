import { useState, useEffect, useRef, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import L from "leaflet";
import { icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import Select from "react-select";

import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import ValidationMessage from "src/helpers/ValidationMessage";
import ValidationMessageKindergarten from "src/helpers/ValidationMessageKindergarten";
import { kindergartenImages } from "src/helpers/AppConfig";
import PATHS from "src/routes/Paths";

interface EditNursery {
  onShowModal: boolean;
  setNurseryVisible: (visible: boolean) => void;
  kindergartenId: number;
  setModified: (visible: boolean) => void;
}

const EditNursery: React.FC<EditNursery> = ({
  onShowModal,
  setNurseryVisible,
  kindergartenId,
  setModified,
}) => {
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [latitude, setLatitude] = useState<number | string>(0);
  const [longitude, setLongitude] = useState<number | string>(0);
  const [type, setType] = useState("cerdhe");
  const [maxNumberChildren, setMaxNumberChildren] = useState("");
  const [loading, setLoading] = useState<boolean>(true);
  const [images, setImages] = useState<File[]>([]);
  const [imagesPath, setImagesPath] = useState<any>([]);
  const [newImages, setNewImages] = useState<File[]>([]);
  const [deleteImages, setDeleteImages] = useState<number[]>([]);
  const [showNewDirectorEmail, setShowNewDirectorEmail] = useState(false);
  const [showSelectDirectorInput, setShowSelectDirectorInput] = useState(false);
  const [directors, setDirectors] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});
  const fileInputRef = useRef<HTMLInputElement | any>(null);

  const handleUploadButtonClicked = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onClose = () => {
    setNurseryVisible(false);
  };

  const getNurseryData = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.showKindergarten(kindergartenId),
        "GET",
        API_HEADERS.authenticated
      );
      const blob = new Blob([response], { type: "application/image" });
      const url = URL.createObjectURL(blob);
      setLoading(false);

      setName(response.kindergarten.name);
      setNumber(response.kindergarten.number);
      setAddress(response.kindergarten.address);
      setPostalCode(response.kindergarten.postal_code);
      setPhone(response.kindergarten.phone);
      setEmail(response.kindergarten.email);
      setWebsite(response.kindergarten.website);
      const latitudeValue = Number(response.kindergarten.latitude) || 0;
      const longitudeValue = Number(response.kindergarten.longtitude) || 0;
      setLatitude(latitudeValue);
      setLongitude(longitudeValue);
      setMaxNumberChildren(response.kindergarten.max_number_children);
      setImagesPath(response.kindergarten.images);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      onClose();
    }
  };

  useEffect(() => {
    getNurseryData();
  }, []);

  const updateNursery = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setBtnLoading(true);

    const formData = new FormData();
    formData.append("type", type);
    formData.append("name", name);
    formData.append("number", number);
    formData.append("address", address);
    formData.append("postal_code", postalCode);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("website", website);
    formData.append("latitude", latitude.toString());
    formData.append("longtitude", longitude.toString());

    formData.append("max_number_children", maxNumberChildren);
    images.forEach((image, index) => {
      formData.append(`images[${index + imagesPath.length}]`, image);
    });
    deleteImages.forEach((imageId, index) => {
      formData.append(`delete_images[${index}]`, imageId.toString());
    });

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.updateKindergarten(kindergartenId),
        "POST",
        API_HEADERS.blob,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);

      setModified(true);

      onClose();
      navigate(PATHS.nursery);
      setBtnLoading(false);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data.errors);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);

        onClose();
      }
      setBtnLoading(false);
    }
  };

  const handleDeleteImage = (indexToDelete: number, imageId: number) => {
    if (imageId) {
      setDeleteImages((prevIds) => [...prevIds, imageId]);

      const updatedImagesPath = [...imagesPath];
      updatedImagesPath.splice(indexToDelete, 1);
      setImagesPath(updatedImagesPath);
    } else {
      const updatedNewImages = [...newImages];
      updatedNewImages.splice(indexToDelete, 1);
      setNewImages(updatedNewImages);

      const updatedImages = [...images];
      updatedImages.splice(indexToDelete, 1);
      setImages(updatedImages);
    }
  };

  const locations = [{ latitude, longitude }];

  const [markerPosition, setMarkerPosition] = useState<[number, number]>([
    typeof latitude === "number" ? latitude : 0,
    typeof longitude === "number" ? longitude : 0,
  ]);

  const MapClickHandler = () => {
    const map = useMapEvents({
      click: (e) => {
        const { lat, lng } = e.latlng;
        setMarkerPosition([lat, lng]);
        setLatitude(lat.toString());
        setLongitude(lng.toString());
      },
    });

    return null;
  };

  const markerColors = [
    "#74dde5",
    "#fac86b",
    "#678af7",
    "#6bdaae",
    "#ab56d4",
    "#fd8adc",
    "#70b0ff",
    "#9386f0",
    "#f1c448",
    "#6592f3",
    "#3d6de0",
    "#e3692c",
    "#ff9900",
    "#ff8c00",
    "#f29b30",
    "#ffdeb5",
    "#008000",
    "#ffc0cb",
    "#59bf80",
    "#cb3438",
  ];

  const createMarker = (location: any, index: number) => {
    const markerColor = markerColors[index % markerColors.length];

    const customIcon = icon({
      iconUrl: require("../../../assets/images/marker-icon.png"),
      iconSize: [20, 20],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      tooltipAnchor: [16, -28],
      className: `custom-marker border marker-color-${index}`,
    });

    const markerStyle = document.createElement("style");

    markerStyle.innerHTML = `
      .marker-color-${index} {
        background: ${markerColor};
      }
    `;
    document.head.appendChild(markerStyle);

    return (
      <Marker
        position={[Number(latitude), Number(longitude)]}
        key={location.id}
        icon={customIcon}
      ></Marker>
    );
  };

  return (
    <Modal
      isOpen={onShowModal && !loading}
      toggle={() => onClose()}
      size="xl"
      centered
    >
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("edit_nursery_add_nursery")}
      </ModalHeader>
      <form onSubmit={(e) => updateNursery(e)}>
        <ModalBody>
          <p className="mb-3 popup_subtitle">
            {t("edit_nursery_instructions")}
          </p>
          <p className="popup_title">{t("edit_nursery_main_info")}</p>
          <div className="d-flex border-bottom mb-5 gap">
            <div className="w-50 flex-column">
              <div className="row">
                <div className="form-block col-12 col-md-6 col-sm-6">
                  <label className="required">{t("edit_nursery_name")}</label>
                  <input
                    type="text"
                    name="name"
                    className={
                      "form-input" + (validations.name ? " input-error" : "")
                    }
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    placeholder={t("edit_nursery_name")}
                  />
                  {validations.name && (
                    <ValidationMessage message={validations.name[0]} />
                  )}
                </div>
                <div className="form-block col-12 col-md-6 col-sm-6">
                  <label className="required">
                    {t("edit_nursery_kindergarten_nr")}
                  </label>
                  <input
                    type="number"
                    name="number"
                    className={
                      "form-input" + (validations.number ? " input-error" : "")
                    }
                    onChange={(e) => setNumber(e.target.value)}
                    value={number}
                    placeholder={t("edit_nursery_kindergarten_nr")}
                  />
                  {validations.number && (
                    <ValidationMessage message={validations.number[0]} />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="form-block col-12 col-md-6 col-sm-6">
                  <label>{t("edit_nursery_email")}</label>
                  <input
                    type="email"
                    name="email"
                    className={
                      "form-input" + (validations.email ? " input-error" : "")
                    }
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder={t("edit_nursery_email")}
                  />
                  {validations.email && (
                    <ValidationMessage message={validations.email[0]} />
                  )}
                </div>
                <div className="form-block col-12 col-md-6 col-sm-6">
                  <label>{t("edit_nursery_phone_number")}</label>
                  <input
                    type="number"
                    name="phone"
                    className={
                      "form-input" + (validations.phone ? " input-error" : "")
                    }
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    placeholder={t("edit_nursery_phone_number")}
                  />
                  {validations.phone && (
                    <ValidationMessage message={validations.phone[0]} />
                  )}
                </div>
              </div>
              <div className="row mb-5">
                <div className="form-block col-12 col-md-6 col-sm-6">
                  <label>{t("edit_nursery_website")}</label>
                  <input
                    type="text"
                    name="website"
                    className={
                      "form-input" + (validations.website ? " input-error" : "")
                    }
                    onChange={(e) => setWebsite(e.target.value)}
                    value={website}
                    placeholder={t("edit_nursery_website")}
                  />
                  {validations.website && (
                    <ValidationMessage message={validations.website[0]} />
                  )}
                </div>
                <div className="form-block col-12 col-md-6 col-sm-6">
                  <label className="required">
                    {t("edit_nursery_max_number_children")}
                  </label>
                  <input
                    type="number"
                    name="max"
                    className={
                      "form-input" +
                      (validations.max_number_children ? " input-error" : "")
                    }
                    onChange={(e) => setMaxNumberChildren(e.target.value)}
                    value={maxNumberChildren}
                    placeholder={t("edit_nursery_max_number_children")}
                  />
                  {validations.max_number_children && (
                    <ValidationMessage
                      message={validations.max_number_children[0]}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="w-50 mb-5">
              <div className="form-block col-12 col-md-12 col-sm-6">
                <label>{t("edit_nursery_images")}</label>
                <div className="upload-file-box">
                  <div
                    className={
                      "form-input" +
                      (validations.images ? " input-error" : "")
                    }
                  >
                    <>
                      <input
                        className="upload-input"
                        onChange={(e) => {
                          const selectedFiles = e.target.files;
                          if (selectedFiles && selectedFiles.length > 0) {
                            setImages((prevImages) => [
                              ...prevImages,
                              ...Array.from(selectedFiles),
                            ]);
                          }
                        }}
                        accept="image/*"
                        name="image"
                        type="file"
                        multiple
                      />
                      <div className="upload-input-placeholder">
                        <i className="icon upload-icon" />
                      </div>
                      <span className="popup_title">
                        {t("edit_nursery_upload_instructions")}
                      </span>
                      <span className="popup_description">
                        {t("edit_nursery_limitations")}
                      </span>
                    </>
                  </div>
                  {validations.images && (
                    <ValidationMessageKindergarten
                      message={validations["images"][0]}
                    />
                  )}
                  <div className="sort-upload row mt-4">
                    {imagesPath.length > 0 &&
                      imagesPath.map((image: any, index: number) => (
                        <div className="upload-area" key={index}>
                          <button
                            className="delete-image-button"
                            onClick={(e) => {
                              e.preventDefault();
                              handleDeleteImage(index, image.id);
                            }}
                          >
                            <i className="icon close-icon" />
                          </button>
                          <img
                            src={kindergartenImages + image.path}
                            alt={`Image ${index}`}
                          />
                        </div>
                      ))}
                    {images.length > 0 &&
                      images.map((image: any, index: number) => (
                        <>
                          <div className="upload-area">
                            <button
                              className="delete-image-button"
                              onClick={(e) => {
                                e.preventDefault();
                                handleDeleteImage(index, image.id);
                              }}
                            >
                              <i className="icon close-icon" />
                            </button>
                            <img
                              key={index}
                              src={URL.createObjectURL(image)}
                              alt={`Image ${index}`}
                            />
                          </div>
                        </>
                      ))}
                    <>
                      {imagesPath.length + images.length < 5 && (
                        <div className="upload-area">
                          <input
                            className="upload-input"
                            onChange={(e) => {
                              const selectedFiles = e.target.files;
                              if (selectedFiles && selectedFiles.length > 0) {
                                setImages((prevImages) => [
                                  ...prevImages,
                                  ...Array.from(selectedFiles),
                                ]);
                              }
                            }}
                            accept="image/*"
                            name="image"
                            type="file"
                            multiple
                          />
                          <span>
                            <i className="icon upload-plus-icon" />
                          </span>
                          <span>{t("edit_nursery_upload")}</span>
                        </div>
                      )}
                    </>
                  </div>
                  <div className="upload-buttons mt-4">
                    <button
                      className="has-icon button-white me-2 mb-1 mt-1"
                      onClick={(e) => handleUploadButtonClicked(e)}
                    >
                      <i className="icon reader-icon" />
                      {t("edit_nursery_browse")}
                    </button>
                    <input
                      ref={fileInputRef}
                      className="upload-input"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        const selectedFiles = e.target.files;
                        if (selectedFiles && selectedFiles.length > 0) {
                          setImages((prevImages) => [
                            ...prevImages,
                            ...Array.from(selectedFiles),
                          ]);
                        }
                      }}
                      accept="image/*"
                      name="image"
                      type="file"
                      multiple
                    />
                    <button
                      className="has-icon button-red delete mb-1 mt-1"
                      onClick={(e) => {
                        e.preventDefault();
                        const imageIdsToDelete = imagesPath.map(
                          (image: any) => image.id
                        );
                        const updatedDeleteImages = [
                          ...deleteImages,
                          ...imageIdsToDelete,
                        ];
                        setDeleteImages(updatedDeleteImages);
                        setImages([]);
                        setImagesPath([]);
                      }}
                    >
                      <i className="icon delete-icon" />
                      {t("edit_nursery_delete")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div
            className={
              "form-block border-bottom" +
              (showNewDirectorEmail === true || showSelectDirectorInput === true
                ? "form-block"
                : "")
            }
          >
            <div className="form-radio mb-5">
              <div className="radio-button">
                <input
                  className="radio-input"
                  id="0"
                  name="pending"
                  type="radio"
                  onChange={(e) => handleRadioChange(e)}
                  checked={showNewDirectorEmail}
                  value="0"
                />
                <label htmlFor="0">{t("edit_nursery_add_new_director")}</label>
              </div>
              <div className="radio-button">
                <input
                  className="radio-input"
                  id="1"
                  name="1"
                  type="radio"
                  onChange={(e) => handleRadioChange(e)}
                  checked={showSelectDirectorInput}
                  value="1"
                />
                <label htmlFor="1">
                  {t("edit_nursery_transfer_existing_director")}
                </label>
              </div>
            </div>
          </div>
          {showNewDirectorEmail && (
            <div className="d-flex border-bottom mb-5 row">
              <p className="popup_title mb-3">
                {t("edit_nursery_add_new_director")}
              </p>
              <div className="form-block col-12 col-md-6 col-sm-6 mb-5">
                <label>{t("edit_nursery_new_director_email")}</label>
                <input
                  type="email"
                  name="email"
                  className={
                    "form-input" +
                    (validations.newDirectorEmail ? " input-error" : "")
                  }
                  onChange={(e) => setNewDirectorEmail(e.target.value)}
                  value={newDirectorEmail}
                  placeholder={t("edit_nursery_new_director_email")}
                />
                {validations.newDirectorEmail && (
                  <ValidationMessage
                    message={validations.newDirectorEmail[0]}
                  />
                )}
              </div>
            </div>
          )}
          {showSelectDirectorInput && (
            <div className="d-flex border-bottom mb-5 row">
              <p className="popup_title mb-3">
                {t("edit_nursery_transfer_new_director")}
              </p>
              <div className="form-block col-12 col-sm-6 col-md-3 pb-4">
                <label>{t("nursery_choose_option")}</label>
                <Select
                  placeholder={t("edit_nursery_select_director")}
                  className={
                    "form-react-select" +
                    (validations.replacement_new_director_id
                      ? " input-error"
                      : "")
                  }
                  classNamePrefix="select_input"
                  name="specification"
                  onChange={(e) =>
                    e
                      ? setReplacementNewDirectorId(e.value)
                      : setReplacementNewDirectorId("")
                  }
                  isClearable={true}
                  isSearchable={false}
                  options={Object.entries(directors).map(([id, label]) => ({
                    value: id,
                    label: label,
                  }))}
                />
                {validations.replacement_new_director_id && (
                  <ValidationMessage
                    message={validations.replacement_new_director_id[0]}
                  />
                )}
              </div>
            </div>
          )} */}
          <p className="popup_title mb-3">{t("edit_nursery_address")}</p>
          <div className="d-flex gap">
            <div className="w-50 flex-column">
              <div className="row">
                <div className="form-block col-12 col-md-6 col-sm-6">
                  <label className="required">{t("edit_nursery_street")}</label>
                  <input
                    type="text"
                    name="address"
                    className={
                      "form-input" + (validations.address ? " input-error" : "")
                    }
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                    placeholder={t("edit_nursery_street")}
                  />
                  {validations.address && (
                    <ValidationMessage message={validations.address[0]} />
                  )}
                </div>
                <div className="form-block col-12 col-md-6 col-sm-6">
                  <label>{t("edit_nursery_postal_code")}</label>
                  <input
                    type="number"
                    name="postal_code"
                    className={
                      "form-input" +
                      (validations.postal_code ? " input-error" : "")
                    }
                    onChange={(e) => setPostalCode(e.target.value)}
                    value={postalCode}
                    placeholder={t("edit_nursery_postal_code")}
                  />
                  {validations.postal_code && (
                    <ValidationMessage message={validations.postal_code[0]} />
                  )}
                </div>
              </div>
              <p className="popup_title mb-3">{t("edit_nursery_location")}</p>
              <div className="row">
                <div className="form-block col-12 col-md-6 col-sm-6">
                  <label>{t("edit_nursery_longtitude")}</label>
                  <input
                    type="number"
                    name="longitude"
                    readOnly
                    className={
                      "form-input" +
                      (validations.longtitude ? " input-error" : "")
                    }
                    value={longitude}
                    placeholder={t("edit_nursery_longtitude")}
                  />
                  {validations.longtitude && (
                    <ValidationMessage message={validations.longtitude[0]} />
                  )}
                </div>
                <div className="form-block col-12 col-md-6 col-sm-6">
                  <label>{t("edit_nursery_latitude")}</label>
                  <input
                    type="number"
                    name="latitude"
                    readOnly
                    className={
                      "form-input" +
                      (validations.latitude ? " input-error" : "")
                    }
                    value={latitude}
                    placeholder={t("edit_nursery_latitude")}
                  />
                  {validations.latitude && (
                    <ValidationMessage message={validations.latitude[0]} />
                  )}
                </div>
              </div>
            </div>
            <div className="w-50">
              <div className="form-block col-12 col-md-12 col-sm-6">
                <div id="map">
                  <MapContainer
                    center={[Number(latitude), Number(longitude)]}
                    zoom={13}
                    scrollWheelZoom={false}
                    attributionControl={false}
                    style={{ width: "auto", height: "217px" }}
                  >
                    <MapClickHandler />
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                      url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
                    />
                    {locations.map((location, index) =>
                      createMarker(location, index)
                    )}
                  </MapContainer>
                </div>
                <p className="popup_description">
                  {t("create_nursery_map_description")}
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("edit_nursery_cancel")}
          </button>
          {btnLoading ? (
            <button type="button" className="button-blue has-icon">
              <i className="icon spinner-icon" />
              {t("edit_nursery_saving")}
            </button>
          ) : (
            <button type="submit" className="button-blue has-icon">
              <i className="icon plus-icon" />
              {t("edit_nursery_save")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};
export default EditNursery;
