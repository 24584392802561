import { useTranslation } from "react-i18next";
import { EditEducationProgramStore } from "src/store/education_program/EditEducationProgram";
import DaysContent from "./DaysContent";

const DayTabs: React.FC = () => {
  const { t } = useTranslation();

  const { submited, tabs, changeTab } = EditEducationProgramStore(
    (state: any) => ({
      submited: state.submited,
      tabs: state.tabs,
      changeTab: state.changeTab,
    })
  );

  const days = [1, 2, 3, 4, 5];

  const handleTabClick = (day: number) => {
    changeTab("days", day);
  };

  return (
    <div className="day-tabs mt-4">
      <div className="tab-buttons">
        {days.map((day) => (
          <button
            key={day}
            className={`tab-button${tabs.days.active === day ? " active" : ""}`}
            onClick={() => handleTabClick(day)}
          >
            {submited.loading &&
              submited.form === "days" &&
              tabs.days.active === day && <i className="spinner-icon" />}
            {t("education_program_edit_day_" + day)}
          </button>
        ))}
      </div>

      <div className="tab-content">
        {days.map(
          (day) =>
            tabs.days.active === day && (
              <div key={day}>
                <DaysContent />
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default DayTabs;
