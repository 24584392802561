import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { makeApiCall } from "src/api/apiRequests";
import { get, map } from "lodash";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import PATHS from "src/routes/Paths";
import i18n from "../../../i18n/i18n";
import languages from "../../../i18n/languages";
import * as AppConfig from "../../../helpers/AppConfig";

const UserDropdown = ({ userData }: any) => {
  const { t } = useTranslation("");

  const [selectedLang, setSelectedLang] = useState<string>("");
  const [user, setUser] = useState<boolean>(false);

  const logout = async () => {
    try {
      await makeApiCall<ResponseType>(
        API_PATHS.logout,
        "POST",
        API_HEADERS.authenticated
      );
      AppConfig.deleteAccessToken();
    } catch (error: any) {
      AppConfig.deleteAccessToken();
    }
  };

  useEffect(() => {
    const currentLanguage = localStorage.getItem("i18nextLng");
    setSelectedLang(currentLanguage ? currentLanguage : "en");
  }, []);

  const changeLanguageAction = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
    window.location.reload();
  };

  return (
    <Dropdown isOpen={user} toggle={() => setUser(!user)}>
      <DropdownToggle className="button-unstyled" tag="button">
        <div className="user-action">
          <div className="avatar avatar-blue size-sm">
            <span className="circle">{`${userData.first_name ? userData.first_name.charAt(0) : ""
              }${userData.last_name ? userData.last_name.charAt(0) : ""}`}</span>
          </div>
          <span className="user-name">
            {(userData.first_name && userData.first_name.length > 20) ||
              (userData.first_name && userData.last_name.length > 20)
              ? userData.first_name
                ? userData.first_name
                : userData.last_name
              : `${userData.first_name ? userData.first_name : ""} ${userData.last_name ? userData.last_name : ""
              }`}
          </span>
          <i className="icon sidebar-arrow" />
        </div>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem tag={Link} to={PATHS.myProfile}>
          <i className="icon dropdown-profile-icon" />
          {`${userData.first_name ? userData.first_name : ""} ${userData.last_name ? userData.last_name : ""
            }`}
        </DropdownItem>
        {map(
          Object.keys(languages),
          (key) =>
            selectedLang !== key && (
              <DropdownItem
                key={key}
                className="d-flex d-sm-none"
                onClick={() => changeLanguageAction(key)}
              >
                <img
                  src={get(languages, `${key}.flag`, "") as string}
                  alt="Flag"
                  className="me-1"
                  height="12"
                />
                <span className="align-middle">
                  {get(languages, `${key}.label`, "") as string}
                </span>
              </DropdownItem>
            )
        )}
        <DropdownItem onClick={() => logout()}>
          <i className="icon dropdown-logout-icon" />
          {t("header_logout_button")}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default UserDropdown;
