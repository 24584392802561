import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import { RoleCheck } from "src/helpers/RoleCheck";
import ValidationMessage from "src/helpers/ValidationMessage";
import TransferPopupNK from "./TransferPopupNK";
import EditTransferPopupNK from "./EditTransferPopupNK";
import ShowTransferNK from "./ShowTransferNK";
import API_PATHS from "src/api/apiPaths";
import SearchFilter from "src/helpers/SearchFilter";
import API_HEADERS from "src/api/apiConfig";
import CustomPagination from "src/helpers/CustomPaginate";
import moment from "moment";
import ListResponse from "src/helpers/ListResponse";
import Loading from "src/helpers/Loading";
import useSearchSubmit from "src/hooks/useSearchSubmit";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import { LanguageDate } from "src/helpers/CheckLanguageDate";

const TransfersNurseryKindergarten: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Transfers
  const [transfers, setTransfers] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [children, setChildren] = useState<any[]>([]);

  const [makeTransferPopup, setMakeTransferPopup] = useState(false);
  const [editTransferPopupNk, setEditTransferPopupNk] = useState(false);
  const [showTransferPopupNk, setShowTransferPopupNk] = useState(false);
  const [modalId, setModalId] = useState<number | null>(null);

  const hasNurseryTransfer = children.some(
    (item: any) => item.kindergarten.type === "cerdhe"
  );

  // Search
  const {
    searchInputs,
    searchBtnLoading,
    searched,
    setSearchBtnLoading,
    handleSubmit,
    setPage,
  } = useSearchSubmit();
  const [referenceNumber, setReferenceNumber] = useState<string>("");


  const getTransfers = async (): Promise<void> => {
    const searchParams: any = searchInputs;
    let request: any = API_PATHS.transferList;

    searchParams.type = "cerdhe-kopesht";

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      setTransfers(response.transfers);
      setLoading(false);
      getChildren();
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    } finally {
      setSearchBtnLoading(false);
    }
  };

  useEffect(() => {
    getTransfers();
  }, [searchInputs]);


  const getChildren = async (): Promise<void> => {
    const request: any = API_PATHS.activeChildList;

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      setChildren(response.activeChildren);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  return (
    <>
      {RoleCheck("1") && (
        <>
          <div className="input-items">
            <form onSubmit={(e) =>
              handleSubmit(e, {
                ...(referenceNumber && { reference_number: referenceNumber }),
              })
            } className="row">
              <div className="form-block col-12 col-sm-6 col-md-3">
                <label>{t("requests_reference_number")}</label>
                <input
                  placeholder={t("requests_reference_number_search_by")}
                  className="form-input"
                  name="text"
                  type="text"
                  value={referenceNumber}
                  onChange={(e) => setReferenceNumber(e.target.value)}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-3 mt-4 mb-4">
                {searchBtnLoading ? (
                  <button type="button" className="button-orange has-icon">
                    <i className="icon spinner-icon" />
                    {t("requests_searching")}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="button-orange has-icon"
                  >
                    <i className="icon search-icon" />
                    {t("requests_search")}
                  </button>
                )}
              </div>
            </form>
            <p className="filter-explain-text">
              {t("requests_filter_explain")}
            </p>
          </div>
          <div className="d-flex align-items-baseline justify-content-end mb-5">
            {hasNurseryTransfer && (
              <div>
                <button
                  type="button"
                  className="button-blue has-icon mb-1"
                  onClick={() => setMakeTransferPopup(true)}
                >
                  <i className="icon plus-icon" />
                  {t("overview_transfers_make_a_transfer")}
                </button>
                {makeTransferPopup && (
                  <TransferPopupNK
                    onShowModal={makeTransferPopup}
                    setShowModal={setMakeTransferPopup}
                    setModified={() => getTransfers()}
                  />
                )}
              </div>
            )}
          </div>
          <div className="transfer-page">
            <div className="table-list-transfers1 mb-5">
              <table role="table" className="w-100 transfer-head">
                <thead>
                  {loading ? (
                    <tr>
                      <td className="text-center" colSpan={3}>
                        <Loading />
                      </td>
                    </tr>
                  ) : (
                    transfers.data
                      .filter((item: any) => item.type === "cerdhe-kopesht")
                      .map((transfer: any) => (
                        <tr role="row" key={transfer.id}>
                          <th
                            style={{ width: "50%" }}
                            role="columnheader"
                            className="text-left p-4"
                          >
                            <i className="icon dropdown-time-icon" />
                            <span className="popup_description">
                              {transfer.created_at
                                ? moment(transfer.created_at).locale(LanguageDate.moment).format(
                                  "DD, MMMM yyyy"
                                )
                                : "-"}
                            </span>
                            <span className="d-flex mt-3">
                              {t("overview_transfer_transfer_request")}
                              <span className="transfer-text mx-2">
                                {transfer.child.first_name +
                                  " " +
                                  transfer.child.last_name}
                              </span>
                            </span>
                          </th>
                          <th
                            style={{ width: "15%" }}
                            role="columnheader"
                            className="text-end"
                          >
                            <div className="actions">
                              {transfer.status_id != 2 &&
                                transfer.status_id != 3 &&
                                transfer.status_id != 4 && (
                                  <button
                                    type="button"
                                    className="button-unstyled-icon"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setEditTransferPopupNk(true);
                                      setModalId(transfer.id);
                                    }}
                                  >
                                    <i className="icon edit-icon" />
                                  </button>
                                )}
                              {editTransferPopupNk &&
                                modalId === transfer.id && (
                                  <EditTransferPopupNK
                                    onShowModal={editTransferPopupNk}
                                    setShowModal={(e) => {
                                      setEditTransferPopupNk(e);
                                      setModalId(null);
                                    }}
                                    transferId={transfer.id}
                                    setModified={() => getTransfers()}
                                  />
                                )}
                              <button
                                type="button"
                                className="button-unstyled-icon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowTransferPopupNk(true);
                                  setModalId(transfer.id);
                                }}
                              >
                                <i className="icon show-icon" />
                              </button>
                              {showTransferPopupNk &&
                                modalId === transfer.id && (
                                  <ShowTransferNK
                                    onShowModal={showTransferPopupNk}
                                    setShowModal={(e) => {
                                      setShowTransferPopupNk(e);
                                      setModalId(null);
                                    }}
                                    transferId={transfer.id}
                                  />
                                )}
                            </div>
                          </th>
                          <th
                            style={{ width: "35%" }}
                            role="columnheader"
                            className="text-end"
                          >
                            {transfer.status_id === 1 ? (
                              <span className="transfer-text">
                                <i className="icon dot-icon my-1 me-2" />
                                {t("not_reviewed")}
                              </span>
                            ) : transfer.status_id === 2 ? (
                              <span className="transfer-text">
                                <i className="icon tick-icon my-0 me-2" />
                                {t("approved_in_the_same_kindergarten")}
                              </span>
                            ) : transfer.status_id === 3 ? (
                              <span className="transfer-text">
                                <i className="icon tick-icon my-0 me-2" />
                                {t("approved_in_another_kindergarten")}
                              </span>
                            ) : transfer.status_id === 4 ? (
                              <span className="transfer-text">
                                <i className="icon close-icon my-0 me-2" />
                                {t("rejected")}
                              </span>
                            ) : transfer.status_id === 5 ? (
                              <span className="transfer-text">
                                <i className="icon dot-icon my-0 me-2" />
                                {t("warrenty_uploaded")}
                              </span>
                            ) : (
                              <span className="transfer-text">
                                <i className="icon tick-icon my-0 me-2" />
                                {t("confirmed")}
                              </span>
                            )}
                          </th>
                        </tr>
                      ))
                  )}
                </thead>
              </table>
              <ListResponse
                totalItems={transfers?.data?.length}
                loading={loading}
                searched={searched}
              />
            </div>
          </div>
          <CustomPagination
            data={transfers}
            setActivePage={(e) => setPage(e)}
          />
        </>
      )}
    </>
  );
};

export default TransfersNurseryKindergarten;
