import { t } from "i18next";

export const StaffRoles = [
  { value: "2", label: t("add_staff_teacher") },
  { value: "4", label: t("add_staff_director") },
  { value: "5", label: t("add_staff_psychologist") },
  { value: "6", label: t("add_staff_hr") },
  { value: "7", label: t("add_staff_it") },
  { value: "8", label: t("add_staff_cooker") },
  { value: "9", label: t("add_staff_committee_member") },
];
