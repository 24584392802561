import React from "react";
import Slider from "./Slider";
import { Link } from "react-router-dom";
import PATHS from "src/routes/Paths";

const Logo: string = require("../../assets/images/logo/smck-logo.svg").default;
interface MainAuthProps {
  children: React.ReactNode;
}

const MainAuth: React.FC<MainAuthProps> = ({ children }) => {
  return (
    <>
      <div className="auth-view">
        <div className="auth-header">
          <Link to={PATHS.homepage}>
            <img src={Logo} alt="Kindergarten" />
          </Link>
        </div>
        <div className="auth-body">
          <div className="left-auth">
            <Slider />
          </div>
          <div className="auth-border"></div>
          <div className="right-auth">{children}</div>
        </div>
      </div>
    </>
  );
};

export default MainAuth;
