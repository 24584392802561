import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeApiCall } from "src/api/apiRequests";
import { useNavigate } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import API_PATHS from "src/api/apiPaths";
import SearchFilter from "src/helpers/SearchFilter";
import API_HEADERS from "src/api/apiConfig";
import CustomPagination from "src/helpers/CustomPaginate";
import ListResponse from "src/helpers/ListResponse";
import Loading from "src/helpers/Loading";
import PATHS from "src/routes/Paths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import useSearchSubmit from "src/hooks/useSearchSubmit";

const Children: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [childrenData, setChildrenData] = useState<any>([]);
  const [ageGroups, setAgeGroups] = useState<any>([]);
  const [groups, setGroups] = useState<any>({});
  const [loading, setLoading] = useState(true);

  // Search
  const {
    searchInputs,
    searchBtnLoading,
    setSearchBtnLoading,
    handleSubmit,
    setPage,
  } = useSearchSubmit();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchGroup, setSearchGroup] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [searchAgeGroup, setSearchAgeGroup] =
    useState<SingleValue<{ value: string; label: string }>>(null);


  const fetchChildrenList = async (
  ): Promise<void> => {
    const searchParams: any = searchInputs;

    const request: any = SearchFilter(searchParams, API_PATHS.childrenList);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setChildrenData(response.children);

      setAgeGroups(
        response.age_group.map((ageGroup: any) => ({
          value: ageGroup.id,
          label: ageGroup.age,
        }))
      );

      setGroups(
        response.kindergarten_group.map((group: any) => ({
          value: group.id,
          label: group.name,
        }))
      );

      setLoading(false);
    } catch (error: any) {
      if (error.response.status === 404) {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
      }
    } finally {
      setSearchBtnLoading(false);
    }
  };

  useEffect(() => {
    fetchChildrenList();
  }, [searchInputs]);

  return (
    <>
      <form onSubmit={(e) =>
        handleSubmit(e, {
          ...(searchTerm && { term: searchTerm }),
          ...(searchGroup && { group: searchGroup.value }),
          ...(searchAgeGroup && { agegroup: searchAgeGroup.value }),
        })
      } className="row">
        <div className="form-block col-12 col-sm-6 col-md-3 col-xxl-2">
          <label>{t("children_search_term_label")}</label>
          <input
            name="term"
            value={searchTerm}
            className="form-input"
            placeholder={t("children_search_term_placeholder")}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="form-block col-12 col-sm-6 col-md-3 col-xxl-2">
          <label>{t("children_search_age_group_label")}</label>
          <Select
            placeholder={t("children_search_age_group_placeholder")}
            className="form-react-select"
            classNamePrefix="select_input"
            name="agegroup"
            onChange={(e) => setSearchAgeGroup(e)}
            isClearable={true}
            isSearchable={false}
            options={ageGroups}
            value={searchAgeGroup}
          />
        </div>
        <div className="form-block col-12 col-sm-6 col-md-3 col-xxl-2">
          <label>{t("children_search_group_label")}</label>
          <Select
            placeholder={t("children_search_group_placeholder")}
            className="form-react-select"
            classNamePrefix="select_input"
            name="group"
            onChange={(e) => setSearchGroup(e)}
            isClearable={true}
            isSearchable={false}
            options={groups}
            value={searchGroup}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-3 col-xxl-2 mb-1">
          {searchBtnLoading ? (
            <button type="button" className="mt-4 button-orange has-icon">
              <i className="icon spinner-icon" />
              {t("children_search_button_searching")}
            </button>
          ) : (
            <button
              type="submit"
              className="mt-4 button-orange has-icon"
            >
              <i className="icon search-icon" />
              {t("children_search_button_search")}
            </button>
          )}
        </div>
      </form>
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                style={{ width: "21.25%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon children-name-icon" />
                {t("children_table_fistname_lastname")}
              </th>
              <th
                style={{ width: "21.25%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon guardian-icon" />
                {t("children_table_guardian")}
              </th>
              <th
                style={{ width: "21.25%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon address-icon" />
                {t("children_table_address")}
              </th>
              <th
                style={{ width: "21.25%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon telephone-icon" />
                {t("children_table_contact")}
              </th>
              <th
                style={{ width: "15%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon actions-icon" />
                {t("children_table_actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={5}>
                  <Loading />
                </td>
              </tr>
            ) : (
              childrenData?.data?.map((child: any) => (
                <tr key={child.id}>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("children_table_fistname_lastname")}
                  >
                    {`${child.first_name} ${child.last_name}`}
                  </td>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("children_table_guardian")}
                  >
                    {`${child.guardian.first_name} ${child.guardian.last_name}`}
                  </td>
                  <td
                    role="cell"
                    className="text-left"
                    data-label={t("children_table_address")}
                  >
                    {`${child.guardian.address}, ${child.guardian.city}`}
                  </td>
                  <td
                    role="cell"
                    className="text-left"
                    data-label={t("children_table_contact")}
                  >
                    <div className="d-flex flex-column">
                      <span>{`${child.guardian.phone}`}</span>
                      <span className="blue-text">
                        {`${child.guardian.email}`}
                      </span>
                    </div>
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("children_table_actions")}
                  >
                    <div className="actions">
                      <button
                        className="button-unstyled-icon"
                        onClick={() => navigate(PATHS.showChild(child.id))}
                      >
                        <i className="icon show-icon" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={childrenData?.data?.length}
          loading={loading}
        />
      </div>
      <CustomPagination
        data={childrenData}
        setActivePage={(e) => setPage(e)}
      />
    </>
  );
};

export default Children;
