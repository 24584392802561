import { create } from "zustand";

export const EducationProgramData = create((set) => ({
  educationProgramData: {},

  addEducationProgramData: (data: any) =>
    set(() => ({
      educationProgramData: data,
    })),

  addEducationProgramUpdate: (data: any) =>
    set(() => ({ educationProgramData: data })),
}));
