import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import AddNewAgeGroup from "./AddNewAgeGroup";
import EditAgeGroup from "./EditAgeGroup";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import ListResponse from "src/helpers/ListResponse";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import Loading from "src/helpers/Loading";
import SearchFilter from "src/helpers/SearchFilter";
import CustomPagination from "src/helpers/CustomPaginate";

import * as ConfirmPopup from "../../../../helpers/ConfirmPopup";

const AgeGroup: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [ageGroups, setAgeGroups] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [activePage, setActivePage] = useState<number>(1);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [modalId, setModalId] = useState<number | null>(null);
  const [modified, setModified] = useState(false);

  const getAgeGroups = async (): Promise<void> => {
    setModified(false);

    let request: any = API_PATHS.educationAgeGroupList;

    var searchParams = {};

    if (activePage > 1) {
      searchParams = {
        page: activePage,
      };
    }

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setAgeGroups(response.ageGroup);
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  useEffect(() => {
    getAgeGroups();
  }, [modified, activePage]);

  const deleteAgeGroup = (ageGroupId: number, ageGroupTitle: string) => {
    ConfirmPopup.deletePopup(
      t("education_age_groups_delete_title"),
      t("education_age_groups_delete_type"),
      ageGroupTitle
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.educationAgeGroupDelete(ageGroupId),
            "DELETE",
            API_HEADERS.authenticated
          );
          setModified(true);
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  return (
    <>
      <div className="w-100 mb-3 d-flex justify-content-end">
        <button
          className="button-blue has-icon"
          onClick={() => setShowAddModal(true)}
        >
          <i className="icon add-icon" />
          {t("education_add_new_age_group")}
        </button>
        {showAddModal && (
          <AddNewAgeGroup
            onShowModal={showAddModal}
            setShowModal={(e) => setShowAddModal(e)}
            setModified={(e) => setModified(e)}
          />
        )}
      </div>
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                style={{ width: "80%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon category-icon" />
                {t("education_age_groups_table_age_group")}
              </th>
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon actions-icon" />
                {t("education_age_groups_table_action")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={2}>
                  <Loading />
                </td>
              </tr>
            ) : (
              ageGroups?.data?.map((ageGroup: any) => (
                <tr key={ageGroup.id}>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("education_age_groups_table_age_group")}
                  >
                    {`${ageGroup.from} - ${ageGroup.to} ${t(
                      "education_age_groups_table_age_group_month"
                    )}`}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("education_age_groups_table_action")}
                  >
                    <div className="actions">
                      <button
                        className="button-unstyled-icon"
                        onClick={() => {
                          setShowEditModal(true);
                          setModalId(ageGroup.id);
                        }}
                      >
                        <i className="icon edit-icon" />
                      </button>
                      {showEditModal && modalId === ageGroup.id && (
                        <EditAgeGroup
                          onShowModal={showEditModal}
                          setShowModal={(e) => {
                            setShowEditModal(e);
                            setModalId(null);
                          }}
                          ageGroupId={ageGroup.id}
                          setModified={(e) => setModified(e)}
                        />
                      )}
                      <button
                        className="button-unstyled-icon"
                        onClick={() =>
                          deleteAgeGroup(
                            ageGroup.id,
                            `${ageGroup.from} - ${ageGroup.to} ${t(
                              "education_age_groups_table_age_group_month"
                            )}`
                          )
                        }
                      >
                        <i className="icon delete-icon" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse totalItems={ageGroups?.data?.length} loading={loading} />
      </div>
      <CustomPagination
        data={ageGroups}
        setActivePage={(e) => setActivePage(e)}
      />
    </>
  );
};

export default AgeGroup;
