import { useState,  FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import Select from "react-select";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import ValidationMessage from "src/helpers/ValidationMessage";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

interface TransferPopupProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  setModified: (visible: boolean) => void;
}

const TransferPopup: React.FC<TransferPopupProps> = ({
  onShowModal,
  setShowModal,
  setModified,
}) => {
  const { t } = useTranslation("");

  const [childId, setChildId] = useState("");
  const [targetKindergartenId, setTargetKindergartenid] = useState("");
  const [transferReason, setTransferReason] = useState("");
  const [kindergartens, setKindergartens] = useState<Record<string, any>[]>([]);
  const [children, setChildren] = useState<any[]>([]);
  const [applicationNumber, setApplicationNumber] = useState("");

  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const storeTransfer = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setBtnLoading(true);

    const formData = new FormData();
    formData.append("child_id", childId);
    formData.append("target_kindergarten_id", targetKindergartenId);
    formData.append("transfer_reason", transferReason);
    formData.append("transfer_number", applicationNumber);

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.storeTransfer,
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);
      setModified(true);
      onClose();

    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
      }
    } finally {
      setBtnLoading(false);
    }
  };

  const getKindergartens = async (): Promise<void> => {
    let request: any = API_PATHS.kindergartensDropdownList;
    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setKindergartens(response.kindergartens);
      setApplicationNumber(response.application_number);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      onClose();
    }
  };

  const onClose = () => {
    setShowModal(false);
  };

  const getChildren = async (): Promise<void> => {
    const request: any = API_PATHS.activeChildList;

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      setChildren(response.activeChildren);
      kindergartens.length <= 0 && getKindergartens();
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      onClose();
    }
  };

  useEffect(() => {
    getChildren();
  }, []);

  const handleChildSelection = (childId: string): void => {
    setChildId(childId);
  };

  return (
    <Modal isOpen={!loading && onShowModal} toggle={() => onClose()} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        <p className="application-formular-title">
          {t("transfer_popup_form")}{" "}
          {applicationNumber ? applicationNumber : "_"}
        </p>
      </ModalHeader>
      <form onSubmit={(e) => storeTransfer(e)}>
        <ModalBody>
          <p className="popup_title mb-4">{t("transfer_popup_description")}</p>
          <div className="form-block">
            {children.length > 0 ? (
              <div className="form-radio d-flex flex-wrap">
                {children
                  .filter((item: any) => item.kindergarten.type === "cerdhe")
                  .map((child: any) => (
                    <div className="radio-button" key={child.id}>
                      <input
                        className={validations.child_id ? "input-error" : ""}
                        id={`child_${child.id}`}
                        name="pending"
                        type="radio"
                        value={`child_${child.id}`}
                        onChange={() => handleChildSelection(child.id)}
                      />
                      <label htmlFor={`child_${child.id}`}>
                        {child.full_name}
                      </label>
                    </div>
                  ))}
              </div>
            ) : (
              <label>{t("transfer_popup_no_active_children")}</label>
            )}
            {validations.child_id && (
              <ValidationMessage message={validations.child_id[0]} />
            )}
          </div>
          <div className="row">
            {children.length > 0 ? (
              <div className="form-block col-12 col-md-6 col-sm-6">
                <label>{t("transfer_popup_source")}</label>
                <input
                  className="form-input"
                  type="text"
                  name="name"
                  value={children
                    .filter(
                      (item: any) =>
                        item.id === childId &&
                        item.kindergarten.type === "cerdhe"
                    )
                    .map((item: any) => item.kindergarten.name)}
                  readOnly
                />
              </div>
            ) : children.filter(
              (item: any) =>
                item.id === childId && item.kindergarten.type === "cerdhe"
            ).length > 0 ? (
              <div className="form-block col-12 col-md-6 col-sm-6">
                <label>{t("transfer_popup_source")}</label>
                <input
                  className="form-input"
                  type="text"
                  name="name"
                  value={children
                    .filter(
                      (item: any) =>
                        item.id === childId &&
                        item.kindergarten.type === "cerdhe"
                    )
                    .map((item: any) => item.kindergarten.name)}
                  readOnly
                />
              </div>
            ) : (
              <div className="form-block col-12 col-md-6 col-sm-6 mt-4">
                <label>{t("transfer_popup_no_nursery")}</label>
              </div>
            )}
            <div className="form-block col-12 col-md-6 col-sm-6">
              <label className="required">
                {t("transfer_popup_destination")}
              </label>
              <Select
                placeholder={t("transfer_popup_destination")}
                className={
                  "form-react-select" +
                  (validations.target_kindergarten_id ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="specification"
                onChange={(e) =>
                  e
                    ? setTargetKindergartenid(e.value)
                    : setTargetKindergartenid("")
                }
                isClearable={true}
                isSearchable={false}
                options={kindergartens
                  .filter((item: any) => item.type === "cerdhe")
                  .map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
              />
              {validations.target_kindergarten_id && (
                <ValidationMessage
                  message={validations.target_kindergarten_id[0]}
                />
              )}
            </div>
          </div>
          <div className="form-block col-12">
            <label className="required">{t("transfer_popup_reason")}</label>
            <textarea
              name="comment"
              className={
                "form-input" +
                (validations.transfer_reason ? " input-error" : "")
              }
              value={transferReason}
              placeholder={t("transfer_popup_reason_of_application")}
              cols={50}
              rows={6}
              onChange={(e) => setTransferReason(e.target.value)}
            />
            {validations.transfer_reason && (
              <ValidationMessage message={validations.transfer_reason[0]} />
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("transfer_popup_close")}
          </button>
          {btnLoading ? (
            <button className="button-blue has-icon" type="button">
              <i className="icon spinner-icon" />
              {t("transfer_popup_transfering")}
            </button>
          ) : (
            <button className="button-blue has-icon" type="submit">
              <i className="icon transfer-arrows-icon" />
              {t("transfer_popup_transfer")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default TransferPopup;
