// Api Base URL
import { apiBaseUrl } from "../helpers/AppConfig";

const API_PATHS = {
  login: apiBaseUrl + "login",
  logout: apiBaseUrl + "logout",
  resetLink: apiBaseUrl + "send-reset-link",
  getPasswordData: (token: string | undefined) =>
    apiBaseUrl + `accept-password-change/${token}`,
  resetPassword: (token: string | undefined) =>
    apiBaseUrl + `reset-password/${token}`,
  guardianRegistration: apiBaseUrl + "guardian-registration",
  guardianRegistrationNationalRegistry:
    apiBaseUrl + "guardian-registration-national-registry",
  completeRegistration: (token: string | undefined) =>
    apiBaseUrl + `complete-registration/${token}`,
  acceptInvitation: (token: string | undefined) =>
    apiBaseUrl + `accept-invitation/${token}`,
  profile: apiBaseUrl + "profile",
  changePassword: apiBaseUrl + "change-password",
  updateProfile: apiBaseUrl + "update-profile",

  // Meal
  foodAgeGroupsList: apiBaseUrl + "foodagegroup/list",
  foodAgeGroupsNames: apiBaseUrl + "foodagegroup/names",
  foodAgeGroupsStore: apiBaseUrl + "foodagegroup/store",
  foodAgeGroupsShow: (ageGroupId: number) =>
    apiBaseUrl + `foodagegroup/${ageGroupId}`,
  foodAgeGroupsUpdate: (ageGroupId: number) =>
    apiBaseUrl + `foodagegroup/update/${ageGroupId}`,
  foodAgeGroupsDelete: (ageGroupId: number) =>
    apiBaseUrl + `foodagegroup/delete/${ageGroupId}`,
  assortmentStore: apiBaseUrl + "assortment/store",
  assortmentList: apiBaseUrl + "assortment/list",
  assortmentShow: (assortmentId: number) =>
    apiBaseUrl + `assortment/${assortmentId}`,
  assortmentUpdate: (assortmentId: number) =>
    apiBaseUrl + `assortment/update/${assortmentId}`,
  assortmentDelete: (assortmentId: number) =>
    apiBaseUrl + `assortment/delete/${assortmentId}`,
  allergicGroupStore: apiBaseUrl + "allergic_group/store",
  allergicProductStore: apiBaseUrl + "allergic_product/store",
  allergicGroupList: apiBaseUrl + "allergic_group/list",
  allergicProductList: (Id: number | string | undefined) =>
    apiBaseUrl + `allergic_group/${Id}/listProducts`,
  allergicGroupShow: (Id: number) => apiBaseUrl + `allergic_group/${Id}`,
  allergicProductShow: (Id: number) => apiBaseUrl + `allergic_product/${Id}`,
  allergicGroupUpdate: (Id: number) =>
    apiBaseUrl + `allergic_group/update/${Id}`,
  allergicProductUpdate: (Id: number) =>
    apiBaseUrl + `allergic_product/update/${Id}`,
  allergicGroupDelete: (Id: number) =>
    apiBaseUrl + `allergic_group/delete/${Id}`,
  allergicProductDelete: (Id: number) =>
    apiBaseUrl + `allergic_product/delete/${Id}`,
  foodMenuStore: apiBaseUrl + "food_menu/store",
  foodMenuList: apiBaseUrl + "food_menu/list",
  foodMenuShow: (id: number) => apiBaseUrl + `food_menu/${id}`,
  foodMenuUpdate: (id: number) => apiBaseUrl + `food_menu/update/${id}`,
  foodMenuDelete: (id: number) => apiBaseUrl + `food_menu/delete/${id}`,

  categoriesList: apiBaseUrl + "categories/list",
  categoryStore: apiBaseUrl + "category/store",
  deleteCategory: (categoryId: number) => apiBaseUrl + `category/${categoryId}`,
  categoryData: (categoryId: number) =>
    apiBaseUrl + `category/${categoryId}/edit`,
  editCategory: (categoryId: number) => apiBaseUrl + `category/${categoryId}`,
  kindergartenStore: apiBaseUrl + "kindergarten/store",
  listKindergartens: apiBaseUrl + "kindergarten/list",
  kindergartensDropdownList: apiBaseUrl + "kindergarten/dropdownlist",
  updateKindergarten: (kindergartenId: number) =>
    apiBaseUrl + `kindergarten/update/${kindergartenId}`,
  showKindergarten: (kindergartenId: number) =>
    apiBaseUrl + `kindergarten/${kindergartenId}`,
  listApplications: apiBaseUrl + "application/list",
  showApplication: (applicationId: number) =>
    apiBaseUrl + `application/${applicationId}`,
  listOfDirectors: apiBaseUrl + "director/list",
  approveApplication: (applicationId: number) =>
    apiBaseUrl + `application/${applicationId}/approve_same_kindergarten`,
  approveAnotherKindergartenApplication: (applicationId: number) =>
    apiBaseUrl + `application/${applicationId}/approve_another_kindergarten`,
  rejectApplication: (applicationId: number) =>
    apiBaseUrl + `application/${applicationId}/reject`,
  approvedByCommittee: (applicationId: number) =>
    apiBaseUrl + `application/${applicationId}/verify_folder`,
  verifiedCommitteeMembers: (applicationId: number) =>
    apiBaseUrl + `application/${applicationId}/verified_committee_members`,
  uploadWarrantyPayment: apiBaseUrl + "application/upload-warranty-payment",
  registerChild: (applicationId: number) =>
    apiBaseUrl + `application/${applicationId}/register_child`,
  showDocument: (documentId: number) =>
    apiBaseUrl + `application/document/${documentId}/inline`,
  showWarrantyDocument: (documentId: number) =>
    apiBaseUrl + `application/warranty-payment-document/${documentId}/inline`,
  downloadDocument: (documentId: number) =>
    apiBaseUrl + `application/document/${documentId}/attachment`,
  downloadWarrantyDocument: (documentId: number) =>
    apiBaseUrl + `application/warranty-payment-document/${documentId}/attachment`,
  storeChild: apiBaseUrl + "application/store_child",
  storeCategory: apiBaseUrl + "application/store_category",
  storeDocs: apiBaseUrl + "application/store_docs",
  updateChild: (applicationId: number) =>
    apiBaseUrl + `application/child_update/${applicationId}`,
  updateDocs: apiBaseUrl + "application/update_docs",
  listOfDocsRequired: (applicationId: number) =>
    apiBaseUrl + `application/${applicationId}/list_of_docs_required`,

  // Education program
  educationAgeGroupStore: apiBaseUrl + "education_program/age_group/store",
  educationAgeGroupList: apiBaseUrl + "education_program/age_group/list",
  educationAgeGroupShow: (id: number) =>
    apiBaseUrl + `education_program/age_group/${id}`,
  educationAgeGroupUpdate: (id: number) =>
    apiBaseUrl + `education_program/age_group/update/${id}`,
  educationAgeGroupDelete: (id: number) =>
    apiBaseUrl + `education_program/age_group/delete/${id}`,
  educationProgramStore: apiBaseUrl + "education_program/monthly/store",
  educationProgramList: apiBaseUrl + "education_program/monthly/list",
  educationProgramShow: (id: number) =>
    apiBaseUrl + `education_program/monthly/${id}`,
  educationProgramUpdate: (id: number) =>
    apiBaseUrl + `education_program/monthly/update/${id}`,
  educationProgramDelete: (id: number) =>
    apiBaseUrl + `education_program/monthly/delete/${id}`,
  educationProgramDeleteWeekly: (id: number) =>
    apiBaseUrl + `education_program/weekly/delete/${id}`,
  educationProgramShowDetailed: apiBaseUrl + "education_program/daily/list",
  educationProgramStoreDetailed: apiBaseUrl + "education_program/daily/store",

  transferList: apiBaseUrl + "transfer/list",
  storeTransfer: apiBaseUrl + "transfer/store",
  updateTransfer: (transferId: number) =>
    apiBaseUrl + `transfer/${transferId}/update`,
  activeChildList: apiBaseUrl + "transfer/child/list",
  showTransfer: (transferId: number) => apiBaseUrl + `transfer/${transferId}`,
  approveRequest: (transferId: number) =>
    apiBaseUrl + `transfer/${transferId}/approve_same_kindergarten`,
  approveRequestAnother: (transferId: number) =>
    apiBaseUrl + `transfer/${transferId}/approve_another_kindergarten`,
  rejectRequest: (transferId: number) =>
    apiBaseUrl + `transfer/${transferId}/reject`,
  approvedByCommitteeTransfer: (transferId: number) =>
    apiBaseUrl + `transfer/${transferId}/verify_folder`,
  verifiedCommitteeMembersTransfer: (transferId: number) =>
    apiBaseUrl + `transfer/${transferId}/verified_committee_members`,
  albumList: apiBaseUrl + "album/list",
  storeAlbum: apiBaseUrl + "album/store",
  updateAlbum: (albumId: number) => apiBaseUrl + `album/update/${albumId}`,
  showAlbum: (albumId: number) => apiBaseUrl + `album/${albumId}`,
  deleteAlbum: (albumId: number) => apiBaseUrl + `album/delete/${albumId}`,
  teachersList: apiBaseUrl + "teachers/list",
  teachersEvaluationList: apiBaseUrl + "teachers/evaluation/list",
  teacherEvaluationDetails: (evaluationId: number) =>
    apiBaseUrl + `teacher/evaluation/${evaluationId}`,
  updateTeacherEvaluation: (evaluationId: number) =>
    apiBaseUrl + `teacher/evaluation/update/${evaluationId}`,
  staffList: apiBaseUrl + "staff_member/list",
  showStaff: (staffId: number) => apiBaseUrl + `staff/member/${staffId}`,
  storeStaff: apiBaseUrl + "staff/member/store",
  updateStaff: (staffId: number) =>
    apiBaseUrl + `staff/member/update/${staffId}`,
  deleteStaff: (staffId: number) => apiBaseUrl + `user/delete/${staffId}`,
  backupList: apiBaseUrl + "backup/listbackups",
  deleteBackup: (backupId: number) => apiBaseUrl + `backup/delete/${backupId}`,
  logsList: apiBaseUrl + "activity/log/list",
  activityLogObjects: apiBaseUrl + "activity/log/objectsSelect",
  unregistrationList: apiBaseUrl + "children/un-registrations/list",
  storeUnregistration: apiBaseUrl + "child/unregister-request/store",
  showUnregistration: (unregistrationId: number) =>
    apiBaseUrl + `child/un-registration/${unregistrationId}`,
  approveUnregistration: (unregistrationId: number) =>
    apiBaseUrl + `child/unregister-request/approve/${unregistrationId}`,
  deleteKindergarten: ($kindergartenId: number | undefined) =>
    apiBaseUrl + `kindergarten/delete/${$kindergartenId}`,
  evaluationTeachersList: apiBaseUrl + "evaluation/teachers/list",
  deleteTeacherEvaluation: ($evaluationId: number | undefined) =>
    apiBaseUrl + `teacher/evaluation/delete/${$evaluationId}`,
  deleteChildEvaluation: ($evaluationId: number | undefined) =>
    apiBaseUrl + `child_evaluation/delete/${$evaluationId}`,

  // Kindergarten groups
  kindergartenGrouplist: apiBaseUrl + "kindergartengroup/list",
  getKindergartenGroupDetails: apiBaseUrl + "childrengroups/list",
  addNewKindergartenGroup: apiBaseUrl + "kindergartengroup/store",
  updateKindergartenGroup: (groupId: string | undefined) =>
    apiBaseUrl + `kindergartengroup/update/${groupId}`,
  deleteKindergartenGroup: (groupId: number | undefined) =>
    apiBaseUrl + `kindergartengroup/delete/${groupId}`,

  // Children
  childrenList: apiBaseUrl + "children/list",
  childrenData: (childId: string | undefined) =>
    apiBaseUrl + `children/${childId}`,
  childrenInvoiceList: (childId: string | undefined) =>
    apiBaseUrl + `invoices/list/${childId}`,
  childrenInvoiceDetails: (invoiceId: number) =>
    apiBaseUrl + `invoices/${invoiceId}`,
  getChildParticipations: (childId: string | undefined) =>
    apiBaseUrl + `children/${childId}/attendance`,
  storeAbsence: apiBaseUrl + "absence/store",
  updateAbsence: (absenceId: string | undefined) =>
    apiBaseUrl + `absence/update/${absenceId}`,
  deleteAbsence: (absenceId: string | undefined) =>
    apiBaseUrl + `absence/delete/${absenceId}`,
  childListProducts: (groupId: number | null) =>
    apiBaseUrl + `allergic_group/${groupId}/childListProducts`,
  childUpdateProducts: (groupId: number | null) =>
    apiBaseUrl + `allergic_product/${groupId}/childAllergicProducts/update`,
  childEvaluationList: (childId: string | undefined) =>
    apiBaseUrl + `child/${childId}/evaluation/list`,
  childStoreEvaluation: apiBaseUrl + "child/evaluation/store",
  childUpdateEvaluation: (evaluationId: number | undefined) =>
    apiBaseUrl + `child/evaluation/update/${evaluationId}`,
  childGetEvaluation: (evaluationId: number | undefined) =>
    apiBaseUrl + `child/evaluation/${evaluationId}`,
  evaluationsChildrenList: apiBaseUrl + "evaluation/children/list",
  childResponsiblePersonsList: (childId: string | undefined) =>
    apiBaseUrl + `child/${childId}/responsible-person/list`,
  childAddResponsiblePerson: apiBaseUrl + `child/responsible-person/store`,
  getChildResponsiblePerson: (responsiblePersonId: number | undefined) =>
    apiBaseUrl + `child/responsible-person/${responsiblePersonId}`,
  childUpdateResponsiblePerson: (responsiblePersonId: number | undefined) =>
    apiBaseUrl + `child/responsible-person/update/${responsiblePersonId}`,
  deleteChildResponsiblePersons: (
    $responsiblePersonId: number,
    $childId: string | undefined
  ) =>
    apiBaseUrl +
    `child/responsible-person/delete/${$responsiblePersonId}?child_id=${$childId}`,
  getChildFood: (childId: string | undefined) =>
    apiBaseUrl + `children/${childId}/food`,
  storeChildFoodNote: apiBaseUrl + "foodcomment/store",
  updateChildFoodNote: (noteId: number | undefined) =>
    apiBaseUrl + `foodcomment/update/${noteId}`,
  deleteChildFoodNote: ($noteId: number | undefined) =>
    apiBaseUrl + `foodcomment/delete/${$noteId}`,

  // Reports
  reportsApplicationsList: apiBaseUrl + "reports/application/list",
  reportsExportApplications: apiBaseUrl + "reports/application/list/download",
  reportsTransfersList: apiBaseUrl + "reports/transfers/list",
  reportsExportTransfers: apiBaseUrl + "reports/transfers/list/download",
  generateInvoice: apiBaseUrl + "invoices/store",

  // Performance
  teacherPerformanceStore: apiBaseUrl + "teacher/evaluation/store",
  teacherPerformanceUpdate: (performanceId: number) =>
    apiBaseUrl + `teacher/evaluation/update/${performanceId}`,
  teacherPerformanceList: apiBaseUrl + "teachers/evaluation/list",
  teacherPerformanceDetails: (performanceId: number) =>
    apiBaseUrl + `teacher/evaluation/${performanceId}`,
  teacherPerformanceDelete: (performanceId: number) =>
    apiBaseUrl + `teacher/evaluation/delete/${performanceId}`,

  // Dashboard
  adminDashboard: apiBaseUrl + "admin/dashboard",
  guardianDashboard: apiBaseUrl + "guardian/dashboard",

  // Participation children list
  participationChildrenList: apiBaseUrl + "participation/children/list",

  // Evaluation children list
  evaluationChildrenList: apiBaseUrl + "evaluation/children/list",

  // Notifications
  notificationsList: apiBaseUrl + "notification/list",
  deleteNotification: (notificationId: number) =>
    apiBaseUrl + `notifications/delete/${notificationId}`,
  deleteSelectedNotifications: apiBaseUrl + "notifications/delete",
  deleteAllNotifications: apiBaseUrl + "notifications/deleteAll",
  markAsRead: (notificationId: number) =>
    apiBaseUrl + `notifications/${notificationId}/mark-as-read`,
  markSelectedAsRead: apiBaseUrl + "notifications/mark-selected-as-read",
  markAllAsRead: apiBaseUrl + "notifications/mark-all-as-read",
  pusherNotification: apiBaseUrl + "broadcasting/auth",
};

export default API_PATHS;
