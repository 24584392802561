import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";
import ValidationMessage from "src/helpers/ValidationMessage";
import CustomPagination from "src/helpers/CustomPaginate";

import EditNursery from "./EditNursery";

import API_PATHS from "src/api/apiPaths";
import PATHS from "src/routes/Paths";
import API_HEADERS from "src/api/apiConfig";
import SearchFilter from "src/helpers/SearchFilter";
import ListResponse from "src/helpers/ListResponse";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

import Loading from "src/helpers/Loading";

const Nursery: React.FC<{
  text: string;
  specification: string;
  filter: string;
  searched: boolean;
  setSearch: (visible: boolean) => void;
}> = ({ text, specification, searched, filter, setSearch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [type, setType] = useState("cerdhe");
  const [pagination, setPagination] = useState("yes");

  const [nurseries, setNurseries] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState("list");
  const [createNurseryModal, setCreateNurseryModal] = useState(false);
  const [editNurseryModal, setEditNurseryModal] = useState(false);
  const [modalId, setModalId] = useState<number | null>(null);
  const [modified, setModified] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [paginate, setPaginate] = useState<any>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const sortingOptions = [
    { value: "newest_first", label: "Sort By: Newest First" },
    { value: "oldest_first", label: "Sort By: Oldest First" },
  ];

  const [selectedOption, setSelectedOption] = useState(sortingOptions[0]);

  const getNurseries = async (hasSearch: any | undefined): Promise<void> => {
    setModified(false);

    if (hasSearch) {
      hasSearch.preventDefault();
      setBtnLoading(true);
    }

    let request: any = API_PATHS.listKindergartens;

    var searchParams: any = {
      type: type,
      pagination: pagination,
      filter: filter,
    };

    if (searched || (text && specification)) {
      if (text) {
        searchParams["text"] = text;
      }
      if (specification) {
        searchParams["specification"] = specification;
      }
    }

    if (activePage > 1) {
      searchParams["page"] = activePage;
    }

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setValidations({});
      setSearch(false);
      setNurseries(response.kindergartens.data);
      setPaginate(response.kindergartens);
      setBtnLoading(false);
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  useEffect(() => {
    getNurseries("");
  }, [modified, activePage, filter]);

  useEffect(() => {
    searched && getNurseries("");
  }, [searched]);

  const deleteKindergarten = (kindergartenId: number, nurseryTitle: string) => {
    ConfirmPopup.deleteNurseryPopup(
      t("delete_nursery_title"),
      t("delete_nursery_type"),
      nurseryTitle
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.deleteKindergarten(kindergartenId),
            "DELETE",
            API_HEADERS.authenticated
          );

          setModified(true);
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  return (
    <>
      <div className="table-list mb-5">
        <table role="table" className="w-100 mb-4">
          <thead>
            <tr role="row">
              <th
                style={{ width: "15%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon kindergarten-table-icon" />
                {t("nursery_nr")}
              </th>
              <th
                style={{ width: "17%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon address-icon" />
                {t("nursery_address")}
              </th>
              <th
                style={{ width: "17%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon telephone-icon" />
                {t("nursery_mobile")}
              </th>
              <th
                style={{ width: "17%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon email-icon" />
                {t("nursery_email")}
              </th>
              <th
                style={{ width: "17%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon website-icon" />
                {t("nursery_website")}
              </th>
              <th
                style={{ width: "17%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon actions-icon" />
                {t("nursery_actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={6}>
                  <Loading />
                </td>
              </tr>
            ) : (
              ""
            )}
            {nurseries.map((nursery: any) => (
              <tr key={nursery.id}>
                <td
                  role="cell"
                  className="text-left bold-text"
                  data-label={t("nursery_nr")}
                >
                  {nursery.name ? nursery.name : "-"}
                </td>
                <td
                  role="cell"
                  className="text-center"
                  data-label={t("nursery_address")}
                >
                  {nursery.address ? nursery.address : "-"}
                </td>
                <td
                  role="cell"
                  className="text-center"
                  data-label={t("nursery_mobile")}
                >
                  {nursery.phone ? nursery.phone : "-"}
                </td>
                <td
                  role="cell"
                  className="text-center blue-text"
                  data-label={t("nursery_email")}
                >
                  {nursery.email ? nursery.email : "-"}
                </td>
                <td
                  role="cell"
                  className="text-center"
                  data-label={t("nursery_website")}
                >
                  {nursery.website ? (
                    <a
                      href={nursery.website}
                      className="text-decoration-none"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {nursery.website}
                    </a>
                  ) : (
                    "-"
                  )}
                </td>
                <td
                  role="cell"
                  className="text-center"
                  data-label={t("nursery_actions")}
                >
                  <div className="actions">
                    <button
                      type="button"
                      className="button-unstyled-icon"
                      onClick={(e) => {
                        setEditNurseryModal(true);
                        setModalId(nursery.id);
                        e.stopPropagation();
                      }}
                    >
                      <i className="icon edit-icon" />
                    </button>
                    {editNurseryModal && modalId === nursery.id && (
                      <EditNursery
                        onShowModal={editNurseryModal}
                        setNurseryVisible={(e) => {
                          setEditNurseryModal(e);
                          setModalId(null);
                        }}
                        kindergartenId={nursery.id}
                        setModified={(e) => setModified(e)}
                      />
                    )}

                    <button
                      type="button"
                      className="button-unstyled-icon"
                      onClick={() => navigate(PATHS.showNursery + nursery.id)}
                    >
                      <i className="icon show-icon" />
                    </button>
                    <button
                      className="button-unstyled-icon"
                      onClick={(e) => {
                        deleteKindergarten(nursery.id, nursery.name);
                      }}
                    >
                      <i className="icon delete-icon" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ListResponse
          totalItems={nurseries.length}
          loading={loading}
          searched={searched}
        />
        <CustomPagination
          data={paginate}
          setActivePage={(e) => {
            setActivePage(e);
            Object.keys(validations).length > 0 && setSearch(false);
          }}
        />
      </div>
    </>
  );
};

export default Nursery;
