import { useState, useRef, FormEvent, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams } from "react-router";
import { LanguageDate } from "src/helpers/CheckLanguageDate";

import Paths from "../../../../routes/Paths";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import ValidationMessage from "src/helpers/ValidationMessage";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import { useUserdata } from "src/store/UserData";

import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { icon } from "leaflet";

import DatePicker from "react-datepicker";
import moment from "moment";
import PATHS from "src/routes/Paths";
import * as AppConfig from "../../../../helpers/AppConfig";
import SearchFilter from "src/helpers/SearchFilter";

const EditFirstStepNursery: React.FC = () => {
  const { t } = useTranslation("");
  const navigate = useNavigate();
  const param = useParams();
  const userData = useUserdata((state: any) => state.userData);

  const [birthday, setBirthday] = useState<Date | null>(null);
  const [childFirstName, setChildFirstName] = useState<string>("");
  const [childLastName, setChildLastName] = useState<string>("");
  const [parentFirstName, setParentFirstName] = useState<string>("");
  const [show, setShow] = useState(false);
  const [type, setType] = useState("cerdhe");
  const [pagination, setPagination] = useState("no");
  const [placeOfBirth, setPlaceOfBirth] = useState("");
  const [administrativeUnit, setAdministrativeUnit] = useState("");
  const [applicationNumber, setApplicationNumber] = useState("");
  const [applicationId, setApplicationId] = useState("");
  const [kindergartenId, setKindergartenId] = useState("");
  const [gender, setGender] = useState("");
  const [kindergartens, setKindergartens] = useState<Record<string, any>[]>([]);
  const [kindergartensList, setKindergartensList] = useState<
    Record<string, any>[]
  >([]);
  const [childrenCategories, setChildrenCategories] = useState<
    Record<string, string>
  >({});

  const [selectedKindergarten, setSelectedKindergarten] = useState<string>("");
  const [selectedKindergartenId, setSelectedKindergartenId] =
    useState<number>();
  const [modified, setModified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});
  const [view, setView] = useState("first");

  const updateChildren = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setBtnLoading(true);
    const applicationId = param.id;

    const formData = new FormData();
    formData.append("type", type);
    formData.append("first_name", childFirstName);
    formData.append("last_name", childLastName);
    formData.append(
      "date_of_birth",
      birthday ? moment(birthday).format("YYYY-MM-DD") : ""
    );
    formData.append("fathers_name", parentFirstName);
    formData.append("place_of_birth", placeOfBirth);
    formData.append("administrative_unit", administrativeUnit);
    formData.append("kindergarten_id", kindergartenId);
    formData.append("gender", gender);

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.updateChild(Number(applicationId)),
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);
      //   const applicationId = response.application_id;

      if (childrenCategories && Object.keys(childrenCategories).length > 0) {
        navigate(PATHS.editApplicationStepTwoNursery + applicationId);
      } else {
        navigate(PATHS.makeApplicationStepTwoNursery + applicationId);
      }

      setBtnLoading(false);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        navigate(-1);
      }
      setBtnLoading(false);
    }
  };

  const getKindergartens = async (): Promise<void> => {
    setModified(false);

    let request: any = API_PATHS.kindergartensDropdownList;
    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setValidations({});
      setKindergartens(response.kindergartens);
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      setBtnLoading(false);
      navigate(-1);
    }
  };

  const showApplication = async (): Promise<void> => {
    const applicationId = param.id;

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.showApplication(Number(applicationId)),
        "GET",
        API_HEADERS.authenticated
      );
      setLoading(false);
      setSelectedKindergarten(response.application.kindergarten.name);
      setKindergartenId(response.application.kindergarten.id);
      setType(response.application.kindergarten.type);
      setApplicationId(response.application.id);
      setBirthday(
        response.application.child.date_of_birth
          ? new Date(response.application.child.date_of_birth)
          : null
      );
      setChildFirstName(response.application.child.first_name);
      setChildLastName(response.application.child.last_name);
      setParentFirstName(response.application.child.fathers_name);
      setPlaceOfBirth(response.application.child.place_of_birth);
      setAdministrativeUnit(response.application.child.administrative_unit);
      setApplicationNumber(response.application.application_number);
      setChildrenCategories(response.application.children_category);
      setGender(response.application.child.gender);
      setShow(true);
      Kindergartens();
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  useEffect(() => {
    showApplication();
  }, []);

  const Kindergartens = async (): Promise<void> => {
    setModified(false);
    var searchParams: any = { type: type, pagination: pagination };

    let request: any = API_PATHS.listKindergartens;

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setValidations({});
      setKindergartensList(response.kindergartens);
      setLoading(false);
      getKindergartens();
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  const markerColors = [
    "#74dde5",
    "#fac86b",
    "#678af7",
    "#6bdaae",
    "#ab56d4",
    "#fd8adc",
    "#70b0ff",
    "#9386f0",
    "#f1c448",
    "#6592f3",
    "#3d6de0",
    "#e3692c",
    "#ff9900",
    "#ff8c00",
    "#f29b30",
    "#ffdeb5",
    "#008000",
    "#ffc0cb",
    "#59bf80",
    "#cb3438",
  ];

  const createMarkers = () => {
    return kindergartensList.map((kindergarten, index) => {
      const markerColor = markerColors[index % markerColors.length];

      const customIcon = icon({
        iconUrl: require("../../../../assets/images/marker-icon.png"),
        iconSize: [20, 20],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        className: `custom-marker border marker-color-${index}`,
      });

      const markerStyle = document.createElement("style");

      markerStyle.innerHTML = `
        .marker-color-${index} {
          background: ${markerColor};
        }
      `;
      document.head.appendChild(markerStyle);

      return (
        <Marker
          position={[
            Number(kindergarten?.latitude ? kindergarten.latitude : 0),
            Number(kindergarten?.longtitude ? kindergarten.longtitude : 0),
          ]}
          key={kindergarten.id}
          icon={customIcon}
        >
          <Popup className="leaflet-popup-content">
            <div className="grid-container1">
              <div className="grid-item">
                <div className="grid-header">
                  <div className="grid-number">
                    {kindergarten.id ? kindergarten.id : "-"}
                  </div>
                  {kindergarten.name ? kindergarten.name : "-"}
                </div>
                <div className="grid-content">
                  <div className="grid-title mt-2">
                    <i className="icon address-icon" />
                    {t("kindergarten_address")}
                  </div>
                  <div className="grid-subtitle">
                    {kindergarten.address ? kindergarten.address : "-"}
                  </div>

                  <div className="grid-title">
                    <i className="icon email-icon" />
                    {t("kindergarten_email")}
                  </div>
                  <div className="grid-subtitle">
                    {kindergarten.email ? kindergarten.email : "-"}
                  </div>
                  <div className="grid-title">
                    <i className="icon kid-icon" />
                    {t("create_nursery_max_number_children")}
                  </div>
                  <div className="grid-subtitle">
                    {kindergarten.max_number_children
                      ? kindergarten.max_number_children
                      : "-"}
                  </div>
                  <div className="grid-title">
                    <i className="icon child-icon" />
                    {t("show_kindergarten_vacancies")}
                  </div>
                  <div className="grid-subtitle">
                    {kindergarten.nr_of_free_places_in_the_kindergarten
                      ? kindergarten.nr_of_free_places_in_the_kindergarten
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
          </Popup>
        </Marker>
      );
    });
  };

  return (
    <>
      <div className="make-application-overview">
        <div className={`div ${view === "first" ? "selected" : ""}`}>
          <i className="dot-icon icon me-3" />
        </div>

        <div className={`div ${view === "second" ? "selected" : ""}`}>
          <i className="icon dot-icon me-3" />
        </div>

        <div className={`div ${view === "third" ? "selected" : ""}`}>
          <i className="icon dot-icon" />
        </div>
      </div>
      <div className="make-application-first-step">
        <form onSubmit={(e) => updateChildren(e)}>
          <div className="background w-100">
            <p className="application-formular-title mb-5">
              {t("first_step_application_formular")}{" "}
              {applicationNumber ? applicationNumber : "_"}
            </p>
            <p className="title mb-4">{t("first_step_main_information")}</p>
            <div className="flex-column">
              <div className="row">
                <div className="form-block col-12 col-md-4 col-sm-6">
                  <label className="required">
                    {t("first_step_child_name")}
                  </label>
                  <input
                    type="text"
                    name="name"
                    className={
                      "form-input" +
                      (validations.first_name ? " input-error" : "")
                    }
                    onChange={(e) => setChildFirstName(e.target.value)}
                    value={childFirstName}
                    placeholder={t("first_step_child_name")}
                  />
                  {validations.first_name && (
                    <ValidationMessage message={validations.first_name[0]} />
                  )}
                </div>
                <div className="form-block col-12 col-md-4 col-sm-6">
                  <label className="required">
                    {t("first_step_child_parent_name")}
                  </label>
                  <input
                    type="text"
                    name="firstname"
                    className={
                      "form-input" +
                      (validations.fathers_name ? " input-error" : "")
                    }
                    onChange={(e) => setParentFirstName(e.target.value)}
                    value={parentFirstName}
                    placeholder={t("first_step_child_parent_name")}
                  />
                  {validations.fathers_name && (
                    <ValidationMessage message={validations.fathers_name[0]} />
                  )}
                </div>
                <div className="form-block col-12 col-md-4 col-sm-6">
                  <label className="required">
                    {t("first_step_child_lastname")}
                  </label>
                  <input
                    type="text"
                    name="lastname"
                    className={
                      "form-input" +
                      (validations.last_name ? " input-error" : "")
                    }
                    onChange={(e) => setChildLastName(e.target.value)}
                    value={childLastName}
                    placeholder={t("first_step_child_lastname")}
                  />
                  {validations.last_name && (
                    <ValidationMessage message={validations.last_name[0]} />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="form-block col-12 col-md-4 col-sm-6">
                  <label className="required">{t("first_step_gender")}</label>
                  <Select
                    placeholder={t("first_step_choose_gender")}
                    className={
                      "form-react-select" +
                      (validations.gender ? " input-error" : "")
                    }
                    classNamePrefix="select_input"
                    name="gender"
                    onChange={(e) => (e ? setGender(e.value) : setGender(""))}
                    value={
                      gender
                        ? {
                            value: gender,
                            label: gender,
                          }
                        : null
                    }
                    isClearable={true}
                    isSearchable={false}
                    options={[
                      { value: "male", label: t("first_step_male") },
                      { value: "female", label: t("first_step_female") },
                    ]}
                  />
                  {validations.gender && (
                    <ValidationMessage message={validations.gender[0]} />
                  )}
                </div>
                <div className="form-block col-12 col-md-4 col-sm-6">
                  <label className="required">{t("first_step_birthday")}</label>
                  <DatePicker
                    name="birthday"
                    selected={birthday}
                    showMonthDropdown
                    showYearDropdown
                    placeholderText={t("first_step_birthday")}
                    onChange={(date) => setBirthday(date)}
                    maxDate={new Date()}
                    dateFormat="dd-MM-yyyy"
                    isClearable
                    className={
                      "form-datepicker" +
                      (validations.date_of_birth ? " input-error" : "")
                    }
                    locale={LanguageDate.datepicker}
                    popperClassName="navigations"
                    autoComplete="off"
                  />
                  {validations.date_of_birth && (
                    <ValidationMessage message={validations.date_of_birth[0]} />
                  )}
                </div>
                <div className="form-block col-12 col-md-4 col-sm-6">
                  <label className="required">
                    {t("first_step_place_of_birth")}
                  </label>
                  <input
                    type="text"
                    name="lastname"
                    className={
                      "form-input" +
                      (validations.place_of_birth ? " input-error" : "")
                    }
                    onChange={(e) => setPlaceOfBirth(e.target.value)}
                    value={placeOfBirth}
                    placeholder={t("first_step_place_of_birth")}
                  />
                  {validations.place_of_birth && (
                    <ValidationMessage
                      message={validations.place_of_birth[0]}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="form-block col-12 col-md-4 col-sm-6">
                  <label className="required">
                    {t("first_step_administrative_unit")}
                  </label>
                  <input
                    type="number"
                    name="administrative_unit"
                    className={
                      "form-input" +
                      (validations.administrative_unit ? " input-error" : "")
                    }
                    onChange={(e) => setAdministrativeUnit(e.target.value)}
                    value={administrativeUnit}
                    placeholder={t("first_step_administrative_unit")}
                  />
                  {validations.administrative_unit && (
                    <ValidationMessage
                      message={validations.administrative_unit[0]}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="form-block col-12 col-md-8 col-sm-6">
                  <label>{t("first_step_email")}</label>
                  <input
                    type="email"
                    name="email"
                    className="form-input"
                    placeholder={userData ? userData.email : "-"}
                    readOnly
                  />
                </div>
                <div className="form-block col-12 col-md-4 col-sm-6">
                  <label>{t("first_step_mobile")}</label>
                  <input
                    type="text"
                    name="phone"
                    className="form-input"
                    placeholder={userData ? userData.phone : "-"}
                    readOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-block col-12">
                  <label>{t("first_step_your_address")}</label>
                  <input
                    type="text"
                    name="address"
                    className="form-input"
                    placeholder={userData ? userData.address : "-"}
                    readOnly
                  />
                </div>
              </div>
              <p className="title mt-2 mb-4">
                {t("first_step_choose_institution")}
              </p>
              <div className="row">
                <div className="form-block col-12 col-md-4 col-sm-6">
                  <label className="required">{t("first_step_nursery")}</label>
                  <Select
                    placeholder={t("first_step_select_nursery")}
                    className={
                      "form-react-select" +
                      (validations.kindergarten_id ? " input-error" : "")
                    }
                    classNamePrefix="select_input"
                    name="specification"
                    value={
                      type === "cerdhe" && kindergartenId
                        ? {
                            value: kindergartenId,
                            label: selectedKindergarten,
                          }
                        : null
                    }
                    onChange={(e) =>
                      e
                        ? (setKindergartenId(e.value),
                          setSelectedKindergarten(e.label))
                        : (setKindergartenId(""), setSelectedKindergarten(""))
                    }
                    isClearable={true}
                    isSearchable={false}
                    options={kindergartens
                      .filter((item) => item.type === "cerdhe")
                      .map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                  />
                  {validations.kindergarten_id && (
                    <ValidationMessage
                      message={validations.kindergarten_id[0]}
                    />
                  )}
                </div>
                <div
                  id="map"
                  className="w-100 map-container mt-5 d-flex flex-wrap justify-content-center"
                >
                  <div className="form-block col-12">
                    <MapContainer
                      center={[
                        userData.latitude ? Number(userData.latitude) : 41.33,
                        userData.longitude ? Number(userData.longitude) : 19.82,
                      ]}
                      zoom={13}
                      scrollWheelZoom={false}
                      attributionControl={false}
                      style={{ width: "auto", height: "350px" }}
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
                      />

                      {createMarkers()}
                    </MapContainer>
                  </div>
                </div>
              </div>
              <div className="buttons d-flex justify-content-end mt-2">
                <button
                  className="button-transparent has-icon me-2"
                  type="button"
                  onClick={() => navigate(PATHS.applicationsNursery)}
                >
                  <i className="icon slider-left-arrow" />
                  {t("first_step_back")}
                </button>
                {btnLoading ? (
                  <button type="button" className="button-white has-icon">
                    <i className="icon spinner-icon" />
                    {t("first_step_saving")}
                  </button>
                ) : (
                  <button type="submit" className="button-white has-icon">
                    {t("first_step_save")}
                    <i className="icon slider-right-arrow" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default EditFirstStepNursery;
