import React from "react";

interface ValidationMessageKindergartenProps {
  message: string;
}


const ValidationMessageKindergarten: React.FC<ValidationMessageKindergartenProps> = ({ message }) => {
  return <div className="invalid-message-kindergarten">{message}</div>;
};

export default ValidationMessageKindergarten;
