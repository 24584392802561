import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { icon } from "leaflet";
import EditNursery from "./EditNursery";

import { useNavigate } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";
import ValidationMessage from "src/helpers/ValidationMessage";
import CustomPagination from "src/helpers/CustomPaginate";

import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import SearchFilter from "src/helpers/SearchFilter";
import ListResponse from "src/helpers/ListResponse";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

const Map: React.FC<{
  text: string;
  specification: string;
  searched: boolean;
  setSearch: (visible: boolean) => void;
}> = ({ text, specification, searched, setSearch }) => {
  const { t } = useTranslation("");
  const [editNurseryModal, setEditNurseryModal] = useState(false);

  const navigate = useNavigate();

  const [type, setType] = useState("cerdhe");
  const [pagination, setPagination] = useState("no");
  const [nurseries, setNurseries] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState(true);
  const [modalId, setModalId] = useState<number | null>(null);
  const [modified, setModified] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const getNurseries = async (): Promise<void> => {
    setModified(false);
    var searchParams: any = { type: type, pagination: pagination };

    if (searched || (text && specification)) {
      if (text) {
        searchParams["text"] = text;
      }
      if (specification) {
        searchParams["specification"] = specification;
      }
    }

    let request: any = API_PATHS.listKindergartens;

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      setValidations({});
      setNurseries(response.kindergartens);
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  useEffect(() => {
    getNurseries();
  }, [modified]);

  useEffect(() => {
    searched && getNurseries();
    searched && setSearch(false);
  }, [searched]);

  const deleteKindergarten = (kindergartenId: number, nurseryTitle: string) => {
    ConfirmPopup.deleteNurseryPopup(
      t("delete_nursery_title"),
      t("delete_nursery_type"),
      nurseryTitle
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.deleteKindergarten(kindergartenId),
            "DELETE",
            API_HEADERS.authenticated
          );

          setModified(true);
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  const markerColors = [
    "#74dde5",
    "#fac86b",
    "#678af7",
    "#6bdaae",
    "#ab56d4",
    "#fd8adc",
    "#70b0ff",
    "#9386f0",
    "#f1c448",
    "#6592f3",
    "#3d6de0",
    "#e3692c",
    "#ff9900",
    "#ff8c00",
    "#f29b30",
    "#ffdeb5",
    "#008000",
    "#ffc0cb",
    "#59bf80",
    "#cb3438",
  ];

  const createMarkers = () => {
    return nurseries.map((nursery, index) => {
      const markerColor = markerColors[index % markerColors.length];

      const customIcon = icon({
        iconUrl: require("../../../assets/images/marker-icon.png"),
        iconSize: [20, 20],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        className: `custom-marker border marker-color-${index}`,
      });

      const markerStyle = document.createElement("style");

      markerStyle.innerHTML = `
        .marker-color-${index} {
          background: ${markerColor};
        }
      `;
      document.head.appendChild(markerStyle);

      return (
        <Marker
          position={[
            Number(nursery?.latitude ? nursery.latitude : 0),
            Number(nursery?.longtitude ? nursery.longtitude : 0),
          ]}
          key={nursery.id}
          icon={customIcon}
        >
          <Popup className="leaflet-popup-content">
            <div className="grid-container1">
              <div className="grid-item">
                <div className="grid-header">
                  <div className="grid-number">
                    {nursery.id ? nursery.id : "-"}
                  </div>
                  {nursery.name ? nursery.name : "-"}
                </div>
                <div className="grid-content">
                  <div className="grid-title mt-2">
                    <i className="icon address-icon" />
                    {t("nursery_address")}
                  </div>
                  <div className="grid-subtitle">
                    {nursery.address ? nursery.address : "-"}
                  </div>
                  <div className="grid-title">
                    <i className="icon telephone-icon" />
                    {t("nursery_mobile")}
                  </div>
                  <div className="grid-subtitle">
                    {nursery.mobile ? nursery.mobile : "-"}
                  </div>
                  <div className="grid-title">
                    <i className="icon email-icon" />
                    {t("nursery_email")}
                  </div>
                  <div className="grid-subtitle">
                    {nursery.email ? nursery.email : "-"}
                  </div>
                  <div className="grid-title">
                    <i className="icon website-icon" />
                    {t("nursery_website")}
                  </div>
                  <div className="grid-subtitle">
                    {nursery.website ? (
                      <a
                        href={nursery.website}
                        className="text-decoration-none"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {nursery.website}
                      </a>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
                <div className="grid-actions">
                  <div className="row mt-2">
                    <div className="col-3">
                      <button
                        type="button"
                        className="button-unstyled-icon"
                        onClick={() => {
                          setEditNurseryModal(true);
                          setModalId(nursery.id);
                        }}
                      >
                        <i className="icon edit-icon" />
                      </button>
                      {editNurseryModal && modalId === nursery.id && (
                        <EditNursery
                          onShowModal={editNurseryModal}
                          setNurseryVisible={(e) => {
                            setEditNurseryModal(e);
                            setModalId(null);
                          }}
                          kindergartenId={nursery.id}
                          setModified={(e) => setModified(e)}
                        />
                      )}
                    </div>
                    <div className="col-3">
                      <button
                        className="button-unstyled-icon"
                        onClick={(e) => {
                          deleteKindergarten(nursery.id, nursery.name);
                        }}
                      >
                        <i className="icon delete-icon" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Popup>
        </Marker>
      );
    });
  };

  return (
    <>
      <div id="map" className="map-container">
        <MapContainer
          center={[41.33, 19.82]}
          zoom={13}
          scrollWheelZoom={false}
          attributionControl={false}
          style={{ width: "1500px", height: "600px" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
          />

          {createMarkers()}
        </MapContainer>
      </div>
    </>
  );
};

export default Map;
