import React, { useState } from "react";
import MainData from "./MainData";
import ChildTabs from "./ChildTabs";

const ShowChild: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  return (
    <>
      <MainData setLoadingProp={(e) => setLoading(e)} />
      {!loading && <ChildTabs />}
    </>
  );
};

export default ShowChild;
