import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PrivateRoutes, PublicRoutes } from "./Routes";
import { makeApiCall } from "src/api/apiRequests";
import { useUserdata } from "src/store/UserData";
import ScrollToTop from "src/helpers/ScrollToTop";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import PATHS from "./Paths";
import Error404 from "src/pages/notfound/Error404";
import * as AppConfig from "src/helpers/AppConfig";
import { useSidebarData } from "src/store/sidebar/SidebarData";
import { useNotificationsCount } from "src/store/Notification";
import Pusher from "pusher-js";

import { pusherKey } from "src/helpers/AppConfig";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import { useTranslation } from "react-i18next";

const AppRouter: React.FC = () => {
  interface UserData {
    role: string;
  }
  const { t } = useTranslation();
  type AddUserData = (userData: UserData) => void;

  const [loading, setLoading] = useState<boolean>(true);

  const [isRequesting, setIsRequesting] = useState(false);
  const [isAppLoaded, setIsAppLoaded] = useState(false);

  const addLoggedUserData: AddUserData = useUserdata(
    (state: any) => state.addUserData
  );

  const addSidebarChildren: any = useSidebarData(
    (state: any) => state.addSidebarChildren
  );

  const addNotificationsCount = useNotificationsCount(
    (state: any) => state.addNotificationsCount
  );

  const userData = useCallback(async () => {
    setIsRequesting(true);
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.profile,
        "POST",
        API_HEADERS.authenticated
      );
      addLoggedUserData(response);
      addNotificationsCount(response.new_notifications_count);
      if (response.role_id === 1) {
        let children: any[] = [];
        response.children.length > 0 &&
          response.children.forEach((element: any) => {
            children.push({
              path: PATHS.showChild(element.id),
              icon: "sidebar-backup-icon",
              name: element.first_name + " " + element.last_name,
              roles: [1],
            });
          });

        addSidebarChildren(children);
      }

      Pusher.logToConsole = false;

      const pusher = new Pusher(`${pusherKey}`, {
        cluster: "eu",
        forceTLS: true,
        authEndpoint: API_PATHS.pusherNotification,
        auth: {
          headers: API_HEADERS.authenticated,
        },
      });

      if (pusher) {
        setLoading(false);
        setIsRequesting(false);
      }

      const channel = pusher.subscribe(`private-notifications.${response.id}`);

      channel.bind("NotificationEvent", function (data: any) {
        if (
          Object.keys(data).length > 0 &&
          `${data.notification.notifiable_id}` === `${response.id}`
        ) {
          addNotificationsCount(data.notification.new_notifications_count);

          FLASH_MESSAGES.infoMsg(t("notifications_new_notification"));
        }
      });
    } catch (error: any) {
      AppConfig.deleteAccessToken();
      window.location.href = PATHS.login;
      setIsRequesting(false);
    }
  }, []);

  useEffect(() => {
    if (!isRequesting || !loading) {
      setIsAppLoaded(true);
    }
  }, [isRequesting, loading]);

  useEffect(() => {
    if (AppConfig.isLogged()) {
      userData();
    }
  }, [userData]);

  const memoizedPrivateRoutes = useMemo(() => PrivateRoutes, []);
  const memoizedPublicRoutes = useMemo(() => PublicRoutes, []);

  return (
    <Router>
      <ScrollToTop />
      {!isRequesting && (
        <Routes>
          {!loading &&
            memoizedPrivateRoutes.map((route) => (
              <Route
                path={route.path}
                element={route.element}
                key={route.path}
              />
            ))}

          {memoizedPublicRoutes.map((route) => (
            <Route path={route.path} element={route.element} key={route.path} />
          ))}
          {isAppLoaded && <Route path="*" element={<Error404 />} />}
        </Routes>
      )}
    </Router>
  );
};

export default AppRouter;
