import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Sidebar from "src/components/layouts/sidebar/Sidebar";
import Breadcrumb from "src/components/layouts/Common/Breadcrumb";
import OverviewTransfersNurseryKindergarten from "src/components/panel/transfers/overview_transfers_nursery_kindergarten/OverviewTransfers";
import * as AppConfig from "../../../helpers/AppConfig";

const OverviewTransfersNurseryKindergartenView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("transfer_list_view")}</title>
      </Helmet>
      <Sidebar>
        <Breadcrumb
          title={t("transfer_list_title")}
          subtitle={t("transfer_nursery_kindergarten_list_subtitle")}
        />
        <OverviewTransfersNurseryKindergarten />
      </Sidebar>
    </>
  );
};

export default OverviewTransfersNurseryKindergartenView;
