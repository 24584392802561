import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeApiCall } from "src/api/apiRequests";
import { LanguageDate } from "src/helpers/CheckLanguageDate";
import { useNotificationsCount } from "src/store/Notification";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import ListResponse from "src/helpers/ListResponse";
import Loading from "src/helpers/Loading";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import CustomPagination from "src/helpers/CustomPaginate";
import SearchFilter from "src/helpers/SearchFilter";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";

const Notifications: React.FC = () => {
  const { t } = useTranslation();

  const addNotificationsCount = useNotificationsCount(
    (state: any) => state.addNotificationsCount
  );

  const [notificationsList, setNotificationsList] = useState<any>({});
  const [modified, setModified] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [activePage, setActivePage] = useState<number>(1);

  const [selectedNotifications, setSelectedNotifications] = useState<any>([]);

  const fetchNotifications = async (): Promise<void> => {
    let searchParams: any = {};

    if (activePage > 1) {
      searchParams["page"] = activePage;
    }

    let request: any = SearchFilter(searchParams, API_PATHS.notificationsList);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      setNotificationsList(response.notification);
      addNotificationsCount(response.new_notifications_count);
      setLoading(false);
      setModified(false);
    } catch (error: any) {}
  };

  const onHandleCheckbox = (e: any, id: any) => {
    if (e.target.checked) {
      setSelectedNotifications((current: any) => [...current, id]);
    } else {
      setSelectedNotifications((current: any) =>
        current.filter((element: any) => {
          return element !== id;
        })
      );
    }
  };

  const markAsRead = (notificationId: number) => {
    ConfirmPopup.confirmationPopup(
      t("notification_mark_as_read_title"),
      t("notification_mark_as_read_type"),
      ""
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.markAsRead(notificationId),
            "POST",
            API_HEADERS.authenticated
          );
          setModified(true);
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  const markSelectedAsRead = () => {
    ConfirmPopup.confirmationPopup(
      t("notification_mark_selected_as_read_title"),
      t("notification_mark_selected_as_read_type"),
      ""
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          let formData = {
            notification_ids: selectedNotifications,
          };

          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.markSelectedAsRead,
            "POST",
            API_HEADERS.authenticated,
            formData
          );
          setSelectedNotifications([]);
          FLASH_MESSAGES.successMsg(response.success);
          setModified(true);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          } else if (error.response.data.notification_ids) {
            FLASH_MESSAGES.errorMsg(error.response.data.notification_ids[0]);
          }
        }
      }
    });
  };

  const markAllAsRead = () => {
    ConfirmPopup.confirmationPopup(
      t("notification_mark_all_as_read_title"),
      t("notification_mark_all_as_read_type"),
      ""
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.markAllAsRead,
            "POST",
            API_HEADERS.authenticated
          );
          setModified(true);
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  const deleteNotification = (notificationId: number) => {
    ConfirmPopup.deletePopup(
      t("notification_delete_title"),
      t("notification_delete_type"),
      ""
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.deleteNotification(notificationId),
            "DELETE",
            API_HEADERS.authenticated
          );
          setModified(true);
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  const deleteAllNotifications = () => {
    ConfirmPopup.deletePopup(
      t("notifications_delete_title"),
      t("notifications_delete_type"),
      ""
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.deleteAllNotifications,
            "DELETE",
            API_HEADERS.authenticated
          );
          setModified(true);
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  const deleteSelectedNotifications = () => {
    ConfirmPopup.deletePopup(
      t("notifications_delete_title"),
      t("notifications_delete_type"),
      ""
    ).then(async (response) => {
      if (response.isConfirmed) {
        let formData = {
          notification_ids: selectedNotifications,
        };

        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.deleteSelectedNotifications,
            "POST",
            API_HEADERS.authenticated,
            formData
          );
          setModified(true);
          setSelectedNotifications([]);
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          } else if (error.response.data.notification_ids) {
            FLASH_MESSAGES.errorMsg(error.response.data.notification_ids[0]);
          }
        }
      }
    });
  };

  useEffect(() => {
    fetchNotifications();
  }, [activePage]);

  useEffect(() => {
    modified && fetchNotifications();
  }, [modified]);

  return (
    <>
      {notificationsList &&
        notificationsList.data &&
        notificationsList.data.length > 0 && (
          <div className="d-flex justify-content-end mb-5">
            <div className="d-flex notification-buttons">
              <button
                type="button"
                className="button-white has-icon me-3 mb-1"
                onClick={() => markSelectedAsRead()}
              >
                <i className="icon eye-icon" />
                {t("notifications_mark_selected_as_read")}
              </button>
              <button
                type="button"
                className="button-white has-icon me-3 mb-1"
                onClick={() => markAllAsRead()}
              >
                <i className="icon eye-icon" />
                {t("notifications_mark_all_as_read")}
              </button>
              <button
                type="button"
                className="button-white has-icon me-3 mb-1"
                onClick={() => deleteSelectedNotifications()}
              >
                <i className="icon delete-icon" />
                {t("notifications_delete_selected")}
              </button>
              <button
                className="button-white has-icon mb-1"
                onClick={() => deleteAllNotifications()}
              >
                <i className="icon delete-icon" />
                {t("notifications_delete_all")}
              </button>
            </div>
          </div>
        )}

      <div className="table-list-notification mb-5">
        <table role="table" className="w-100">
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={3}>
                  <Loading />
                </td>
              </tr>
            ) : (
              notificationsList.data.map((notification: any, inx: number) => (
                <tr
                  className={notification.read_at === null ? "unread" : "read"}
                  key={notification.id + inx}
                >
                  <td
                    style={{ width: "5%" }}
                    role="cell"
                    className="text-center"
                  >
                    <div className="form-block">
                      <input
                        type="checkbox"
                        className="form-checkbox"
                        id={notification.id}
                        checked={selectedNotifications.includes(
                          notification.id
                        )}
                        onChange={(e) => onHandleCheckbox(e, notification.id)}
                      />
                      <label htmlFor={notification.id}></label>
                    </div>
                  </td>
                  <td role="cell" className="text-start">
                    <div className="d-flex flex-column">
                      <div className="time">
                        <i className="icon dropdown-time-icon"></i>
                        {moment
                          .utc(notification.created_at)
                          .locale(LanguageDate.moment)
                          .local()
                          .startOf("minute")
                          .fromNow()}
                      </div>
                      <div className="message">
                        {notification.translated_message}
                      </div>
                    </div>
                  </td>
                  <td style={{ width: "5%" }} role="cell" className="text-end">
                    <div className="actions">
                      {!notification.read_at ? (
                        <button
                          className="button-unstyled-icon"
                          onClick={() => markAsRead(notification.id)}
                        >
                          <i className="icon eye-icon" />
                        </button>
                      ) : (
                        <i className="icon read-icon" />
                      )}

                      <button
                        className="button-unstyled-icon"
                        onClick={() => deleteNotification(notification.id)}
                      >
                        <i className="icon delete-icon" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={
            notificationsList &&
            notificationsList.data &&
            notificationsList.data.length
          }
          loading={loading}
        />
      </div>
      <CustomPagination
        data={notificationsList}
        setActivePage={(e) => setActivePage(e)}
      />
    </>
  );
};

export default Notifications;
