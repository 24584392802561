import { useState, FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Select, { SingleValue } from "react-select";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import ValidationMessage from "src/helpers/ValidationMessage";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

interface AddNewMenuProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  setModified: (visible: boolean) => void;
}

const AddNewMenu: React.FC<AddNewMenuProps> = ({
  onShowModal,
  setShowModal,
  setModified,
}) => {
  const { t } = useTranslation();
  const [ageGroupsList, setFoodAgeGroupsList] = useState<any>([]);

  const [name, setName] = useState("");
  const [ageGroup, setAgeGroup] =
    useState<SingleValue<{ value: string; label: string }>>(null);

  const [menufile, setMenufile] = useState<File | null>(null);

  const [loadingButton, setLoadingButton] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const onClose = () => {
    setShowModal(false);
  };

  const addAgeGroup = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setLoadingButton(true);

    const formData = new FormData();

    formData.append("name", name);
    formData.append("food_age_group_id", ageGroup ? ageGroup?.value : "");
    formData.append("menufile", menufile ? menufile : "");

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.foodMenuStore,
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);

      setModified(true);

      onClose();
      getFoodAgeGroupData();
      setLoadingButton(false);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        onClose();
      }
      setLoadingButton(false);
    }
  };

  const getFoodAgeGroupData = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.foodAgeGroupsNames,
        "GET",
        API_HEADERS.authenticated
      );

      const ageGroupsArr = [];
      for (const [key, value] of Object.entries(response.foodAgeGroup)) {
        ageGroupsArr.push({
          label: value,
          value: key,
        });
      }

      setFoodAgeGroupsList(ageGroupsArr);
    } catch (error: any) {}
  };

  useEffect(() => {
    getFoodAgeGroupData();
  }, []);

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = e.target;

    if (fileInput && fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      setMenufile(file);
    } else {
      setMenufile(null);
    }
  };

  return (
    <Modal isOpen={onShowModal} toggle={() => onClose()} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("meal_food_menu_add_new_title")}
      </ModalHeader>
      <form onSubmit={(e) => addAgeGroup(e)}>
        <ModalBody>
          <div className="modal-note">{t("meal_food_menu_add_new_desc")}</div>
          <div className="row">
            <div className="form-block col-12 col-md-6">
              <label className="required">
                {t("meal_food_menu_add_new_name_label")}
              </label>
              <input
                type="text"
                name="name"
                placeholder={t("meal_food_menu_add_new_name_placeholder")}
                value={name}
                className={
                  "form-input" + (validations.name ? " input-error" : "")
                }
                autoComplete="off"
                onChange={(e) => setName(e.target.value)}
              />
              {validations.name && (
                <ValidationMessage message={validations.name[0]} />
              )}
            </div>
            <div className="form-block col-12 col-md-6">
              <label className="required">
                {t("meal_food_menu_add_new_age_group_label")}
              </label>
              <Select
                options={ageGroupsList}
                isClearable={true}
                name="food_age_group_id"
                placeholder={t("meal_food_menu_add_new_age_group_placeholder")}
                className={
                  "form-react-select" +
                  (validations.food_age_group_id ? " input-error" : "")
                }
                value={ageGroup}
                classNamePrefix="select_input"
                onChange={(e) => setAgeGroup(e)}
              />

              {validations.food_age_group_id && (
                <ValidationMessage message={validations.food_age_group_id[0]} />
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-block col-12">
              <label className="required">
                {t("meal_food_menu_add_new_document_label")}
              </label>
              <div className="upload-box">
                <div
                  className={
                    "form-input" + (validations.menufile ? " input-error" : "")
                  }
                >
                  {menufile === null ? (
                    <>
                      <input
                        className="upload-input"
                        onChange={(e) => handleFileUpload(e)}
                        name="menufile"
                        type="file"
                        accept=".pdf"
                      />
                      <div className="upload-input-placeholder">
                        <span>
                          {t("meal_food_menu_add_new_document_placeholder")}
                        </span>
                        <button type="button" className="button-white has-icon">
                          <i className="icon upload-box-icon" />
                          {t("meal_food_menu_add_new_upload_button")}
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="upload-input-placeholder">
                        <span>{menufile.name}</span>
                        <i
                          className="icon close-icon"
                          onClick={() => setMenufile(null)}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              {validations.menufile && (
                <ValidationMessage message={validations.menufile[0]} />
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("meal_food_menu_add_new_cancel_button")}
          </button>

          {loadingButton ? (
            <button type="button" className="button-blue has-icon">
              <i className="spinner-icon" />
              {t("meal_food_menu_add_new_adding_button")}
            </button>
          ) : (
            <button type="submit" className="button-blue has-icon">
              <i className="icon add-icon" />
              {t("meal_food_menu_add_new_add_button")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddNewMenu;
