import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RoleCheck } from "src/helpers/RoleCheck";
import { makeApiCall } from "src/api/apiRequests";
import EditNote from "./EditNote";
import AddNote from "./AddNote";
import Loading from "src/helpers/Loading";
import * as ConfirmPopup from "../../../../../../helpers/ConfirmPopup";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

interface FoodNotesProps {
  foodNote: any;
  loading: boolean;
  setModified: (value: boolean) => void;
  date: Date;
  assortmentsLength: number;
}

const FoodNotes: React.FC<FoodNotesProps> = ({
  foodNote,
  loading,
  setModified,
  date,
  assortmentsLength,
}) => {
  const { t } = useTranslation();

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const deleteCategory = (noteId: number) => {
    ConfirmPopup.deletePopup(
      t("child_food_note_delete_title"),
      t("child_food_note_delete_type"),
      ""
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.deleteChildFoodNote(noteId),
            "DELETE",
            API_HEADERS.authenticated
          );
          setModified(true);
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  return (
    <div className="food-notes col-12 col-lg-4">
      <div className="title">{t("child_food_food_consumption")}</div>

      {loading ? (
        <div className="m-auto">
          <Loading />
        </div>
      ) : (
        <>
          {Object.keys(foodNote).length > 0 ? (
            <div key={foodNote.id} className="card">
              <div className="card-body">
                <div className="main-title">{t("child_food_notes")}</div>
                <div className="teacher">
                  {t("child_food_note_teacher")}:{" "}
                  <span>
                    {foodNote.author.first_name +
                      " " +
                      foodNote.author.last_name}
                  </span>
                </div>
                <div className="description">{foodNote.comment}</div>
                {RoleCheck("2") ? (
                  <div className="actions">
                    <button
                      type="button"
                      className="button-unstyled-icon"
                      onClick={() => setShowEditModal(true)}
                    >
                      <i className="icon edit-icon"></i>
                    </button>
                    {showEditModal && (
                      <EditNote
                        foodNote={foodNote.comment}
                        onShowModal={showEditModal}
                        setShowModal={(e) => setShowEditModal(e)}
                        noteId={foodNote.id}
                        setModified={(e) => setModified(e)}
                      />
                    )}
                    <button
                      type="button"
                      className="button-unstyled-icon"
                      onClick={() => deleteCategory(foodNote.id)}
                    >
                      <i className="icon delete-icon"></i>
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            <div className="note-block">
              <div className="note-title">
                {RoleCheck("2")
                  ? t("child_food_note_add_title")
                  : t("child_food_note_no_note_title")}
              </div>
              <div className="note-subtitle">
                {RoleCheck("2")
                  ? t("child_food_note_add_subtitle")
                  : t("child_food_note_no_note_subtitle")}
              </div>
              {RoleCheck("2") && assortmentsLength > 0 ? (
                <>
                  <button
                    className="button-blue has-icon"
                    onClick={() => setShowAddModal(true)}
                  >
                    <i className="icon add-icon"></i>
                    {t("child_food_note_add_add_button")}
                  </button>
                  {showAddModal && (
                    <AddNote
                      onShowModal={showAddModal}
                      setShowModal={(e) => setShowAddModal(e)}
                      setModified={(e) => setModified(e)}
                      date={date}
                    />
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FoodNotes;
