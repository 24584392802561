import React from "react";
import { useTranslation } from "react-i18next";
import { AddEducationProgram } from "src/store/education_program/AddEducationProgram";
import { EditEducationProgramStore } from "src/store/education_program/EditEducationProgram";
import { LanguageDate } from "src/helpers/CheckLanguageDate";
import DatePicker from "react-datepicker";
import CustomEditor from "./CustomEditor";

export enum EditorType {
  DayTopic = "day_topic",
  DayActivity = "day_activity",
  DayTools = "day_tools",
}

const DaysContent: React.FC = () => {
  const { t } = useTranslation();

  const { educationProgramEdit, addEducationProgramEdit } = AddEducationProgram(
    (state: any) => ({
      educationProgramEdit: state.educationProgramEdit,
      addEducationProgramEdit: state.addEducationProgramEdit,
    })
  );

  const { submited, tabs, setSubmited } = EditEducationProgramStore(
    (state: any) => ({
      submited: state.submited,
      tabs: state.tabs,
      setSubmited: state.setSubmited,
    })
  );

  const handleEditorUpdate = (type: EditorType, content: string) => {
    addEducationProgramEdit({ ...educationProgramEdit, [type]: content });
  };

  const submitEducationProgram = () => {
    setSubmited(true, "button");
  };

  return (
    <div className="days-content mt-4">
      <div className="row mb-4">
        <div className="form-block col-12 col-sm-7 col-md-5 col-lg-5 col-xxl-3">
          <label htmlFor="checkbox2">
            {t("education_program_edit_day_" + tabs.days.active)}
          </label>
          <DatePicker
            selected={educationProgramEdit.date}
            onChange={(e: any) =>
              addEducationProgramEdit({ ...educationProgramEdit, date: e })
            }
            dateFormat="dd/MM/yyyy"
            name="date"
            isClearable
            placeholderText={t(
              "education_program_edit_choose_date_placeholder"
            )}
            className="form-datepicker"
            filterDate={(date) => date.getDay() !== 0 && date.getDay() !== 6}
            showMonthDropdown
            showYearDropdown
            autoComplete="off"
            locale={LanguageDate.datepicker}
          />
        </div>
      </div>
      <div className="accordion custom-accordion">
        {Object.values(EditorType).map((editorType, index) => (
          <div className="accordion-custom-item" key={editorType}>
            <button
              className={`accordion-custom-button ${
                index === 0 ? "aqua" : index === 1 ? "green" : "violet"
              } ${editorType} mb-3`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={"#" + editorType}
              aria-expanded="true"
              aria-controls={editorType}
            >
              {t(`education_program_edit_accordion_${editorType}`)}
              <i className="icon arrow-down-icon" />
            </button>
            <div
              id={editorType}
              className={`accordion-collapse collapse ${
                index === 0 ? "show" : ""
              } mb-5`}
            >
              <div className="tinymce">
                <CustomEditor
                  onSubmit={(content) =>
                    handleEditorUpdate(editorType, content)
                  }
                  initialValue={educationProgramEdit[editorType]}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      {submited.loading && submited.form === "button" ? (
        <button type="button" className="button-blue has-icon ms-auto">
          <i className="spinner-icon" />
          {t("education_program_edit_saving_button")}
        </button>
      ) : (
        <button
          type="button"
          className="button-blue has-icon ms-auto"
          onClick={() => submitEducationProgram()}
        >
          {t("education_program_edit_save_button")}
        </button>
      )}
    </div>
  );
};

export default DaysContent;
