import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Sidebar from "src/components/layouts/sidebar/Sidebar";
import Breadcrumb from "src/components/layouts/Common/Breadcrumb";
import ShowNursery from "src/components/panel/nursery/show_nursery/ShowNursery";

const ShowNurseryView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("nursery_view")}</title>
      </Helmet>
      <Sidebar>
        <Breadcrumb title={t("nursery_title")} />
        <ShowNursery />
      </Sidebar>
    </>
  );
};

export default ShowNurseryView;
