import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import { kindergartenImages } from "src/helpers/AppConfig";
import { makeApiCall } from "src/api/apiRequests";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

const ShowKindergartenImages: React.FC = () => {
  const { t } = useTranslation("");
  const param = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [images, setImages] = useState<File[]>([]);
  const [kindergartenId, setKindergartenId] = useState<number>(0);

  const showKindergartenImages = async (): Promise<void> => {
    const kindergartenId = param.id;

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.showKindergarten(Number(kindergartenId)),
        "GET",
        API_HEADERS.authenticated
      );
      setLoading(false);

      setKindergartenId(response.kindergarten.id);
      setImages(response.kindergarten.images);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  useEffect(() => {
    showKindergartenImages();
  }, []);

  const carouselRef = useRef<AliceCarousel>(null);

  const handlePrevClick = () => {
    if (carouselRef.current) {
      carouselRef.current.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (carouselRef.current) {
      carouselRef.current.slideNext();
    }
  };

  const responsive = {
    0: { items: 0, itemsFit: "contain" },
    568: { items: 0, itemsFit: "contain" },
    1024: { items: 0, itemsFit: "contain" },
  };

  const photos = images.map((image: any, index: number) => (
    <div key={index}>
      <img
        src={kindergartenImages + image.path}
        width="530px"
        height="340px"
        alt={`Image ${index + 1}`}
      />
    </div>
  ));

  return (
    <>
      {images.length > 0 ? (
        <div className="image-slider">
          <div className="slider-container">
            <button className="button-slider prev" onClick={handlePrevClick}>
              <i className="icon slider-left-arrow" />
            </button>
            <div className="slider">
              <AliceCarousel
                mouseTracking
                responsive={responsive}
                controlsStrategy="alternate"
                disableDotsControls
                disableButtonsControls
                ref={carouselRef}
              >
                {photos}
              </AliceCarousel>
            </div>
            <button className="button-slider next" onClick={handleNextClick}>
              <i className="icon slider-right-arrow" />
            </button>
          </div>
          <div className="photo-list">
            {images.map((image: any, index: number) => (
              <div className="photo-item" key={index}>
                <img
                  src={kindergartenImages + image.path}
                  width="100px"
                  height="100px"
                  alt={`Image ${index + 1}`}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <span className="d-flex justify-content-center no-images">
          {t("no_images_uploaded")}
        </span>
      )}
    </>
  );
};

export default ShowKindergartenImages;
