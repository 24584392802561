import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import Loading from "src/helpers/Loading";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import { useChildAllergies } from "src/store/child/ChildAllergies";
const allergiesIcon: string =
  require("../../../../assets/images/icons/allergies-icon.svg").default;

interface MainDataProps {
  setLoadingProp: (loading: boolean) => void;
}

const MainData: React.FC<MainDataProps> = ({ setLoadingProp }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { childId } = useParams();

  const addChildAllergies: any = useChildAllergies(
    (state: any) => state.addChildAllergies
  );
  const childAllergies = useChildAllergies(
    (state: any) => state.childAllergies
  );

  const [childFirstName, setChildFirstName] = useState<string>("");
  const [childLastName, setChildLastName] = useState<string>("");
  const [guardianFirstName, setGuardianFirstName] = useState<string>("");
  const [guardianLastName, setGuardianLastName] = useState<string>("");
  const [birthday, setBirthday] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const fetchChildData = async (): Promise<void> => {
    setLoadingProp(true);
    setLoading(true);
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.childrenData(childId),
        "GET",
        API_HEADERS.authenticated
      );
      setChildFirstName(response.child.first_name);
      setChildLastName(response.child.last_name);
      setGuardianFirstName(response.child.guardian.first_name);
      setGuardianLastName(response.child.guardian.last_name);
      setBirthday(response.child.date_of_birth);
      setCategory(response.child.category.title);
      setPhone(response.child.guardian.phone);
      setEmail(response.child.guardian.email);

      let selected: any[] = [];

      response.child.allergicproducts[0].forEach((product: any) => {
        selected.push({
          id: product.id,
          name: product.name,
        });
      });
      addChildAllergies(selected);
      setLoading(false);
      setLoadingProp(false);
    } catch (error: any) {
      if (error.response.status === 404) {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    fetchChildData();
  }, [childId]);

  return !loading ? (
    <>
      <div className="child-info">
        <div className="child-initials">
          <div className="avatar-violet size-xl">
            <span className="circle">{`${childFirstName.charAt(
              0
            )}${childLastName.charAt(0)}`}</span>
          </div>
        </div>
        <div>
          <div className="child-data">
            <div>
              <span className="label">
                <i className="icon children-name-icon" />
                {t("show_child_name")}
              </span>
              <span className="text">{`${childFirstName} ${childLastName}`}</span>
            </div>
            <div>
              <span className="label">
                <i className="icon calender-icon" />
                {t("show_child_birthday")}
              </span>
              <span className="text">{birthday}</span>
            </div>
            <div>
              <span className="label">
                <i className="icon telephone-icon" />
                {t("show_child_phone")}
              </span>
              <span className="text">{phone}</span>
            </div>
            <div>
              <span className="label">
                <i className="icon guardian-icon" />
                {t("show_child_guardian")}
              </span>
              <span className="text">{`${guardianFirstName} ${guardianLastName}`}</span>
            </div>
            {category && category !== "other" && (
              <div>
                <span className="label">
                  <i className="icon category-icon" />
                  {t("show_child_category")}
                </span>
                <span className="text">{category}</span>
              </div>
            )}
            <div>
              <span className="label">
                <i className="icon email-icon" />
                {t("show_child_email")}
              </span>
              <span className="email">{email}</span>
            </div>
          </div>

          {childAllergies.length > 0 && (
            <div className="card" style={{ maxWidth: "800px" }}>
              <div className="card-body">
                <div className="row">
                  <div className="col-12 allergies">
                    <span>
                      <img src={allergiesIcon} alt="allergies" />
                    </span>
                    <div className="allergies-info">
                      <span className="title">{t("show_child_alergies")}</span>
                      <ul
                        className="mb-0"
                        style={{
                          display: "flex",
                          columnGap: "40px",
                          flexWrap: "wrap",
                        }}
                      >
                        {childAllergies.map((product: any, index: number) => (
                          <li key={product.id + index + product.name}>
                            {product.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  ) : (
    <div className="text-center">
      <Loading />
    </div>
  );
};

export default MainData;
