import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import Loading from "src/helpers/Loading";
import AddNewAllergicGroup from "./AddNewAllergicGroup";
import SimpleBar from "simplebar-react";
import EditAllergicGroup from "./EditAllergicGroup";
import PATHS from "src/routes/Paths";

import * as ConfirmPopup from "../../../../helpers/ConfirmPopup";

interface AddNewAllergicGroupProps {
  allergyId: string | number | undefined;
}

const AllergicGroupsSidebar: React.FC<AddNewAllergicGroupProps> = ({
  allergyId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const ref = useRef<null>(null);

  const [allergicGroups, setAllergicGroups] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number | null>(null);

  const getGroups = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.allergicGroupList,
        "GET",
        API_HEADERS.authenticated
      );
      setAllergicGroups(response.allergicGroups);
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  useEffect(() => {
    getGroups();
  }, []);

  const deleteGroup = (groupId: number, groupTitle: string) => {
    ConfirmPopup.deletePopup(
      t("meal_allergies_sidebar_delete_group_title"),
      t("meal_allergies_sidebar_delete_group_type"),
      groupTitle
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.allergicGroupDelete(groupId),
            "DELETE",
            API_HEADERS.authenticated
          );
          getGroups();
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  return (
    <>
      <div className="sidebar-content">
        <div className="sidebar-header">
          <div className="sidebar-title">
            {t("meal_allergies_sidebar_title")}
          </div>
          <button
            type="button"
            className="button-white has-icon"
            onClick={() => setShowAddModal(true)}
          >
            <i className="icon plus-icon" />
            {t("meal_allergies_sidebar_add_new_group_button")}
          </button>
          {showAddModal && (
            <AddNewAllergicGroup
              onShowModal={showAddModal}
              setShowModal={(e) => setShowAddModal(e)}
              setModified={() => getGroups()}
            />
          )}
        </div>
        <div className="sidebar-body">
          {loading ? (
            <div className="text-center">
              <Loading />
            </div>
          ) : (
            <SimpleBar ref={ref} style={{ maxHeight: "270px" }}>
              <div className="sidebar-list">
                {allergicGroups?.length > 0 ? (
                  allergicGroups?.map((group: any) => (
                    <div
                      key={group.id}
                      className={
                        allergyId === `${group.id}`
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <div
                        className="name"
                        onClick={() => {
                          navigate(PATHS.mealsAllergyProducts(group.id));
                        }}
                      >
                        <span>{group.name}</span>
                      </div>
                      <div
                        className="action"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <button
                          type="button"
                          className="button-unstyled-icon me-3"
                          onClick={() => {
                            setShowEditModal(true);
                            setModalId(group.id);
                          }}
                        >
                          <i className="icon edit-icon" />
                        </button>
                        {showEditModal && modalId === group.id && (
                          <EditAllergicGroup
                            onShowModal={showEditModal}
                            setShowModal={(e) => setShowEditModal(e)}
                            allergicGroupId={group.id}
                            setModified={() => getGroups()}
                          />
                        )}
                        <button
                          type="button"
                          className="button-unstyled-icon"
                          onClick={(e) => {
                            deleteGroup(group.id, group.name);
                          }}
                        >
                          <i className="icon delete-icon" />
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="sidebar-item no-items">
                    {t("no_results_found")}
                  </div>
                )}
              </div>
            </SimpleBar>
          )}
        </div>
      </div>
    </>
  );
};

export default AllergicGroupsSidebar;
