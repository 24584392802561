import { useState } from "react";

const useSearchSubmit = () => {
  const [searchInputs, setSearchInputs] = useState<any>({});
  const [searchBtnLoading, setSearchBtnLoading] = useState<boolean>(false);
  const [searched, setSearched] = useState(false);
  const [page, setActivePage] = useState<number>(0);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>, input: any) => {
    e.preventDefault();
    setSearchBtnLoading(true);
    setSearched(true);
    setActivePage(0);

    const params: any = {};

    if (Object.entries(input).length === 0) {
      setSearchInputs({});
    } else {
      Object.entries(input).map(([key, value]) => (params[key] = value));
      setSearchInputs(params);
    }
  };

  const setPage = (page: number) => {
    if (searchInputs) {
      setSearchInputs((prevInputs: any) => ({
        ...prevInputs,
        page: page,
      }));
    } else {
      setSearchInputs({
        page: page,
      });
    }
    setActivePage(page);
  };

  return {
    searchInputs,
    searchBtnLoading,
    searched,
    page,
    setSearchBtnLoading,
    setActivePage,
    handleSubmit,
    setPage,
  };
};

export default useSearchSubmit;
