import React from "react";

const Image1 = require("../../assets/images/slider/image-20240701-131539.png");
const Image2 = require("../../assets/images/slider/image-20240701-110810.png");
const Image3 = require("../../assets/images/slider/image-20240701-111234.jpg");

const features = [
  { id: 1, cover: Image1 },
  { id: 2, cover: Image2 },
  { id: 3, cover: Image3 },
];

const Slider: React.FC = () => {
  return (
    <>
      <div className="features">
        <div id="features" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators">
            {features.map((book, index) => (
              <button
                key={index}
                type="button"
                data-bs-target="#features"
                data-bs-slide-to={index}
                className={index === 0 ? "active" : ""}
                aria-current={index === 0 ? true : false}
                aria-label={`${index}`}
              />
            ))}
          </div>
          <div className="carousel-inner">
            {features.map((book, index) => (
              <div
                key={book.id}
                className={`carousel-item ${index === 0 ? "active" : ""}`}
              >
                <div
                  className="features-image"
                  style={{
                    backgroundImage: `url('${book.cover}')`,
                  }}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Slider;
