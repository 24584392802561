import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";
import ValidationMessage from "src/helpers/ValidationMessage";
import CustomPagination from "src/helpers/CustomPaginate";

import Map from "./Map";
import CreateNursery from "./CreateNursery";
import Nursery from "./NurseryList";
import NurseryGrid from "./NurseryGrid";

import API_PATHS from "src/api/apiPaths";
import PATHS from "src/routes/Paths";
import API_HEADERS from "src/api/apiConfig";
import SearchFilter from "src/helpers/SearchFilter";
import ListResponse from "src/helpers/ListResponse";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

import Loading from "src/helpers/Loading";

const NurseryOverview: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [type, setType] = useState("cerdhe");
  const [specification, setSpecification] = useState("");
  const [text, setText] = useState<string>("");
  const [filter, setFilter] = useState("newest_first");

  const [nurseries, setNurseries] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState("list");
  const [createNurseryModal, setCreateNurseryModal] = useState(false);
  const [editNurseryModal, setEditNurseryModal] = useState(false);
  const [modalId, setModalId] = useState<number | null>(null);
  const [modified, setModified] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [paginate, setPaginate] = useState<any>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [searched, setSearched] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const sortingOptions = [
    { value: "newest_first", label: t("nursery_sort_by_newest_first") },
    { value: "oldest_first", label: t("nursery_sort_by_oldest_first") },
  ];

  const [selectedOption, setSelectedOption] = useState(sortingOptions[0]);

  const handleOptionChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
  };

  const handleSearchClick = () => {
    setSearched(true);
  };

  return (
    <>
      <div className="input-items">
        <div className="row">
          <div className="form-block col-12 col-sm-6 col-md-3">
            <label>{t("nursery_search_by_term")}</label>
            <input
              placeholder={t("nursery_search")}
              className={
                "form-input" + (validations.text ? " input-error" : "")
              }
              name="text"
              type="text"
              onChange={(e) => setText(e.target.value)}
            />
            {validations.text && (
              <ValidationMessage message={validations.text[0]} />
            )}
            <p className="filter-explain-text mt-2 red mb-3">
          {t("kindergarten_filter_explain")}
        </p>
          </div>
          <div className="form-block col-12 col-sm-6 col-md-3">
            <label>{t("nursery_choose_option")}</label>
            <Select
              placeholder={t("nursery_choose")}
              className={"form-react-select"}
              classNamePrefix="select_input"
              name="specification"
              onChange={(e) =>
                e ? setSpecification(e.value) : setSpecification("")
              }
              isClearable={true}
              isSearchable={false}
              options={[
                { value: "address", label: t("nursery_address") },
                { value: "name", label: t("nursery_name") },
              ]}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-3 mb-1 mt-4">
            {btnLoading ? (
              <button type="button" className="button-orange has-icon">
                <i className="icon spinner-icon" />
                {t("nursery_searching")}
              </button>
            ) : (
              <button
                type="submit"
                className="button-orange has-icon"
                onClick={() => {
                  handleSearchClick();
                  setActivePage(1);
                }}
              >
                <i className="icon search-icon" />
                {t("nursery_search")}
              </button>
            )}
          </div>
          <div className="col-12 col-sm-6 col-md-3 d-flex align-items-baseline justify-content-end mt-4">
            <button
              type="button"
              className="button-blue has-icon"
              onClick={() => setCreateNurseryModal(true)}
            >
              <i className="icon plus-icon" />
              {t("nursery_add_a_nursery")}
            </button>
            {createNurseryModal && (
              <CreateNursery
                onShowModal={createNurseryModal}
                setShowModal={setCreateNurseryModal}
                setNurseryVisible={(e) => setCreateNurseryModal(e)}
              />
            )}
          </div>
        </div>
        
      </div>
      <div className="input-items">
        {view !== "list" && view !== "grid" ? (
          ""
        ) : (
          <label>{t("nursery_filter")}</label>
        )}
        <div className="row">
          <div className="form-block col-12 col-sm-6 col-md-3 pb-4">
            {view !== "list" && view !== "grid" ? (
              ""
            ) : (
              <Select
                className={"select-sort"}
                classNamePrefix="select_input"
                value={
                  filter
                    ? {
                        value: filter,
                        label: `${
                          filter === "newest_first" ? t("nursery_sort_by_newest_first") : t("nursery_sort_by_oldest_first")
                        }`,
                      }
                    : null
                }
                options={[
                  {
                    value: "newest_first",
                    label: t("nursery_sort_by_newest_first"),
                  },
                  {
                    value: "oldest_first",
                    label: t("nursery_sort_by_oldest_first"),
                  },
                ]}
                onChange={(e) =>
                  e ? setFilter(e.value) : setFilter("newest_first")
                }
              />
            )}
          </div>
          <div
            className={
              view !== "list" && view !== "grid"
                ? "form-block col-12 col-sm-6 col-md-9 pb-4 organize-sort mt-4"
                : "form-block col-12 col-sm-6 col-md-9 pb-4 organize-sort"
            }
          >
            <div
              className={`div ${view === "list" ? "selected" : ""}`}
              onClick={(e) => {
                e ? setView("list") : setView("");
              }}
            >
              {t("nursery_list")} <i className="icon list-icon" />
            </div>

            <div
              className={`div ${view === "grid" ? "selected" : ""}`}
              onClick={(e) => {
                e ? setView("grid") : setView("");
              }}
            >
              {t("nursery_grid")} <i className="icon grid-icon" />
            </div>

            <div
              className={`div ${view === "map" ? "selected" : ""}`}
              onClick={(e) => {
                e ? setView("map") : setView("");
              }}
            >
              {t("nursery_map")} <i className="icon map-icon" />
            </div>
          </div>
        </div>
      </div>
      {view === "list" && (
        <Nursery
          text={text}
          specification={specification}
          searched={searched}
          filter={filter}
          setSearch={(e) => setSearched(e)}
        />
      )}
      {view === "grid" && (
        <NurseryGrid
          text={text}
          specification={specification}
          searched={searched}
          filter={filter}
          setSearch={(e) => setSearched(e)}
        />
      )}
      {view === "map" && (
        <Map
          text={text}
          specification={specification}
          searched={searched}
          setSearch={(e) => setSearched(e)}
        />
      )}
    </>
  );
};

export default NurseryOverview;
