import React, { useState, useEffect } from "react";
import Select, { SingleValue } from "react-select";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import CustomPagination from "src/helpers/CustomPaginate";
import ShowRequest from "./ShowRequest";
import ShowApprovedTransfer from "./ShowApprovedTransfer";
import ShowRejectedTransfer from "./ShowRejectedTransfer";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import SearchFilter from "src/helpers/SearchFilter";
import ListResponse from "src/helpers/ListResponse";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import Loading from "src/helpers/Loading";
import useSearchSubmit from "src/hooks/useSearchSubmit";

const RequestsList: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();


  // Transfers
  const [transfers, setTransfers] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  // Search
  const {
    searchInputs,
    searchBtnLoading,
    searched,
    setSearchBtnLoading,
    handleSubmit,
    setPage,
  } = useSearchSubmit();
  const [childName, setChildName] = useState<string>("");
  const [sourceKindergartenId, setSourceKindergartenId] = useState<SingleValue<{ value: string; label: string }>>(null);
  const [targetKindergartenId, setTargetKindergartenId] = useState<SingleValue<{ value: string; label: string }>>(null);
  const [referenceNumber, setReferenceNumber] = useState<string>("");
  const [statusId, setStatusId] = useState<SingleValue<{ value: string; label: string }>>(null);
  const [kindergartens, setKindergartens] = useState<Record<string, any>[]>([]);

  // Modals
  const [showRequestModal, setShowRequestModal] = useState<boolean>(false);
  const [showApprovedTransferModal, setShowApprovedTransferModal] =
    useState<boolean>(false);
  const [showRejectedTransferModal, setShowRejectedTransferModal] =
    useState<boolean>(false);
  const [modalId, setModalId] = useState<number | null>(null);

  const getTransfers = async (): Promise<void> => {
    const searchParams: any = searchInputs;
    let request: any = API_PATHS.transferList;

    searchParams["type"] = "cerdhe-cerdhe";

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      setTransfers(response.transfers);
      setLoading(false);
      kindergartens.length <= 0 && getKindergartens();
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    } finally {
      setSearchBtnLoading(false);
    }
  };

  useEffect(() => {
    getTransfers();
  }, [searchInputs]);

  const getKindergartens = async (): Promise<void> => {
    let request: any = API_PATHS.kindergartensDropdownList;
    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setKindergartens(response.kindergartens.filter((item: any) => item.type === "cerdhe"));
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  return (
    <>
      <div className="input-items">
        <form onSubmit={(e) =>
          handleSubmit(e, {
            ...(childName && { child_name: childName }),
            ...(sourceKindergartenId && { source_kindergarten_id: sourceKindergartenId.value }),
            ...(targetKindergartenId && { target_kindergarten_id: targetKindergartenId.value }),
            ...(referenceNumber && { reference_number: referenceNumber }),
            ...(statusId && { status_id: statusId.value }),
          })
        }
          className="row">
          <div className="form-block col-12 col-sm-6 col-md-3">
            <label>{t("requests_term")}</label>
            <input
              placeholder={t("requests_search_by_term")}
              className="form-input"
              value={childName}
              name="text"
              type="text"
              onChange={(e) => setChildName(e.target.value)}
            />
          </div>
          <div className="form-block col-12 col-sm-6 col-md-3">
            <label>{t("requests_source")}</label>
            <Select
              placeholder={t("requests_choose_source")}
              className="form-react-select"
              classNamePrefix="select_input"
              name="specification"
              onChange={(e) =>
                setSourceKindergartenId(e)
              }
              value={sourceKindergartenId}
              isClearable={true}
              isSearchable={false}
              options={kindergartens.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            />
          </div>
          <div className="form-block col-12 col-sm-6 col-md-3">
            <label>{t("requests_destination")}</label>
            <Select
              placeholder={t("requests_choose_destination")}
              className="form-react-select"
              classNamePrefix="select_input"
              name="specification"
              onChange={(e) =>
                setTargetKindergartenId(e)
              }
              value={targetKindergartenId}
              isClearable={true}
              isSearchable={false}
              options={kindergartens.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            />
          </div>
          <div className="form-block col-12 col-sm-6 col-md-3">
            <label>{t("application_status")}</label>
            <Select
              placeholder={t("application_choose_status")}
              className={"form-react-select"}
              classNamePrefix="select_input"
              name="specification"
              onChange={(e) => setStatusId(e)}
              isClearable={true}
              isSearchable={false}
              value={statusId}
              options={[
                { value: "1", label: t("not_reviewed") },
                { value: "2", label: t("approved_in_the_same_kindergarten") },
                { value: "3", label: t("approved_in_another_kindergarten") },
                { value: "4", label: t("rejected") },
              ]}
            />
          </div>
          <div className="form-block col-12 col-sm-6 col-md-3">
            <label>{t("requests_reference_number")}</label>
            <input
              placeholder={t("requests_reference_number_search_by")}
              className="form-input"
              name="text"
              type="text"
              value={referenceNumber}
              onChange={(e) => setReferenceNumber(e.target.value)}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-3 mt-4 mb-4">
            {searchBtnLoading ? (
              <button type="button" className="button-orange has-icon">
                <i className="icon spinner-icon" />
                {t("requests_searching")}
              </button>
            ) : (
              <button
                type="submit"
                className="button-orange has-icon"
              >
                <i className="icon search-icon" />
                {t("requests_search")}
              </button>
            )}
          </div>
        </form>
        <p className="filter-explain-text mb-5">
          {t("requests_filter_explain")}
        </p>
      </div>
      <div className="table-list mb-5">
        <table role="table" className="w-100 mb-4">
          <thead>
            <tr role="row">
              <th
                style={{ width: "10%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon children-name-icon" />
                {t("requests_child")}
              </th>
              <th
                style={{ width: "15%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon guardian-icon" />
                {t("requests_guardian")}
              </th>
              <th
                style={{ width: "15%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon email-icon" />
                {t("requests_guardian_email")}
              </th>
              <th
                style={{ width: "15%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon kindergarten-table-icon" />
                {t("requests_source")}
              </th>
              <th
                style={{ width: "15%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon status-icon" />
                {t("requests_destination")}
              </th>
              <th
                style={{ width: "25%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon actions-icon" />
                {t("requests_actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={6}>
                  <Loading />
                </td>
              </tr>
            ) : (
              transfers.data
                .filter((transfer: any) => transfer.type === "cerdhe-cerdhe")
                .map((transfer: any) => (
                  <tr key={transfer.id}>
                    <td
                      role="cell"
                      className="text-left bold-text"
                      data-label={t("requests_child")}
                    >
                      {transfer.child.first_name +
                        " " +
                        transfer.child.last_name}
                    </td>
                    <td
                      role="cell"
                      className="text-center bold-text"
                      data-label={t("requests_guardian")}
                    >
                      {transfer.child.guardian.first_name +
                        " " +
                        transfer.child.guardian.last_name}
                    </td>
                    <td
                      role="cell"
                      className="text-center blue-text"
                      data-label={t("requests_guardian_email")}
                    >
                      {transfer.child.guardian.email}
                    </td>
                    <td
                      role="cell"
                      className="text-center"
                      data-label={t("requests_source")}
                    >
                      {transfer.source_kindergarten.name}
                    </td>
                    <td
                      role="cell"
                      className="text-center"
                      data-label={t("requests_destination")}
                    >
                      {transfer.target_kindergarten.name}
                    </td>
                    <td
                      role="cell"
                      className="text-center"
                      data-label={t("requests_actions")}
                    >
                      {transfer.status_id === 4 ? (
                        <div className="actions">
                          <button
                            type="button"
                            className="button-unstyled-icon"
                            onClick={(e) => {
                              setShowRejectedTransferModal(true);
                              setModalId(transfer.id);
                              e.stopPropagation();
                            }}
                          >
                            <i className="icon show-icon" />
                          </button>
                          {showRejectedTransferModal &&
                            modalId === transfer.id && (
                              <ShowRejectedTransfer
                                onShowModal={showRejectedTransferModal}
                                setShowModal={(e) => {
                                  setShowRejectedTransferModal(e);
                                  setModalId(null);
                                }}
                                transferId={transfer.id}
                              />
                            )}
                        </div>
                      ) : transfer.status_id === 2 ||
                        transfer.status_id === 3 ? (
                        <div className="actions">
                          <button
                            type="button"
                            className="button-unstyled-icon"
                            onClick={(e) => {
                              setShowApprovedTransferModal(true);
                              setModalId(transfer.id);
                              e.stopPropagation();
                            }}
                          >
                            <i className="icon show-icon" />
                          </button>
                          {showApprovedTransferModal &&
                            modalId === transfer.id && (
                              <ShowApprovedTransfer
                                onShowModal={showApprovedTransferModal}
                                setShowModal={(e) => {
                                  setShowApprovedTransferModal(e);
                                  setModalId(null);
                                }}
                                transferId={transfer.id}
                              />
                            )}
                        </div>
                      ) : (
                        <div className="actions">
                          <button
                            type="button"
                            className="button-unstyled-icon has-icon"
                            onClick={(e) => {
                              setShowRequestModal(true);
                              setModalId(transfer.id);
                              e.stopPropagation();
                              e.preventDefault();
                            }}
                          >
                            <i className="icon show-icon" />
                          </button>
                          {showRequestModal && modalId === transfer.id && (
                            <ShowRequest
                              onShowModal={showRequestModal}
                              setShowModal={(e) => {
                                setShowRequestModal(e);
                                setModalId(null);
                              }}
                              transferId={transfer.id}
                              setModified={() => getTransfers()}
                            />
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={transfers?.data?.length}
          loading={loading}
          searched={searched}
        />
        <CustomPagination
          data={transfers}
          setActivePage={(e) => setPage(e)}
        />
      </div>
    </>
  );
};

export default RequestsList;
