import i18n from "i18next";

// For moment.js
import "moment/locale/sq";
import "moment/locale/en-gb";

// For datepicker
import sq from "date-fns/locale/sq";

export const LanguageDate = {
  moment: i18n.language === "al" ? "sq" : "en",
  datepicker: i18n.language === "al" ? sq : "",
};
