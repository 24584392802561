import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeApiCall } from "src/api/apiRequests";
import Select, { SingleValue } from "react-select";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import ValidationMessage from "src/helpers/ValidationMessage";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import PATHS from "src/routes/Paths";

const CreateNewAccount = (): JSX.Element => {
  const { t } = useTranslation("");
  const navigate = useNavigate();

  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [gender, setGender] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [city, setCity] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const [validations, setValidations] = useState<Record<string, string>>({});
  const [userShowBtnLoading, setUserShowBtnLoading] = useState(false);

  const createAccount = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setUserShowBtnLoading(true);

    const userData = {
      first_name: firstname,
      last_name: lastname,
      email: email,
      phone: phone,
      gender: gender ? gender.value : null,
      city: city,
      address: address,
      password: password,
      password_confirmation: confirmPassword,
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.guardianRegistration,
        "POST",
        API_HEADERS.unauthenticated,
        userData
      );

      setValidations({});
      FLASH_MESSAGES.successMsg(response.success);
      navigate(PATHS.login);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.error);
        setValidations({});
      }
    } finally {
      setUserShowBtnLoading(false);
    }
  };

  return (
    <>
      <div className="auth-card">
        <p className="auth-note">{t("create_new_account_note")}</p>
        <form className="w-100" onSubmit={(e) => createAccount(e)}>
          <div className="form-block">
            <label className="required">
              {t("create_new_account_firstname_label")}
            </label>
            <input
              type="text"
              name="first_name"
              value={firstname}
              placeholder={t("create_new_account_firstname_placeholder")}
              className={
                "form-input" + (validations.first_name ? " input-error" : "")
              }
              onChange={(e) => setFirstname(e.target.value)}
            />
            {validations.first_name && (
              <ValidationMessage message={validations.first_name[0]} />
            )}
          </div>
          <div className="form-block">
            <label className="required">
              {t("create_new_account_lastname_label")}
            </label>
            <input
              type="text"
              name="last_name"
              value={lastname}
              placeholder={t("create_new_account_lastname_placeholder")}
              className={
                "form-input" + (validations.last_name ? " input-error" : "")
              }
              onChange={(e) => setLastname(e.target.value)}
            />
            {validations.last_name && (
              <ValidationMessage message={validations.last_name[0]} />
            )}
          </div>
          <div className="form-block">
            <label className="required">
              {t("create_new_account_email_label")}
            </label>
            <input
              type="email"
              name="email"
              value={email}
              placeholder={t("create_new_account_email_placeholder")}
              className={
                "form-input" + (validations.email ? " input-error" : "")
              }
              onChange={(e) => setEmail(e.target.value)}
            />
            {validations.email && (
              <ValidationMessage message={validations.email[0]} />
            )}
          </div>
          <div className="form-block">
            <label className="required">
              {t("create_new_account_phonenumber_label")}
            </label>
            <input
              type="number"
              name="phone"
              value={phone}
              placeholder={t("create_new_account_phonenumber_placeholder")}
              className={
                "form-input" + (validations.phone ? " input-error" : "")
              }
              onChange={(e) => setPhone(e.target.value)}
            />
            {validations.phone && (
              <ValidationMessage message={validations.phone[0]} />
            )}
          </div>
          <div className="form-block">
            <label className="required">
              {t("create_new_account_gender_label")}
            </label>
            <Select
              placeholder={t("create_new_account_gender_placeholder")}
              className={
                "form-react-select" + (validations.gender ? " input-error" : "")
              }
              classNamePrefix="select_input"
              name="gender"
              onChange={(e) => setGender(e)}
              value={gender}
              isClearable={true}
              isSearchable={false}
              options={[
                { value: "male", label: t("create_new_account_male_option") },
                {
                  value: "female",
                  label: t("create_new_account_female_option"),
                },
              ]}
            />
            {validations.gender && (
              <ValidationMessage message={validations.gender[0]} />
            )}
          </div>
          <div className="form-block">
            <label className="required">
              {t("create_new_account_city_label")}
            </label>
            <input
              type="text"
              name="city"
              value={city}
              placeholder={t("create_new_account_city_placeholder")}
              className={
                "form-input" + (validations.city ? " input-error" : "")
              }
              onChange={(e) => setCity(e.target.value)}
            />
            {validations.city && (
              <ValidationMessage message={validations.city[0]} />
            )}
          </div>
          <div className="form-block">
            <label className="required">
              {t("create_new_account_address_label")}
            </label>
            <input
              type="text"
              name="address"
              value={address}
              placeholder={t("create_new_account_address_placeholder")}
              className={
                "form-input" + (validations.address ? " input-error" : "")
              }
              onChange={(e) => setAddress(e.target.value)}
            />
            {validations.address && (
              <ValidationMessage message={validations.address[0]} />
            )}
          </div>
          <div className="form-block">
            <label className="required">
              {t("create_new_account_password_label")}
            </label>
            <div className="password-input">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={password}
                placeholder={t("create_new_account_password_placeholder")}
                className={
                  "form-input" + (validations.password ? " input-error" : "")
                }
                onChange={(e) => setPassword(e.target.value)}
              />
              <div
                className="show-hide"
                onClick={(e) => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <i className="icon show-password-icon" />
                ) : (
                  <i className="icon hide-password-icon" />
                )}
              </div>
            </div>
            {validations.password && (
              <ValidationMessage message={validations.password[0]} />
            )}
          </div>
          <div className="form-block">
            <label className="required">
              {t("create_new_account_confirm_password_label")}
            </label>
            <div className="password-input">
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="password_confirmation"
                value={confirmPassword}
                placeholder={t(
                  "create_new_account_confirm_password_placeholder"
                )}
                className={
                  "form-input" +
                  (validations.password_confirmation ? " input-error" : "")
                }
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <div
                className="show-hide"
                onClick={(e) => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <i className="icon show-password-icon" />
                ) : (
                  <i className="icon hide-password-icon" />
                )}
              </div>
            </div>
            {validations.password_confirmation && (
              <ValidationMessage
                message={validations.password_confirmation[0]}
              />
            )}
          </div>
          {userShowBtnLoading ? (
            <button type="button" className="button-blue w-100 has-icon">
              <i className="spinner-icon" />
              {t("create_new_account_registering_button")}
            </button>
          ) : (
            <button type="submit" className="button-blue w-100">
              {t("create_new_account_register_button")}
            </button>
          )}
          <div className="register-now">
            {t("create_new_account_already_have_account")}
            <Link to={PATHS.login}>{t("create_new_account_login")}</Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateNewAccount;
