import { useState, FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import ValidationMessage from "src/helpers/ValidationMessage";

interface EditAgeGroupProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  ageGroupId: number;
  setModified: (visible: boolean) => void;
}

const EditAgeGroup: React.FC<EditAgeGroupProps> = ({
  onShowModal,
  setShowModal,
  ageGroupId,
  setModified,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);

  const [from, setFrom] = useState<any>();
  const [to, setTo] = useState<any>();

  const [loadingButton, setLoadingButton] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const onClose = () => {
    setShowModal(false);
  };

  const getAgeGroupData = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.educationAgeGroupShow(ageGroupId),
        "GET",
        API_HEADERS.authenticated
      );
      setLoading(false);

      setFrom(response.ageGroup.from);
      setTo(response.ageGroup.to);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      onClose();
    }
  };

  useEffect(() => {
    getAgeGroupData();
  }, []);

  const editAgeGroup = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setLoadingButton(true);

    const formData = {
      from: from,
      to: to,
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.educationAgeGroupUpdate(ageGroupId),
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);

      setModified(true);

      onClose();

      setLoadingButton(false);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        onClose();
      }
      setLoadingButton(false);
    }
  };

  return (
    <Modal
      isOpen={onShowModal && !loading}
      toggle={() => onClose()}
      size="md"
      centered
    >
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("education_edit_age_group_title")}
      </ModalHeader>
      <form onSubmit={(e) => editAgeGroup(e)}>
        <ModalBody>
          <div className="modal-note">{t("education_edit_age_group_desc")}</div>
          <div className="row">
            <div className="form-block col-12 col-md-6">
              <label className="required">
                {t("education_edit_age_group_from_label")}
              </label>
              <input
                type="number"
                name="name"
                placeholder={t("education_edit_age_group_from_placeholder")}
                value={from}
                className={
                  "form-input" + (validations.from ? " input-error" : "")
                }
                autoComplete="off"
                onChange={(e) => setFrom(e.target.value)}
              />
              {validations.from && (
                <ValidationMessage message={validations.from[0]} />
              )}
            </div>
            <div className="form-block col-12 col-md-6">
              <label className="required">
                {t("education_edit_age_group_to_label")}
              </label>
              <input
                type="number"
                name="name"
                min={(parseInt(from) + 1).toString()}
                placeholder={t("education_edit_age_group_to_placeholder")}
                value={to}
                className={
                  "form-input" + (validations.to ? " input-error" : "")
                }
                autoComplete="off"
                onChange={(e) => setTo(e.target.value)}
              />
              {validations.to && (
                <ValidationMessage message={validations.to[0]} />
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("education_edit_age_group_cancel")}
          </button>

          {loadingButton ? (
            <button type="button" className="button-blue has-icon">
              <i className="spinner-icon" />
              {t("education_edit_age_group_editing_button")}
            </button>
          ) : (
            <button type="submit" className="button-blue has-icon">
              <i className="icon edit-icon" />
              {t("education_edit_age_group_edit_button")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditAgeGroup;
