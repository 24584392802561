import { forwardRef } from "react";

interface DatePickerInputProps {
  onClick?: () => void;
  placeholder?: string;
  value?: string;
  errorClass?: string;
  otherClass?: string;
}

const DatePickerButton = forwardRef<HTMLButtonElement, DatePickerInputProps>(
  ({ otherClass, errorClass, placeholder, value, onClick }, ref) => (
    <button
      type="button"
      className={`datepicker-button ${otherClass ? otherClass : ""}${
        errorClass ? " input-error" : ""
      }`}
      onClick={onClick}
      ref={ref}
    >
      {value ? value : placeholder}
    </button>
  )
);

export default DatePickerButton;
