import React from "react";
import MainAuth from "./MainAuth";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import CreateNewAccount from "src/components/authentication/CreateNewAccount";

const CreateNewAccountView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("create_new_account_tab")}</title>
      </Helmet>
      <MainAuth>
        <CreateNewAccount />
      </MainAuth>
    </>
  );
};

export default CreateNewAccountView;
