import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import ListResponse from "src/helpers/ListResponse";
import CustomPagination from "src/helpers/CustomPaginate";
import SearchFilter from "src/helpers/SearchFilter";
import ShowBill from "./ShowBill";
import Loading from "src/helpers/Loading";
import moment from "moment";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

const Billing: React.FC = () => {
  const { t } = useTranslation();
  const { childId } = useParams();

  // Invoice list
  const [invoicesList, setInvoicesList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [activePage, setActivePage] = useState<number>(1);

  // Invoice details modal
  const [showBillModal, setShowBillModal] = useState(false);
  const [modalId, setModalId] = useState<number | null>(null);

  const fetchInvoiceList = async (): Promise<void> => {
    const searchParams: any = {};
    activePage > 1 && (searchParams["page"] = activePage);
    const request: any = SearchFilter(
      searchParams,
      API_PATHS.childrenInvoiceList(childId)
    );

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      setInvoicesList(response.invoices);
      setLoading(false);
    } catch (error: any) {
      if (error.response.status === 404) {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
      }
    } 
  };

  useEffect(() => {
    fetchInvoiceList();
  }, []);

  return (
    <>
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                style={{ width: "40%" }}
                role="columnheader"
                className="text-left"
              >
                {t("child_billing_bill_number")}
              </th>
              <th
                style={{ width: "40%" }}
                role="columnheader"
                className="text-left"
              >
                {t("child_billing_generation_date")}
              </th>
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-center"
              >
                {t("child_billing_actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={3}>
                  <Loading />
                </td>
              </tr>
            ) : (
              invoicesList?.data?.map((invoice: any) => (
                <tr key={invoice.id}>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("child_billing_bill_number")}
                  >
                    {invoice.invoice_number}
                  </td>
                  <td
                    role="cell"
                    className="text-left"
                    data-label={t("child_billing_generation_date")}
                  >
                    {moment(invoice.created_at).format("DD-MM-yyyy")}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("child_billing_actions")}
                  >
                    <div className="actions">
                      <button
                        className="button-unstyled-icon"
                        onClick={() => {
                          setShowBillModal(true);
                          setModalId(invoice.id);
                        }}
                      >
                        <i className="icon show-icon" />
                      </button>
                      {showBillModal && modalId === invoice.id && (
                        <ShowBill
                          onShowModal={showBillModal}
                          setShowModal={(e) => {
                            setShowBillModal(e);
                            setModalId(null);
                          }}
                          invoiceId={invoice.id}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={invoicesList?.data?.length}
          loading={loading}
        />
      </div>
      <CustomPagination
        data={{
          total: invoicesList.total,
          current_page: invoicesList.current_page,
          per_page: invoicesList.per_page,
          last_page: invoicesList.last_page,
        }}
        setActivePage={(e) => setActivePage(e)}
      />
    </>
  );
};

export default Billing;
