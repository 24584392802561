import { useState, FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import { LanguageDate } from "src/helpers/CheckLanguageDate";
import DatePicker from "react-datepicker";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import ValidationMessage from "src/helpers/ValidationMessage";

interface EditEvaluationProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  evaluationId: number;
  setModified: (visible: boolean) => void;
}

const EditEvaluation: React.FC<EditEvaluationProps> = ({
  onShowModal,
  setShowModal,
  evaluationId,
  setModified,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);

  const [evaluationsDate, setEvaluationsDate] = useState<Date | null>(null);
  const [comment, setComment] = useState("");

  const [loadingButton, setLoadingButton] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const onClose = () => {
    setShowModal(false);
  };

  const fetchEvaluationData = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.childGetEvaluation(evaluationId),
        "GET",
        API_HEADERS.authenticated
      );
      setLoading(false);
      setEvaluationsDate(new Date(response.evaluation.evaluation_date));
      setComment(response.evaluation.evaluation);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      onClose();
    }
  };

  useEffect(() => {
    fetchEvaluationData();
  }, []);

  const editEvaluation = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setLoadingButton(true);

    const formData = {
      evaluation: comment,
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.childUpdateEvaluation(evaluationId),
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);

      setModified(true);

      onClose();

      setLoadingButton(false);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        onClose();
      }
      setLoadingButton(false);
    }
  };

  return (
    <Modal
      isOpen={onShowModal && !loading}
      toggle={() => onClose()}
      size="md"
      centered
    >
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("child_evaluation_edit_modal_title")}
      </ModalHeader>
      <form onSubmit={(e) => editEvaluation(e)}>
        <ModalBody>
          <div className="modal-note">
            {t("child_evaluation_edit_modal_desc")}
          </div>
          <div className="row">
            <div className="form-block col-12">
              <label>
                {t("child_evaluation_edit_modal_date_of_evaluation_label")}
              </label>
              <DatePicker
                name="year"
                showMonthDropdown
                showYearDropdown
                selected={evaluationsDate}
                readOnly
                onChange={() => {}}
                dateFormat="dd MMM, yyyy"
                placeholderText={t(
                  "child_evaluation_edit_modal_date_of_evaluation_placeholder"
                )}
                className="form-datepicker"
                locale={LanguageDate.datepicker}
                autoComplete="off"
              />
            </div>
            <div className="form-block col-12">
              <label className="required">
                {t("child_evaluation_edit_modal_comment_label")}
              </label>
              <textarea
                name="evaluation"
                rows={3}
                placeholder={t(
                  "child_evaluation_edit_modal_comment_placeholder"
                )}
                value={comment}
                className={
                  "form-input" + (validations.evaluation ? " input-error" : "")
                }
                autoComplete="off"
                onChange={(e) => setComment(e.target.value)}
              />
              {validations.evaluation && (
                <ValidationMessage message={validations.evaluation[0]} />
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("child_evaluation_edit_modal_close_button")}
          </button>

          {loadingButton ? (
            <button type="button" className="button-blue has-icon">
              <i className="spinner-icon" />
              {t("child_evaluation_edit_modal_editing_button")}
            </button>
          ) : (
            <button type="submit" className="button-blue has-icon">
              <i className="icon add-icon" />
              {t("child_evaluation_edit_modal_edit_button")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditEvaluation;
