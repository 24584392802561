import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";

import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import ValidationMessage from "src/helpers/ValidationMessage";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import PATHS from "src/routes/Paths";

const ForgotPassword = (): JSX.Element => {
  const { t } = useTranslation("");
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [validations, setValidations] = useState<Record<string, string>>({});
  const [userShowBtnLoading, setUserShowBtnLoading] = useState(false);

  const forgotPassword = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setUserShowBtnLoading(true);

    const userData = {
      email: email,
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.resetLink,
        "POST",
        API_HEADERS.unauthenticated,
        userData
      );

      setUserShowBtnLoading(false);

      setEmail("");
      setValidations({});
      FLASH_MESSAGES.successMsg(response.success);
    } catch (error: any) {
      if (error.response.status === 404 || error.response.status === 422) {
        setValidations(error.response.data);
      }
      setUserShowBtnLoading(false);
    }
  };

  return (
    <>
      <h3 className="auth-title">{t("auth_forgot_password_title")}</h3>
      <div className="auth-card">
        <p className="auth-note">{t("auth_forgot_password_note")}</p>
        <form className="w-100" onSubmit={(e) => forgotPassword(e)}>
          <div className="form-block">
            <label className="required">
              {t("auth_forgot_password_email_label")}
            </label>
            <input
              type="text"
              name="email"
              value={email}
              placeholder={t("auth_forgot_password_email_placeholder")}
              className={
                "form-input" +
                (validations.email || validations.error ? " input-error" : "")
              }
              onChange={(e) => setEmail(e.target.value)}
            />
            {(validations.email || validations.error) && (
              <ValidationMessage
                message={
                  validations.email ? validations.email[0] : validations.error
                }
              />
            )}
          </div>

          {userShowBtnLoading ? (
            <button type="button" className="button-blue w-100 has-icon">
              <i className="spinner-icon" />
              {t("auth_forgot_password_sending_button")}
            </button>
          ) : (
            <button type="submit" className="button-blue w-100">
              {t("auth_forgot_password_send_button")}
            </button>
          )}

          <button
            type="button"
            style={{ marginTop: "25px" }}
            className="button-white w-100"
            onClick={() => navigate(PATHS.login)}
          >
            {t("auth_forgot_password_back_button")}
          </button>
        </form>
      </div>
    </>
  );
};

export default ForgotPassword;
