import { useState, useRef, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import L from "leaflet";
import { icon } from "leaflet";
import "leaflet/dist/leaflet.css";

import PATHS from "../../../routes/Paths";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import ValidationMessage from "src/helpers/ValidationMessage";
import ValidationMessageKindergarten from "src/helpers/ValidationMessageKindergarten";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

interface CreateNursery {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  setNurseryVisible: (visible: boolean) => void;
}

const CreateNursery: React.FC<CreateNursery> = ({
  onShowModal,
  setShowModal,
  setNurseryVisible,
}) => {
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [latitude, setLatitude] = useState<number>(41.33);
  const [longitude, setLongitude] = useState<number>(19.82);
  const [type, setType] = useState("cerdhe");
  const [maxNumberChildren, setMaxNumberChildren] = useState("");
  const [images, setImages] = useState<File[]>([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const onClose = () => {
    setNurseryVisible(false);
    setShowModal(false);
  };

  const handleUploadButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const locations = [{ latitude, longitude }];

  const [markerPosition, setMarkerPosition] = useState<[number, number]>([
    latitude,
    longitude,
  ]);

  const createNursery = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setBtnLoading(true);

    const formData = new FormData();
    formData.append("type", type);
    formData.append("name", name);
    formData.append("number", number);
    formData.append("address", address);
    formData.append("postal_code", postalCode);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("website", website);
    formData.append("latitude", latitude.toString());
    formData.append("longtitude", longitude.toString());
    formData.append("max_number_children", maxNumberChildren);
    images.forEach((image, index) => {
      formData.append(`images[]`, image);
    });

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.kindergartenStore,
        "POST",
        API_HEADERS.blob,
        formData
      );
      const blob = new Blob([response], { type: "application/image" });
      const url = window.URL.createObjectURL(blob);

      FLASH_MESSAGES.successMsg(response.success);
      window.location.reload();

      setNurseryVisible(true);

      onClose();

      setBtnLoading(false);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data.errors);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        onClose();
      }
      setBtnLoading(false);
    }
  };

  const handleRemoveImage = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const MapClickHandler = () => {
    const map = useMapEvents({
      click: (e) => {
        const { lat, lng } = e.latlng;
        setMarkerPosition([lat, lng]);

        setLatitude(lat);
        setLongitude(lng);
      },
    });

    return null;
  };

  const markerColors = [
    "#74dde5",
    "#fac86b",
    "#678af7",
    "#6bdaae",
    "#ab56d4",
    "#fd8adc",
    "#70b0ff",
    "#9386f0",
    "#f1c448",
    "#6592f3",
    "#3d6de0",
    "#e3692c",
    "#ff9900",
    "#ff8c00",
    "#f29b30",
    "#ffdeb5",
    "#008000",
    "#ffc0cb",
    "#59bf80",
    "#cb3438",
  ];

  const createMarker = (location: any, index: number) => {
    const markerColor = markerColors[index % markerColors.length];

    const customIcon = icon({
      iconUrl: require("../../../assets/images/marker-icon.png"),
      iconSize: [20, 20],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      tooltipAnchor: [16, -28],
      className: `custom-marker border marker-color-${index}`,
    });

    const markerStyle = document.createElement("style");

    markerStyle.innerHTML = `
      .marker-color-${index} {
        background: ${markerColor};
      }
    `;
    document.head.appendChild(markerStyle);

    return (
      <Marker
        position={markerPosition}
        // key={location.name}
        icon={customIcon}
      ></Marker>
    );
  };

  return (
    <Modal isOpen={onShowModal} toggle={() => onClose()} size="xl" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("create_nursery_add_nursery")}
      </ModalHeader>
      <form onSubmit={(e) => createNursery(e)} encType="multipart/form-data">
        <ModalBody>
          <p className="mb-3 popup_subtitle">
            {t("create_nursery_instructions")}
          </p>
          <p className="popup_title">{t("create_nursery_main_info")}</p>
          <div className="d-flex h border-bottom mb-5 gap">
            <div className="w-50 flex-column">
              <div className="row">
                <div className="form-block col-12 col-md-6 col-sm-6">
                  <label className="required">{t("create_nursery_name")}</label>
                  <input
                    type="text"
                    name="name"
                    className={
                      "form-input" + (validations.name ? " input-error" : "")
                    }
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    placeholder={t("create_nursery_name")}
                  />
                  {validations.name && (
                    <ValidationMessage message={validations.name[0]} />
                  )}
                </div>
                <div className="form-block col-12 col-md-6 col-sm-6">
                  <label className="required">
                    {t("create_nursery_kindergarten_nr")}
                  </label>
                  <input
                    type="number"
                    name="number"
                    className={
                      "form-input" + (validations.number ? " input-error" : "")
                    }
                    onChange={(e) => setNumber(e.target.value)}
                    value={number}
                    placeholder={t("create_nursery_kindergarten_nr")}
                  />
                  {validations.number && (
                    <ValidationMessage message={validations.number[0]} />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="form-block col-12 col-md-6 col-sm-6">
                  <label>{t("create_nursery_email")}</label>
                  <input
                    type="email"
                    name="email"
                    className={
                      "form-input" + (validations.email ? " input-error" : "")
                    }
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder={t("create_nursery_email")}
                  />
                  {validations.email && (
                    <ValidationMessage message={validations.email[0]} />
                  )}
                </div>
                <div className="form-block col-12 col-md-6 col-sm-6">
                  <label>{t("create_nursery_phone_number")}</label>
                  <input
                    type="number"
                    name="phone"
                    className={
                      "form-input" + (validations.phone ? " input-error" : "")
                    }
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    placeholder={t("create_nursery_phone_number")}
                  />
                  {validations.phone && (
                    <ValidationMessage message={validations.phone[0]} />
                  )}
                </div>
              </div>
              <div className="row mb-5">
                <div className="form-block col-12 col-md-6 col-sm-6">
                  <label>{t("create_nursery_website")}</label>
                  <input
                    type="text"
                    name="website"
                    className={
                      "form-input" + (validations.website ? " input-error" : "")
                    }
                    onChange={(e) => setWebsite(e.target.value)}
                    value={website}
                    placeholder={t("create_nursery_website")}
                  />
                  {validations.website && (
                    <ValidationMessage message={validations.website[0]} />
                  )}
                </div>
                <div className="form-block col-12 col-md-6 col-sm-6">
                  <label className="required">
                    {t("create_nursery_max_number_children")}
                  </label>
                  <input
                    type="number"
                    name="max"
                    className={
                      "form-input" +
                      (validations.max_number_children ? " input-error" : "")
                    }
                    onChange={(e) => setMaxNumberChildren(e.target.value)}
                    value={maxNumberChildren}
                    placeholder={t("create_nursery_max_number_children")}
                  />
                  {validations.max_number_children && (
                    <ValidationMessage
                      message={validations.max_number_children[0]}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex w-50 mb-5">
              <div className="form-block col-12 col-md-12 col-sm-6">
                <label>{t("create_nursery_images")}</label>
                <div className="upload-file-box">
                  <div
                    className={
                      "form-input" + (validations.images ? " input-error" : "")
                    }
                  >
                    <>
                      <input
                        className="upload-input"
                        onChange={(e) => {
                          const selectedFiles = e.target.files;
                          if (selectedFiles && selectedFiles.length > 0) {
                            setImages((prevImages) => [
                              ...prevImages,
                              ...Array.from(selectedFiles),
                            ]);
                          }
                        }}
                        accept="image/*"
                        name="image"
                        type="file"
                        multiple
                      />

                      <div className="upload-input-placeholder">
                        <i className="icon upload-icon" />
                      </div>
                      <span className="popup_title">
                        {t("create_nursery_upload_instructions")}
                      </span>
                      <span className="popup_description">
                        {t("create_nursery_limitations")}
                      </span>
                    </>
                  </div>
                  {validations.images && (
                    <ValidationMessageKindergarten
                      message={validations["images"][0]}
                    />
                  )}
                  <div className="sort-upload row mt-4">
                    {images.length > 0 &&
                      images.slice(0, 5).map((image, index) => (
                        <>
                          <div className="upload-area">
                          <button
                            className="delete-image-button"
                            onClick={(e) => {
                              e.preventDefault();
                              handleRemoveImage(index);
                            }}
                          >
                            <i className="icon close-icon" />
                          </button>
                            <img
                              key={index}
                              src={URL.createObjectURL(image)}
                              alt={`Image ${index}`}
                            />
                          </div>
                        </>
                      ))}
                    <>
                      {images.length < 5 && (
                        <div className="upload-area">
                          <input
                            className="upload-input"
                            onChange={(e) => {
                              const selectedFiles = e.target.files;
                              if (selectedFiles && selectedFiles.length > 0) {
                                setImages((prevImages) => [
                                  ...prevImages,
                                  ...Array.from(selectedFiles),
                                ]);
                              }
                            }}
                            accept="image/*"
                            name="image"
                            type="file"
                            multiple
                          />
                          <span>
                            <i className="icon upload-plus-icon" />
                          </span>
                          <span>{t("create_nursery_upload")}</span>
                        </div>
                      )}
                    </>
                  </div>
                  <div className="upload-buttons mt-4">
                    <button
                      className="has-icon button-white me-2 mb-1 mt-1"
                      onClick={(e) => handleUploadButtonClick(e)}
                    >
                      <i className="icon reader-icon" />
                      {t("create_nursery_browse")}
                    </button>
                    <input
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      className="upload-input"
                      onChange={(e) => {
                        const selectedFiles = e.target.files;
                        if (selectedFiles && selectedFiles.length > 0) {
                          setImages((prevImages) => [
                            ...prevImages,
                            ...Array.from(selectedFiles),
                          ]);
                        }
                      }}
                      accept="image/*"
                      name="image"
                      type="file"
                      multiple
                    />
                    <button
                      className="has-icon button-red delete mb-1 mt-1"
                      onClick={(e) => {
                        e.preventDefault();
                        setImages([]);
                      }}
                    >
                      <i className="icon delete-icon" />
                      {t("create_nursery_delete")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="d-flex border-bottom mb-5 row">
            <p className="popup_title mb-3">
              {t("create_nursery_add_director")}
            </p>
            <div className="form-block col-12 col-md-6 col-sm-6 mb-5">
              <label className="required">
                {t("create_nursery_director_email")}
              </label>
              <input
                type="email"
                name="email"
                className={
                  "form-input" +
                  (validations.director_email ? " input-error" : "")
                }
                onChange={(e) => setDirector_email(e.target.value)}
                value={director_email}
                placeholder={t("create_nursery_add_director_email")}
              />
              {validations.director_email && (
                <ValidationMessage message={validations.director_email[0]} />
              )}
            </div>
          </div> */}
          <p className="popup_title mb-3">{t("create_nursery_address")}</p>
          <div className="d-flex gap">
            <div className="w-50 flex-column">
              <div className="row">
                <div className="form-block col-12 col-md-6 col-sm-6">
                  <label className="required">
                    {t("create_nursery_street")}
                  </label>
                  <input
                    type="text"
                    name="address"
                    className={
                      "form-input" + (validations.address ? " input-error" : "")
                    }
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                    placeholder={t("create_nursery_street")}
                  />
                  {validations.address && (
                    <ValidationMessage message={validations.address[0]} />
                  )}
                </div>
                <div className="form-block col-12 col-md-6 col-sm-6">
                  <label>{t("create_nursery_postal_code")}</label>
                  <input
                    type="number"
                    name="postal_code"
                    className={
                      "form-input" +
                      (validations.postal_code ? " input-error" : "")
                    }
                    onChange={(e) => setPostalCode(e.target.value)}
                    value={postalCode}
                    placeholder={t("create_nursery_postal_code")}
                  />
                  {validations.postal_code && (
                    <ValidationMessage message={validations.postal_code[0]} />
                  )}
                </div>
              </div>
              <p className="popup_title mb-3">{t("create_nursery_location")}</p>
              <div className="row">
                <div className="form-block col-12 col-md-6 col-sm-6">
                  <label>{t("create_nursery_longtitude")}</label>
                  <input
                    type="number"
                    name="longitude"
                    readOnly
                    className={
                      "form-input" +
                      (validations.longtitude ? " input-error" : "")
                    }
                    value={longitude}
                    placeholder={t("create_nursery_longtitude")}
                  />
                  {validations.longtitude && (
                    <ValidationMessage message={validations.longtitude[0]} />
                  )}
                </div>
                <div className="form-block col-12 col-md-6 col-sm-6">
                  <label>{t("create_nursery_latitude")}</label>
                  <input
                    type="number"
                    name="latitude"
                    readOnly
                    className={
                      "form-input" +
                      (validations.latitude ? " input-error" : "")
                    }
                    value={latitude}
                    placeholder={t("create_nursery_latitude")}
                  />
                  {validations.latitude && (
                    <ValidationMessage message={validations.latitude[0]} />
                  )}
                </div>
              </div>
            </div>
            <div className="w-50">
              <div className="form-block col-12 col-md-12 col-sm-6">
                <div id="map">
                  <MapContainer
                    center={[latitude, longitude]}
                    zoom={13}
                    scrollWheelZoom={false}
                    attributionControl={false}
                    style={{ width: "auto", height: "217px" }}
                  >
                    <MapClickHandler />
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                      url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
                    />
                    {locations.map((location, index) =>
                      createMarker(location, index)
                    )}
                  </MapContainer>
                </div>
                <p className="popup_description">
                  {t("create_nursery_map_description")}
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("create_nursery_cancel")}
          </button>
          {btnLoading ? (
            <button type="button" className="button-blue has-icon">
              <i className="icon spinner-icon" />
              {t("create_nursery_saving")}
            </button>
          ) : (
            <button type="submit" className="button-blue has-icon">
              <i className="icon plus-icon" />
              {t("create_nursery_save")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};
export default CreateNursery;
