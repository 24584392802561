import * as AppConfig from "../helpers/AppConfig";

const API_HEADERS = {
  unauthenticated: {
    "api-key": AppConfig.apiKey,
    "Accept-Language": localStorage.getItem("i18nextLng") === "al" ? "al" : "en",
  },
  authenticated: {
    authorization: `Bearer ${AppConfig.accessData("token")}`,
    "api-key": AppConfig.apiKey,
    "Accept-Language": localStorage.getItem("i18nextLng") === "al" ? "al" : "en",
  },
  blob: {
    Authorization: `Bearer ${AppConfig.accessData("token")}`,
    "api-key": AppConfig.apiKey,
    "Accept-Language":
      localStorage.getItem("i18nextLng") === "al" ? "al" : "en",
    responseType: "blob",
  },
};

export default API_HEADERS;
