import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams } from "react-router";
import * as AppConfig from "../../../../helpers/AppConfig";
import { makeApiCall } from "src/api/apiRequests";

import API_PATHS from "src/api/apiPaths";
import PATHS from "src/routes/Paths";
import API_HEADERS from "src/api/apiConfig";
import SearchFilter from "src/helpers/SearchFilter";
import ListResponse from "src/helpers/ListResponse";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import CustomPagination from "src/helpers/CustomPaginate";
import * as ConfirmPopup from "../../../../helpers/ConfirmPopup";
import Loading from "src/helpers/Loading";

const ShowKindergartenStaff: React.FC = () => {
  const { t } = useTranslation("");
  const param = useParams();

  const [staff, setStaff] = useState<Record<string, any>[]>([]);
  const [kindergartenId, setKindergartenId] = useState<Record<string, any>[]>([]);

  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState<number>(1);
  const [paginate, setPaginate] = useState<any>([]);
  const [modalId, setModalId] = useState<number | null>(null);
  const [modified, setModified] = useState(false);

  const getStaff = async (hasSearch: any | undefined): Promise<void> => {
    setModified(false);
    const kindergartenId = param.id;

    const searchParams: any = {};
    let request: any = API_PATHS.staffList;

    if (activePage > 1) {
      searchParams["page"] = activePage;
    }

    if (kindergartenId) {
      searchParams["kindergarten_ids[]"] = kindergartenId;
    }

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      setStaff(response.staff_members.data);
      setPaginate(response.staff_members);
      setLoading(false);
    } catch (error: any) {
    } finally {
    }
  };

  useEffect(() => {
    getStaff("");
  }, [modified, activePage]);  
  
  return (
    <>
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                style={{ width: "25%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon name-lastname-icon" />
                {t("show_kindergarten_staff_name_lastname")}
              </th>
              <th
                style={{ width: "25%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon address-icon" />
                {t("show_kindergarten_staff_address")}
              </th>
              <th
                style={{ width: "25%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon telephone-icon" />
                {t("show_kindergarten_staff_mobile")}
              </th>
              <th
                style={{ width: "25%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon email-icon" />
                {t("show_kindergarten_staff_email")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
        <tr>
          <td className="text-center" colSpan={4}>
            <Loading />
          </td>
        </tr>
      ) : (
        staff.map((member, index) => (
            <tr key={member.id}>
              <td
                role="cell"
                className="text-left bold-text"
                data-label={t("show_kindergarten_staff_name_lastname")}
              >
                {(member.first_name ? member.first_name : "-") +
                      " " +
                      (member.last_name ? member.last_name : "-")}
              </td>
              <td
                role="cell"
                className="text-center"
                data-label={t("show_kindergarten_staff_address")}
              >
                {member.address ? member.address : "-"}
              </td>
              <td
                role="cell"
                className="text-center"
                data-label={t("show_kindergarten_staff_mobile")}
              >
                {member.phone ? member.phone : "-"}
              </td>
              <td
                role="cell"
                className="text-center blue-text"
                data-label={t("show_kindergarten_staff_email")}
              >
                {member.email ? member.email : "-"}
              </td>
            </tr>)))}
          </tbody>
        </table>
        <ListResponse totalItems={staff.length} loading={loading} />
      </div>
      <CustomPagination
        data={paginate}
        setActivePage={(e) => setActivePage(e)}
      />
    </>
  );
};
export default ShowKindergartenStaff;
