import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Sidebar from "src/components/layouts/sidebar/Sidebar";
import Breadcrumb from "src/components/layouts/Common/Breadcrumb";
import EditFirstStepNursery from "src/components/panel/applications_nursery/application_first_step/EditFirstStep";
import * as AppConfig from "../../../../helpers/AppConfig";

const EditFirstStepNurseryView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("applications_list_view")}</title>
      </Helmet>
      <Sidebar>
        <Breadcrumb title={t("applications_title")} />
        
        <EditFirstStepNursery />
      </Sidebar>
    </>
  );
};

export default EditFirstStepNurseryView;
