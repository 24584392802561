import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import { useParams } from "react-router-dom";
import SearchFilter from "src/helpers/SearchFilter";

interface ViewResponsiblePersonProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  responsiblePersonId: number;
}

const ViewResponsiblePerson: React.FC<ViewResponsiblePersonProps> = ({
  onShowModal,
  setShowModal,
  responsiblePersonId,
}) => {
  const { t } = useTranslation();
  const { childId } = useParams();

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [relationToChild, setRelationToChild] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(true);

  const onClose = () => {
    setShowModal(false);
  };

  const fetchResponsiblePerson = async (): Promise<void> => {
    const searchParams: any = {
      child_id: childId,
    };

    let request: any = SearchFilter(
      searchParams,
      API_PATHS.getChildResponsiblePerson(responsiblePersonId)
    );

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      const { responsiblePerson } = response;

      setLoading(false);
      setFirstName(responsiblePerson.first_name);
      setLastName(responsiblePerson.last_name);
      setPhone(responsiblePerson.phone ? responsiblePerson.phone : "-");
      setEmail(responsiblePerson.email ? responsiblePerson.email : "-");
      setRelationToChild(responsiblePerson.relation_to_child);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      onClose();
    }
  };

  useEffect(() => {
    fetchResponsiblePerson();
  }, []);

  return (
    <Modal
      isOpen={
        onShowModal
        && !loading
      }
      toggle={() => onClose()}
      size="md"
      centered
    >
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {firstName + " " + lastName}
      </ModalHeader>
      <ModalBody>
        <div className="modal-note">
          {`${t(
            "child_responsible_persons_show_modal_desc"
          )} ${firstName} ${lastName}`}
        </div>
        <div className="modal-info-block">
          <div className="row">
            <div className="info col-12 col-sm-6">
              <span className="label">
                {t("child_responsible_persons_show_modal_firstname")}
              </span>
              <span className="item">{firstName}</span>
            </div>
            <div className="info col-12 col-sm-6">
              <span className="label">
                {t("child_responsible_persons_show_modal_lastname")}
              </span>
              <span className="item">{lastName}</span>
            </div>
            <div className="info col-12 col-sm-6">
              <span className="label">
                {t("child_responsible_persons_show_modal_phone")}
              </span>
              <span className="item">{phone}</span>
            </div>
            <div className="info col-12 col-sm-6">
              <span className="label">
                {t("child_responsible_persons_show_modal_email")}
              </span>
              <span className="item">{email}</span>
            </div>
            <div className="info col-12 col-sm-6">
              <span className="label">
                {t("child_responsible_persons_show_modal_relation_with_child")}
              </span>
              <span className="item">{relationToChild}</span>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className="button-white"
          type="button"
          onClick={() => onClose()}
        >
          {t("child_responsible_persons_show_modal_close_button")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ViewResponsiblePerson;
