import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import EvaluationStars from "./EvaluationStars";
import moment from "moment";

interface ViewPerformanceProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  performanceId: number;
}

const ViewPerformance: React.FC<ViewPerformanceProps> = ({
  onShowModal,
  setShowModal,
  performanceId,
}) => {
  const { t } = useTranslation();

  const [teacher, setTeacher] = useState<string>("");
  const [evaluationDate, setEvaluationDate] = useState<Date | null>(null);
  const [evaluation, setEvaluation] = useState<number>(0);
  const [comment, setComment] = useState<string>("");

  const [loading, setLoading] = useState(true);

  const fetchPerformance = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.teacherPerformanceDetails(performanceId),
        "GET",
        API_HEADERS.authenticated
      );
      setLoading(false);
      setTeacher(
        response.teacher_evaluation.teacher.first_name +
          " " +
          response.teacher_evaluation.teacher.last_name
      );
      setEvaluationDate(response.teacher_evaluation.evaluation_date);
      setEvaluation(response.teacher_evaluation.evaluation_score);
      setComment(response.teacher_evaluation.evaluation_comment);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      onClose();
    }
  };

  useEffect(() => {
    fetchPerformance();
  }, []);

  const onClose = () => {
    setShowModal(false);
  };

  return (
    <Modal
      isOpen={onShowModal && !loading}
      toggle={() => onClose()}
      size="md"
      centered
    >
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("performance_view_evaluation_title")}
      </ModalHeader>
      <ModalBody>
        <div className="modal-note">
          {t("performance_view_evaluation_desc") + " " + teacher}
        </div>
        <div className="modal-info-block">
          <div className="row">
            <div className="info col-12 col-sm-6 col-lg-4">
              <span className="label">
                {t("performance_view_evaluation_evaluation_date_label")}
              </span>
              <span className="item">
                {moment(evaluationDate).format("DD-MM-YYYY")}
              </span>
            </div>
            <div className="info col-12 col-sm-6 col-lg-4">
              <span className="label">
                {t("performance_view_evaluation_evaluation_label")}
              </span>
              <span className="item">
                <EvaluationStars evaluationNr={evaluation} />
              </span>
            </div>
            <div className="info col-12">
              <span className="label">
                {t("performance_view_evaluation_comment_label")}
              </span>
              <span className="item">{comment}</span>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className="button-white"
          type="button"
          onClick={() => onClose()}
        >
          {t("performance_view_evaluation_cancel_button")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ViewPerformance;
