import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AllergicGroupsSidebar from "./AllergicGroupsSidebar";
import Products from "./allergic_products/Products";
import AddNewProduct from "./allergic_products/AddNewProduct";

const AllergicGroups: React.FC = () => {
  const { t } = useTranslation();
  const { Id } = useParams();

  // Add new product modal
  const [addNewProductModal, setAddNewProductModal] = useState<boolean>(false);
  const [modified, setModified] = useState<boolean>(false);

  return (
    <>
      <div className="sidebar-block">
        <AllergicGroupsSidebar allergyId={Id} />
        <div className="products">
          {Id && (
            <div className="add-product">
              <button
                type="button"
                className="button-blue has-icon"
                onClick={() => setAddNewProductModal(true)}
              >
                <i className="icon plus-icon" />
                {t("meal_allergies_product_add_new")}
              </button>

              {addNewProductModal && (
                <AddNewProduct
                  onShowModal={addNewProductModal}
                  setShowModal={(e) => setAddNewProductModal(e)}
                  setModified={(e) => setModified(e)}
                />
              )}
            </div>
          )}
          {Id ? (
            <Products
              modified={modified}
              setModified={(e) => setModified(e)}
            />
          ) : (
            <div className="no-product-selected">
              {t("meal_allergies_product_no_selected_product")}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AllergicGroups;
