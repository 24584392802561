import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { SingleValue } from "react-select";
import { makeApiCall } from "src/api/apiRequests";
import { useNavigate } from "react-router-dom";
import { LanguageDate } from "src/helpers/CheckLanguageDate";
import DatePicker from "react-datepicker";
import ReactChart from "src/components/common/ReactChart";
import moment from "moment";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import SearchFilter from "src/helpers/SearchFilter";
import ExportTransfer from "./ExportTransfer";

const Transfers: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Search
  const [dateStart, setDateStart] = useState<Date | null>(null);
  const [dateEnd, setDateEnd] = useState<Date | null>(null);
  const [kindergartenId, setKindergartenId] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [statusId, setStatusId] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [childrenCategory, setChildrenCategory] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [type, setType] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [transferType, setTransferType] =
    useState<SingleValue<{ value: string; label: string }>>(null);

  // Transfers data
  const [transfersData, setTransfersData] = useState<any[]>([]);
  const [transferFiltersData, setTransferFiltersData] = useState<any>({});
  const [searchBtnLoading, setSearchBtnLoading] = useState<boolean>(false);

  // Export modal
  const [exportModal, setExportModal] = useState<boolean>(false);

  const fetchReport = async (e?: any): Promise<void> => {
    let searchParams: any = {};

    if (e) {
      e.preventDefault();
      setSearchBtnLoading(true);

      dateStart &&
        (searchParams["date_start"] = moment(dateStart).format("yyy-MM-DD"));
      dateEnd &&
        (searchParams["date_end"] = moment(dateEnd).format("yyy-MM-DD"));
      kindergartenId &&
        (searchParams["kindergarten_id"] = kindergartenId.value);
      statusId && (searchParams["status_id"] = statusId.value);
      childrenCategory &&
        (searchParams["children_category"] = childrenCategory.value);
      type && (searchParams["type"] = type.value);
      transferType && (searchParams["transfer_type"] = transferType.value);
    }

    let request: any = SearchFilter(
      searchParams,
      API_PATHS.reportsTransfersList
    );
    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setTransfersData(response.transfers);
      setTransferFiltersData(response.filters);
    } catch (error: any) {
      if (error.response.status === 404) {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        navigate(-1);
      }
    } finally {
      setSearchBtnLoading(false);
    }
  };

  useEffect(() => {
    fetchReport();
  }, []);

  const fillOptions = (filter: string, label: string) => {
    const options: any[] = [];

    transferFiltersData[filter] &&
      transferFiltersData[filter].forEach((option: any) => {
        option[label] !== "other" &&
          options.push({
            value: option.id,
            label: option[label],
          });
      });
    return options;
  };

  const fullYearData = (type: string) => {
    let chartData: any[] = [];
    transfersData.forEach((transfer) => {
      chartData.push(
        type === "data"
          ? {
              x: `${transfer.month}/${transfer.year}`,
              y: transfer.nr_transfers,
            }
          : `${transfer.month}/${transfer.year}`
      );
    });
    return chartData;
  };

  return (
    <>
      <div className="card mt-4">
        <div className="card-body">
          <div className="card-title">{t("reports_transfer_title")}</div>
          <form onSubmit={(e) => fetchReport(e)} className="row reports-search">
            <div className="form-block">
              <label>{t("reports_transfer_date_start_label")}</label>
              <DatePicker
                selected={dateStart}
                onChange={(e) => setDateStart(e)}
                showMonthDropdown
                showYearDropdown
                dateFormat="dd-MM-yyyy"
                placeholderText={t("reports_transfer_date_start_placeholder")}
                className="form-datepicker"
                autoComplete="off"
                name="date_start"
                maxDate={new Date()}
                locale={LanguageDate.datepicker}
              />
            </div>
            <div className="form-block">
              <label>{t("reports_transfer_date_end_label")}</label>
              <DatePicker
                selected={dateEnd}
                onChange={(e) => setDateEnd(e)}
                showMonthDropdown
                showYearDropdown
                dateFormat="dd-MM-yyyy"
                placeholderText={t("reports_transfer_date_end_placeholder")}
                className="form-datepicker"
                autoComplete="off"
                name="date_end"
                maxDate={new Date()}
                locale={LanguageDate.datepicker}
              />
            </div>
            <div className="form-block">
              <label>{t("reports_transfer_kindergarten_label")}</label>
              <Select
                placeholder={t("reports_transfer_kindergarten_placeholder")}
                className="form-react-select"
                classNamePrefix="select_input"
                name="kindergarten_id"
                onChange={(e) => setKindergartenId(e)}
                isClearable={true}
                isSearchable={false}
                options={fillOptions("kindergartens", "name")}
                value={kindergartenId}
              />
            </div>
            <div className="form-block">
              <label>{t("reports_transfer_status_label")}</label>
              <Select
                placeholder={t("reports_transfer_status_placeholder")}
                className="form-react-select"
                classNamePrefix="select_input"
                name="status_id"
                onChange={(e) => setStatusId(e)}
                isClearable={true}
                isSearchable={false}
                options={fillOptions("statuses", "name")}
                value={statusId}
              />
            </div>
            <div className="form-block">
              <label>{t("reports_transfer_children_category_label")}</label>
              <Select
                placeholder={t(
                  "reports_transfer_children_category_placeholder"
                )}
                className="form-react-select"
                classNamePrefix="select_input"
                name="children_category"
                onChange={(e) => setChildrenCategory(e)}
                isClearable={true}
                isSearchable={false}
                options={fillOptions("children_category", "title")}
                value={childrenCategory}
              />
            </div>
            <div className="form-block">
              <label>{t("reports_transfer_type_label")}</label>
              <Select
                placeholder={t("reports_transfer_type_placeholder")}
                className="form-react-select"
                classNamePrefix="select_input"
                name="type"
                onChange={(e) => setType(e)}
                isClearable={true}
                isSearchable={false}
                options={[
                  {
                    value: "cerdhe",
                    label: t("reports_transfer_type_nursery"),
                  },
                  {
                    value: "kopesht",
                    label: t("reports_transfer_type_kindergarten"),
                  },
                ]}
                value={type}
              />
            </div>
            <div className="form-block">
              <label>{t("reports_transfer_type_label")}</label>
              <Select
                placeholder={t("reports_transfer_type_placeholder")}
                className="form-react-select"
                classNamePrefix="select_input"
                name="transfer_type"
                onChange={(e) => setTransferType(e)}
                isClearable={true}
                isSearchable={false}
                options={[
                  {
                    value: "cerdhe-cerdhe",
                    label: t("reports_transfer_type_nursery_nursery"),
                  },
                  {
                    value: "cerdhe-kopesht",
                    label: t("reports_transfer_type_nursery_kindergarten"),
                  },
                  {
                    value: "kopesht-kopesht",
                    label: t("reports_transfer_type_kindergarten_kindergarten"),
                  },
                ]}
                value={transferType}
              />
            </div>
            <div className="d-flex">
              {searchBtnLoading ? (
                <button
                  type="button"
                  className="button-orange has-icon my-auto"
                >
                  <i className="icon spinner-icon" />
                  {t("reports_transfer_searching_button")}
                </button>
              ) : (
                <button
                  type="submit"
                  className="button-orange has-icon my-auto"
                >
                  <i className="icon search-icon" />
                  {t("reports_transfer_search_button")}
                </button>
              )}
              <button
                type="button"
                className="button-blue ms-4 has-icon my-auto"
                onClick={() => setExportModal(true)}
              >
                <i className="icon export-icon" />
                {t("reports_transfer_export_type_button")}
              </button>
              {exportModal && (
                <ExportTransfer
                  filterData={{
                    dateStart: dateStart,
                    dateEnd: dateEnd,
                    kindergartenId: kindergartenId,
                    statusId: statusId,
                    childrenCategory: childrenCategory,
                    type: type,
                    transferType: transferType,
                  }}
                  onShowModal={exportModal}
                  setShowModal={setExportModal}
                />
              )}
            </div>
          </form>
          <div className="row">
            <ReactChart
              type="line"
              series={[
                {
                  name: t("reports_transfer_chart_title"),
                  color: "#3366ff",
                  data: fullYearData("data"),
                },
              ]}
              otherOptions={{
                xaxis: {
                  type: "numeric",
                  categories: fullYearData("categories"),
                  labels: {
                    rotate: -45,
                  },
                  tickPlacement: "between",
                },
                yaxis: {
                  title: {
                    text: t("reports_transfer_chart_title"),
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Transfers;
