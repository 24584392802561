import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { LanguageDate } from "src/helpers/CheckLanguageDate";

interface DatePickerInputProps {
  value?: string;
  onClick?: () => void;
}

interface FoodNavProps {
  setSelectedDate: (date: Date) => void;
  date: Date;
}

const DatePickerButton = forwardRef<HTMLButtonElement, DatePickerInputProps>(
  ({ value, onClick }, ref) => (
    <button
      className="datepicker-button button-unstyled"
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  )
);

const FoodNav: React.FC<FoodNavProps> = ({ setSelectedDate, date }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<any>(0);

  useEffect(() => {
    getWeekdayFromDate(date);
  }, [date]);

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const handleDayClick = (dayIndex: any) => {
    if (date) {
      setActiveTab(dayIndex);
      const currentDate = new Date();
      const selectedDay = new Date(currentDate);
      selectedDay.setDate(date.getDate() - date.getDay() + dayIndex);

      setSelectedDate(selectedDay);
    }
  };

  const isWeekend = (date: any) => {
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6;
  };

  function getWeekdayFromDate(date: any) {
    const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
    const dayIndex = date.getDay();

    setActiveTab(daysOfWeek[dayIndex - 1]);
  }

  const weekdays = [
    t("child_food_week_monday"),
    t("child_food_week_tuesday"),
    t("child_food_week_wednesday"),
    t("child_food_week_thursday"),
    t("child_food_week_friday"),
  ];

  return (
    <div className="child-food-nav">
      <div className="form-block">
        <DatePicker
          name="year"
          showMonthDropdown
          showYearDropdown
          selected={date}
          customInput={<DatePickerButton />}
          onChange={handleDateChange}
          dateFormat="dd MMM, yyyy"
          className="form-datepicker"
          isClearable={false}
          filterDate={(date) => !isWeekend(date)}
          locale={LanguageDate.datepicker}
        />
      </div>
      <div className="customized-tabs">
        <div className="blue-tabs">
          <div className="tab-buttons">
            {weekdays.map((weekday, index) => (
              <button
                key={index}
                className={`tab-button${
                  activeTab === weekday ? " active" : ""
                }`}
                onClick={() => handleDayClick(index + 1)}
              >
                {weekday}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoodNav;
