import React from "react";

interface BreadcrumbProps {
  title?: string;
  subtitle?: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ title, subtitle }) => {
  return (
    <div className="row breadcrumb">
      <div className="col-12 col">
        <div className="page-title-box">
          {title ? <h4 className="breadcrumb-title">{title}</h4> : ""}
          {subtitle ? (
            <span className="breadcrumb-subtitle">{subtitle}</span>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
