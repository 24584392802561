import { useTranslation } from "react-i18next";
import { EditEducationProgramStore } from "src/store/education_program/EditEducationProgram";
import WeekContent from "./WeekContent";

const WeekTabs: React.FC = () => {
  const { t } = useTranslation();

  const { submited, tabs, changeTab } = EditEducationProgramStore(
    (state: any) => ({
      submited: state.submited,
      tabs: state.tabs,
      changeTab: state.changeTab,
    })
  );
  const weeks = [1, 2, 3, 4];

  const handleTabClick = (week: number) => {
    changeTab("weeks", week);
  };

  return (
    <div className="customized-tabs">
      <div className="week-tabs">
        <div className="tab-buttons">
          {weeks.map((week) => (
            <button
              key={week}
              className={`tab-button${
                tabs.weeks.active === week ? " active" : ""
              }`}
              onClick={() => handleTabClick(week)}
            >
              {submited.loading &&
                submited.form === "weeks" &&
                tabs.weeks.active === week && <i className="spinner-icon" />}
              {t("education_program_edit_week_" + week)}
            </button>
          ))}
        </div>
        <div className="tab-content-container">
          {weeks.map(
            (week) =>
              tabs.weeks.active === week && (
                <div key={week} className="card-content">
                  <WeekContent />
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default WeekTabs;
