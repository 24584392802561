import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import Select, { SingleValue } from "react-select";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import ValidationMessage from "src/helpers/ValidationMessage";
import SearchFilter from "src/helpers/SearchFilter";
import moment from "moment";

interface ExportTransferProps {
  onShowModal: boolean;
  filterData: any;
  setShowModal: (visible: boolean) => void;
}

const ExportTransfer: React.FC<ExportTransferProps> = ({
  onShowModal,
  filterData,
  setShowModal,
}) => {
  const { t } = useTranslation();

  const [exportType, setExportType] =
    useState<SingleValue<{ value: string; label: string }>>(null);

  const [loadingButton, setLoadingButton] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const onClose = () => {
    setShowModal(false);
  };

  const exportTransfers = async (e: any): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    setLoadingButton(true);
    let searchParams: any = {};

    filterData.dateStart &&
      (searchParams["date_start"] = moment(filterData.dateStart).format(
        "yyy-MM-DD"
      ));
    filterData.dateEnd &&
      (searchParams["date_end"] = moment(filterData.dateEnd).format(
        "yyy-MM-DD"
      ));
    filterData.kindergartenId &&
      (searchParams["kindergarten_id"] = filterData.kindergartenId.value);
    filterData.statusId &&
      (searchParams["status_id"] = filterData.statusId.value);
    filterData.childrenCategory &&
      (searchParams["children_category"] = filterData.childrenCategory.value);
    filterData.type && (searchParams["type"] = filterData.type.value);
    filterData.transferType &&
      (searchParams["transfer_type"] = filterData.transferType.value);
    exportType && (searchParams["export_type"] = exportType.value);

    let request: any = SearchFilter(
      searchParams,
      API_PATHS.reportsExportTransfers
    );

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated,
        {},
        "blob"
      );

      let mimeType;

      switch (exportType?.value) {
        case "CSV":
          mimeType = "text/csv";
          break;
        case "XLS":
          mimeType = "application/vnd.ms-excel";
          break;
        case "XLSX":
          mimeType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          break;
        case "HTML":
          mimeType = "text/html";
          break;
        default:
          mimeType = "application/octet-stream";
      }

      const blob = new Blob([response], { type: mimeType });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = `transfers.${exportType?.value.toLowerCase()}`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      onClose();
    } catch (error: any) {
      let errorString = error.response.data;
      if (
        error.request.responseType === "blob" &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf("json") !== -1
      ) {
        errorString = JSON.parse(await error.response.data.text());
      }

      if (error.response.status === 422) {
        setValidations(errorString);
      } else {
        FLASH_MESSAGES.errorMsg(errorString.error);
        onClose();
      }
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <Modal isOpen={onShowModal} toggle={() => onClose()} size="sm" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("reports_transfer_export_modal_title")}
      </ModalHeader>
      <form onSubmit={(e) => exportTransfers(e)}>
        <ModalBody>
          <div className="modal-note">
            {t("reports_transfer_export_modal_desc")}
          </div>
          <div className="row">
            <div className="form-block">
              <label className="required">
                {t("reports_transfer_export_modal_format_label")}
              </label>
              <Select
                placeholder={t(
                  "reports_transfer_export_modal_format_placeholder"
                )}
                className={
                  "form-react-select" +
                  (validations.export_type ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="export_type"
                onChange={(e) => setExportType(e)}
                isClearable={true}
                isSearchable={false}
                options={[
                  {
                    value: "CSV",
                    label: "CSV",
                  },
                  {
                    value: "XLSX",
                    label: "XLSX",
                  },
                  {
                    value: "XLS",
                    label: "XLS",
                  },
                  {
                    value: "HTML",
                    label: "HTML",
                  },
                ]}
                value={exportType}
              />

              {validations.export_type && (
                <ValidationMessage message={validations.export_type[0]} />
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("reports_transfer_export_modal_close")}
          </button>

          {loadingButton ? (
            <button type="button" className="button-blue has-icon">
              <i className="spinner-icon" />
              {t("reports_transfer_export_modal_exporting")}
            </button>
          ) : (
            <button type="submit" className="button-blue has-icon">
              <i className="icon export-icon" />
              {t("reports_transfer_export_modal_export")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ExportTransfer;
