import { useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Select, { SingleValue } from "react-select";
import { makeApiCall } from "src/api/apiRequests";
import { useAgeGroups } from "src/store/education_program/AgeGroups";
import { months } from "../data";
import { LanguageDate } from "src/helpers/CheckLanguageDate";
import moment from "moment";
import DatePicker from "react-datepicker";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import ValidationMessage from "src/helpers/ValidationMessage";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

interface AddNewEducationProgramsProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  setModified: (visible: boolean) => void;
}

const AddNewEducationPrograms: React.FC<AddNewEducationProgramsProps> = ({
  onShowModal,
  setShowModal,
  setModified,
}) => {
  const { t } = useTranslation();

  const ageGroupsData = useAgeGroups((state: any) => state.ageGroups);

  const [name, setName] = useState<string>("");
  const [programAgeGroup, setProgramAgeGroup] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [topic, setTopic] = useState<string>("");
  const [year, setYear] = useState<Date | null>(null);
  const [month, setMonth] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [image, setImage] = useState<any>(null);

  const [loadingButton, setLoadingButton] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const onClose = () => {
    setShowModal(false);
  };

  const addEducationProgram = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setLoadingButton(true);

    const formData = new FormData();
    formData.append("name", name);
    formData.append("topic", topic);
    formData.append("year", moment(year).format("yyyy"));
    formData.append("month", month ? month?.value : "");

    image && formData.append("image", image);
    formData.append(
      "program_age_group_id",
      programAgeGroup ? programAgeGroup?.value : ""
    );

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.educationProgramStore,
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);

      setModified(true);

      onClose();

      setLoadingButton(false);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        onClose();
      }
      setLoadingButton(false);
    }
  };

  return (
    <Modal isOpen={onShowModal} toggle={() => onClose()} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("education_add_new_title")}
      </ModalHeader>
      <form onSubmit={(e) => addEducationProgram(e)}>
        <ModalBody>
          <div className="modal-note">{t("education_add_new_desc")}</div>
          <div className="row">
            <div className="form-block col-12 col-sm-6">
              <label className="required">
                {t("education_add_new_name_label")}
              </label>
              <input
                type="text"
                name="name"
                placeholder={t("education_add_new_name_placeholder")}
                value={name}
                className={
                  "form-input" + (validations.name ? " input-error" : "")
                }
                autoComplete="off"
                onChange={(e) => setName(e.target.value)}
              />
              {validations.name && (
                <ValidationMessage message={validations.name[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6">
              <label className="required">
                {t("education_add_new_age_group_label")}
              </label>
              <Select
                placeholder={t(
                  "education_add_new_choose_age_group_placeholder"
                )}
                className={
                  "form-react-select" +
                  (validations.program_age_group_id ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="program_age_group_id"
                onChange={(e) => setProgramAgeGroup(e)}
                isClearable={true}
                isSearchable={false}
                options={ageGroupsData.map((ageGroup: any) => ({
                  value: ageGroup.id,
                  label: `${ageGroup.from} - ${ageGroup.to} ${t(
                    "education_add_new_choose_age_group_months_placeholder"
                  )}`,
                }))}
                value={programAgeGroup}
              />
              {validations.program_age_group_id && (
                <ValidationMessage
                  message={validations.program_age_group_id[0]}
                />
              )}
            </div>
            <div className="form-block col-12">
              <label className="required">
                {t("education_add_new_topic_label")}
              </label>
              <input
                type="text"
                name="topic"
                placeholder={t("education_add_new_topic_placeholder")}
                value={topic}
                className={
                  "form-input" + (validations.topic ? " input-error" : "")
                }
                autoComplete="off"
                onChange={(e) => setTopic(e.target.value)}
              />
              {validations.topic && (
                <ValidationMessage message={validations.topic[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6">
              <label className="required">
                {t("education_add_new_year_label")}
              </label>
              <DatePicker
                name="year"
                selected={year}
                onChange={(e) => setYear(e)}
                showYearPicker
                dateFormat="yyyy"
                isClearable
                placeholderText={t("education_add_new_year_placeholder")}
                className={
                  "form-datepicker" + (validations.year ? " input-error" : "")
                }
                popperClassName="navigations"
                yearItemNumber={12}
                autoComplete="off"
                minDate={new Date(new Date().getFullYear() - 10, 0, 1)}
                maxDate={new Date(new Date().getFullYear() + 10, 0, 1)}
                locale={LanguageDate.datepicker}
              />
              {validations.year && (
                <ValidationMessage message={validations.year[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6">
              <label className="required">
                {t("education_add_new_month_label")}
              </label>
              <Select
                placeholder={t("education_add_new_month_placeholder")}
                className={
                  "form-react-select" +
                  (validations.month ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="month"
                onChange={(e) => setMonth(e)}
                isClearable={true}
                isSearchable={false}
                options={months}
                value={month}
              />
              {validations.month && (
                <ValidationMessage message={validations.month[0]} />
              )}
            </div>
            <div className="form-block col-12">
              <label>{t("education_add_new_image_label")}</label>
              <div className="upload-box">
                <div
                  className={
                    "form-input" + (validations.image ? " input-error" : "")
                  }
                >
                  {image === null ? (
                    <>
                      <input
                        className="upload-input"
                        onChange={(e) =>
                          setImage(e.target.files ? e.target.files[0] : null)
                        }
                        name="image"
                        accept="image/*"
                        type="file"
                      />
                      <div className="upload-input-placeholder">
                        <span>{t("education_add_new_image_placeholder")}</span>
                        <button type="button" className="button-white has-icon">
                          <i className="icon upload-box-icon" />
                          {t("education_add_new_image_button")}
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="upload-input-placeholder">
                        <span>{image.name}</span>
                        <i
                          className="icon close-icon"
                          onClick={() => setImage(null)}
                        />
                      </div>
                    </>
                  )}
                </div>
                {image && (
                  <div className="image">
                    <img
                      src={URL.createObjectURL(image)}
                      alt="Education program"
                    />
                  </div>
                )}
              </div>
              {validations.image && (
                <ValidationMessage message={validations.image[0]} />
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("education_add_new_cancel_button")}
          </button>

          {loadingButton ? (
            <button type="button" className="button-blue has-icon">
              <i className="spinner-icon" />
              {t("education_add_new_adding_button")}
            </button>
          ) : (
            <button type="submit" className="button-blue has-icon">
              <i className="icon add-icon" />
              {t("education_add_new_add_button")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddNewEducationPrograms;
