import { useState, useRef, FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Select, { SingleValue } from "react-select";

import PATHS from "../../../routes/Paths";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import ValidationMessage from "src/helpers/ValidationMessage";
import ValidationMessageKindergarten from "src/helpers/ValidationMessageKindergarten";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import { useUserdata } from "src/store/UserData";
import { albumsImages } from "src/helpers/AppConfig";

interface EditAlbumPopup {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  albumId: number;
  setModified: (visible: boolean) => void;
}

const EditAlbumPopup: React.FC<EditAlbumPopup> = ({
  onShowModal,
  setShowModal,
  albumId,
  setModified,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation("");
  const userData = useUserdata((state: any) => state.userData);

  const [name, setName] = useState("");
  const [kindergartenId, setKindergartenId] = useState(
    userData.kindergarten.id
  );
  const [kindergartenGroupId, setKindergartenGroupId] = useState("");
  const [kindergartenGroupLabel, setKindergartenGroupLabel] = useState("");
  const [images, setImages] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [kindergartenGroup, setKindergartenGroup] = useState<
    Record<string, any>[]
  >([]);

  const [newImages, setNewImages] = useState<File[]>([]);
  const [imagesPath, setImagesPath] = useState<any>([]);
  const [deleteImages, setDeleteImages] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const updateAlbum = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setBtnLoading(true);

    const formData = new FormData();
    formData.append("name", name);
    images.forEach((image, index) => {
      formData.append(`images[${index + imagesPath.length}]`, image);
    });
    deleteImages.forEach((imageId, index) => {
      formData.append(`delete_images[${index}]`, imageId.toString());
    });
    formData.append("kindergarten_group_id", kindergartenGroupId);

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.updateAlbum(albumId),
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);
      navigate(PATHS.gallery);
      onClose();
      setModified(true);

      setBtnLoading(false);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
      }
      setBtnLoading(false);
    }
  };

  const handleUploadButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const showAlbumnImages = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.showAlbum(Number(albumId)),
        "GET",
        API_HEADERS.authenticated
      );
      setLoading(false);

      setImagesPath(response.album.photos);
      setName(response.album.name);
      setKindergartenGroupId(response.album.kindergarten_group.id);
      setKindergartenGroupLabel(response.album.kindergarten_group.first_name);
      getKindergartenGroup();
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
    }
  };

  useEffect(() => {
    showAlbumnImages();
  }, []);

  const getKindergartenGroup = async (): Promise<void> => {
    let request: any =
      API_PATHS.kindergartenGrouplist + "?/kindergarten_id=" + kindergartenId;
    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setValidations({});
      setKindergartenGroup(response.kindergartensgroups);
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      setBtnLoading(false);
      navigate(-1);
    }
  };

  const onClose = () => {
    setShowModal(false);
    setModified(false);
  };

  const handleDeleteImage = (indexToDelete: number, imageId: number) => {
    if (imageId) {
      setDeleteImages((prevIds) => [...prevIds, imageId]);

      const updatedImagesPath = [...imagesPath];
      updatedImagesPath.splice(indexToDelete, 1);
      setImagesPath(updatedImagesPath);
    } else {
      const updatedNewImages = [...newImages];
      updatedNewImages.splice(indexToDelete, 1);
      setNewImages(updatedNewImages);

      const updatedImages = [...images];
      updatedImages.splice(indexToDelete, 1);
      setImages(updatedImages);
    }
  };

  return (
    <Modal isOpen={onShowModal} toggle={() => onClose()} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("edit_album_add_album")}
      </ModalHeader>
      <form onSubmit={(e) => updateAlbum(e)}>
        <ModalBody>
          <div className="modal-note">{t("edit_album_description")}</div>
          <p className="popup_title mb-4">
            {t("edit_album_popup_description")}
          </p>
          <div className="row">
            <div className="form-block col-12 col-md-6 col-sm-6">
              <label className="required">{t("edit_album_name")}</label>
              <input
                name="comment"
                type="text"
                className={
                  "form-input" + (validations.name ? " input-error" : "")
                }
                value={name}
                placeholder={t("edit_album_name")}
                onChange={(e) => setName(e.target.value)}
              />
              {validations.name && (
                <ValidationMessage message={validations.name[0]} />
              )}
            </div>
            <div className="form-block col-12 col-md-6 col-sm-6">
              <label className="required">{t("edit_album_group")}</label>
              <Select
                placeholder={t("edit_album_group")}
                className={
                  "form-react-select" +
                  (validations.kindergarten_group_id ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="specification"
                onChange={(e) =>
                  e
                    ? setKindergartenGroupId(e.value)
                    : setKindergartenGroupId("")
                }
                value={
                  kindergartenGroupId
                    ? {
                        value: kindergartenGroupId,
                        label: kindergartenGroupLabel,
                      }
                    : null
                }
                isClearable={true}
                isSearchable={false}
                options={kindergartenGroup.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
              {validations.kindergarten_group_id && (
                <ValidationMessage
                  message={validations.kindergarten_group_id[0]}
                />
              )}
            </div>
          </div>
          <p className="popup_title mb-4">{t("edit_album_images")}</p>
          <div className="form-block col-12 col-md-12 col-sm-6">
            <label>{t("edit_album_images")}</label>
            <div className="upload-file-box">
              <div
                className={
                  "form-input" +
                  (validations.images ? " input-error" : "")
                }
              >
                <>
                  <input
                    className="upload-input"
                    onChange={(e) => {
                      const selectedFiles = e.target.files;
                      if (selectedFiles && selectedFiles.length > 0) {
                        setImages((prevImages) => [
                          ...prevImages,
                          ...Array.from(selectedFiles),
                        ]);
                      }
                    }}
                    accept="image/*"
                    name="image"
                    type="file"
                    multiple
                  />

                  <div className="upload-input-placeholder">
                    <i className="icon upload-icon" />
                  </div>
                  <span className="popup_title">
                    {t("edit_kindergarten_upload_instructions")}
                  </span>
                  <span className="popup_description">
                    {t("edit_kindergarten_limitations")}
                  </span>
                </>
              </div>
              {validations.images && (
                <ValidationMessageKindergarten
                  message={validations["images"][0]}
                />
              )}
              <div className="sort-upload row mt-4">
                {imagesPath.length > 0 &&
                  imagesPath.map((image: any, index: number) => (
                    <div className="upload-area" key={index}>
                      <button
                        className="delete-image-button"
                        onClick={(e) => {
                          e.preventDefault();
                          handleDeleteImage(index, image.id);
                        }}
                      >
                        <i className="icon close-icon" />
                      </button>
                      <img
                        src={albumsImages + image.path}
                        alt={`Image ${index}`}
                      />
                    </div>
                  ))}
                {images.length > 0 &&
                  images.map((image: any, index: number) => (
                    <>
                      <div className="upload-area">
                        <button
                          className="delete-image-button"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDeleteImage(index, image.id);
                          }}
                        >
                          <i className="icon close-icon" />
                        </button>
                        <img
                          key={index}
                          src={URL.createObjectURL(image)}
                          alt={`Image ${index}`}
                        />
                      </div>
                    </>
                  ))}
                <>
                  {imagesPath.length + images.length < 5 && (
                    <div className="upload-area">
                      <input
                        className="upload-input"
                        onChange={(e) => {
                          const selectedFiles = e.target.files;
                          if (selectedFiles && selectedFiles.length > 0) {
                            setImages((prevImages) => [
                              ...prevImages,
                              ...Array.from(selectedFiles),
                            ]);
                          }
                        }}
                        accept="image/*"
                        name="image"
                        type="file"
                        multiple
                      />
                      <span>
                        <i className="icon upload-plus-icon" />
                      </span>
                      <span>{t("edit_kindergarten_upload")}</span>
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("edit_album_close")}
          </button>
          {btnLoading ? (
            <button className="button-blue has-icon" type="button">
              <i className="icon spinner-icon" />
              {t("edit_album_saving")}
            </button>
          ) : (
            <button className="button-blue has-icon" type="submit">
              <i className="icon plus-icon" />
              {t("edit_album_save")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditAlbumPopup;
