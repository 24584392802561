import { useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import moment from "moment";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import ValidationMessage from "src/helpers/ValidationMessage";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

interface AddNewEvaluationProps {
  onShowModal: boolean;
  childId: number;
  evaluationDate: Date;
  setShowModal: (visible: boolean) => void;
  setModified: (visible: boolean) => void;
}

const AddNewEvaluation: React.FC<AddNewEvaluationProps> = ({
  onShowModal,
  childId,
  evaluationDate,
  setShowModal,
  setModified,
}) => {
  const { t } = useTranslation();

  const [evaluation, setEvaluation] = useState<string>("");

  const [loadingButton, setLoadingButton] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const onClose = () => {
    setShowModal(false);
  };

  const addEvaluation = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setLoadingButton(true);

    const formData = {
      evaluation_date: evaluationDate
        ? moment(evaluationDate).format("yyyy-MM-DD")
        : "",
      child_id: childId,
      evaluation: evaluation,
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.childStoreEvaluation,
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);
      setModified(true);

      onClose();
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        onClose();
      }
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <Modal isOpen={onShowModal} toggle={() => onClose()} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("evaluations_add_modal_title")}
      </ModalHeader>
      <form onSubmit={(e) => addEvaluation(e)}>
        <ModalBody>
          <div className="modal-note">{t("evaluations_add_modal_desc")}</div>
          <div className="row">
            <div className="form-block col-12">
              <label className="required">
                {t("evaluations_add_modal_evaluation_label")}
              </label>
              <textarea
                className={
                  "form-input" + (validations.evaluation ? " input-error" : "")
                }
                placeholder={t("evaluations_add_modal_evaluation_placeholder")}
                onChange={(e) => setEvaluation(e.target.value)}
                name="evaluation"
                rows={3}
              />
              {validations.evaluation && (
                <ValidationMessage message={validations.evaluation[0]} />
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("evaluations_add_modal_close_button")}
          </button>

          {loadingButton ? (
            <button type="button" className="button-blue has-icon">
              <i className="spinner-icon" />
              {t("evaluations_add_modal_adding_button")}
            </button>
          ) : (
            <button type="submit" className="button-blue has-icon">
              <i className="icon add-icon" />
              {t("evaluations_add_modal_add_button")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddNewEvaluation;
