import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { icon } from "leaflet";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import "leaflet/dist/leaflet.css";
import EditKindergarten from "./EditKindergarten";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import SearchFilter from "src/helpers/SearchFilter";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";
import Loading from "src/helpers/Loading";


const Map: React.FC<{
  searchInputs: Record<string, string>;
  modified: boolean,
  setSearchBtnLoading: (value: boolean) => void;
  setModified: (value: boolean) => void;
}> = ({
  searchInputs,
  modified,
  setSearchBtnLoading,
  setModified
}) => {
    const { t } = useTranslation("");
    const [editKindergartenModal, setEditKindergartenModal] = useState(false);
    const navigate = useNavigate();

    const [kindergartens, setKindergartens] = useState<Record<string, any>[]>([]);
    const [loading, setLoading] = useState(true);
    const [modalId, setModalId] = useState<number | null>(null);

    const getKindergartens = async (): Promise<void> => {
      const { filter: _, ...noFilter } = searchInputs;

      var searchParams: any = noFilter;
      let request: any = API_PATHS.listKindergartens;
      searchParams.type = "kopesht";
      searchParams.pagination = "no";

      request = SearchFilter(searchParams, request);

      try {
        const response: any = await makeApiCall<ResponseType>(
          request,
          "GET",
          API_HEADERS.authenticated
        );
        setKindergartens(response.kindergartens);
        setLoading(false);
      } catch (error: any) {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        navigate(-1);
      } finally {
        setSearchBtnLoading(false)
      }
    };

    useEffect(() => {
      getKindergartens();
    }, [searchInputs]);

    useEffect(() => {
      modified && getKindergartens();
    }, [modified]);

    const deleteKindergarten = (
      kindergartenId: number,
      kindergartenTitle: string
    ) => {
      ConfirmPopup.deleteKindergartenPopup(
        t("delete_kindergarten_title"),
        t("delete_kindergarten_type"),
        kindergartenTitle
      ).then(async (response) => {
        if (response.isConfirmed) {
          try {
            const response: any = await makeApiCall<ResponseType>(
              API_PATHS.deleteKindergarten(kindergartenId),
              "DELETE",
              API_HEADERS.authenticated
            );
            getKindergartens();
            FLASH_MESSAGES.successMsg(response.success);
          } catch (error: any) {
            if (error.response.data.error) {
              FLASH_MESSAGES.errorMsg(error.response.data.error);
            }
          }
        }
      });
    };

    const markerColors = [
      "#74dde5",
      "#fac86b",
      "#678af7",
      "#6bdaae",
      "#ab56d4",
      "#fd8adc",
      "#70b0ff",
      "#9386f0",
      "#f1c448",
      "#6592f3",
      "#3d6de0",
      "#e3692c",
      "#ff9900",
      "#ff8c00",
      "#f29b30",
      "#ffdeb5",
      "#008000",
      "#ffc0cb",
      "#59bf80",
      "#cb3438",
    ];

    const createMarkers = () => {
      return kindergartens.map((kindergarten, index) => {
        const markerColor = markerColors[index % markerColors.length];

        const customIcon = icon({
          iconUrl: require("../../../assets/images/marker-icon.png"),
          iconSize: [20, 20],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
          tooltipAnchor: [16, -28],
          className: `custom-marker border marker-color-${index}`,
        });

        const markerStyle = document.createElement("style");

        markerStyle.innerHTML = `
        .marker-color-${index} {
          background: ${markerColor};
        }
      `;
        document.head.appendChild(markerStyle);

        return (
          <Marker
            position={[
              Number(kindergarten?.latitude ? kindergarten.latitude : 0),
              Number(kindergarten?.longtitude ? kindergarten.longtitude : 0),
            ]}

            key={kindergarten.id}
            icon={customIcon}
          >
            <Popup className="leaflet-popup-content">
              <div className="grid-container1">
                <div className="grid-item">
                  <div className="grid-header">
                    <div className="grid-number">
                      {kindergarten.id ? kindergarten.id : "-"}
                    </div>
                    {kindergarten.name ? kindergarten.name : "-"}
                  </div>
                  <div className="grid-content">
                    <div className="grid-title mt-2">
                      <i className="icon address-icon" />
                      {t("kindergarten_address")}
                    </div>
                    <div className="grid-subtitle">
                      {kindergarten.address ? kindergarten.address : "-"}
                    </div>
                    <div className="grid-title">
                      <i className="icon telephone-icon" />
                      {t("kindergarten_mobile")}
                    </div>
                    <div className="grid-subtitle">
                      {kindergarten.mobile ? kindergarten.mobile : "-"}
                    </div>
                    <div className="grid-title">
                      <i className="icon email-icon" />
                      {t("kindergarten_email")}
                    </div>
                    <div className="grid-subtitle">
                      {kindergarten.email ? kindergarten.email : "-"}
                    </div>
                    <div className="grid-title">
                      <i className="icon website-icon" />
                      {t("kindergarten_website")}
                    </div>
                    <div className="grid-subtitle">
                      {kindergarten.website ? (
                        <a
                          href={kindergarten.website}
                          className="text-decoration-none"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {kindergarten.website}
                        </a>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                  <div className="grid-actions">
                    <div className="row mt-2">
                      <div className="col-3">
                        <button
                          type="button"
                          className="button-unstyled-icon"
                          onClick={() => {
                            setEditKindergartenModal(true);
                            setModalId(kindergarten.id);
                          }}
                        >
                          <i className="icon edit-icon" />
                        </button>
                        {editKindergartenModal && modalId === kindergarten.id && (
                          <EditKindergarten
                            onShowModal={editKindergartenModal}
                            setKindergartenVisible={(e) => {
                              setEditKindergartenModal(e);
                              setModalId(null);
                            }}
                            kindergartenId={kindergarten.id}
                            setModified={(e) => setModified(e)}
                          />
                        )}
                      </div>
                      <div className="col-3">
                        <button
                          className="button-unstyled-icon"
                          key={kindergarten.id}
                          onClick={(e) => {
                            deleteKindergarten(kindergarten.id, kindergarten.name);
                          }}
                        >
                          <i className="icon delete-icon" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Popup>
          </Marker>
        );
      });
    };

    return (
      <>
        {loading ? (
          <div className="text-center">
            <Loading />
          </div>
        ) : (
          <div id="map" className="map-container">
            <MapContainer
              center={[41.33, 19.82]}
              zoom={13}
              scrollWheelZoom={false}
              attributionControl={false}
              style={{ width: "1500px", height: "600px" }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
              />

              {createMarkers()}
            </MapContainer>
          </div>)}
      </>
    );
  };

export default Map;
