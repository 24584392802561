import { useState, useRef, FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Select, { SingleValue } from "react-select";

import PATHS from "../../../routes/Paths";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import ValidationMessage from "src/helpers/ValidationMessage";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

interface RegistrationRequestPopup {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  setModified: (visible: boolean) => void;
}

const RegistrationRequestPopup: React.FC<RegistrationRequestPopup> = ({
  onShowModal,
  setShowModal,
  setModified,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation("");

  const [childId, setChildId] = useState("");
  const [unregisterDate, setUnregisterDate] = useState("");
  const [unregisterReason, setUnregisterReason] = useState("");
  const [children, setChildren] = useState<any[]>([]);

  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const getCurrentDate = (): string => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");

    return `${yyyy}-${mm}-${dd}`;
  };

  const onClose = () => {
    setModified(false);
    setShowModal(false);
  };

  const storeUnregistration = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setBtnLoading(true);

    const formData = new FormData();
    formData.append("child_id", childId);
    formData.append("unregister_reason", unregisterReason);
    formData.append("unregister_date", getCurrentDate());

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.storeUnregistration,
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);

      onClose();
      setModified(true);
      setBtnLoading(false);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
      }
      setBtnLoading(false);
    }
  };

  const getChildren = async (): Promise<void> => {
    setModified(false);

    const request: any = API_PATHS.activeChildList;

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      setChildren(response?.activeChildren ? response.activeChildren : []);
      setLoading(false);
    } catch (error: any) {
      navigate(-1);
    } finally {
    }
  };

  useEffect(() => {
    getChildren();
  }, []);

  const handleChildSelection = (childId: string): void => {
    setChildId(childId);
  };

  return (
    <Modal isOpen={onShowModal} toggle={() => onClose()} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("registration_request_popup_modal_title")}
      </ModalHeader>
      <form onSubmit={(e) => storeUnregistration(e)}>
        <ModalBody>
          <p className="popup_title mb-4">
            {t("registration_request_popup_description")}
          </p>
          <div className="form-block">
            {children.length > 0 ? (
              <div className="form-radio">
                {children.map((child: any) => (
                  <div className="radio-button" key={child.id}>
                    <input
                      className={validations.child_id ? "input-error" : ""}
                      id={`child_${child.id}`}
                      name="pending"
                      type="radio"
                      value={`child_${child.id}`}
                      onChange={() => handleChildSelection(child.id)}
                    />
                    <label htmlFor={`child_${child.id}`}>
                      {child.full_name}
                    </label>
                  </div>
                ))}
              </div>
            ) : (
              <label>{t("transfer_popup_no_active_children")}</label>
            )}
            {validations.child_id && (
              <ValidationMessage message={validations.child_id[0]} />
            )}
          </div>
          <div className="form-block col-12">
            <label className="required">
              {t("registration_request_popup_reason")}
            </label>
            <textarea
              name="comment"
              className={
                "form-input" + (validations.unregister_reason ? " input-error" : "")
              }
              value={unregisterReason}
              placeholder={t(
                "registration_request_popup_reason_of_application"
              )}
              cols={50}
              rows={6}
              onChange={(e) => setUnregisterReason(e.target.value)}
            />
            {validations.unregister_reason && (
              <ValidationMessage message={validations.unregister_reason[0]} />
            )}
            {validations.unregister_date && (
              <ValidationMessage message={validations.unregister_date[0]} />
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("registration_request_popup_close")}
          </button>
          {btnLoading ? (
            <button className="button-red has-icon" type="button">
              <i className="icon spinner-icon" />
              {t("registration_request_popup_unregistering")}
            </button>
          ) : (
            <button className="button-red has-icon" type="submit">
              <i className="icon close-icon" />
              {t("registration_request_popup_unregister")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default RegistrationRequestPopup;
