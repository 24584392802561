import React from "react";
import MainAuth from "./MainAuth";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import ForgotPassword from "src/components/authentication/ForgotPassword";

const ForgotPasswordView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("auth_forgot_password_tab")}</title>
      </Helmet>
      <MainAuth>
        <ForgotPassword />
      </MainAuth>
    </>
  );
};

export default ForgotPasswordView;
