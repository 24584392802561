import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Sidebar from "src/components/layouts/sidebar/Sidebar";
import Breadcrumb from "src/components/layouts/Common/Breadcrumb";
import ApplicationsKindergarten from "src/components/panel/municipality_kindergarten_application/Applications";
import * as AppConfig from "../../../helpers/AppConfig";

const ApplicationsKindergartenMunicicpalityView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("applications_list_view")}</title>
      </Helmet>
      <Sidebar>
          <Breadcrumb
            title={t("applications_list_title")}
            subtitle={t("applications_list_subtitle")}
          />
       
        <ApplicationsKindergarten />
      </Sidebar>
    </>
  );
};

export default ApplicationsKindergartenMunicicpalityView;
