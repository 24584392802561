import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import ValidationMessage from "src/helpers/ValidationMessage";

interface ChangePasswordProps {
  onShowModal: boolean;
  setSiteVisible: (visible: boolean) => void;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({
  onShowModal,
  setSiteVisible,
}) => {
  const { t } = useTranslation("");

  const [oldPassword, setOldPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setConfirmNewPassword] = useState(false);

  const [validations, setValidations] = useState<Record<string, string>>({});

  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  const onClose = () => {
    setSiteVisible(false);
  };

  const changePassword = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setButtonIsLoading(true);

    const newPasswordData = {
      current_password: oldPassword,
      new_password: newPassword,
      new_password_confirmation: confirmPassword,
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.changePassword,
        "POST",
        API_HEADERS.authenticated,
        newPasswordData
      );
      FLASH_MESSAGES.successMsg(response.success);
      setButtonIsLoading(false);
      onClose();
    } catch (error: any) {
      if (error.response.status === 404 || error.response.status === 422) {
        setValidations(error.response.data);
        setButtonIsLoading(false);
      }
    }
  };

  return (
    <Modal isOpen={onShowModal} toggle={() => onClose()} size="sm" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("profile_modal_title")}
      </ModalHeader>
      <form onSubmit={(e) => changePassword(e)}>
        <ModalBody>
          <div className="form-block">
            <label className="required">
              {t("profile_modal_old_password_label")}
            </label>
            <div className="password-input">
              <input
                type={showOldPassword ? "text" : "password"}
                name="password"
                placeholder={t("profile_modal_old_password_placeholder")}
                className={
                  "form-input" +
                  (validations.current_password || validations.error
                    ? " input-error"
                    : "")
                }
                autoComplete="off"
                onChange={(e) => setOldPassword(e.target.value)}
              />
              <div
                className="show-hide"
                onClick={(e) => setShowOldPassword(!showOldPassword)}
              >
                {showOldPassword ? (
                  <i className="icon show-password-icon" />
                ) : (
                  <i className="icon hide-password-icon" />
                )}
              </div>
            </div>

            {(validations.current_password || validations.error) && (
              <ValidationMessage
                message={
                  validations.current_password
                    ? validations.current_password[0]
                    : validations.error
                }
              />
            )}
          </div>
          <div className="form-block">
            <label className="required">
              {t("profile_modal_new_password_label")}
            </label>
            <div className="password-input">
              <input
                type={showNewPassword ? "text" : "password"}
                name="password"
                placeholder={t("profile_modal_new_password_placeholder")}
                className={
                  "form-input" +
                  (validations.new_password ? " input-error" : "")
                }
                autoComplete="off"
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <div
                className="show-hide"
                onClick={(e) => setShowNewPassword(!showNewPassword)}
              >
                {showNewPassword ? (
                  <i className="icon show-password-icon" />
                ) : (
                  <i className="icon hide-password-icon" />
                )}
              </div>
            </div>

            {validations.new_password && (
              <ValidationMessage message={validations.new_password[0]} />
            )}
          </div>
          <div className="form-block">
            <label className="required">
              {t("profile_modal_confirm_new_password_label")}
            </label>
            <div className="password-input">
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="password"
                placeholder={t(
                  "profile_modal_confirm_new_password_placeholder"
                )}
                className={
                  "form-input" +
                  (validations.new_password_confirmation ? " input-error" : "")
                }
                autoComplete="off"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <div
                className="show-hide"
                onClick={(e) => setConfirmNewPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <i className="icon show-password-icon" />
                ) : (
                  <i className="icon hide-password-icon" />
                )}
              </div>
            </div>

            {validations.new_password_confirmation && (
              <ValidationMessage
                message={validations.new_password_confirmation[0]}
              />
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("profile_modal_cancel_button")}
          </button>

          {buttonIsLoading ? (
            <button type="button" className="button-blue has-icon">
              <i className="spinner-icon" />
              {t("profile_modal_changing_button")}
            </button>
          ) : (
            <button type="submit" className="button-blue has-icon">
              <i className="icon edit-icon" />
              {t("profile_modal_change_button")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};
export default ChangePassword;
