import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { RoleCheck } from "src/helpers/RoleCheck";
import { makeApiCall } from "src/api/apiRequests";
import { LanguageDate } from "src/helpers/CheckLanguageDate";
import API_PATHS from "src/api/apiPaths";
import SearchFilter from "src/helpers/SearchFilter";
import API_HEADERS from "src/api/apiConfig";
import CustomPagination from "src/helpers/CustomPaginate";
import ListResponse from "src/helpers/ListResponse";
import Loading from "src/helpers/Loading";
import PATHS from "src/routes/Paths";
import moment from "moment";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import UploadWarrantyPopup from "./UploadWarrantyPopup";
import useSearchSubmit from "src/hooks/useSearchSubmit";

const ApplicationsKindergarten: React.FC = () => {
  const { t } = useTranslation("");
  const navigate = useNavigate();

  // Applications
  const [applications, setApplications] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // Search
  const {
    searched,
    searchInputs,
    searchBtnLoading,
    setSearchBtnLoading,
    handleSubmit,
    setPage,
  } = useSearchSubmit();
  const [referenceNumber, setReferenceNumber] = useState("");

  // Modals
  const [uploadWarrantyModal, setUploadWarrantyModal] = useState(false);
  const [modalId, setModalId] = useState<number | null>(null);

  const getApplications = async (): Promise<void> => {
    const searchParams: any = searchInputs;
    let request: any = API_PATHS.listApplications;

    searchParams.type = "kopesht";

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      setApplications(response.applications);
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    } finally {
      setSearchBtnLoading(false);
    }
  };

  useEffect(() => {
    getApplications();
  }, [searchInputs]);

  return (
    <>
      {RoleCheck("1") && (
        <>
          <div className="input-items">
            <form
              onSubmit={(e) =>
                handleSubmit(e, {
                  ...(referenceNumber && { reference_number: referenceNumber }),
                })
              }
            >
              <div className="row">
                <div className="form-block col-12 col-sm-6 col-md-3">
                  <label>{t("applications_reference_number")}</label>
                  <input
                    placeholder={t("applications_reference_number_search_by")}
                    className="form-input"
                    name="text"
                    type="text"
                    value={referenceNumber}
                    onChange={(e) => setReferenceNumber(e.target.value)}
                  />
                  <p className="filter-explain-text mt-2">
                    {t("application_filter_explain")}
                  </p>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mt-4 mb-1">
                  {searchBtnLoading ? (
                    <button type="button" className="button-orange has-icon">
                      <i className="icon spinner-icon" />
                      {t("application_searching")}
                    </button>
                  ) : (
                    <button type="submit" className="button-orange has-icon">
                      <i className="icon search-icon" />
                      {t("application_search")}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className="d-flex align-items-baseline justify-content-end mb-5">
            <button
              type="button"
              className="button-blue has-icon mb-1"
              onClick={() => navigate(PATHS.makeApplicationStepOneKindergarten)}
            >
              <i className="icon plus-icon" />
              {t("applications_make_an_application")}
            </button>
          </div>
          <div className="transfer-page">
            <div className="table-list-transfers1 mb-5">
              <table role="table" className="w-100 transfer-head">
                <thead>
                  {loading ? (
                    <tr>
                      <td className="text-center" colSpan={3}>
                        <Loading />
                      </td>
                    </tr>
                  ) : (
                    applications.data
                      .filter(
                        (item: any) => item.kindergarten.type === "kopesht"
                      )
                      .map((application: any) => (
                        <tr
                          role="row"
                          className="applications"
                          onClick={() =>
                            navigate(
                              PATHS.showApplicationsKindergarten +
                                application.id
                            )
                          }
                        >
                          <th
                            style={{ width: "30%" }}
                            role="columnheader"
                            className="text-left p-4"
                          >
                            <i className="icon dropdown-time-icon" />
                            <span className="popup_description">
                              {application.created_at
                                ? moment(application.created_at)
                                    .locale(LanguageDate.moment)
                                    .format("DD, MMMM yyyy")
                                : "-"}
                            </span>
                            <span className="d-flex mt-3">
                              {application.kindergarten.type === "kopesht"
                                ? t("applications_application_for_kindergarten")
                                : t("applications_application_for_nursery")}
                              <span className="transfer-text mx-2">
                                {application.child.first_name +
                                  " " +
                                  application.child.last_name}
                              </span>
                            </span>
                          </th>
                          <th
                            style={{ width: "10%" }}
                            role="columnheader"
                            className="text-center"
                          >
                            <div className="actions">
                              <button
                                type="button"
                                className="button-unstyled-icon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigate(
                                    PATHS.editApplicationStepOneKindergarten +
                                      application.id
                                  );
                                }}
                              >
                                <i className="icon edit-icon" />
                              </button>
                            </div>
                          </th>
                          {application.status_id === 2 ||
                          application.status_id === 3 ? (
                            <th
                              style={{ width: "30%" }}
                              role="columnheader"
                              className="text-center"
                            >
                              <div className="actions">
                                <button
                                  type="button"
                                  className="button-blue-border has-icon"
                                  onClick={(e) => {
                                    setUploadWarrantyModal(true);
                                    setModalId(application.id);
                                    e.stopPropagation();
                                    e.preventDefault();
                                  }}
                                >
                                  {t("applications_upload")}
                                </button>
                                {uploadWarrantyModal &&
                                  modalId === application.id && (
                                    <UploadWarrantyPopup
                                      onShowModal={uploadWarrantyModal}
                                      setShowModal={(e) => {
                                        setUploadWarrantyModal(e);
                                        setModalId(null);
                                      }}
                                      applicationId={application.id}
                                      setModified={() => getApplications()}
                                    />
                                  )}
                              </div>
                            </th>
                          ) : (
                            <th
                              style={{ width: "30%" }}
                              role="columnheader"
                              className="text-center"
                            >
                            </th>
                          )}
                          <th
                            style={{ width: "30%" }}
                            role="columnheader"
                            className="text-end"
                          >
                            {application.status_id === 1 ? (
                              <span className="transfer-text">
                                <i className="icon dot-icon my-1 me-2" />
                                {t("not_reviewed")}
                              </span>
                            ) : application.status_id === 2 ? (
                              <span className="transfer-text">
                                <i className="icon tick-icon my-0 me-2" />
                                {t("approved_in_the_same_kindergarten")}
                              </span>
                            ) : application.status_id === 3 ? (
                              <span className="transfer-text">
                                <i className="icon tick-icon my-0 me-2" />
                                {t("approved_in_another_kindergarten")}
                              </span>
                            ) : application.status_id === 4 ? (
                              <span className="transfer-text">
                                <i className="icon close-icon my-0 me-2" />
                                {t("rejected")}
                              </span>
                            ) : application.status_id === 5 ? (
                              <span className="transfer-text">
                                <i className="icon dot-icon my-0 me-2" />
                                {t("warrenty_uploaded")}
                              </span>
                            ) : (
                              <span className="transfer-text">
                                <i className="icon tick-icon my-0 me-2" />
                                {t("confirmed")}
                              </span>
                            )}
                          </th>
                        </tr>
                      ))
                  )}
                </thead>
              </table>
              <ListResponse
                totalItems={applications?.data?.length}
                loading={loading}
                searched={searched}
              />
            </div>
          </div>

          <CustomPagination
            data={applications}
            setActivePage={(e) => setPage(e)}
          />
        </>
      )}
    </>
  );
};

export default ApplicationsKindergarten;
