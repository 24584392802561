import { t } from "i18next";

export const months = [
  { value: "1", label: t("education_age_groups_month_1") },
  { value: "2", label: t("education_age_groups_month_2") },
  { value: "3", label: t("education_age_groups_month_3") },
  { value: "4", label: t("education_age_groups_month_4") },
  { value: "5", label: t("education_age_groups_month_5") },
  { value: "6", label: t("education_age_groups_month_6") },
  { value: "7", label: t("education_age_groups_month_7") },
  { value: "8", label: t("education_age_groups_month_8") },
  { value: "9", label: t("education_age_groups_month_9") },
  { value: "10", label: t("education_age_groups_month_10") },
  { value: "11", label: t("education_age_groups_month_11") },
  { value: "12", label: t("education_age_groups_month_12") },
];
