import { useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import { LanguageDate } from "src/helpers/CheckLanguageDate";
import DatePicker from "react-datepicker";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import ValidationMessage from "src/helpers/ValidationMessage";
import moment from "moment";

interface EvaluationPopupChildProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  setModified: (visible: boolean) => void;
  childId: number;
  dateOfEvaluation: Date | null;
}

const EvaluationPopupChild: React.FC<EvaluationPopupChildProps> = ({
  onShowModal,
  setShowModal,
  setModified,
  childId,
  dateOfEvaluation,
}) => {
  const { t } = useTranslation();

  const [evaluationsDate, setEvaluationsDate] = useState<Date | null>(null);
  const [comment, setComment] = useState("");

  const [loadingButton, setLoadingButton] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const onClose = () => {
    setShowModal(false);
  };

  const addEvaluation = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setLoadingButton(true);

    const formData = {
      evaluation_date: moment(dateOfEvaluation).format("yyyy-MM-DD"),
      child_id: childId,
      evaluation: comment,
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.childStoreEvaluation,
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);
      setModified(true);

      onClose();
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        onClose();
      }
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <Modal isOpen={onShowModal} toggle={() => onClose()} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("child_evaluation_add_modal_title")}
      </ModalHeader>
      <form onSubmit={(e) => addEvaluation(e)}>
        <ModalBody>
          <div className="modal-note">
            {t("child_evaluation_add_modal_desc")}
          </div>
          <div className="row">
            <div className="form-block col-12">
              <label className="required">
                {t("child_evaluation_add_modal_comment_label")}
              </label>
              <textarea
                name="evaluation"
                rows={3}
                placeholder={t(
                  "child_evaluation_add_modal_comment_placeholder"
                )}
                value={comment}
                className={
                  "form-input" + (validations.evaluation ? " input-error" : "")
                }
                autoComplete="off"
                onChange={(e) => setComment(e.target.value)}
              />
              {validations.evaluation && (
                <ValidationMessage message={validations.evaluation[0]} />
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("child_evaluation_add_modal_close_button")}
          </button>

          {loadingButton ? (
            <button type="button" className="button-blue has-icon">
              <i className="spinner-icon" />
              {t("child_evaluation_add_modal_adding_button")}
            </button>
          ) : (
            <button type="submit" className="button-blue has-icon">
              <i className="icon add-icon" />
              {t("child_evaluation_add_modal_add_button")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};
export default EvaluationPopupChild;
