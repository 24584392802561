import { create } from "zustand";

const educationProgramEdit = {
  educationProgramId: "",
  education_program_weekly_id: "",
  week_nr: "",
  week_topic: "",
  date: "",
  day: "",
  day_topic: "",
  day_activity: "",
  day_tools: "",
  education_program_daily_id: "",
};

export const AddEducationProgram = create((set) => ({
  educationProgramEdit,

  addEducationProgramEdit: (data: any) =>
    set(() => ({ educationProgramEdit: data })),

  resetEducationProgram: () =>
    set(() => ({
      educationProgramEdit: {
        educationProgramId: "",
        education_program_weekly_id: "",
        week_nr: "",
        week_topic: "",
        date: "",
        day: "",
        day_topic: "",
        day_activity: "",
        day_tools: "",
        education_program_daily_id: "",
      },
    })),
}));
