import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import Loading from "src/helpers/Loading";
import SimpleBar from "simplebar-react";

interface AddNewAllergicGroupProps {
  setGroupId: (id: number) => void;
}

const AllergicGroupsSidebar: React.FC<AddNewAllergicGroupProps> = ({
  setGroupId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const ref = useRef<null>(null);

  const [allergicGroups, setAllergicGroups] = useState<any>([]);
  const [groupIdN, setGroupIdN] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [modified, setModified] = useState(false);

  const getGroups = async (): Promise<void> => {
    setModified(false);

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.allergicGroupList,
        "GET",
        API_HEADERS.authenticated
      );
      setAllergicGroups(response.allergicGroups);
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  useEffect(() => {
    getGroups();
  }, [modified]);

  return (
    <>
      <div className="sidebar-content">
        <div className="sidebar-header">
          <div className="sidebar-title">
            {t("child_allergies_sidebar_title")}
          </div>
        </div>
        <div className="sidebar-body">
          {loading ? (
            <div className="text-center">
              <Loading />
            </div>
          ) : (
            <SimpleBar ref={ref} style={{ maxHeight: "270px" }}>
              <div className="sidebar-list">
                {allergicGroups?.length > 0 ? (
                  allergicGroups?.map((group: any) => (
                    <div
                      key={group.id}
                      className={
                        groupIdN === group.id
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <div
                        className="name"
                        onClick={() => {
                          setGroupId(group.id);
                          setGroupIdN(group.id);
                        }}
                      >
                        <span>{group.name}</span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="sidebar-item no-items">
                    {t("no_results_found")}
                  </div>
                )}
              </div>
            </SimpleBar>
          )}
        </div>
      </div>
    </>
  );
};

export default AllergicGroupsSidebar;
