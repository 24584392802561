import React from "react";
import { RoleCheck } from "src/helpers/RoleCheck";
import AdminDashboard from "./admin/AdminDashboard";
import GuardianDashboard from "./guardian/GuardianDashboard";

const Dashboard: React.FC = () => {
  return (
    <div className="dashboard">
      {RoleCheck("3") ? (
        <AdminDashboard />
      ) : (
        RoleCheck("1") && <GuardianDashboard />
      )}
    </div>
  );
};

export default Dashboard;
