import WeekTabs from "./weeks/WeekTabs";

const ViewEducationProgram: React.FC = () => {
  return (
    <>
      <WeekTabs />
    </>
  );
};

export default ViewEducationProgram;
