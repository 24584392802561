import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {  useParams } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import CustomPagination from "src/helpers/CustomPaginate";
import ShowTeacherEvaluation from "./ShowTeacherEvaluation";
import EditTeacherEvaluation from "./EditTeacherEvaluation";
import EvaluationStars from "../../performance/EvaluationStars";
import moment from "moment";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import SearchFilter from "src/helpers/SearchFilter";
import ListResponse from "src/helpers/ListResponse";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import Loading from "src/helpers/Loading";
import * as ConfirmPopup from "../../../../helpers/ConfirmPopup";
import { LanguageDate } from "src/helpers/CheckLanguageDate";

const ShowTeacher: React.FC = () => {
  const { t } = useTranslation();
  const param = useParams();

  const [showTeacherEvaluationModal, setShowTeacherEvaluationModal] =
    useState(false);
  const [editTeacherEvaluationModal, setEditTeacherEvaluationModal] =
    useState(false);
  const [modalId, setModalId] = useState<number | null>(null);

  const [teachersEvaluation, setTeachersEvaluation] = useState<
    Record<string, any>[]
  >([]);
  const [teacherFirstName, setTeacherFirstName] = useState("");
  const [teacherLastName, setTeacherLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(true);
  const [modified, setModified] = useState(false);
  const [paginate, setPaginate] = useState<any>([]);
  const [activePage, setActivePage] = useState<number>(1);

  const showTeacher = async (): Promise<void> => {
    const teacherId = param.id;
    setModified(false);

    const searchParams: any = {
      pagination: 5,
      teacher_id: teacherId,
      role: "director",
    };
    let request: any = API_PATHS.teachersEvaluationList;

    if (activePage > 1) {
      searchParams["page"] = activePage;
    }
    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setTeachersEvaluation(response.teachersevaluation.data);
      setTeacherFirstName(response.teacher.first_name);
      setTeacherLastName(response.teacher.last_name);
      setAddress(response.teacher.address);
      setEmail(response.teacher.email);
      setPhone(response.teacher.phone);
      setPaginate(response.teachersevaluation);
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
    }
  };

  useEffect(() => {
    showTeacher();
  }, [modified, activePage]);

  const deleteEvaluation = (evaluationId: number, evaluationTitle: string) => {
    ConfirmPopup.deletePopup(
      t("delete_evaluation_title"),
      t("delete_evaluation_type"),
      evaluationTitle
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.deleteTeacherEvaluation(evaluationId),
            "DELETE",
            API_HEADERS.authenticated
          );

          setModified(true);
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  return (
    <>
      <div className="profile-info-application teacher-view mb-5">
        <div className="profile-initials">
          <div className="avatar-violet teacher-details size-xl">
            <span className="circle">
              {`${teacherFirstName ? teacherFirstName.charAt(0) : ""}${
                teacherLastName ? teacherLastName.charAt(0) : ""
              }`}
            </span>
          </div>
        </div>
        <div className="profile-data teacher-width border-bottom w-50">
          <div className="d-flex flex-direction-column-teach">
            <div className="info my-5 pb-0">
              <div className="mb-5">
                <span className="label">
                  <i className="icon table-teachers-icon" />
                  {t("show_teacher_teachers_name")}
                </span>
                <span className="text mx-1">
                  {(teacherFirstName ? teacherFirstName : "-") +
                    " " +
                    (teacherLastName ? teacherLastName : "-")}
                </span>
              </div>
              <div className="mb-5">
                <i className="icon address-icon" />
                <span className="label">{t("show_teacher_address")}</span>
                <span className="text mx-1">{address ? address : "-"}</span>
              </div>
            </div>
            <div className="info my-5 pb-0">
              <div className="mb-5">
                <i className="icon telephone-icon" />
                <span className="label">{t("show_teacher_mobile")}</span>
                <span className="text mx-1">{phone ? phone : "-"}</span>
              </div>
              <div className="mb-5">
                <i className="icon email-icon" />
                <span className="label">{t("show_teacher_email")}</span>
                <span className="email mx-1">{email ? email : "-"}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <p className="text-blue">{t("show_teacher_performance")}</p>
      </div>
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                style={{ width: "16%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon calender-icon" />
                {t("show_teacher_evaluation_date")}
              </th>
              <th
                style={{ width: "19%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon comment-icon" />
                {t("show_teacher_comments")}
              </th>
              <th
                style={{ width: "19%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon evaluation-icon" />
                {t("show_teacher_evaluations")}
              </th>
              <th
                style={{ width: "19%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon actions-icon" />
                {t("show_teacher_actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={4}>
                  <Loading />
                </td>
              </tr>
            ) : (
              teachersEvaluation.map((evaluation, index) => (
                <tr>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("show_teacher_evaluation_date")}
                  >
                    {evaluation.evaluation_date
                      ? moment(evaluation.evaluation_date)
                          .locale(LanguageDate.moment)
                          .format("DD, MMMM yyyy")
                      : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("show_teacher_comments")}
                  >
                    {evaluation.evaluation_comment
                      ? evaluation.evaluation_comment.length > 100
                        ? evaluation.evaluation_comment.substring(0, 100) +
                          "..."
                        : evaluation.evaluation_comment
                      : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("show_teacher_evaluations")}
                  >
                    {evaluation.evaluation_score ? (
                      <EvaluationStars
                        evaluationNr={evaluation.evaluation_score}
                      />
                    ) : (
                      "-"
                    )}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("teachers_actions")}
                  >
                    <div className="actions">
                      <button
                        type="button"
                        className="button-unstyled-icon"
                        onClick={(e) => {
                          setEditTeacherEvaluationModal(true);
                          setModalId(evaluation.id);
                          e.stopPropagation();
                        }}
                      >
                        <i className="icon edit-icon" />
                      </button>
                      {editTeacherEvaluationModal &&
                        modalId === evaluation.id && (
                          <EditTeacherEvaluation
                            onShowModal={editTeacherEvaluationModal}
                            setShowModal={(e) => {
                              setEditTeacherEvaluationModal(e);
                              setModalId(null);
                            }}
                            evaluationId={evaluation.id}
                            setModified={(e) => setModified(e)}
                          />
                        )}
                      <button
                        type="button"
                        className="button-unstyled-icon"
                        onClick={(e) => {
                          setShowTeacherEvaluationModal(true);
                          setModalId(evaluation.id);
                          e.stopPropagation();
                        }}
                      >
                        <i className="icon show-icon" />
                      </button>
                      {showTeacherEvaluationModal &&
                        modalId === evaluation.id && (
                          <ShowTeacherEvaluation
                            onShowModal={showTeacherEvaluationModal}
                            setShowModal={(e) => {
                              setShowTeacherEvaluationModal(e);
                              setModalId(null);
                            }}
                            evaluationId={evaluation.id}
                            setModified={(e) => setModified(e)}
                          />
                        )}
                      <button
                        className="button-unstyled-icon"
                        onClick={(e) => {
                          deleteEvaluation(
                            evaluation.id,
                            (teacherFirstName ? teacherFirstName : "-") +
                              " " +
                              (teacherLastName ? teacherLastName : "-")
                          );
                        }}
                      >
                        <i className="icon delete-icon" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={teachersEvaluation.length}
          loading={loading}
        />
      </div>
      <CustomPagination
        data={paginate}
        setActivePage={(e) => setActivePage(e)}
      />
    </>
  );
};

export default ShowTeacher;
