import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import { privateRoutes } from "../../routes/Routes";

import { makeApiCall } from "src/api/apiRequests";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import ValidationMessage from "src/helpers/ValidationMessage";
import * as AppConfig from "../../helpers/AppConfig";
import PATHS from "src/routes/Paths";

const Login = (): JSX.Element => {
  const { t } = useTranslation("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState("0");
  const [showPassword, setShowPassword] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});
  const [userShowBtnLoading, setUserShowBtnLoading] = useState(false);

  const userLogin = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setUserShowBtnLoading(true);

    const loginData = {
      email: email,
      password: password,
      remember: remember,
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.login,
        "POST",
        API_HEADERS.unauthenticated,
        loginData
      );

      setUserShowBtnLoading(false);

      const userData = {
        token: response.token,
        roleId: response.user.role_id,
      };

      AppConfig.setAuth(userData);

      switch (response.user.role_id) {
        case 1:
        case 3:
          window.location.replace(PATHS.dashboard);
          break;
        case 2:
        case 4:
        case 8:
          window.location.replace(PATHS.children);
          break;
        case 5:
          window.location.replace(PATHS.kindergartenNursery);
          break;
        case 6:
          window.location.replace(PATHS.staff);
          break;
        case 7:
          window.location.replace(PATHS.logs);
          break;
        case 9:
          window.location.replace(PATHS.registerApplicationsNursery);
      }
    } catch (error: any) {
      if (
        error.response.status === 401 ||
        error.response.status === 403 ||
        error.response.status === 422
      ) {
        setValidations(error.response.data);
      }
      setUserShowBtnLoading(false);
    }
  };

  return (
    <>
      <h3 className="auth-title">{t("auth_login_title")}</h3>
      <div className="auth-card">
        <p className="auth-note">{t("auth_login_note")}</p>
        <form className="w-100" onSubmit={(e) => userLogin(e)}>
          <div className="form-block">
            <label className="required">{t("auth_login_email_label")}</label>
            <input
              type="text"
              name="email"
              value={email}
              placeholder={t("auth_login_email_placeholder")}
              className={
                "form-input" +
                (validations.email || validations.error ? " input-error" : "")
              }
              onChange={(e) => setEmail(e.target.value)}
            />
            {(validations.email || validations.error) && (
              <ValidationMessage
                message={
                  validations.email ? validations.email[0] : validations.error
                }
              />
            )}
          </div>
          <div className="form-block">
            <label className="required">{t("auth_login_password_label")}</label>
            <div className="password-input">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder={t("auth_login_password_placeholder")}
                className={
                  "form-input" +
                  (validations.password || validations.error
                    ? " input-error"
                    : "")
                }
                autoComplete="off"
                onChange={(e) => setPassword(e.target.value)}
              />
              <div
                className="show-hide"
                onClick={(e) => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <i className="icon show-password-icon" />
                ) : (
                  <i className="icon hide-password-icon" />
                )}
              </div>
            </div>
            {validations.password && (
              <ValidationMessage message={validations.password[0]} />
            )}
          </div>
          <div className="form-check-auth">
            <div className="form-block">
              <input
                type="checkbox"
                className="form-checkbox"
                name="remember"
                id="remember"
                onChange={(e) => setRemember(e.target.checked ? "1" : "0")}
              />
              <label htmlFor="remember">{t("auth_login_remember_me")}</label>
            </div>
            <div className="forgot-password">
              <Link to={PATHS.forgotPassword}>
                {t("auth_login_forgot_password")}
              </Link>
            </div>
          </div>
          {userShowBtnLoading ? (
            <button type="button" className="button-blue w-100 has-icon">
              <i className="spinner-icon" />
              {t("auth_login_identifying_button")}
            </button>
          ) : (
            <button type="submit" className="button-blue w-100">
              {t("auth_login_identify_button")}
            </button>
          )}
          <div className="register-now">
            {t("auth_login_dont_have_account")}
            <Link to={PATHS.createNewAccount}>
              {t("auth_login_register_now")}
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
