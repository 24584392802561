import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import CustomPagination from "src/helpers/CustomPaginate";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import ListResponse from "src/helpers/ListResponse";
import Loading from "src/helpers/Loading";
import SearchFilter from "src/helpers/SearchFilter";
import EditAllergicProduct from "./EditProduct";
import * as ConfirmPopup from "../../../../../helpers/ConfirmPopup";

interface ProductsProps {
  modified: boolean;
  setModified: (visible: boolean) => void;
}

const Products: React.FC<ProductsProps> = ({
  modified,
  setModified,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { Id } = useParams();

  const [products, setProducts] = useState<any>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number | null>(null);

  const productsList = async (): Promise<void> => {
    setModified(false);

    let request: any = API_PATHS.allergicProductList(Id);

    var searchParams = {};

    if (activePage > 1) {
      searchParams = {
        page: activePage,
      };
    }

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      setProducts(response.allergicProducts);
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  useEffect(() => {
    productsList();
  }, [Id, activePage]);

  useEffect(() => {
    setActivePage(1);
    if (modified) {
      productsList();
    }
  }, [Id, modified]);

  const deleteProduct = (productId: number, productName: string) => {
    ConfirmPopup.deletePopup(
      t("meal_allergies_product_delete_title"),
      t("meal_allergies_product_delete__type"),
      productName
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.allergicProductDelete(productId),
            "DELETE",
            API_HEADERS.authenticated
          );
          setModified(true);
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  return (
    <>
      <div className="table-list">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                style={{ width: "80%" }}
                role="columnheader"
                className="text-left"
              >
                {t("meal_allergies_product_table_name")}
              </th>
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon actions-icon" />
                {t("meal_allergies_product_table_actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={2}>
                  <Loading />
                </td>
              </tr>
            ) : (
              products?.data?.map((product: any) => (
                <tr key={product.id}>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("meal_allergies_product_table_name")}
                  >
                    {product.name}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("meal_allergies_product_table_actions")}
                  >
                    <div className="actions">
                      <button
                        className="button-unstyled-icon"
                        onClick={() => {
                          setShowEditModal(true);
                          setModalId(product.id);
                        }}
                      >
                        <i className="icon edit-icon" />
                      </button>
                      {showEditModal && modalId === product.id && (
                        <EditAllergicProduct
                          onShowModal={showEditModal}
                          setShowModal={(e) => {
                            setShowEditModal(e);
                            setModalId(null);
                          }}
                          productId={product.id}
                          setModified={(e) => setModified(e)}
                        />
                      )}
                      <button
                        className="button-unstyled-icon"
                        onClick={() => deleteProduct(product.id, product.name)}
                      >
                        <i className="icon delete-icon" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse totalItems={products?.data?.length} loading={loading} />
      </div>
      <CustomPagination
        data={products}
        setActivePage={(e) => setActivePage(e)}
      />
    </>
  );
};

export default Products;
