import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Breadcrumb from "src/components/layouts/Common/Breadcrumb";
import Sidebar from "src/components/layouts/sidebar/Sidebar";
import ChildrenCategories from "src/components/panel/children_categories/ChildrenCategories";

const ChildrenCategoriesView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("childrens_categories_tab")}</title>
      </Helmet>
      <Sidebar>
        <Breadcrumb
          title={t("childrens_categories_title")}
          subtitle={t("childrens_categories_subtitle")}
        />
        <ChildrenCategories />
      </Sidebar>
    </>
  );
};

export default ChildrenCategoriesView;
