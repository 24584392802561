import React, { useState, useEffect, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { makeApiCall } from "src/api/apiRequests";
import { LanguageDate } from "src/helpers/CheckLanguageDate";
import ShowEvaluationTeacher from "./show_evaluation_popups/ShowEvaluationTeacher";
import EditEvaluationPopupTeacher from "./edit_evaluations/EditEvaluationPopupTeacher";
import EvaluationStars from "../../performance/EvaluationStars";
import EvaluationPopupTeacher from "./evaluation_popups/EvaluationPopupTeacher";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import SearchFilter from "src/helpers/SearchFilter";
import ListResponse from "src/helpers/ListResponse";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import CustomPagination from "src/helpers/CustomPaginate";
import Loading from "src/helpers/Loading";
import moment from "moment";
import DatePicker from "react-datepicker";
import ValidationMessage from "src/helpers/ValidationMessage";
import * as ConfirmPopup from "../../../../helpers/ConfirmPopup";
import useSearchSubmit from "src/hooks/useSearchSubmit";

interface DatePickerInputProps {
  value?: string;
  onClick?: () => void;
  errorClass?: string;
}

const StaffList: React.FC = () => {
  const { t } = useTranslation("");
  const { id } = useParams();

  // Staff list
  const [staff, setStaff] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // Search
  const {
    searchInputs,
    searchBtnLoading,
    setSearchBtnLoading,
    handleSubmit,
    setPage,
  } = useSearchSubmit();
  const [dateOfEvaluation, setDateOfEvaluation] = useState<Date | null>(
    new Date()
  );
  const [term, setTerm] = useState<string>("");
  const [validations, setValidations] = useState<Record<string, string>>({});

  // Modals
  const [addEvaluationTeacherModal, setAddEvaluationTeacherModal] =
    useState<boolean>(false);
  const [showTeacherEvaluationModal, setShowTeacherEvaluationModal] =
    useState<boolean>(false);
  const [editTeacherEvaluationModal, setEditTeacherEvaluationModal] =
    useState<boolean>(false);
  const [modalId, setModalId] = useState<number | null>(null);

  const getStaff = async (): Promise<void> => {
    let searchParams: any = searchInputs;
    let request: any = API_PATHS.evaluationTeachersList;

    searchParams.date_of_evaluation = moment(dateOfEvaluation).format("yyyy-MM-DD");
    searchParams.kindergarten_id = id;

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setStaff(response.teachers);
      setLoading(false);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      }
    } finally {
      setSearchBtnLoading(false);
    }
  };

  useEffect(() => {
    getStaff();
  }, [searchInputs]);


  const DatePickerButton = forwardRef<HTMLButtonElement, DatePickerInputProps>(
    ({ errorClass, value, onClick }, ref) => (
      <button
        type="button"
        className={
          "datepicker-button simple-input" + (errorClass ? " input-error" : "")
        }
        onClick={onClick}
        ref={ref}
      >
        {value}
      </button>
    )
  );

  const deleteEvaluation = (evaluationId: number, evaluationTitle: string) => {
    ConfirmPopup.deletePopup(
      t("delete_evaluation_title"),
      t("delete_evaluation_type"),
      evaluationTitle
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.deleteTeacherEvaluation(evaluationId),
            "DELETE",
            API_HEADERS.authenticated
          );
          getStaff();
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  return (
    <>
      <div className="input-items">
        <form onSubmit={(e) =>
          handleSubmit(e, {
            ...(term && { term: term }),
          })
        }>
          <div className="row mb-1">
            <div className="form-block col-12 col-sm-6 col-md-2">
              <label>{t("children_search_term_label")}</label>
              <input
                name="term"
                value={term}
                className="form-input"
                placeholder={t("children_search_term_placeholder")}
                onChange={(e) => setTerm(e.target.value)}
              />
            </div>
            <div className="form-block col-12 col-sm-6 col-md-2">
              <label>{t("performance_add_new_evaluation_date_label")}</label>
              <DatePicker
                selected={dateOfEvaluation}
                onChange={(e) => setDateOfEvaluation(e)}
                customInput={<DatePickerButton
                  errorClass={
                    validations.date_of_evaluation ? " input-error" : ""
                  }
                />}
                showMonthDropdown
                showYearDropdown
                dateFormat="dd MMM, yyyy"
                isClearable
                placeholderText={t(
                  "participation_list_search_date_placeholder"
                )}
                name="date_of_participation"
                className={
                  "form-datepicker" +
                  (validations.date_of_evaluation ? " input-error" : "")
                }
                locale={LanguageDate.datepicker}
                autoComplete="off"
              />
              {validations.date_of_evaluation && (
                <ValidationMessage
                  message={validations.date_of_evaluation[0]}
                />
              )}
            </div>
            <div className="col-12 col-sm-6 col-md-3 col-xxl-2 mb-1">
              {searchBtnLoading ? (
                <button type="button" className="mt-4 button-orange has-icon">
                  <i className="icon spinner-icon" />
                  {t("participation_list_searching_button")}
                </button>
              ) : (
                <button type="submit" className="mt-4 button-orange has-icon">
                  <i className="icon search-icon" />
                  {t("participation_list_search_button")}
                </button>
              )}
            </div>
          </div>
          <p className="filter-explain-text">
            {t("unregistration_filter_explain")}
          </p>
        </form>
      </div>
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon name-lastname-icon" />
                {t("staff_list_name_lastname")}
              </th>
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon telephone-icon" />
                {t("staff_list_contact")}
              </th>
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon calender-icon" />
                {t("staff_list_evaluation_date")}
              </th>
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon evaluation-icon" />
                {t("staff_list_evaluation")}
              </th>
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon actions-icon" />
                {t("staff_list_actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={5}>
                  <Loading />
                </td>
              </tr>
            ) : (
              staff.data.map((member: any) => (
                <tr key={member.id}>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("staff_list_name_lastname")}
                  >
                    {(member.first_name ? member.first_name : "-") +
                      " " +
                      (member.last_name ? member.last_name : "-")}
                  </td>
                  <td
                    role="cell"
                    className="text-center d-grid"
                    data-label={t("staff_list_contact")}
                  >
                    <span>{member.phone ? member.phone : "-"}</span>
                    <span className="blue-text">
                      {member.email ? member.email : "-"}
                    </span>
                  </td>
                  <td
                    role="cell"
                    className="text-center dark-text"
                    data-label={t("staff_list_evaluation_date")}
                  >
                    {member.evaluation
                      ? moment(member.evaluation.evaluation_date)
                        .locale(LanguageDate.moment)
                        .format("DD MMMM, yyyy")
                      : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("staff_list_evaluation")}
                  >
                    {member.evaluation ? (
                      <EvaluationStars
                        evaluationNr={Number(
                          member.evaluation.evaluation_score
                        )}
                      />
                    ) : (
                      "-"
                    )}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("staff_list_actions")}
                  >
                    <div className="actions">
                      {member.evaluation ? (
                        <>
                          <button
                            type="button"
                            className="button-unstyled-icon"
                            onClick={(e) => {
                              setEditTeacherEvaluationModal(true);
                              setModalId(member.evaluation.id);
                              e.stopPropagation();
                            }}
                          >
                            <i className="icon edit-icon" />
                          </button>
                          {editTeacherEvaluationModal &&
                            modalId === member.evaluation.id && (
                              <EditEvaluationPopupTeacher
                                onShowModal={editTeacherEvaluationModal}
                                setShowModal={(e) => {
                                  setEditTeacherEvaluationModal(e);
                                  setModalId(null);
                                }}
                                setModified={() => getStaff()}
                                evaluationId={member.evaluation.id}
                              />
                            )}

                          <button
                            type="button"
                            className="button-unstyled-icon"
                            onClick={(e) => {
                              setShowTeacherEvaluationModal(true);
                              setModalId(member.evaluation.id);
                            }}
                          >
                            <i className="icon show-icon" />
                          </button>
                          {showTeacherEvaluationModal &&
                            modalId === member.evaluation.id && (
                              <ShowEvaluationTeacher
                                onShowModal={showTeacherEvaluationModal}
                                setShowModal={(e) => {
                                  setShowTeacherEvaluationModal(e);
                                  setModalId(null);
                                }}
                                evaluationId={member.evaluation.id}
                              />
                            )}
                          <button
                            className="button-unstyled-icon"
                            onClick={() => {
                              deleteEvaluation(
                                member.evaluation.id,
                                member.first_name + " " + member.last_name
                              );
                            }}
                          >
                            <i className="icon delete-icon" />
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="button-unstyled-icon"
                            onClick={() => setAddEvaluationTeacherModal(true)}
                          >
                            <i className="icon plus-bordered-icon" />
                          </button>
                          {addEvaluationTeacherModal && (
                            <EvaluationPopupTeacher
                              onShowModal={addEvaluationTeacherModal}
                              setShowModal={setAddEvaluationTeacherModal}
                              setModified={() => getStaff()}
                              teacherId={member.id}
                              dateOfEvaluation={dateOfEvaluation}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse totalItems={staff?.data?.length} loading={loading} />
      </div>
      <CustomPagination data={staff} setActivePage={(e) => setPage(e)} />
    </>
  );
};
export default StaffList;
