import { useState, useRef, FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import ValidationMessage from "src/helpers/ValidationMessage";
import moment from "moment";
import EvaluationStars from "../../performance/EvaluationStars";
import { LanguageDate } from "src/helpers/CheckLanguageDate";
interface ShowTeacherEvaluationProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  evaluationId: number;
  setModified: (visible: boolean) => void;
}

const ShowTeacherEvaluation: React.FC<ShowTeacherEvaluationProps> = ({
  onShowModal,
  setShowModal,
  evaluationId,
  setModified,
}) => {
  const { t } = useTranslation();

  const [teacherEvaluationDate, setTeacherEvaluationDate] = useState("");
  const [teacherEvaluationComment, setTeacherEvaluationComment] = useState("");
  const [teacherEvaluationScore, setTeacherEvaluationScore] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const onClose = () => {
    setShowModal(false);
  };

  const getShowTeacherEvaluationData = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.teacherEvaluationDetails(evaluationId),
        "GET",
        API_HEADERS.authenticated
      );
      setTeacherEvaluationDate(response.teacher_evaluation.evaluation_date);
      setTeacherEvaluationComment(
        response.teacher_evaluation.evaluation_comment
      );
      setTeacherEvaluationScore(response.teacher_evaluation.evaluation_score);
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      onClose();
    }
  };

  useEffect(() => {
    getShowTeacherEvaluationData();
  }, []);

  return (
    <Modal isOpen={!loading && onShowModal} toggle={() => onClose()} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("show_teacher_evaluation_title")}
      </ModalHeader>
      <ModalBody>
        <div className="modal-note">{t("show_teacher_evaluation_details")}</div>
        <div className="d-flex border-bottom modal-requests">
          <div className="modal-sections mb-4">
            <span className="header-text">
              {t("show_teacher_evaluation_date")}
            </span>
            <span className="bold-text">
              {teacherEvaluationDate
                ? moment(teacherEvaluationDate).locale(LanguageDate.moment).format("DD, MMMM yyyy")
                : "-"}
            </span>
          </div>
          <div className="modal-sections">
            <span className="header-text">{t("show_teacher_evaluation")}</span>
            <span className="bold-text">
              {teacherEvaluationScore ? (
                <EvaluationStars evaluationNr={Number(teacherEvaluationScore)} />
              ) : (
                "-"
              )}
            </span>
          </div>
        </div>

        <div className="d-flex mt-3 mb-4 modal-requests">
          <div className="modal-sections">
            <span className="modal-note">
              {t("show_teacher_evaluation_comments")}
            </span>
            <span className="bold-text">
              {teacherEvaluationComment ? teacherEvaluationComment : "-"}
            </span>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className="button-white"
          type="button"
          onClick={() => onClose()}
        >
          {t("show_teacher_evaluation_close")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ShowTeacherEvaluation;
