type LanguageEntry = {
  label: string;
  flag: string;
};

type Languages = {
  [key: string]: LanguageEntry;
};

const alFlag = require("../assets/images/flags/al.jpg");
const usFlag = require("../assets/images/flags/us.jpg");

const languages: Languages = {
  al: {
    label: "Albanian",
    flag: alFlag,
  },
  en: {
    label: "English",
    flag: usFlag,
  },
};

export default languages;
