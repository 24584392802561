import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import { useTranslation } from "react-i18next";
import { RoleCheck } from "src/helpers/RoleCheck";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import AddAbsence from "./AddAbsence";
import CalendarHeader from "./CalendarHeader";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import SearchFilter from "src/helpers/SearchFilter";
import UpdateAbsence from "./UpdateAbsence";
import * as ConfirmPopup from "../../../../../../helpers/ConfirmPopup";

interface ExtendedEventObject {
  id: string;
  title?: string;
  start: Date;
  description?: string;
  class?: string;
}

const Participation: React.FC = () => {
  const { childId } = useParams();
  const { t } = useTranslation();

  // Add absence modal
  const [addAbsenceModal, setAddAbsenceModal] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  // Update absence modal
  const [updateAbsenceModal, setUpdateAbsenceModal] = useState<boolean>(false);
  const [absenceId, setAbsenceId] = useState<number | string | null>(null);
  const [modified, setModified] = useState(false);

  const [participations, setParticipations] = useState<ExtendedEventObject[]>(
    []
  );
  const [totalAbsence, setTotalAbsence] = useState<number | null>(null);

  const [currentView, setCurrentView] = useState<
    "dayGridMonth" | "dayGridWeek"
  >("dayGridMonth");

  const [date, setDate] = useState(new Date());
  const calendarRef = useRef<any>(null);

  const fetchChildParticipations = async (): Promise<void> => {
    setModified(false);
    const monthNumber = date.getMonth() + 1;
    const year = date.getFullYear();

    let searchParams: any = {
      month: monthNumber,
      year: year,
    };

    const request: any = SearchFilter(
      searchParams,
      API_PATHS.getChildParticipations(childId)
    );

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      setTotalAbsence(response.total_absence);

      const filteredPaticipations: any[] = [];

      response.attendance.forEach((participation: any) => {
        var filteredPaticipation = {
          start: new Date(participation.date),
          id: participation.id,
          title: participation.participation,
          description: participation.description,
          class: participation.participation,
        };

        if (new Date() >= new Date(participation.date)) {
          filteredPaticipations.push(filteredPaticipation);
        }
      });
      setParticipations(filteredPaticipations);
    } catch (error: any) {
      if (error.response.status === 404) {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
      }
    }
  };

  useEffect(() => {
    fetchChildParticipations();
  }, [date, modified]);

  const handleDateClick = (arg: any) => {
    if (RoleCheck("2")) {
      const matchingEvent = participations.find(
        (event) => event.start >= arg.date
      );

      if (matchingEvent?.title === "present") {
        setSelectedDate(arg.dateStr);
        setAddAbsenceModal(true);
      }
    }
  };

  const navigate = (direction: "prev" | "next") => {
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();

    let newDate: Date;

    newDate = new Date(
      currentYear,
      direction === "prev" ? currentMonth - 1 : currentMonth + 1,
      1
    );

    setDate(newDate);
    calendarRef.current?.getApi().gotoDate(newDate);
  };

  const switchView = (view: "dayGridMonth" | "dayGridWeek") => {
    setCurrentView(view);
    calendarRef.current?.getApi().changeView(view);
  };

  const handleDateChange = (selectedDate: Date) => {
    setDate(selectedDate);
    if (calendarRef.current) {
      calendarRef.current.getApi().gotoDate(selectedDate);
    }
  };

  const deleteAbsence = (absenceId: string) => {
    ConfirmPopup.deletePopup(
      t("child_participation_delete_title"),
      t("child_participation_delete_desc"),
      ""
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.deleteAbsence(absenceId),
            "DELETE",
            API_HEADERS.authenticated
          );

          setModified(true);
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  return (
    <div className="child-participation">
      <CalendarHeader
        setView={(e) => switchView(e)}
        setNavigate={(e) => navigate(e)}
        setDate={(e) => handleDateChange(e)}
        view={currentView}
        date={date}
        totalAbsence={totalAbsence}
      />
      <div className="calendarCustomStyle">
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          events={participations}
          initialView={currentView}
          ref={calendarRef}
          headerToolbar={false}
          dateClick={(e) => handleDateClick(e)}
          eventContent={(eventInfo) => {
            const extendedEvent = eventInfo.event
              .extendedProps as ExtendedEventObject;
            return (
              <>
                <div className={extendedEvent.class ? extendedEvent.class : ""}>
                  <span className="title">
                    {eventInfo.event.title === "absence"
                      ? t("child_participation_title_absence")
                      : eventInfo.event.title === "holiday"
                      ? t("child_participation_title_holiday")
                      : ""}
                  </span>
                  <div className="description">{extendedEvent.description}</div>
                  {RoleCheck("2") && eventInfo.event.title === "absence" && (
                    <div className="actions">
                      <button type="button" className="button-unstyled-icon">
                        <i
                          className="icon edit-icon"
                          onClick={() => {
                            setUpdateAbsenceModal(true);
                            setAbsenceId(eventInfo.event.id);
                          }}
                        />
                      </button>
                      <button
                        type="button"
                        className="button-unstyled-icon"
                        onClick={() => deleteAbsence(eventInfo.event.id)}
                      >
                        <i className="icon delete-icon" />
                      </button>
                      {eventInfo.event.title === "absence" &&
                        updateAbsenceModal &&
                        absenceId === eventInfo.event.id && (
                          <UpdateAbsence
                            onShowModal={updateAbsenceModal}
                            setShowModal={(e) => {
                              setUpdateAbsenceModal(e);
                              setAbsenceId(null);
                            }}
                            reason={extendedEvent.description}
                            absenceId={eventInfo.event.id}
                            setModified={(e) => setModified(e)}
                          />
                        )}
                    </div>
                  )}
                </div>
              </>
            );
          }}
        />

        {addAbsenceModal && (
          <AddAbsence
            onShowModal={addAbsenceModal}
            setShowModal={(e) => setAddAbsenceModal(e)}
            setModified={(e) => setModified(e)}
            setDate={() => setSelectedDate(null)}
            date={selectedDate}
          />
        )}
      </div>
    </div>
  );
};

export default Participation;
