import { useTranslation } from "react-i18next";
import DayTabs from "../days/DayTabs";
import { AddEducationProgram } from "src/store/education_program/AddEducationProgram";
import ValidationMessage from "src/helpers/ValidationMessage";
import { EditEducationProgramStore } from "src/store/education_program/EditEducationProgram";
import * as ConfirmPopup from "../../../../../../../helpers/ConfirmPopup";
import { makeApiCall } from "src/api/apiRequests";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import { DeleteEducationProgramWeekly } from "src/store/education_program/DeleteEducationProgramWeekly";

const WeekContent: React.FC = () => {
  const { t } = useTranslation();

  const educationProgramEdit = AddEducationProgram(
    (state: any) => state.educationProgramEdit
  );

  const addEducationProgramEdit = AddEducationProgram(
    (state: any) => state.addEducationProgramEdit
  );

  const validations = EditEducationProgramStore(
    (state: any) => state.validations
  );

  const setDelete = DeleteEducationProgramWeekly(
    (state: any) => state.setDelete
  );

  const deleteCategory = (noteId: number) => {
    ConfirmPopup.deletePopup(
      t("education_program_edit_week_delete_title"),
      t("education_program_edit_week_delete_type"),
      ""
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.educationProgramDeleteWeekly(noteId),
            "DELETE",
            API_HEADERS.authenticated
          );
          setDelete(true);
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  return (
    <>
      <div className="week-data">
        <div className="main-title">
          {t("education_program_edit_main_data")}
        </div>
        <div className="row">
          <div className="form-block col-12 col-sm-7 col-md-5 col-lg-5 col-xxl-3">
            <label>{t("education_program_edit_week_topic_label")}</label>
            <input
              type="text"
              name="week_topic"
              placeholder={t("education_program_edit_week_topic_placeholder")}
              value={educationProgramEdit.week_topic}
              className={
                "form-input" + (validations.week_topic ? " input-error" : "")
              }
              autoComplete="off"
              onChange={(e) =>
                addEducationProgramEdit({
                  ...educationProgramEdit,
                  week_topic: e.target.value,
                })
              }
            />
            {validations.week_topic && (
              <ValidationMessage message={validations.week_topic[0]} />
            )}
          </div>
          {educationProgramEdit.education_program_weekly_id && (
            <div className="actions">
              <button
                type="button"
                className="button-unstyled-icon"
                onClick={() =>
                  deleteCategory(
                    educationProgramEdit.education_program_weekly_id
                  )
                }
              >
                <i className="icon delete-icon"></i>
              </button>
            </div>
          )}
        </div>
        <DayTabs />
      </div>
    </>
  );
};

export default WeekContent;
