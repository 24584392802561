import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import moment from "moment";

interface ViewEvaluationProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  evaluationId: number;
}

const ViewEvaluation: React.FC<ViewEvaluationProps> = ({
  onShowModal,
  setShowModal,
  evaluationId,
}) => {
  const { t } = useTranslation();

  const [evaluationDate, setEvaluationDate] = useState<Date | null>(null);
  const [name, setName] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [role, setRole] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(true);

  const onClose = () => {
    setShowModal(false);
  };

  const fetchEvaluation = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.childGetEvaluation(evaluationId),
        "GET",
        API_HEADERS.authenticated
      );

      setName(
        response.evaluation.author.first_name +
          " " +
          response.evaluation.author.last_name
      );
      setComment(response.evaluation.evaluation);
      setEvaluationDate(new Date(response.evaluation.evaluation_date));
      setRole(response.evaluation.author.role);

      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      onClose();
    }
  };

  useEffect(() => {
    fetchEvaluation();
  }, []);

  return (
    <Modal
      isOpen={onShowModal && !loading}
      toggle={() => onClose()}
      size="md"
      centered
    >
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("child_evaluation_show_modal_title")}
      </ModalHeader>
      <ModalBody>
        <div className="modal-note">
          {`${t("child_evaluation_show_modal_desc")}`}
        </div>
        <div className="modal-info-block">
          <div className="row">
            <div className="info col-12 col-sm-6">
              <span className="label">
                {t("child_evaluation_show_modal_date_of_evaluation")}
              </span>
              <span className="item">
                {moment(evaluationDate).format("DD-MM-YYYY")}
              </span>
            </div>
            <div className="info col-12 col-sm-6">
              <span className="label">
                {role === "teacher"
                  ? t("child_evaluation_show_modal_teacher")
                  : t("child_evaluation_show_modal_psychologist")}
              </span>
              <span className="item">{name}</span>
            </div>
            <div className="info col-12">
              <span className="label">
                {t("child_evaluation_show_modal_comment")}
              </span>
              <span className="item">{comment}</span>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className="button-white"
          type="button"
          onClick={() => onClose()}
        >
          {t("child_evaluation_show_modal_close")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ViewEvaluation;
