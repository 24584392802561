import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Sidebar from "src/components/layouts/sidebar/Sidebar";
import Breadcrumb from "src/components/layouts/Common/Breadcrumb";
import TransfersNurseryKindergarten from "src/components/panel/transfers/transfers_kindergarten/TransfersNurseryKindergarten";
import * as AppConfig from "../../../helpers/AppConfig";

const TransferNurseryToKindergartenListView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("transfer_view")}</title>
      </Helmet>
      <Sidebar>
        <Breadcrumb
          title={t("transfer_title")}
          subtitle={t("transfer_subtitle")}
        />

        {/* <Breadcrumb
            title={t("transfer_hr_title")}
            subtitle={t("transfer_hr_subtitle")}
          /> */}

        <TransfersNurseryKindergarten />
      </Sidebar>
    </>
  );
};

export default TransferNurseryToKindergartenListView;
