import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeApiCall } from "src/api/apiRequests";
import API_PATHS from "src/api/apiPaths";
import SearchFilter from "src/helpers/SearchFilter";
import API_HEADERS from "src/api/apiConfig";
import CustomPagination from "src/helpers/CustomPaginate";
import ListResponse from "src/helpers/ListResponse";
import Loading from "src/helpers/Loading";
import ViewPerformance from "./ViewPerformance";
import EvaluationStars from "./EvaluationStars";
import moment from "moment";

const TeacherPerformance: React.FC = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<"director" | "psychologist">(
    "director"
  );
  const [performanceList, setPerformanceList] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState<number>(1);

  // Modals
  const [showViewModal, setShowViewModal] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number | null>(null);

  const fetchEvaluations = async (): Promise<void> => {
    setLoading(true);
    const searchParams: any = {
      pagination: 10,
      role: activeTab,
    };

    if (activePage > 1) {
      searchParams["page"] = activePage;
    }

    const request: any = SearchFilter(
      searchParams,
      API_PATHS.teachersEvaluationList
    );

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setPerformanceList(response.teachersevaluation);
      setLoading(false);
    } catch (error: any) {}
  };

  useEffect(() => {
    fetchEvaluations();
  }, [activeTab, activePage]);

  const changeTab = (active: "director" | "psychologist") => {
    if (!loading) {
      setActiveTab(active);
      setActivePage(1);
    }
  };

  return (
    <>
      <div className="customized-tabs">
        <div className="blue-tabs mt-4">
          <div className="tab-buttons mb-4">
            <button
              className={`tab-button${
                activeTab === "director" ? " active" : ""
              }`}
              onClick={() => changeTab("director")}
            >
              {t("teacher_performance_director_tab")}
            </button>
            <button
              className={`tab-button${
                activeTab === "psychologist" ? " active" : ""
              }`}
              onClick={() => changeTab("psychologist")}
            >
              {t("teacher_performance_psychologist_tab")}
            </button>
          </div>
          <div className="tab-content">
            <div className="table-list mb-5">
              <table role="table" className="w-100">
                <thead>
                  <tr role="row">
                    <th
                      style={{ width: "25%" }}
                      role="columnheader"
                      className="text-left"
                    >
                      <i className="icon teacher-icon" />
                      {t("teacher_performance_author_name")}
                    </th>
                    <th
                      style={{ width: "20%" }}
                      role="columnheader"
                      className="text-left"
                    >
                      <i className="icon calender-icon" />
                      {t("teacher_performance_evaluation_date")}
                    </th>
                    <th
                      style={{ width: "25%" }}
                      role="columnheader"
                      className="text-left"
                    >
                      <i className="icon comment-icon" />
                      {t("teacher_performance_comment")}
                    </th>
                    <th
                      style={{ width: "15%" }}
                      role="columnheader"
                      className="text-left"
                    >
                      <i className="icon evaluation-icon" />
                      {t("teacher_performance_score")}
                    </th>
                    <th
                      style={{ width: "15%" }}
                      role="columnheader"
                      className="text-center"
                    >
                      <i className="icon actions-icon" />
                      {t("teacher_performance_action")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td className="text-center" colSpan={5}>
                        <Loading />
                      </td>
                    </tr>
                  ) : (
                    performanceList?.data?.map((performance: any) => (
                      <tr key={performance.id}>
                        <td
                          role="cell"
                          className="text-left bold-text"
                          data-label={t("teacher_performance_author_name")}
                        >
                          {`${performance.author.first_name} ${performance.author.last_name}`}
                        </td>
                        <td
                          role="cell"
                          className="text-left"
                          data-label={t("teacher_performance_evaluation_date")}
                        >
                          {moment(performance.evaluation_date).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td
                          role="cell"
                          className="text-left"
                          data-label={t("teacher_performance_comment")}
                        >
                          {performance.evaluation_comment
                            ? performance.evaluation_comment.length > 100
                              ? performance.evaluation_comment.substring(
                                  0,
                                  100
                                ) + "..."
                              : performance.evaluation_comment
                            : "-"}
                        </td>
                        <td
                          role="cell"
                          className="text-left"
                          data-label={t("teacher_performance_score")}
                        >
                          <EvaluationStars
                            evaluationNr={performance.evaluation_score}
                          />
                        </td>
                        <td
                          role="cell"
                          className="text-center"
                          data-label={t("teacher_performance_action")}
                        >
                          <div className="actions">
                            <button
                              className="button-unstyled-icon"
                              onClick={() => {
                                setShowViewModal(true);
                                setModalId(performance.id);
                              }}
                            >
                              <i className="icon show-icon" />
                            </button>
                            {showViewModal && modalId === performance.id && (
                              <ViewPerformance
                                onShowModal={showViewModal}
                                setShowModal={(e) => {
                                  setShowViewModal(e);
                                  setModalId(null);
                                }}
                                performanceId={performance.id}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <ListResponse
                totalItems={performanceList?.data?.length}
                loading={loading}
              />
            </div>
            <CustomPagination
              data={performanceList}
              setActivePage={(e) => setActivePage(e)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherPerformance;
