import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Sidebar from "src/components/layouts/sidebar/Sidebar";
import Breadcrumb from "src/components/layouts/Common/Breadcrumb";
import Gallery from "src/components/panel/gallery/Gallery";

const GalleryView: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
     
      <Helmet>
        <title>{t("gallery_title_view")}</title>
      </Helmet>
      <Sidebar>
        <Breadcrumb title={t("gallery_title")} 
        subtitle={t("gallery_subtitle")}/>
        <Gallery />
      </Sidebar>
    </>
  );
};

export default GalleryView;
