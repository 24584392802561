import { t } from "i18next";
import Swal from "sweetalert2";

export const deletePopup = (
  title: string,
  deleteType: string,
  description: string
) => {
  var deletIcon = `<i class="icon delete2-icon" />`;
  var boldText = description
    ? ` <span class="bold-text">${description}</span>`
    : "";

  const text = `${deleteType ? deleteType : ""}${boldText}`;

  return Swal.fire({
    title: `${title}`,
    html: `${t("delete_text")} ${text}?`,
    showCancelButton: true,
    showCloseButton: true,
    cancelButtonText: t("delete_cancel_button"),
    confirmButtonText: t("delete_button") + deletIcon,
    customClass: {
      cancelButton: "swal-button-white",
      confirmButton: "swal-button-red has-icon",
      container: "confirmation-popup",
    },
    showClass: {
      popup: "",
    },
    hideClass: {
      popup: "",
    },
  });
};

export const deleteKindergartenPopup = (
  title: string,
  deleteType: string,
  description: string
) => {
  var deletIcon = `<i class="icon delete2-icon" />`;
  var boldText = description
    ? ` <span class="bold-text">${description}</span>`
    : "";

  const text = `${deleteType ? deleteType : ""}${boldText}`;

  return Swal.fire({
    title: `${title}`,
    html: `${t("delete_text")} ${text}?
    <div class="d-flex flex-column popup-message">
      <span class="mt-4">${t("delete_kindergarten_warning")}</span>
    </div>`,
    showCancelButton: true,
    showCloseButton: true,
    cancelButtonText: t("delete_cancel_button"),
    confirmButtonText: t("delete_button") + deletIcon,
    customClass: {
      cancelButton: "swal-button-white",
      confirmButton: "swal-button-red has-icon",
      container: "confirmation-popup",
    },
    showClass: {
      popup: "",
    },
    hideClass: {
      popup: "",
    },
  });
};

export const deleteNurseryPopup = (
  title: string,
  deleteType: string,
  description: string
) => {
  var deletIcon = `<i class="icon delete2-icon" />`;
  var boldText = description
    ? ` <span class="bold-text">${description}</span>`
    : "";

  const text = `${deleteType ? deleteType : ""}${boldText}`;

  return Swal.fire({
    title: `${title}`,
    html: `${t("delete_text")} ${text}?
    <div class="d-flex flex-column popup-message">
      <span class="mt-4">${t("delete_nursery_warning")}</span>
    </div>`,
    showCancelButton: true,
    showCloseButton: true,
    cancelButtonText: t("delete_cancel_button"),
    confirmButtonText: t("delete_button") + deletIcon,
    customClass: {
      cancelButton: "swal-button-white",
      confirmButton: "swal-button-red has-icon",
      container: "confirmation-popup",
    },
    showClass: {
      popup: "",
    },
    hideClass: {
      popup: "",
    },
  });
};

export const confirmationPopup = (
  title: string,
  confirmType: string,
  description: string
) => {
  var boldText = description
    ? ` <span class="bold-text">${description}</span>`
    : "";

  const text = `${confirmType ? confirmType : ""}${boldText}`;

  return Swal.fire({
    title: `${title}`,
    html: `${t("confirmation_text")} ${text}?`,
    showCancelButton: true,
    showCloseButton: true,
    cancelButtonText: t("confirmation_cancel_button"),
    confirmButtonText: t("confirmation_button"),
    customClass: {
      cancelButton: "swal-button-white",
      confirmButton: "swal-button-blue",
      container: "confirmation-popup",
    },
    showClass: {
      popup: "",
    },
    hideClass: {
      popup: "",
    },
  });
};
