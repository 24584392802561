import React from "react";
import MainAuth from "./MainAuth";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Login from "src/components/authentication/Login";

const LoginView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("auth_login_tab")}</title>
      </Helmet>
      <MainAuth>
        <Login />
      </MainAuth>
    </>
  );
};

export default LoginView;
