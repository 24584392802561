import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import Select, { SingleValue } from "react-select";
import { months } from "../data";
import { useAgeGroups } from "src/store/education_program/AgeGroups";
import { LanguageDate } from "src/helpers/CheckLanguageDate";
import { RoleCheck } from "src/helpers/RoleCheck";
import DatePicker from "react-datepicker";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import ListResponse from "src/helpers/ListResponse";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import SearchFilter from "src/helpers/SearchFilter";
import CustomPagination from "src/helpers/CustomPaginate";
import Loading from "src/helpers/Loading";
import EditEducationPrograms from "./EditEducationPrograms";
import AddNewEducationPrograms from "./AddNewEducationPrograms";
import moment from "moment";
import PATHS from "src/routes/Paths";
import useSearchSubmit from "src/hooks/useSearchSubmit";
import * as ConfirmPopup from "../../../../helpers/ConfirmPopup";

const EducationPrograms: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  type AddAgeGroups = (userData: any) => void;

  const addAgeGroups: AddAgeGroups = useAgeGroups(
    (state: any) => state.addAgeGroups
  );

  const ageGroupsData = useAgeGroups((state: any) => state.ageGroups);

  // Education program list
  const [programs, setPrograms] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  // Search
  const {
    searchInputs,
    searchBtnLoading,
    page,
    setSearchBtnLoading,
    handleSubmit,
    setPage,
  } = useSearchSubmit();
  const [searchMonths, setSearchMonths] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [yearSearch, setYearSearch] = useState<any>(null);
  const [ageGroupSearch, setAgeGroupSearch] =
    useState<SingleValue<{ value: string; label: string }>>(null);

  // Modal
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [modalId, setModalId] = useState<number | null>(null);

  const getPrograms = async (): Promise<void> => {
    var searchParams: any = searchInputs;
    let request: any = API_PATHS.educationProgramList;

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      addAgeGroups(response.educationAgeGroups);
      setPrograms(response.educationProgrames);
      setLoading(false);
      setSearchBtnLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  useEffect(() => {
    getPrograms();
  }, [searchInputs]);

  const deleteEducationProgram = (programId: number, programTitle: string) => {
    ConfirmPopup.deletePopup(
      t("education_program_delete_title"),
      t("education_program_delete__type"),
      programTitle
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.educationProgramDelete(programId),
            "DELETE",
            API_HEADERS.authenticated
          );
          if (programs.data.length === 1 && page > 1) {
            setPage(page - 1);
          } else {
            getPrograms();
          }
          console.log(page);
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  return (
    <>
      <form
        onSubmit={(e) =>
          handleSubmit(e, {
            ...(ageGroupSearch && {
              program_age_group_id: ageGroupSearch.value,
            }),
            ...(yearSearch && { year: moment(yearSearch).format("yyyy") }),
            ...(searchMonths && { month: searchMonths.value }),
          })
        }
        className="row"
      >
        <div className="form-block col-12 col-sm-6 col-md-3 col-xxl-2">
          <label>{t("education_list_month_label")}</label>
          <Select
            placeholder={t("education_list_month_placeholder")}
            className="form-react-select"
            classNamePrefix="select_input"
            name="month"
            onChange={(e) => setSearchMonths(e)}
            isClearable={true}
            isSearchable={false}
            options={months}
            value={searchMonths}
          />
        </div>
        <div className="form-block col-12 col-sm-6 col-md-3 col-xxl-2">
          <label>{t("education_list_year_label")}</label>
          <DatePicker
            selected={yearSearch}
            onChange={(e) => setYearSearch(e)}
            showYearPicker
            dateFormat="yyyy"
            isClearable
            placeholderText={t("education_list_year_placeholder")}
            className="form-datepicker"
            yearItemNumber={12}
            autoComplete="off"
            popperClassName="navigations"
            locale={LanguageDate.datepicker}
            name="year"
          />
        </div>
        <div className="form-block col-12 col-sm-6 col-md-3 col-xxl-2">
          <label>{t("education_list_age_group_label")}</label>
          <Select
            placeholder={t("education_list_choose_age_group_placeholder")}
            className="form-react-select"
            classNamePrefix="select_input"
            name="program_age_group_id"
            onChange={(e) => setAgeGroupSearch(e)}
            isClearable={true}
            isSearchable={false}
            value={ageGroupSearch}
            options={ageGroupsData.map((ageGroup: any) => ({
              value: ageGroup.id,
              label: `${ageGroup.from} - ${ageGroup.to} ${t(
                "education_list_choose_age_group_months"
              )}`,
            }))}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-3 col-xxl-2 mb-1">
          {searchBtnLoading ? (
            <button type="button" className="mt-4 button-orange has-icon">
              <i className="icon spinner-icon" />
              {t("education_list_searching_button")}
            </button>
          ) : (
            <button type="submit" className="mt-4 button-orange has-icon">
              <i className="icon search-icon" />
              {t("education_list_search_button")}
            </button>
          )}
        </div>
      </form>
      {RoleCheck("3") && (
        <div className="w-100 mb-3 d-flex justify-content-end">
          <button
            className="button-blue has-icon"
            onClick={() => setShowAddModal(true)}
          >
            <i className="icon add-icon" />
            {t("education_add_new_program")}
          </button>
          {showAddModal && (
            <AddNewEducationPrograms
              onShowModal={showAddModal}
              setShowModal={(e) => setShowAddModal(e)}
              setModified={() => getPrograms()}
            />
          )}
        </div>
      )}
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                style={{ width: "30%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon file-landscape-icon" />
                {t("education_table_name")}
              </th>
              <th
                style={{ width: "25%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon document-layout-icon" />
                {t("education_table_topic")}
              </th>
              <th
                style={{ width: "15%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon calender-icon" />
                {t("education_table_month_year")}
              </th>
              <th
                style={{ width: "15%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon age-group-icon" />
                {t("education_table_age_group")}
              </th>
              <th
                style={{ width: "15%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon actions-icon" />
                {t("education_table_action")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={5}>
                  <Loading />
                </td>
              </tr>
            ) : (
              programs?.data?.map((program: any) => (
                <tr key={program.id}>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("education_table_name")}
                  >
                    {program.name}
                  </td>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("education_table_topic")}
                  >
                    {program.topic}
                  </td>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("education_table_month_year")}
                  >
                    {program.month + "/" + program.year}
                  </td>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("education_table_age_group")}
                  >
                    {`${program.program_age_group.from} - ${
                      program.program_age_group.to
                    } ${t("education_table_age_group_months")}`}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("education_table_action")}
                  >
                    <div className="actions">
                      {RoleCheck("3") && (
                        <>
                          <button
                            className="button-unstyled-icon"
                            onClick={() => {
                              setShowEditModal(true);
                              setModalId(program.id);
                            }}
                          >
                            <i className="icon edit-icon" />
                          </button>
                          {showEditModal && modalId === program.id && (
                            <EditEducationPrograms
                              onShowModal={showEditModal}
                              setShowModal={(e) => {
                                setShowEditModal(e);
                                setModalId(null);
                              }}
                              programId={program.id}
                              setModified={() => getPrograms()}
                            />
                          )}
                        </>
                      )}
                      <button
                        className="button-unstyled-icon"
                        onClick={() =>
                          navigate(
                            RoleCheck("3")
                              ? PATHS.educationEditView(program.id)
                              : PATHS.educationView(program.id)
                          )
                        }
                      >
                        <i className="icon show-icon" />
                      </button>
                      {RoleCheck("3") && (
                        <button
                          className="button-unstyled-icon"
                          onClick={() =>
                            deleteEducationProgram(program.id, program.name)
                          }
                        >
                          <i className="icon delete-icon" />
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse totalItems={programs?.data?.length} loading={loading} />
      </div>
      <CustomPagination data={programs} setActivePage={(e) => setPage(e)} />
    </>
  );
};

export default EducationPrograms;
