import { useState, FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Select, { SingleValue } from "react-select";
import { makeApiCall } from "src/api/apiRequests";
import { evaluations } from "./data";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import ValidationMessage from "src/helpers/ValidationMessage";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import moment from "moment";
import EvaluationStars from "./EvaluationStars";

interface EditEvaluationProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  performanceId: number;
  setModified: (visible: boolean) => void;
  teachers: any[];
}

const EditEvaluation: React.FC<EditEvaluationProps> = ({
  onShowModal,
  setShowModal,
  performanceId,
  setModified,
  teachers,
}) => {
  const { t } = useTranslation();

  const [teacher, setTeacher] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [evaluation, setEvaluation] =
    useState<SingleValue<{ value: number; text?: string; icon: JSX.Element }>>(
      null
    );
  const [evaluationDate, setEvaluationDate] = useState<string>("");
  const [comment, setComment] = useState<string>("");

  const [loadingButton, setLoadingButton] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const [loading, setLoading] = useState<boolean>(true);

  const onClose = () => {
    setShowModal(false);
  };

  const fetchPerformance = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.teacherPerformanceDetails(performanceId),
        "GET",
        API_HEADERS.authenticated
      );

      setLoading(false);
      const { teacher, evaluation_date, evaluation_score, evaluation_comment } =
        response.teacher_evaluation;
      setTeacher({
        value: teacher.id,
        label: `${teacher.first_name} ${teacher.last_name}`,
      });
      setEvaluationDate(moment(evaluation_date).format("DD-MM-YYYY"));
      setEvaluation({
        value: evaluation_score,
        text: `${evaluation_score} star`,
        icon: <EvaluationStars evaluationNr={evaluation_score} />,
      });
      setComment(evaluation_comment);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      onClose();
    }
  };

  useEffect(() => {
    fetchPerformance();
  }, []);

  const editEvaluation = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setLoadingButton(true);

    let formData = {
      evaluation_comment: comment,
      evaluation_score: evaluation ? evaluation.value : "",
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.teacherPerformanceUpdate(performanceId),
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);

      setModified(true);

      onClose();
      setLoadingButton(false);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        onClose();
      }
      setLoadingButton(false);
    }
  };

  return (
    <Modal
      isOpen={onShowModal && !loading}
      toggle={() => onClose()}
      size="md"
      centered
    >
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("performance_edit_evaluation_title")}
      </ModalHeader>
      <form onSubmit={(e) => editEvaluation(e)}>
        <ModalBody>
          <div className="modal-note">
            {t("performance_edit_evaluation_desc")}
          </div>
          <div className="row">
            <div className="form-block col-12 col-sm-6">
              <label>{t("performance_edit_evaluation_teacher_label")}</label>
              <input
                name="teacher"
                className="form-input"
                defaultValue={teacher ? teacher.label : ""}
                placeholder={t(
                  "performance_edit_evaluation_comment_placeholder"
                )}
                readOnly
              />
            </div>
            <div className="form-block col-12 col-sm-6">
              <label>{t("performance_edit_evaluation_date_label")}</label>
              <input
                name="evaluation_date"
                className="form-input"
                defaultValue={evaluationDate}
                placeholder={t("performance_edit_evaluation_date_placeholder")}
                readOnly
              />
            </div>
            <div className="form-block col-12 col-sm-6">
              <label className="required">
                {t("performance_edit_evaluation_evaluation_label")}
              </label>
              <Select
                placeholder={t(
                  "performance_edit_evaluation_evaluation_placeholder"
                )}
                className={
                  "form-react-select" +
                  (validations.evaluation_score ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="evaluation_score"
                onChange={(e) => setEvaluation(e)}
                isClearable={true}
                isSearchable={false}
                options={evaluations}
                value={evaluation}
                getOptionLabel={(e: any) => e.icon}
              />
              {validations.evaluation_score && (
                <ValidationMessage message={validations.evaluation_score[0]} />
              )}
            </div>
            <div className="form-block col-12">
              <label>{t("performance_edit_evaluation_comment_label")}</label>
              <textarea
                name="comment"
                className={
                  "form-input" +
                  (validations.evaluation_comment ? " input-error" : "")
                }
                value={comment}
                placeholder={t(
                  "performance_edit_evaluation_comment_placeholder"
                )}
                rows={3}
                onChange={(e) => setComment(e.target.value)}
              />
              {validations.evaluation_comment && (
                <ValidationMessage
                  message={validations.evaluation_comment[0]}
                />
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("performance_edit_evaluation_cancel_button")}
          </button>

          {loadingButton ? (
            <button type="button" className="button-blue has-icon">
              <i className="spinner-icon" />
              {t("performance_edit_evaluation_editing_button")}
            </button>
          ) : (
            <button type="submit" className="button-blue has-icon">
              <i className="icon add-icon" />
              {t("performance_edit_evaluation_edit_button")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditEvaluation;
