import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeApiCall } from "src/api/apiRequests";
import { EducationProgramData } from "src/store/education_program/EducationProgramData";
import WeekContent from "./WeekContent";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
// import FLASH_MESSAGES from "src/helpers/FlashMessages";
import Loading from "src/helpers/Loading";
import SearchFilter from "src/helpers/SearchFilter";

type TabsContentState = {
  activeTab: number;
};

const WeekTabs: React.FC = () => {
  const { t } = useTranslation();
  const { educationProgramId } = useParams();

  const [loading, setLoading] = useState<boolean>(true);

  const addEducationProgramData = EducationProgramData(
    (state: any) => state.addEducationProgramData
  );

  const weeks = [1, 2, 3, 4];

  const [activeTab, setActiveTab] = useState<TabsContentState["activeTab"]>(1);

  const handleTabClick = (week: number) => {
    setActiveTab(week);
  };

  const getEducationProgramData = async (): Promise<void> => {
    let request: any = API_PATHS.educationProgramShowDetailed;

    var searchParams: any = {
      education_program_monthly_id: educationProgramId,
    };

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      addEducationProgramData(response.educationProgram);
      setLoading(false);
    } catch (error: any) {}
  };

  useEffect(() => {
    getEducationProgramData();
  }, []);

  return loading ? (
    <div className="text-center">
      <Loading />
    </div>
  ) : (
    <div className="customized-tabs">
      <div className="week-tabs">
        <div className="tab-buttons">
          {weeks.map((week) => (
            <button
              key={week}
              className={`tab-button${activeTab === week ? " active" : ""}`}
              onClick={() => handleTabClick(week)}
            >
              {t("education_program_view_week_" + week)}
            </button>
          ))}
        </div>
        <div className="tab-content-container">
          {weeks.map(
            (week) =>
              activeTab === week && (
                <div key={week} className="card-content">
                  <WeekContent week={week} />
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default WeekTabs;
