import React, { useState } from "react";
import { RoleCheck } from "src/helpers/RoleCheck";
import RequestsList from "./RequestsList";


const OverviewTransfersNurseryKindergarten: React.FC = () => {
  const [view, setView] = useState("pending");

  return (
    <>
      {(RoleCheck("3") || RoleCheck("9")) && (
        <>{view === "pending" && <RequestsList />}</>
      )}
    </>
  );
};

export default OverviewTransfersNurseryKindergarten;
