import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

interface ViewAssortmentProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  assortmentId: number;
}

const ViewAssortment: React.FC<ViewAssortmentProps> = ({
  onShowModal,
  setShowModal,
  assortmentId,
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState<string>("");
  const [day, setDay] = useState<string>("");
  const [meal, setMeal] = useState<string>("");
  const [ageGroup, setAgeGroup] = useState<string>("");
  const [calories, setCalories] = useState<string>("");
  const [ingredients, setIngredients] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(true);

  const getAssortmentData = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.assortmentShow(assortmentId),
        "GET",
        API_HEADERS.authenticated
      );
      setLoading(false);
      setName(response.name);
      setDay(response.day);
      setMeal(response.meal);
      setAgeGroup(response.food_age_group);
      setCalories(response.calories);
      setIngredients(response.ingredients);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      onClose();
    }
  };

  useEffect(() => {
    getAssortmentData();
  }, []);

  const onClose = () => {
    setShowModal(false);
  };

  return (
    <Modal
      isOpen={onShowModal && !loading}
      toggle={() => onClose()}
      size="md"
      centered
    >
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {name}
      </ModalHeader>
      <ModalBody>
        <div className="modal-note">
          {t("meal_view_assortment_desc") + " " + name}
        </div>
        <div className="modal-info-block">
          <div className="row">
            <div className="info col-12 col-sm-6 col-lg-4">
              <span className="label">
                {t("meal_add_new_assortment_name_label")}
              </span>
              <span className="item">{name}</span>
            </div>
            <div className="info col-12 col-sm-6 col-lg-4">
              <span className="label">
                {t("meal_add_new_assortment_day_label")}
              </span>
              <span className="item">{day}</span>
            </div>
            <div className="info col-12 col-sm-6 col-lg-4">
              <span className="label">
                {t("meal_add_new_assortment_meal_label")}
              </span>
              <span className="item">{t("child_food_" + meal)}</span>
            </div>
            <div className="info col-12 col-sm-6 col-lg-4">
              <span className="label">
                {t("meal_add_new_assortment_age_group_label")}
              </span>
              <span className="item">{ageGroup}</span>
            </div>
            <div className="info col-12 col-sm-6 col-lg-4">
              <span className="label">
                {t("meal_add_new_assortment_calories_label")}
              </span>
              <span className="item">{calories}</span>
            </div>
          </div>
          <div className="row">
            <div className="info col-12">
              <span className="label">
                {t("meal_add_new_assortment_ingredients_label")}
              </span>
              <span className="item">{ingredients}</span>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className="button-white"
          type="button"
          onClick={() => onClose()}
        >
          {t("meal_view_assortment_cancel_button")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ViewAssortment;
