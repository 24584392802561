import { create } from "zustand";

type Allergy = {
  id: number;
  name: string;
};

export const useChildAllergies = create((set) => ({
  childAllergies: [] as Allergy[],
  addChildAllergies: (data: Allergy[]) => set(() => ({ childAllergies: data })),
}));
