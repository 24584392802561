import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Sidebar from "src/components/layouts/sidebar/Sidebar";
import Breadcrumb from "src/components/layouts/Common/Breadcrumb";
import Logs from "src/components/panel/logs/Logs";

const LogsView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("logs_list_view")}</title>
      </Helmet>
      <Sidebar>
        <Breadcrumb title={t("logs_list_title")} 
        subtitle={t("logs_list_subtitle")}/>
        <Logs/>
      </Sidebar>
    </>
  );};

export default LogsView;
