import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Breadcrumb from "src/components/layouts/Common/Breadcrumb";
import Sidebar from "src/components/layouts/sidebar/Sidebar";
import EducationPrograms from "src/components/panel/education_programs/list/EducationPrograms";

const EducationListView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("education_list_tab")}</title>
      </Helmet>
      <Sidebar>
        <Breadcrumb
          title={t("education_list_title")}
          subtitle={t("education_list_subtitle")}
        />
        <EducationPrograms />
      </Sidebar>
    </>
  );
};

export default EducationListView;
