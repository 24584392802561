import { useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Select, { SingleValue } from "react-select";
import { ageGroups, seasons } from "../data";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import ValidationMessage from "src/helpers/ValidationMessage";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

interface AddNewAgeGroupProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  setModified: (visible: boolean) => void;
}

const AddNewAgeGroup: React.FC<AddNewAgeGroupProps> = ({
  onShowModal,
  setShowModal,
  setModified,
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState<string>("");
  const [ageGroup, setAgeGroup] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [season, setSeason] =
    useState<SingleValue<{ value: string; label: string }>>(null);

  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const onClose = () => {
    setShowModal(false);
  };

  const addAgeGroup = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setLoadingButton(true);

    const ageGroupData = {
      name: name,
      age: ageGroup?.value,
      season: season?.value,
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.foodAgeGroupsStore,
        "POST",
        API_HEADERS.authenticated,
        ageGroupData
      );

      FLASH_MESSAGES.successMsg(response.success);

      setModified(true);

      onClose();

      setLoadingButton(false);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        onClose();
      }
      setLoadingButton(false);
    }
  };

  return (
    <Modal isOpen={onShowModal} toggle={() => onClose()} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("meal_add_age_groups_title")}
      </ModalHeader>
      <form onSubmit={(e) => addAgeGroup(e)}>
        <ModalBody>
          <div className="modal-note">{t("meal_add_age_groups_desc")}</div>
          <div className="form-block">
            <label className="required">
              {t("meal_add_age_groups_name_label")}
            </label>
            <input
              type="text"
              name="name"
              placeholder={t("meal_add_age_groups_name_placeholder")}
              value={name}
              className={
                "form-input" + (validations.name ? " input-error" : "")
              }
              autoComplete="off"
              onChange={(e) => setName(e.target.value)}
            />
            {validations.name && (
              <ValidationMessage message={validations.name[0]} />
            )}
          </div>
          <div className="row">
            <div className="form-block col-6">
              <label className="required">
                {t("meal_add_age_groups_age_group_label")}
              </label>
              <Select
                options={ageGroups}
                isClearable={true}
                placeholder={t("meal_add_age_groups_age_group_placeholder")}
                className={
                  "form-react-select" + (validations.age ? " input-error" : "")
                }
                classNamePrefix="select_input"
                onChange={(e) => setAgeGroup(e)}
              />

              {validations.age && (
                <ValidationMessage message={validations.age[0]} />
              )}
            </div>
            <div className="form-block col-6">
              <label className="required">
                {t("meal_add_age_groups_season_label")}
              </label>
              <Select
                options={seasons}
                isClearable={true}
                placeholder={t("meal_add_age_groups_seasonp_placeholder")}
                className={
                  "form-react-select" +
                  (validations.season ? " input-error" : "")
                }
                classNamePrefix="select_input"
                onChange={(e) => setSeason(e)}
              />
              {validations.season && (
                <ValidationMessage message={validations.season[0]} />
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("meal_add_age_groups_cancel")}
          </button>

          {loadingButton ? (
            <button type="button" className="button-blue has-icon">
              <i className="spinner-icon" />
              {t("meal_add_age_groups_adding_button")}
            </button>
          ) : (
            <button type="submit" className="button-blue has-icon">
              <i className="icon add-icon" />
              {t("meal_add_age_groups_add_button")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddNewAgeGroup;
