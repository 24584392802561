import { useTranslation } from "react-i18next";
import { AddEducationProgram } from "src/store/education_program/AddEducationProgram";
import DayTabs from "../days/DayTabs";

interface WeekContentProps {
  week: number;
}

const WeekContent: React.FC<WeekContentProps> = ({ week }) => {
  const { t } = useTranslation();

  const educationProgramEdit = AddEducationProgram(
    (state: any) => state.educationProgramEdit
  );

  return (
    <>
      <div className="week-data">
        <div className="main-title">
          {t("education_program_view_main_data")}
        </div>
        <div className="row">
          {educationProgramEdit.topic ? (
            <div className="form-block col-12 col-md-5 col-lg-5 col-xxl-3">
              <label>{t("education_program_view_week_topic_label")}</label>
              <div className="education-info">{educationProgramEdit.topic}</div>
            </div>
          ) : (
            <div className="education-info">
              {t("education_program_view_no_information_week")}
            </div>
          )}
        </div>
        <DayTabs week={week} />
      </div>
    </>
  );
};

export default WeekContent;
