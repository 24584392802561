import "toastr/build/toastr.min.css";
const toastr = require("toastr");

toastr.options = {
  closeButton: true,
  newestOnTop: true,
  positionClass: "toast-bottom-center",
  preventDuplicates: false,
  onclick: null,
  showDuration: 0,
  hideDuration: 1000,
  timeOut: 5000,
  extendedTimeOut: 1000,
  showEasing: "swing",
  hideEasing: "linear",
  hideMethod: "fadeOut",
};

const FLASH_MESSAGES = {
  successMsg: (message: string): void => {
    return toastr.success(message);
  },
  infoMsg: (message: string): void => {
    toastr.info(message);
  },
  warningMsg: (message: string): void => {
    toastr.warning(message);
  },
  errorMsg: (message: string): void => {
    return toastr.error(message);
  },
};
export default FLASH_MESSAGES;
