import React from "react";
import { useTranslation } from "react-i18next";
import { CheckMeal } from "src/helpers/CheckMeal";
import ListResponse from "src/helpers/ListResponse";
import Loading from "src/helpers/Loading";

interface FoodNotesProps {
  assortments: any;
  name: string | null;
  loading: boolean;
}

const FoodList: React.FC<FoodNotesProps> = ({ assortments, name, loading }) => {
  const { t } = useTranslation();

  const assortmentsArray = Object.values(assortments);

  return (
    <>
      <div className="table-list col-12 col-lg-8">
        <div className="title">{`${t("child_food_daily_menu")}${
          name ? " / " + name : ""
        }`}</div>
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon meal-icon" />
                {t("child_food_table_meal")}
              </th>
              <th
                style={{ width: "30%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon assortment-icon" />
                {t("child_food_table_name")}
              </th>
              <th
                style={{ width: "35%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon ingredients-icon" />
                {t("child_food_table_ingredients")}
              </th>
              <th
                style={{ width: "15%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon calories-icon" />
                {t("child_food_table_calories")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={4}>
                  <Loading />
                </td>
              </tr>
            ) : (
              assortmentsArray?.map((assortment: any) => (
                <tr key={assortment.id}>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("child_food_table_meal")}
                  >
                    {CheckMeal(assortment.meal)}
                  </td>
                  <td
                    role="cell"
                    className="text-left"
                    data-label={t("child_food_table_name")}
                  >
                    {assortment.name}
                  </td>
                  <td
                    role="cell"
                    className="text-left"
                    data-label={t("child_food_table_ingredients")}
                  >
                    {assortment.ingredients}
                  </td>
                  <td
                    role="cell"
                    className="text-left"
                    data-label={t("child_food_table_calories")}
                  >
                    {assortment.calories}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse totalItems={assortmentsArray?.length} loading={loading} />
      </div>
    </>
  );
};

export default FoodList;
