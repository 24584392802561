import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import { EducationProgramData } from "src/store/education_program/EducationProgramData";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import SearchFilter from "src/helpers/SearchFilter";
import WeekTabs from "./weeks/WeekTabs";
import Loading from "src/helpers/Loading";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import moment from "moment";
import { AddEducationProgram } from "src/store/education_program/AddEducationProgram";
import { EditEducationProgramStore } from "src/store/education_program/EditEducationProgram";
import { DeleteEducationProgramWeekly } from "src/store/education_program/DeleteEducationProgramWeekly";

const EditEducationProgram: React.FC = () => {
  const navigate = useNavigate();

  const { educationProgramId } = useParams();

  const [loading, setLoading] = useState<boolean>(true);

  const addEducationProgramData = EducationProgramData(
    (state: any) => state.addEducationProgramData
  );

  const educationProgramEdit = AddEducationProgram(
    (state: any) => state.educationProgramEdit
  );

  const educationProgramData = EducationProgramData(
    (state: any) => state.educationProgramData
  );
  const { addEducationProgramEdit, resetEducationProgram } =
    AddEducationProgram((state: any) => ({
      addEducationProgramEdit: state.addEducationProgramEdit,
      resetEducationProgram: state.resetEducationProgram,
    }));

  const {
    submited,
    success,
    tabs,
    setValidations,
    changeTab,
    resetValidations,
    setSuccess,
    setSubmited,
  } = EditEducationProgramStore((state: any) => ({
    submited: state.submited,
    success: state.success,
    tabs: state.tabs,
    setValidations: state.setValidations,
    resetValidations: state.resetValidations,
    setSuccess: state.setSuccess,
    setSubmited: state.setSubmited,
    changeTab: state.changeTab,
  }));

  const { deleted, setDelete } = DeleteEducationProgramWeekly((state: any) => ({
    deleted: state.deleted,
    setDelete: state.setDelete,
  }));

  const getEducationProgramData = async (): Promise<void> => {
    let request: any = API_PATHS.educationProgramShowDetailed;

    var searchParams: any = {
      education_program_monthly_id: educationProgramId,
    };

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      addEducationProgramData(response.educationProgram);
      setLoading(false);
      setSuccess(false);
      setDelete(false);
    } catch (error: any) {
      if (error.response.status === 404) {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      }
    }
  };

  const setEducationProgramData = () => {
    resetEducationProgram();

    const { id, weekly_curriculum_schedules } = educationProgramData;

    const educationProgramEditData = {
      educationProgramId: id,
      week_nr: tabs.weeks.active,
      day: tabs.days.active,
      education_program_weekly_id: "",
      week_topic: "",
      date: "",
      day_topic: "",
      day_activity: "",
      day_tools: "",
      education_program_daily_id: "",
    };

    if (weekly_curriculum_schedules && weekly_curriculum_schedules.length > 0) {
      const weekData = weekly_curriculum_schedules.find(
        (item: any) => item.week_nr === `${tabs.weeks.active}`
      );

      if (weekData) {
        const { id, topic, daily_curriculum_schedules } = weekData;

        educationProgramEditData.education_program_weekly_id = id;
        educationProgramEditData.week_topic = topic;

        if (daily_curriculum_schedules.length > 0) {
          const dayData = daily_curriculum_schedules.find(
            (item: any) => item.day === `${tabs.days.active}`
          );

          if (dayData) {
            const { id: dayId, date, topic, activity, work_tools } = dayData;
            educationProgramEditData.date = date && new Date(date);
            educationProgramEditData.day_topic = topic || "";
            educationProgramEditData.day_activity = activity || "";
            educationProgramEditData.day_tools = work_tools || "";
            educationProgramEditData.education_program_daily_id = dayId || "";
          }
        }
      }
    }

    addEducationProgramEdit(educationProgramEditData);
  };

  useEffect(() => {
    if (Object.keys(educationProgramData).length > 0) {
      setEducationProgramData();
    }
  }, [educationProgramData, tabs.weeks.active, tabs.days.active]);

  useEffect(() => {
    if (submited.loading) {
      const updateEdutcationProgram = async () => {
        const {
          education_program_weekly_id,
          week_nr,
          week_topic,
          day,
          date,
          day_topic,
          day_activity,
          day_tools,
          education_program_daily_id,
        } = educationProgramEdit;

        const formData = new FormData();

        if (educationProgramId) {
          formData.append("education_program_monthly_id", educationProgramId);
        }

        education_program_weekly_id &&
          formData.append(
            "education_program_weekly_id",
            education_program_weekly_id
          );

        week_nr && formData.append("week_nr", week_nr);

        formData.append("week_topic", week_topic);

        formData.append("day", day);

        education_program_daily_id &&
          formData.append(
            "education_program_daily_id",
            education_program_daily_id
          );

        formData.append("date", date ? moment(date).format("YYYY-MM-DD") : "");

        formData.append("day_topic", day_topic);
        formData.append("day_activity", day_activity);
        formData.append("day_tools", day_tools);

        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.educationProgramStoreDetailed,
            "POST",
            API_HEADERS.authenticated,
            formData
          );
          if (response) {
            FLASH_MESSAGES.successMsg(response.success);
          }
          setSuccess(true);
          resetValidations();

          if (submited.form === "weeks" || submited.form === "days") {
            const tab = tabs[submited.form];
            changeTab(submited.form, tab.clicked, true);
          }
        } catch (error: any) {
          if (error.response.status === 422) {
            setValidations(error.response.data);
          } else {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        } finally {
          setSubmited(false, "");
        }
      };

      updateEdutcationProgram();
    }
  }, [submited.loading]);

  useEffect(() => {
    getEducationProgramData();
  }, []);

  useEffect(() => {
    if (success || deleted) {
      getEducationProgramData();
    }
  }, [success, deleted]);

  return (
    <>
      {loading ? (
        <div className="text-center">
          <Loading />
        </div>
      ) : (
        <WeekTabs />
      )}
    </>
  );
};

export default EditEducationProgram;
