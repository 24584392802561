import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import ListResponse from "src/helpers/ListResponse";
import Loading from "src/helpers/Loading";
import SearchFilter from "src/helpers/SearchFilter";
import { useChildAllergies } from "src/store/child/ChildAllergies";

interface ProductsProps {
  groupId: number | null;
  modified: boolean;
  setModified: (visible: boolean) => void;
}

const Products: React.FC<ProductsProps> = ({
  groupId,
  modified,
  setModified,
}) => {
  const { t } = useTranslation();
  const { childId } = useParams();

  const addChildAllergies: any = useChildAllergies(
    (state: any) => state.addChildAllergies
  );

  const [products, setProducts] = useState<any>([]);
  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const productsList = async (): Promise<void> => {
    setModified(false);

    var searchParams: any = {};

    childId && (searchParams["child_id"] = childId);

    const request: any = SearchFilter(
      searchParams,
      API_PATHS.childListProducts(groupId)
    );

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setProducts(response.all);

      let selected: any[] = [];

      Object.entries(response.childAllergicProducts).forEach(function ([
        key,
        value,
      ]) {
        selected.push({
          id: key,
          name: value,
        });
      });

      let allAllergies: any[] = [];

      Object.entries(response.allChildAllergicProducts).forEach(function ([
        key,
        value,
      ]) {
        allAllergies.push({
          id: key,
          name: value,
        });
      });
      addChildAllergies(allAllergies);
      setSelectedProducts(selected);
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
    }
  };

  useEffect(() => {
    productsList();
  }, [groupId]);

  useEffect(() => {
    modified && productsList();
  }, [modified]);

  const onHandleCheckbox = (e: any, data: any) => {
    if (e.target.checked) {
      setSelectedProducts((current: any) => [...current, data]);
    } else {
      setSelectedProducts((current: any) =>
        current.filter((element: any) => {
          return element.id != data.id;
        })
      );
    }
  };

  const saveChildAllergies = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setIsLoading(true);

    let selectedProductsArr: any[] = [];

    selectedProducts.forEach((product: any) => {
      selectedProductsArr.push(product.id);
    });

    const formData = {
      child_id: childId,
      childAllergicProducts: selectedProductsArr,
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.childUpdateProducts(groupId),
        "POST",
        API_HEADERS.authenticated,
        formData
      );
      FLASH_MESSAGES.successMsg(response.success);
      setModified(true);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="table-list mt-0">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                style={{ width: "80%" }}
                role="columnheader"
                className="text-left"
              >
                {t("child_allergies_product_table_name")}
              </th>
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-center"
              >
                {t("child_allergies_product_table_actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={2}>
                  <Loading />
                </td>
              </tr>
            ) : (
              products?.map((product: any) => (
                <tr key={product.id}>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("child_allergies_product_table_name")}
                  >
                    {product.name}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("child_allergies_product_table_actions")}
                  >
                    <div className="actions">
                      <div className="form-block mb-0">
                        <input
                          type="checkbox"
                          className="form-checkbox"
                          id={"checkbox_" + product.id}
                          defaultChecked={selectedProducts.find(
                            (prod: any) => prod.id == product.id
                          )}
                          onChange={(e) => onHandleCheckbox(e, product)}
                        />
                        <label htmlFor={"checkbox_" + product.id}></label>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse totalItems={products?.length} loading={loading} />
        {products?.length > 0 && (
          <div className="align-self-end mt-4">
            {isLoading ? (
              <button type="button" className="button-blue has-icon">
                <i className="spinner-icon" />
                {t("child_allergies_product_saving_button")}
              </button>
            ) : (
              <button
                type="button"
                className="button-blue has-icon"
                onClick={(e: any) => saveChildAllergies(e)}
              >
                {t("child_allergies_product_save_button")}
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Products;
