import { useState, useRef, FormEvent, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams } from "react-router";

import Paths from "../../../../routes/Paths";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import ValidationMessage from "src/helpers/ValidationMessage";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import { useUserdata } from "src/store/UserData";
import Loading from "src/helpers/Loading";
import ListResponse from "src/helpers/ListResponse";

import DatePicker from "react-datepicker";
import moment from "moment";
import PATHS from "src/routes/Paths";
import * as AppConfig from "../../../../helpers/AppConfig";
import SearchFilter from "src/helpers/SearchFilter";

const EditThirdStepNursery: React.FC = () => {
  const { t } = useTranslation("");
  const navigate = useNavigate();
  const { id } = useParams();

  const [percentageComplete, setPercentageComplete] = useState(100);
  const [view, setView] = useState("third");
  const [applicationId, setApplicationId] = useState(id ? id : "");
  const [documentType, setDocumentType] = useState("");
  const [idDocument, setIdDocument] = useState<File | null>(null);
  const [documentName, setDocumentName] = useState("");
  const [documentsRequired, setDocumentsRequired] = useState<any>("");
  const [fileInfos, setFileInfos] = useState<any>({});

  const [fatherName, setFatherName] = useState("");
  const [fatherLastname, setFatherLastname] = useState("");
  const [fatherOccupation, setFatherOccupation] = useState("");
  const [fatherWorkAddress, setFatherWorkAddress] = useState("");
  const [fatherPhone, setFatherPhone] = useState("");
  const [fatherEmail, setFatherEmail] = useState("");

  const [motherName, setMotherName] = useState("");
  const [motherLastname, setMotherLastname] = useState("");
  const [motherOccupation, setMotherOccupation] = useState("");
  const [motherWorkAddress, setMotherWorkAddress] = useState("");
  const [motherPhone, setMotherPhone] = useState("");
  const [motherEmail, setMotherEmail] = useState("");
  const [applicationDocuments, setApplicationDocuments] = useState<any>([]);
  const [parents, setParents] = useState<any>([]);
  const [childrenCategoriesDocuments, setChildrenCategoriesDocuments] =
  useState<Record<string, any>[]>([]);
const [childrenCategories, setChildrenCategories] = useState("");

  const [modified, setModified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});
  const addedDocuments = new Set();

  const updateDocument = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setBtnLoading(true);

    const formData = new FormData();
    formData.append("application_id", applicationId ? applicationId : "");
    formData.append("fathers_first_name", fatherName ? fatherName : "");
    formData.append("fathers_last_name", fatherLastname ? fatherLastname : "");
    formData.append(
      "fathers_occupation",
      fatherOccupation ? fatherOccupation : ""
    );
    formData.append(
      "fathers_work_address",
      fatherWorkAddress ? fatherWorkAddress : ""
    );
    formData.append("fathers_phone", fatherPhone ? fatherPhone : "");
    formData.append("fathers_email", fatherEmail ? fatherEmail : "");
    formData.append("mothers_first_name", motherName ? motherName : "");
    formData.append("mothers_last_name", motherLastname ? motherLastname : "");
    formData.append(
      "mothers_occupation",
      motherOccupation ? motherOccupation : ""
    );
    formData.append(
      "mothers_work_address",
      motherWorkAddress ? motherWorkAddress : ""
    );
    formData.append("mothers_phone", motherPhone ? motherPhone : "");
    formData.append("mothers_email", motherEmail ? motherEmail : "");

    for (const document of applicationDocuments) {
      if (document.file) {
        formData.append(
          `documents[${document.children_category_document_id}]`,
          document.file
        );
      }
    }

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.updateDocs,
        "POST",
        API_HEADERS.blob,
        formData
      );
      FLASH_MESSAGES.successMsg(response.success);
      navigate(PATHS.applicationsNursery);
      setModified(true);

      setBtnLoading(false);

      setTimeout(() => {
        setPercentageComplete(100);
      }, 3000);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        navigate(-1);
      }
      setBtnLoading(false);
    }
  };

  const handleFileChange = (documentId: any) => (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const updatedApplicationDocuments = applicationDocuments.map((doc: any) =>
        doc.id === documentId
          ? { ...doc, file, path: file.name, size: file.size }
          : doc
      );
      setApplicationDocuments(updatedApplicationDocuments);
    }
  };

  const deleteDocument = (documentId: string): void => {
    const updatedApplicationDocuments = applicationDocuments.map(
      (document: any) => {
        if (document.id === documentId) {
          return { ...document, path: "" };
        }
        return document;
      }
    );
    setApplicationDocuments(updatedApplicationDocuments);
  };

  const showApplication = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.showApplication(Number(applicationId)),
        "GET",
        API_HEADERS.authenticated
      );
      setLoading(false);
      setApplicationDocuments(response.application.application_documents);
      setChildrenCategories(response.application.children_category.title);
      setChildrenCategoriesDocuments(
        response.application.children_category.children_category_documents
      );
      setParents(response.application.child.parents);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  useEffect(() => {
    showApplication();
  }, []);

  useEffect(() => {
    if (parents.length > 0) {
      const father = parents.find(
        (parent: any) => parent.relation === "father"
      );
      const mother = parents.find(
        (parent: any) => parent.relation === "mother"
      );

      if (father) {
        setFatherName(father.first_name);
        setFatherLastname(father.last_name);
        setFatherOccupation(father.occupation);
        setFatherWorkAddress(father.work_address);
        setFatherPhone(father.phone);
        setFatherEmail(father.email);
      }

      if (mother) {
        setMotherName(mother.first_name);
        setMotherLastname(mother.last_name);
        setMotherOccupation(mother.occupation);
        setMotherWorkAddress(mother.work_address);
        setMotherPhone(mother.phone);
        setMotherEmail(mother.email);
      }
    }
  }, [parents]);

  const getDocsRequired = async (): Promise<void> => {
    setModified(false);

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.listOfDocsRequired(Number(applicationId)),
        "GET",
        API_HEADERS.authenticated
      );
      setDocumentsRequired(response.documentsNeeded);
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  useEffect(() => {
    getDocsRequired();
  }, [modified]);

  return (
    <>
      <div className="make-application-overview">
        <div className={`div ${view === "third" ? "selected" : ""}`}>
          <i className="dot-icon icon me-3" />
        </div>

        <div className={`div ${view === "third" ? "selected" : ""}`}>
          <i className="icon dot-icon me-3" />
        </div>

        <div className={`div ${view === "third" ? "selected" : ""}`}>
          <i className="icon dot-icon" />
        </div>
      </div>
      <form onSubmit={(e) => updateDocument(e)}>
        <div className="background w-100 mt-5">
          <div className="row">
            <div className="col">
              <p className="text-blue mb-4">
                {t("third_step_main_information_father")}
              </p>
              <div className="form-block col-12 col-md-6 col-sm-6">
                <label className="required">
                  {t("third_step_father_name")}
                </label>
                <input
                  type="text"
                  name="name"
                  className={
                    "form-input" +
                    (validations.fathers_first_name ? " input-error" : "")
                  }
                  onChange={(e) => setFatherName(e.target.value)}
                  value={fatherName}
                  placeholder={t("third_step_father_name")}
                />
                {validations.fathers_first_name && (
                  <ValidationMessage
                    message={validations.fathers_first_name[0]}
                  />
                )}
              </div>

              <div className="form-block col-12 col-md-6 col-sm-6">
                <label className="required">
                  {t("third_step_father_lastname")}
                </label>
                <input
                  type="text"
                  name="lastname"
                  className={
                    "form-input" +
                    (validations.fathers_last_name ? " input-error" : "")
                  }
                  onChange={(e) => setFatherLastname(e.target.value)}
                  value={fatherLastname}
                  placeholder={t("third_step_father_lastname")}
                />
                {validations.fathers_last_name && (
                  <ValidationMessage
                    message={validations.fathers_last_name[0]}
                  />
                )}
              </div>
              <div className="form-block col-12 col-md-6 col-sm-6">
                <label>{t("third_step_occupation")}</label>
                <input
                  type="text"
                  name="lastname"
                  className={
                    "form-input" +
                    (validations.fathers_occupation ? " input-error" : "")
                  }
                  onChange={(e) => setFatherOccupation(e.target.value)}
                  value={fatherOccupation}
                  placeholder={t("third_step_occupation")}
                />
                {validations.fathers_occupation && (
                  <ValidationMessage
                    message={validations.fathers_occupation[0]}
                  />
                )}
              </div>
              <div className="form-block col-12 col-md-6 col-sm-6">
                <label>{t("third_step_work_address")}</label>
                <input
                  type="text"
                  name="lastname"
                  className={
                    "form-input" +
                    (validations.fathers_work_address ? " input-error" : "")
                  }
                  onChange={(e) => setFatherWorkAddress(e.target.value)}
                  value={fatherWorkAddress}
                  placeholder={t("third_step_work_address")}
                />
                {validations.fathers_work_address && (
                  <ValidationMessage
                    message={validations.fathers_work_address[0]}
                  />
                )}
              </div>
              <div className="form-block col-12 col-md-6 col-sm-6">
                <label>{t("third_step_phone")}</label>
                <input
                  type="number"
                  name="phone"
                  className={
                    "form-input" +
                    (validations.fathers_phone ? " input-error" : "")
                  }
                  onChange={(e) => setFatherPhone(e.target.value)}
                  value={fatherPhone}
                  placeholder={t("third_step_phone")}
                />
                {validations.fathers_phone && (
                  <ValidationMessage message={validations.fathers_phone[0]} />
                )}
              </div>
              <div className="form-block col-12 col-md-6 col-sm-6">
                <label>{t("third_step_email")}</label>
                <input
                  type="text"
                  name="lastname"
                  className={
                    "form-input" +
                    (validations.fathers_email ? " input-error" : "")
                  }
                  onChange={(e) => setFatherEmail(e.target.value)}
                  value={fatherEmail}
                  placeholder={t("third_step_email")}
                />
                {validations.fathers_email && (
                  <ValidationMessage message={validations.fathers_email[0]} />
                )}
              </div>
            </div>
            <div className="col">
              <div className="form-block col-12 col-md-6 col-sm-6">
                <p className="text-blue mb-4">
                  {t("third_step_main_information_mother")}
                </p>
                <label className="required">
                  {t("third_step_mother_name")}
                </label>
                <input
                  type="text"
                  name="name"
                  className={
                    "form-input" +
                    (validations.mothers_first_name ? " input-error" : "")
                  }
                  onChange={(e) => setMotherName(e.target.value)}
                  value={motherName}
                  placeholder={t("third_step_mother_name")}
                />
                {validations.mothers_first_name && (
                  <ValidationMessage
                    message={validations.mothers_first_name[0]}
                  />
                )}
              </div>

              <div className="form-block col-12 col-md-6 col-sm-6">
                <label className="required">
                  {t("third_step_mother_lastname")}
                </label>
                <input
                  type="text"
                  name="firstname"
                  className={
                    "form-input" +
                    (validations.mothers_last_name ? " input-error" : "")
                  }
                  onChange={(e) => setMotherLastname(e.target.value)}
                  value={motherLastname}
                  placeholder={t("third_step_mother_lastname")}
                />
                {validations.mothers_last_name && (
                  <ValidationMessage
                    message={validations.mothers_last_name[0]}
                  />
                )}
              </div>
              <div className="form-block col-12 col-md-6 col-sm-6">
                <label>{t("third_step_occupation")}</label>
                <input
                  type="text"
                  name="lastname"
                  className={
                    "form-input" +
                    (validations.mothers_occupation ? " input-error" : "")
                  }
                  onChange={(e) => setMotherOccupation(e.target.value)}
                  value={motherOccupation}
                  placeholder={t("third_step_occupation")}
                />
                {validations.mothers_occupation && (
                  <ValidationMessage
                    message={validations.mothers_occupation[0]}
                  />
                )}
              </div>
              <div className="form-block col-12 col-md-6 col-sm-6">
                <label>{t("third_step_work_address")}</label>
                <input
                  type="text"
                  name="lastname"
                  className={
                    "form-input" +
                    (validations.mothers_work_address ? " input-error" : "")
                  }
                  onChange={(e) => setMotherWorkAddress(e.target.value)}
                  value={motherWorkAddress}
                  placeholder={t("third_step_work_address")}
                />
                {validations.mothers_work_address && (
                  <ValidationMessage
                    message={validations.mothers_work_address[0]}
                  />
                )}
              </div>
              <div className="form-block col-12 col-md-6 col-sm-6">
                <label>{t("third_step_phone")}</label>
                <input
                  type="text"
                  name="lastname"
                  className={
                    "form-input" +
                    (validations.mothers_phone ? " input-error" : "")
                  }
                  onChange={(e) => setMotherPhone(e.target.value)}
                  value={motherPhone}
                  placeholder={t("third_step_phone")}
                />
                {validations.mothers_phone && (
                  <ValidationMessage message={validations.mothers_phone[0]} />
                )}
              </div>
              <div className="form-block col-12 col-md-6 col-sm-6">
                <label>{t("third_step_email")}</label>
                <input
                  type="text"
                  name="lastname"
                  className={
                    "form-input" +
                    (validations.mothers_email ? " input-error" : "")
                  }
                  onChange={(e) => setMotherEmail(e.target.value)}
                  value={motherEmail}
                  placeholder={t("third_step_email")}
                />
                {validations.mothers_email && (
                  <ValidationMessage message={validations.mothers_email[0]} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div>
            <p className="text-blue mb-5">
              {t("third_step_upload_neccessary_documents")}
            </p>
          </div>
          <div className="make-application-third-step">
            <div className="upload-documents">
              {applicationDocuments.map((document: any) => (
                <div key={document.children_category_document_id}>
                  <div className="popup_title mb-2 mt-4">
                    <span>
                      {document.path ? "" : t("third_step_upload")}
                      {document.title ? document.title : "-"}
                    </span>
                    <span
                      style={{
                        color: "#d14343",
                        fontSize: "20px",
                      }}
                    >
                      {" "}
                      *
                    </span>
                  </div>
                  <div className="upload-file-box">
                    <div
                      className={
                        "form-input" +
                        (validations[document.children_category_document_id]
                          ? " input-error"
                          : "")
                      }
                    >
                      <>
                        <input
                          className="upload-input"
                          accept="image/*,application/pdf"
                          name="image"
                          type="file"
                          onChange={(e) => handleFileChange(document.id)(e)}
                        />
                        <div className="upload-input-placeholder">
                          <i className="icon upload-documents-icon" />
                          <div className="subicon">
                            <i className="icon  upload-documents-subicon mx-1" />
                          </div>
                        </div>
                        <span className="popup_title text-center">
                          {t("third_step_upload_instructions")}
                        </span>
                        <span className="popup_description text-center">
                          {t("third_step_limitations")}
                        </span>
                      </>
                    </div>
                  </div>
                  {validations[document.children_category_document_id] && (
                    <ValidationMessage
                      message={
                        validations[document.children_category_document_id]
                      }
                    />
                  )}

                  <>
                    <div className="download-zone mb-4 mt-4">
                      <div className="document-type">
                        <span>
                          <i className="icon pdf-icon mx-4 me-3"></i>
                        </span>
                        <span className="document-text">
                          {document.path ? document.path : "-"}
                        </span>
                      </div>
                      <div className="document-info">
                        <span className="document-text my-1 me-4">
                          {document.size
                            ? (document.size / (1024 * 1024)).toFixed(1) + " MB"
                            : " "}
                        </span>
                        <span onClick={() => deleteDocument(document.id)}>
                          <i className="icon close-icon me-2" />
                        </span>
                      </div>
                    </div>
                  </>
                </div>
              ))}
              <div className="buttons d-flex justify-content-end mt-5">
                <button
                  className="button-transparent has-icon me-2"
                  type="button"
                  onClick={() =>
                    navigate(
                      PATHS.editApplicationStepTwoNursery + applicationId
                    )
                  }
                >
                  <i className="icon slider-left-arrow" />
                  {t("third_step_back")}
                </button>
                {btnLoading ? (
                  <button type="button" className="button-blue has-icon">
                    <i className="icon spinner-icon" />
                    {t("third_step_saving")}
                  </button>
                ) : (
                  <button className="button-blue has-icon" type="submit">
                    {t("third_step_save")}
                    <i className="icon tick-icon" />
                  </button>
                )}
              </div>
            </div>

            <div className="d-flex flex-direction-column-app">
              <div className="info">
                <div className="mb-0">
                <div className="border-bottom mb-0">
                  <i className="icon category-icon" />
                  <span className="label">{t("third_step_category")}</span>
                  <span className="text sub-category mb-2">
                  {childrenCategories ? childrenCategories : t("show_application_no_category_selected")}
                  </span>
                  </div>
                  {childrenCategories ? (
                  <div className="mt-3">
                    {childrenCategoriesDocuments.map((category, index) => (
                      <p className="text-categories">
                        <i className="icon dot-icon"/>
                        {category.title ? category.title : "-"}
                      </p>
                    ))}
                  </div>
                ) : (
                  " "
                )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default EditThirdStepNursery;
