import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AllergicGroupsSidebar from "./AllergicGroupsSidebar";
import Products from "./allergic_products/Products";

const AllergicGroups: React.FC = () => {
  const { t } = useTranslation();

  const [modified, setModified] = useState<boolean>(false);
  const [groupId, setGroupId] = useState<number | null>(null);

  return (
    <>
      <div className="sidebar-block">
        <AllergicGroupsSidebar setGroupId={(e) => setGroupId(e)} />
        <div className="products">
          {groupId ? (
            <Products
              groupId={groupId}
              modified={modified}
              setModified={(e) => setModified(e)}
            />
          ) : (
            <div className="no-product-selected">
              {t("child_allergies_product_no_selected_product")}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AllergicGroups;
