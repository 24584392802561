import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { icon } from "leaflet";
import "leaflet/dist/leaflet.css";

import StaffList from "./StaffList";
import ChildrenList from "./ChildrenList";

import PATHS from "src/routes/Paths";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import Loading from "src/helpers/Loading";

const ShowKindergartenNursery: React.FC = () => {
  const { t } = useTranslation("");
  const navigate = useNavigate();
  const param = useParams();
  const [tab, setTab] = useState("staff");

  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [latitude, setLatitude] = useState<number | string>(0);
  const [longitude, setLongitude] = useState<number | string>(0);
  const [type, setType] = useState("");
  const [nrChildrenKindergarten, setNrChildrenKindergarten] = useState("");
  const [nrFreePlaces, setNrFreePlaces] = useState("");
  const [nrStaffKindergarten, setNrStaffKindergarten] = useState("");
  const [loading, setLoading] = useState<boolean>(true);

  const showKindergarten = async (): Promise<void> => {
    const kindergartenId = param.id;

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.showKindergarten(Number(kindergartenId)),
        "GET",
        API_HEADERS.authenticated
      );
      setLoading(false);

      setName(response.kindergarten.name);
      setNumber(response.kindergarten.number);
      setAddress(response.kindergarten.address);
      setPhone(response.kindergarten.phone);
      setEmail(response.kindergarten.email);
      setWebsite(response.kindergarten.website);
      setType(response.kindergarten.type);
      setNrChildrenKindergarten(response.nr_of_children_in_the_kindergarten);
      setNrFreePlaces(response.nr_of_free_places_in_the_kindergarten);
      setNrStaffKindergarten(response.nr_of_staff_in_kindergarten);
      const latitudeValue = Number(response.kindergarten.latitude) || 0;
      const longitudeValue = Number(response.kindergarten.longtitude) || 0;
      setLatitude(latitudeValue);
      setLongitude(longitudeValue);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  useEffect(() => {
    showKindergarten();
  }, []);

  const markerColors = [
    "#74dde5",
    "#fac86b",
    "#678af7",
    "#6bdaae",
    "#ab56d4",
    "#fd8adc",
    "#70b0ff",
    "#9386f0",
    "#f1c448",
    "#6592f3",
    "#3d6de0",
    "#e3692c",
    "#ff9900",
    "#ff8c00",
    "#f29b30",
    "#ffdeb5",
    "#008000",
    "#ffc0cb",
    "#59bf80",
    "#cb3438",
  ];

  const locations = [{ latitude, longitude, name }];

  const createMarker = (location: any) => {
    const randomColor =
      markerColors[Math.floor(Math.random() * markerColors.length)];

    const customIcon = icon({
      iconUrl: require("../../../../assets/images/marker-icon.png"),
      iconSize: [20, 20],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      tooltipAnchor: [16, -28],
      className: "custom-marker",
    });

    customIcon.options.className += " marker-color";

    const markerStyle = document.createElement("style");

    markerStyle.innerHTML = `
      .marker-color {
        background: ${randomColor};
      }
    `;
    document.head.appendChild(markerStyle);

    return (
      <Marker
        position={[
          location?.latitude ? location.latitude : 0,
          location?.longitude ? location.longitude : 0,
        ]}
        key={location.name}
        icon={customIcon}
      >
        <Popup className="leaflet-popup-content">
          <div className="grid-container1">
            <div className="grid-item">
              <div className="grid-header">
                <div className="grid-number">{number ? number : "-"}</div>
                {name ? name : "-"}
              </div>
              <div className="grid-content">
                <div className="grid-title mt-2">
                  <i className="icon address-icon" />
                  {t("kindergarten_address")}
                </div>
                <div className="grid-subtitle">{address ? address : "-"}</div>
                <div className="grid-title">
                  <i className="icon telephone-icon" />
                  {t("kindergarten_mobile")}
                </div>
                <div className="grid-subtitle">{phone ? phone : "-"}</div>
                <div className="grid-title">
                  <i className="icon email-icon" />
                  {t("kindergarten_email")}
                </div>
                <div className="grid-subtitle">{email ? email : "-"}</div>
                <div className="grid-title">
                  <i className="icon website-icon" />
                  {t("kindergarten_website")}
                </div>
                <div className="grid-subtitle">
                  {website ? (
                    <a
                      href={website}
                      className="text-decoration-none"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {website}
                    </a>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </div>
          </div>
        </Popup>
      </Marker>
    );
  };

  return loading ? (
    <div className="text-center">
      <Loading />
    </div>
  ) : (
    <>
      <div className="buttons d-flex justify-content-end mb-3">
        <button
          type="button"
          className="me-3 button-text-blue"
          onClick={() => navigate(PATHS.kindergartenNursery)}
        >
          {type === "kopesht"
            ? t("show_kindergarten_nursery_all_kindergartens")
            : t("show_kindergarten_nursery_all_nurseries")}
        </button>
        <p className="neutral my-3 me-3">/</p>
        <button type="button" className="button-text-blue album">
          {name ? name : "-"}
        </button>
      </div>
      <div className="show-nursery mb-5">
        <div className="show-map">
          <div id="map">
            <MapContainer
              center={[41.33, 19.82]}
              zoom={13}
              scrollWheelZoom={false}
              attributionControl={false}
              style={{ width: "640", height: "370px" }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
              />
              {locations.map((location) => createMarker(location))}
            </MapContainer>
          </div>
        </div>
        <div className="info-section">
          <div className="mb-5 me-3">
            <div className="mb-5">
              <span className="show-report-title">
                <i className="icon address-icon" />
                {t("show_kindergarten_nursery_address")}
              </span>
              <p className="show-report-description mx-1">
                {address ? address : "-"}
              </p>
            </div>
            <div>
              <span className="show-report-title">
                <i className="icon telephone-icon" />
                {t("show_kindergarten_nursery_mobile")}
              </span>
              <p className="show-report-description mx-1">
                {phone ? phone : "-"}
              </p>
            </div>
          </div>
          <div className="mb-5 me-5">
            <div className="mb-5 me-5">
              <span className="show-report-title">
                <i className="icon website-icon" />
                {t("show_kindergarten_nursery_website")}
              </span>
              <p className="show-report-description">
                {website ? (
                  <a
                    href={website}
                    className="text-decoration-none"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {website}
                  </a>
                ) : (
                  "-"
                )}
              </p>
            </div>
            <div>
              <span className="show-report-title">
                <i className="icon email-icon" />
                {t("show_kindergarten_nursery_email")}
              </span>
              <p className="show-report-description blue-text">
                {email ? email : "-"}
              </p>
            </div>
          </div>
          <div className="mb-5 ml-auto"></div>
          <div className="section-container">
            <div className="nursery-report">
              <div className="circle">
                <i className="icon-before staff-number-icon" />
              </div>
              <div className="circle-info">
                <span className="show-report-cards">
                  {t("show_kindergarten_nursery_staff_number")}
                </span>
                <span>{nrStaffKindergarten ? nrStaffKindergarten : "-"}</span>
              </div>
            </div>
            <div className="nursery-report">
              <div className="circle red">
                <i className="icon-before kid-icon" />
              </div>
              <div className="circle-info">
                <span className="show-report-cards">
                  {t("show_kindergarten_nursery_vacancies")}
                </span>
                <span>{nrFreePlaces ? nrFreePlaces : "-"}</span>
              </div>
            </div>
            <div className="nursery-report">
              <div className="circle blue">
                <i className="icon-before kid-icon" />
              </div>
              <div className="circle-info">
                <span className="show-report-cards">
                  {t("show_kindergarten_nursery_children_number")}
                </span>
                <span>
                  {nrChildrenKindergarten ? nrChildrenKindergarten : "-"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="d-flex justify-content-between">
          <div className="tab-sort">
            <div
              className={`div ${tab === "staff" ? "selected" : ""}`}
              onClick={(e) => {
                e ? setTab("staff") : setTab("");
              }}
            >
              {t("show_kindergarten_nursery_staff")}
            </div>

            <div
              className={`div ${tab === "children" ? "selected" : ""}`}
              onClick={(e) => {
                e ? setTab("children") : setTab("");
              }}
            >
              {t("show_kindergarten_nursery_children")}
            </div>
          </div>
        </div>
        {tab === "staff" ? <StaffList /> : <ChildrenList />}
      </div>
    </>
  );
};
export default ShowKindergartenNursery;
