import { useState, useRef, FormEvent, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams } from "react-router";

import Paths from "../../../../routes/Paths";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import ValidationMessage from "src/helpers/ValidationMessage";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import { useUserdata } from "src/store/UserData";
import Loading from "src/helpers/Loading";
import ListResponse from "src/helpers/ListResponse";

import DatePicker from "react-datepicker";
import moment from "moment";
import PATHS from "src/routes/Paths";
import * as AppConfig from "../../../../helpers/AppConfig";
import SearchFilter from "src/helpers/SearchFilter";

import ShowCategory from "./ShowCategory";

const SecondStepKindergarten: React.FC = () => {
  const { t } = useTranslation("");
  const navigate = useNavigate();
  const { id } = useParams();

  const [applicationId, setApplicationId] = useState(id ? id : "");
  const [categoryId, setCategoryId] = useState("");
  const [list, setList] = useState("");

  const [showYes, setShowYes] = useState(false);
  const [showNo, setShowNo] = useState(true);
  const [applicationDocuments, setApplicationDocuments] = useState<any>([]);

  const [modified, setModified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});
  const [view, setView] = useState("second");
  const [childrenCategories, setChildrenCategories] = useState<any>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const [child, setChild] = useState<Record<string, string>>({});

  const [showModal, setShowModal] = useState(false);
  const [modalId, setModalId] = useState<number | null>(null);

  const handleRadioChange = (categoryId: string) => {
    setCategoryId(categoryId);
  };

  const getCategories = async (): Promise<void> => {
    setModified(false);

    let request: any = API_PATHS.categoriesList;

    var searchParams: any = {
      list: list,
    };

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setChildrenCategories(response.childrenCategories);
      setLoading(false);
      showApplication();
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  useEffect(() => {
    getCategories();
  }, [modified]);

  const handleYesNoChange = (e: any) => {
    const value = e.target.value;
    setShowYes(value === "0" ? true : false && value === "null");
    setShowNo(value === "1" ? true : false && value === "null");
  };

  const storeCategory = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setBtnLoading(true);

    const formData = new FormData();
    formData.append("application_id", applicationId ? applicationId : "");
    formData.append("category_id", categoryId);

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.storeCategory,
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);
      if (applicationDocuments && applicationDocuments.length > 0) {
        navigate(PATHS.editApplicationStepThreeKindergarten + applicationId);
      } else {
        navigate(PATHS.makeApplicationStepThreeKindergarten + applicationId);
      }
      setModified(true);

      setBtnLoading(false);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        navigate(-1);
      }
      setBtnLoading(false);
    }
  };

  const showApplication = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.showApplication(Number(applicationId)),
        "GET",
        API_HEADERS.authenticated
      );
      setLoading(false);

      setChild(response.application?.child);

      setApplicationDocuments(response.application.application_documents);

      // setFileSize(response.application.application_documents)
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  return (
    <>
      <div className="make-application-overview">
        <div className={`div ${view === "second" ? "selected" : ""}`}>
          <i className="dot-icon icon me-3" />
        </div>

        <div className={`div ${view === "second" ? "selected" : ""}`}>
          <i className="icon dot-icon me-3" />
        </div>

        <div className={`div ${view === "third" ? "selected" : ""}`}>
          <i className="icon dot-icon" />
        </div>
      </div>

      <form onSubmit={(e) => storeCategory(e)}>
        <div className="form-block mt-5">
          <div className="form-radio mb-2">
            <span className="title">{t("second_step_yes_or_no")}</span>
            <div className="radio-button">
              <input
                className="radio-input"
                id="0"
                name="pending"
                type="radio"
                onClick={(e) => {
                  handleYesNoChange(e);
                }}
                checked={showYes}
                value="0"
              />
              <label htmlFor="0">{t("second_step_yes")}</label>
            </div>
            <div className="radio-button">
              <input
                className="radio-input"
                id="1"
                name="1"
                type="radio"
                onClick={(e) => {
                  handleYesNoChange(e);
                }}
                checked={showNo}
                value="1"
              />
              <label htmlFor="1">{t("second_step_no")}</label>
            </div>
          </div>
        </div>
        {showYes && (
          <div className="table-list mt-5 mb-5">
            <table role="table" className="w-100">
              <thead>
                <tr role="row">
                  <th
                    style={{ width: "60%" }}
                    role="columnheader"
                    className="text-left"
                  >
                    {t("second_step_category")}
                  </th>
                  <th
                    style={{ width: "20%" }}
                    role="columnheader"
                    className="text-center"
                  >
                    {t("second_step_documentation")}
                  </th>
                  <th
                    style={{ width: "20%" }}
                    role="columnheader"
                    className="text-center"
                  >
                    {t("second_step_select")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan={2}>
                      <Loading />
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {childrenCategories?.data
                  ?.filter((item: any) => item.list === "y")
                  .map((category: any) => (
                    <tr key={category.id}>
                      <td
                        role="cell"
                        className="text-left bold-text"
                        data-label={t("second_step_category")}
                      >
                        {category.title}
                      </td>
                      <td
                        role="cell"
                        className="text-center"
                        data-label={t("second_step_documentation")}
                      >
                        <div className="actions">
                          <button
                            type="button"
                            className="button-unstyled-icon"
                            onClick={() => {
                              setShowModal(true);
                              setModalId(category.id);
                            }}
                          >
                            {showModal && modalId === category.id && (
                              <ShowCategory
                                onShowModal={showModal}
                                setShowModal={(e) => {
                                  setShowModal(e);
                                  setModalId(null);
                                }}
                                title={category.title}
                                documents={category.children_category_documents}
                              />
                            )}
                            <i className="icon show-icon" />
                          </button>
                        </div>
                      </td>
                      <td
                        role="cell"
                        className="d-flex justify-content-center"
                        data-label={t("second_step_select")}
                      >
                        <div className="form-block">
                          <div className="form-radio">
                            <div className="radio-button">
                              <input
                                className={
                                  validations.category_id ? " input-error" : ""
                                }
                                id={category.id}
                                name="category"
                                value={category.id}
                                checked={categoryId === category.id}
                                onChange={() => handleRadioChange(category.id)}
                                type="radio"
                              />
                              <label htmlFor={category.id}></label>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <span className="invalid-message">
              {validations.category_id && (
                <ValidationMessage message={validations.category_id[0]} />
              )}
            </span>
            <ListResponse
              totalItems={childrenCategories?.data?.length}
              loading={loading}
            />
          </div>
        )}
        {showNo && (
          <div className="table-list mt-5 mb-5">
            <table role="table" className="w-100">
              <thead>
                <tr role="row">
                  <th
                    style={{ width: "60%" }}
                    role="columnheader"
                    className="text-left"
                  >
                    {t("second_step_category")}
                  </th>
                  <th
                    style={{ width: "20%" }}
                    role="columnheader"
                    className="text-center"
                  >
                    {t("second_step_documentation")}
                  </th>
                  <th
                    style={{ width: "20%" }}
                    role="columnheader"
                    className="text-center"
                  >
                    {t("second_step_select")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan={2}>
                      <Loading />
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {childrenCategories?.data
                  ?.filter((item: any) => item.list === "n")
                  .map((category: any) => (
                    <tr key={category.id}>
                      <td
                        role="cell"
                        className="text-left bold-text"
                        data-label={t("second_step_category")}
                      >
                        {category.title}
                      </td>
                      <td
                        role="cell"
                        className="text-center"
                        data-label={t("second_step_documentation")}
                      >
                        <div className="actions">
                          <button
                            type="button"
                            className="button-unstyled-icon"
                            onClick={() => {
                              setShowModal(true);
                              setModalId(category.id);
                            }}
                          >
                            {showModal && modalId === category.id && (
                              <ShowCategory
                                onShowModal={showModal}
                                setShowModal={(e) => {
                                  setShowModal(e);
                                  setModalId(null);
                                }}
                                title={category.title}
                                documents={category.children_category_documents}
                              />
                            )}
                            <i className="icon show-icon" />
                          </button>
                        </div>
                      </td>
                      <td
                        role="cell"
                        className="d-flex justify-content-center"
                        data-label={t("second_step_select")}
                      >
                        <div className="form-block">
                          <div className="form-radio">
                            <div className="radio-button">
                              <input
                                className={
                                  validations.category_id ? " input-error" : ""
                                }
                                id={category.id}
                                name="category"
                                value={category.id}
                                checked={categoryId === category.id}
                                onChange={() => handleRadioChange(category.id)}
                                type="radio"
                              />
                              <label htmlFor={category.id}></label>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <span className="invalid-message">
              {validations.category_id && (
                <ValidationMessage message={validations.category_id[0]} />
              )}
            </span>
            <ListResponse
              totalItems={childrenCategories?.data?.length}
              loading={loading}
            />
          </div>
        )}
        <div className="buttons d-flex justify-content-end mt-2">
          <button
            className="button-transparent has-icon me-2"
            type="button"
            onClick={() => {
              if (child && Object.keys(child).length > 0) {
                navigate(
                  PATHS.editApplicationStepOneKindergarten + applicationId
                );
              }
            }}
          >
            <i className="icon slider-left-arrow" />
            {t("second_step_back")}
          </button>

          {btnLoading ? (
            <button type="button" className="button-white has-icon">
              <i className="icon spinner-icon" />
              {t("second_step_saving")}
            </button>
          ) : (
            <button className="button-white has-icon" type="submit">
              {t("second_step_save")}
              <i className="icon slider-right-arrow" />
            </button>
          )}
        </div>
      </form>
    </>
  );
};
export default SecondStepKindergarten;
