import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Breadcrumb from "src/components/layouts/Common/Breadcrumb";
import Sidebar from "src/components/layouts/sidebar/Sidebar";
import AllergicGroups from "src/components/panel/meal/allergy/AllergicGroups";

const AllergyView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("meal_allergies_list_tab")}</title>
      </Helmet>
      <Sidebar>
        <Breadcrumb
          title={t("meal_allergies_list_title")}
          subtitle={t("meal_allergies_list_subtitle")}
        />
        <AllergicGroups />
      </Sidebar>
    </>
  );
};

export default AllergyView;
