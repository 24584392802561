import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import CustomPagination from "src/helpers/CustomPaginate";
import EditKindergarten from "./EditKindergarten";
import PATHS from "src/routes/Paths";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import SearchFilter from "src/helpers/SearchFilter";
import ListResponse from "src/helpers/ListResponse";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import Loading from "src/helpers/Loading";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";

const Kindergarten: React.FC<{
  searchInputs: Record<string, string>;
  modified: boolean,
  filter: "newest_first" | "oldest_first" | undefined;
  searched: boolean,
  page: number,
  setPage: (nr: number) => void;
  setSearchBtnLoading: (value: boolean) => void;
  setModified: (value: boolean) => void;
}> = ({
  searchInputs,
  modified,
  searched,
  filter,
  page,
  setPage,
  setSearchBtnLoading,
  setModified
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Kindergarten
    const [kindergartens, setKindergartens] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);

    // Modals
    const [editKindergartenModal, setEditKindergartenModal] = useState<boolean>(false);
    const [modalId, setModalId] = useState<number | null>(null);

    const getKindergartens = async (): Promise<void> => {
      var searchParams: any = searchInputs
      let request: any = API_PATHS.listKindergartens;

      searchParams.type = "kopesht";
      searchParams.pagination = "yes";
      searchParams.filter = filter;

      request = SearchFilter(searchParams, request);

      try {
        const response: any = await makeApiCall<ResponseType>(
          request,
          "GET",
          API_HEADERS.authenticated
        );
        setKindergartens(response.kindergartens);
        setLoading(false);
        setModified(false);
      } catch (error: any) {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        navigate(-1);
      } finally {
        setSearchBtnLoading(false)
      }
    };

    useEffect(() => {
      getKindergartens();
    }, [filter, searchInputs]);

    useEffect(() => {
      modified && getKindergartens();
    }, [modified]);

    const deleteKindergarten = (
      kindergartenId: number,
      kindergartenTitle: string
    ) => {
      ConfirmPopup.deleteKindergartenPopup(
        t("delete_kindergarten_title"),
        t("delete_kindergarten_type"),
        kindergartenTitle
      ).then(async (response) => {
        if (response.isConfirmed) {
          try {
            const response: any = await makeApiCall<ResponseType>(
              API_PATHS.deleteKindergarten(kindergartenId),
              "DELETE",
              API_HEADERS.authenticated
            );
            if (kindergartens.data.length === 1 && page > 1) {
              setPage(page - 1);
            } else {
              getKindergartens();
            }
            FLASH_MESSAGES.successMsg(response.success);
          } catch (error: any) {
            if (error.response.data.error) {
              FLASH_MESSAGES.errorMsg(error.response.data.error);
            }
          }
        }
      });
    };

    return (
      <>
        <div className="table-list mb-5">
          <table role="table" className="w-100 mb-4">
            <thead>
              <tr role="row">
                <th
                  style={{ width: "15%" }}
                  role="columnheader"
                  className="text-left"
                >
                  <i className="icon kindergarten-table-icon" />
                  {t("kindergarten_nr")}
                </th>
                <th
                  style={{ width: "17%" }}
                  role="columnheader"
                  className="text-center"
                >
                  <i className="icon address-icon" />
                  {t("kindergarten_address")}
                </th>
                <th
                  style={{ width: "17%" }}
                  role="columnheader"
                  className="text-center"
                >
                  <i className="icon telephone-icon" />
                  {t("kindergarten_mobile")}
                </th>
                <th
                  style={{ width: "17%" }}
                  role="columnheader"
                  className="text-center"
                >
                  <i className="icon email-icon" />
                  {t("kindergarten_email")}
                </th>
                <th
                  style={{ width: "17%" }}
                  role="columnheader"
                  className="text-center"
                >
                  <i className="icon website-icon" />
                  {t("kindergarten_website")}
                </th>
                <th
                  style={{ width: "17%" }}
                  role="columnheader"
                  className="text-center"
                >
                  <i className="icon actions-icon" />
                  {t("kindergarten_actions")}
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td className="text-center" colSpan={6}>
                    <Loading />
                  </td>
                </tr>
              ) : (
                kindergartens.data.map((kindergarten: any) => (
                  <tr key={kindergarten.id}>
                    <td
                      role="cell"
                      className="text-left bold-text"
                      data-label={t("kindergarten_nr")}
                    >
                      {kindergarten.name ? kindergarten.name : "-"}
                    </td>
                    <td
                      role="cell"
                      className="text-center"
                      data-label={t("kindergarten_address")}
                    >
                      {kindergarten.address ? kindergarten.address : "-"}
                    </td>
                    <td
                      role="cell"
                      className="text-center"
                      data-label={t("kindergarten_mobile")}
                    >
                      {kindergarten.phone ? kindergarten.phone : "-"}
                    </td>
                    <td
                      role="cell"
                      className="text-center blue-text"
                      data-label={t("kindergarten_email")}
                    >
                      {kindergarten.email ? kindergarten.email : "-"}
                    </td>
                    <td
                      role="cell"
                      className="text-center"
                      data-label={t("kindergarten_website")}
                    >
                      {kindergarten.website ? (
                        <a
                          href={kindergarten.website}
                          className="text-decoration-none"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {kindergarten.website}
                        </a>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td
                      role="cell"
                      className="text-center"
                      data-label={t("kindergarten_actions")}
                    >
                      <div className="actions">
                        <button
                          type="button"
                          className="button-unstyled-icon"
                          onClick={(e) => {
                            setEditKindergartenModal(true);
                            setModalId(kindergarten.id);
                            e.stopPropagation();
                          }}
                        >
                          <i className="icon edit-icon" />
                        </button>
                        {editKindergartenModal && modalId === kindergarten.id && (
                          <EditKindergarten
                            onShowModal={editKindergartenModal}
                            setKindergartenVisible={(e) => {
                              setEditKindergartenModal(e);
                              setModalId(null);
                            }}
                            kindergartenId={kindergarten.id}
                            setModified={() => getKindergartens()}
                          />
                        )}

                        <button
                          type="button"
                          className="button-unstyled-icon"
                          onClick={() =>
                            navigate(PATHS.showKindergarten + kindergarten.id)
                          }
                        >
                          <i className="icon show-icon" />
                        </button>
                        <button
                          className="button-unstyled-icon"
                          key={kindergarten.id}
                          onClick={(e) => {
                            deleteKindergarten(kindergarten.id, kindergarten.name);
                          }}
                        >
                          <i className="icon delete-icon" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <ListResponse
            totalItems={kindergartens?.data?.length}
            loading={loading}
            searched={searched}
          />
          <CustomPagination
            data={kindergartens}
            setActivePage={(e) => setPage(e)}
          />
        </div>
      </>
    );
  };

export default Kindergarten;
