import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import { makeApiCall } from "src/api/apiRequests";
import { LanguageDate } from "src/helpers/CheckLanguageDate";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import SearchFilter from "src/helpers/SearchFilter";
import DatePicker from "react-datepicker";
import CustomPagination from "src/helpers/CustomPaginate";
import Loading from "src/helpers/Loading";
import ListResponse from "src/helpers/ListResponse";
import AddNewEvaluation from "./AddNewEvaluation";
import EditEvaluation from "./EditEvaluation";
import ShowEvaluation from "./ShowEvaluation";
import ValidationMessage from "src/helpers/ValidationMessage";
import DatePickerButton from "src/components/common/DatePickerButton";
import useSearchSubmit from "src/hooks/useSearchSubmit";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";

const Evaluations: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Children
  const [listOfChildren, setListOfChildren] = useState<any>([]);
  const [listOfGroups, setListOfGroups] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // Search
  const {
    searchInputs,
    searchBtnLoading,
    setSearchBtnLoading,
    handleSubmit,
    setPage,
  } = useSearchSubmit();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchGroup, setSearchGroup] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [searchDate, setSearchDate] = useState<any>(new Date());
  const [validations, setValidations] = useState<Record<string, string>>({});

  // Modals
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [modalId, setModalId] = useState<number | null>(null);

  const fetchChildren = async (): Promise<void> => {
    let searchParams: any = searchInputs

    searchParams.date_of_evaluation = moment(searchDate).format("yyyy-MM-DD");

    let request: any = SearchFilter(
      searchParams,
      API_PATHS.evaluationChildrenList
    );

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setListOfChildren(response.children);
      setListOfGroups(
        response.kindergarten_groups.map((group: any) => ({
          value: group.id,
          label: group.name,
        }))
      );
      setLoading(false);
      setValidations({});
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
        setLoading(true);
        setListOfChildren([]);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        navigate(-1);
      }
    } finally {
      setSearchBtnLoading(false);
    }
  };

  useEffect(() => {
    fetchChildren();
  }, [searchInputs]);

  const deleteEvaluation = (evaluationId: number, evaluationTitle: string) => {
    ConfirmPopup.deletePopup(
      t("evaluations_delete_title"),
      t("evaluations_delete_type"),
      evaluationTitle
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.deleteChildEvaluation(evaluationId),
            "DELETE",
            API_HEADERS.authenticated
          );
          fetchChildren()
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  return (
    <>
      <form onSubmit={(e) =>
        handleSubmit(e, {
          ...(searchTerm && { term: searchTerm }),
          ...(searchGroup && { group: searchGroup.value }),
        })
      } className="row mb-4">
        <div className="form-block col-12 col-sm-6 col-md-3 col-xxl-2">
          <label>{t("evaluations_list_search_term_label")}</label>
          <input
            type="text"
            name="term"
            className="form-input"
            placeholder={t("evaluations_list_search_term_placeholder")}
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
        </div>
        <div className="form-block col-12 col-sm-6 col-md-3 col-xxl-2">
          <label>{t("evaluations_list_search_group_label")}</label>
          <Select
            placeholder={t("evaluations_list_search_group_placeholder")}
            className="form-react-select"
            classNamePrefix="select_input"
            name="program_age_group_id"
            onChange={(e) => setSearchGroup(e)}
            isClearable={true}
            isSearchable={false}
            value={searchGroup}
            options={listOfGroups}
          />
        </div>
        <div className="form-block col-12 col-sm-6 col-md-3 col-xxl-2">
          <label>{t("evaluations_list_search_date_label")}</label>
          <DatePicker
            selected={searchDate}
            onChange={(e) => setSearchDate(e)}
            showMonthDropdown
            showYearDropdown
            dateFormat="dd MMM, yyyy"
            isClearable
            customInput={
              <DatePickerButton
                otherClass="simple-input"
                errorClass={validations.date_of_evaluation}
              />
            }
            maxDate={new Date()}
            placeholderText={t("evaluations_list_search_date_placeholder")}
            className={
              "form-datepicker" +
              (validations.date_of_evaluation ? " input-error" : "")
            }
            locale={LanguageDate.datepicker}
            autoComplete="off"
            name="date_of_evaluation"
          />
          {validations.date_of_evaluation && (
            <ValidationMessage message={validations.date_of_evaluation[0]} />
          )}
        </div>
        <div className="col-12 col-sm-6 col-md-3 col-xxl-2 mb-1">
          {searchBtnLoading ? (
            <button type="button" className="mt-4 button-orange has-icon">
              <i className="icon spinner-icon" />
              {t("evaluations_list_searching_button")}
            </button>
          ) : (
            <button
              type="submit"
              className="mt-4 button-orange has-icon"
            >
              <i className="icon search-icon" />
              {t("evaluations_list_search_button")}
            </button>
          )}
        </div>
      </form>
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                style={{ width: "17%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon children-name-icon" />
                {t("evaluations_list_table_child_name")}
              </th>
              <th
                style={{ width: "17%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon guardian-icon" />
                {t("evaluations_list_table_guardian")}
              </th>
              <th
                style={{ width: "15%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon group-icon" />
                {t("evaluations_list_table_group")}
              </th>
              <th
                style={{ width: "15%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon telephone-icon" />
                {t("evaluations_list_table_contact")}
              </th>
              <th
                style={{ width: "21%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon evaluation-icon" />
                {t("evaluations_list_table_participation")}
              </th>
              <th
                style={{ width: "15%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon calender-icon" />
                {t("evaluations_list_table_actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={6}>
                  <Loading />
                </td>
              </tr>
            ) : (
              listOfChildren?.data?.map((child: any) => (
                <tr key={child.id}>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("evaluations_list_table_child_name")}
                  >
                    {`${child.first_name} ${child.last_name}`}
                  </td>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("evaluations_list_table_guardian")}
                  >
                    {`${child.guardian.first_name} ${child.guardian.last_name}`}
                  </td>
                  <td
                    role="cell"
                    className="text-left"
                    data-label={t("evaluations_list_table_group")}
                  >
                    {child.kindergarten_group
                      ? child.kindergarten_group.name
                      : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-left"
                    data-label={t("evaluations_list_table_contact")}
                  >
                    <div className="d-flex flex-column">
                      <span>{child.guardian.phone}</span>
                      <span className="blue-text">{child.guardian.email}</span>
                    </div>
                  </td>
                  <td
                    role="cell"
                    className="text-left"
                    data-label={t("evaluations_list_table_participation")}
                  >
                    {child.evaluation && child.evaluation.evaluation
                      ? child.evaluation.evaluation.length > 100
                        ? child.evaluation.evaluation.substring(0, 100) + "..."
                        : child.evaluation.evaluation
                      : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("evaluations_list_table_actions")}
                  >
                    <div className="actions">
                      {child.evaluation ? (
                        <>
                          <button
                            className="button-unstyled-icon"
                            onClick={() => {
                              setShowEditModal(true);
                              setModalId(child.id);
                            }}
                          >
                            <i className="icon edit-icon" />
                          </button>
                          {showEditModal && modalId === child.id && (
                            <EditEvaluation
                              onShowModal={showEditModal}
                              setShowModal={(e) => {
                                setShowEditModal(e);
                                setModalId(null);
                              }}
                              evaluationId={child.evaluation.id}
                              setModified={() => fetchChildren()}
                              childName={`${child.first_name} ${child.last_name}`}
                            />
                          )}
                          <button
                            className="button-unstyled-icon"
                            onClick={() => {
                              setShowViewModal(true);
                              setModalId(child.id);
                            }}
                          >
                            <i className="icon show-icon" />
                          </button>
                          {showViewModal && modalId === child.id && (
                            <ShowEvaluation
                              onShowModal={showViewModal}
                              setShowModal={(e) => {
                                setShowViewModal(e);
                                setModalId(null);
                              }}
                              evaluationId={child.evaluation.id}
                              childName={`${child.first_name} ${child.last_name}`}
                            />
                          )}
                          <button
                            className="button-unstyled-icon"
                            onClick={() =>
                              deleteEvaluation(
                                child.evaluation.id,
                                `${child.first_name} ${child.last_name}`
                              )
                            }
                          >
                            <i className="icon delete-icon" />
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="button-unstyled-icon"
                            onClick={() => {
                              setShowAddModal(true);
                              setModalId(child.id);
                            }}
                          >
                            <i className="icon plus-bordered-icon"></i>
                          </button>

                          {showAddModal && modalId === child.id && (
                            <AddNewEvaluation
                              onShowModal={showAddModal}
                              setShowModal={(e) => setShowAddModal(e)}
                              setModified={() => fetchChildren()}
                              childId={child.id}
                              evaluationDate={searchDate}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={listOfChildren?.data?.length}
          loading={loading}
        />
      </div>

      <CustomPagination
        data={listOfChildren}
        setActivePage={(e) => setPage(e)}
      />
    </>
  );
};

export default Evaluations;
