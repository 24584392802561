import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import { LanguageDate } from "src/helpers/CheckLanguageDate";
import { useNotificationsCount } from "src/store/Notification";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import Loading from "src/helpers/Loading";
import SearchFilter from "src/helpers/SearchFilter";
import PATHS from "src/routes/Paths";
import ListResponse from "src/helpers/ListResponse";

interface Notification {
  text: string;
  time: string;
}

const NotificationDropdown: React.FC = () => {
  const { t } = useTranslation();

  const notificationsCount = useNotificationsCount(
    (state: any) => state.notificationsCount
  );

  const [notification, setNotification] = useState<boolean>(false);
  const [notificationsList, setNotificationsList] = useState<Notification[]>(
    []
  );

  const [loading, setLoading] = useState<boolean>(true);

  const fetchUnreadNotifications = async (): Promise<void> => {
    setLoading(true);
    const searchParams: any = {
      type: "unread",
    };

    const request: any = SearchFilter(
      searchParams,
      API_PATHS.notificationsList
    );

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setNotificationsList(
        response.notification.map((notification: any) => ({
          text: notification.translated_message,
          time: moment
            .utc(notification.created_at)
            .locale(LanguageDate.moment)
            .local()
            .startOf("minute")
            .fromNow(),
        }))
      );
      setLoading(false);
    } catch (error: any) {}
  };

  useEffect(() => {
    if (notification) {
      fetchUnreadNotifications();
      setLoading(true);
    } else {
      setNotificationsList([]);
    }
  }, [notification]);

  return (
    <Dropdown
      isOpen={notification}
      toggle={() => setNotification(!notification)}
      className="notification-dropdown"
    >
      <DropdownToggle className="button-transparent d-flex" tag="button">
        <i className="icon notification-icon"></i>
        {notificationsCount > 0 && (
          <span className="rounded-pill red">{notificationsCount}</span>
        )}
      </DropdownToggle>
      <DropdownMenu
        className="notification-dropdown dropdown-menu-end"
        size="lg"
      >
        <div className="notifications-header">
          <span className="notifications-title">
            {t("notifications_dropdown_new_notifications")}
          </span>

          {!loading && (
            <Link to={PATHS.notifications} className="notifications-link">
              {t("notifications_dropdown_see_all")}
            </Link>
          )}
        </div>

        {loading ? (
          <div className="mt-2 text-center">
            <Loading />
          </div>
        ) : (
          notificationsList.map((notification, index) => (
            <DropdownItem key={index} className="notify-item">
              <div className="title">{notification.text}</div>
              <div className="time">
                <i className="icon dropdown-time-icon" />
                {notification.time}
              </div>
            </DropdownItem>
          ))
        )}
        <ListResponse totalItems={notificationsList.length} loading={loading} />
      </DropdownMenu>
    </Dropdown>
  );
};

export default NotificationDropdown;
