import React from "react";
import { useTranslation } from "react-i18next";
import { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Navigate, useNavigate } from "react-router";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import PATHS from "src/routes/Paths";

import { albumsImages } from "src/helpers/AppConfig";
import { makeApiCall } from "src/api/apiRequests";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

const Album: React.FC = () => {
  const { t } = useTranslation();
  const param = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [albumId, setAlbumId] = useState("");
  const [name, setName] = useState("");
  const [images, setImages] = useState<File[]>([]);

  const showAlbumnImages = async (): Promise<void> => {
    const albumId = param.id;

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.showAlbum(Number(albumId)),
        "GET",
        API_HEADERS.authenticated
      );
      setLoading(false);

      setAlbumId(response.album.id);
      setImages(response.album.photos);
      setName(response.album.name);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
    }
  };

  useEffect(() => {
    showAlbumnImages();
  }, []);

  const carouselRef = useRef<AliceCarousel>(null);

  const handlePrevClick = () => {
    if (carouselRef.current) {
      carouselRef.current.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (carouselRef.current) {
      carouselRef.current.slideNext();
    }
  };

  const responsive = {
    0: { items: 0, itemsFit: "contain" },
    568: { items: 0, itemsFit: "contain" },
    1024: { items: 0, itemsFit: "contain" },
  };

  const photos = images.map((image: any, index: number) => (
    <div key={index}>
      <img
        src={albumsImages + image.path}
        width="530px"
        height="340px"
        alt={`Image ${index + 1}`}
      />
    </div>
  ));

  return (
    <>
      {images.length > 0 ? (
        <>
          <div className="buttons d-flex justify-content-end mb-3">
            <button
              type="button"
              className="me-3 button-text-blue"
              onClick={() => navigate(PATHS.gallery)}
            >
              {t("album_gallery")}
            </button>
            <p className="neutral my-3 me-3">/</p>
            <button
              type="button"
              className="button-text-blue album"
            >
              {name ? name : "-"}
            </button>
          </div>
          <div className="image-slider">
            <div className="slider-container">
              <button className="button-slider prev" onClick={handlePrevClick}>
                <i className="icon slider-left-arrow" />
              </button>
              <div className="slider">
                <AliceCarousel
                  mouseTracking
                  responsive={responsive}
                  controlsStrategy="alternate"
                  disableDotsControls
                  disableButtonsControls
                  ref={carouselRef}
                >
                  {photos}
                </AliceCarousel>
              </div>
              <button className="button-slider next" onClick={handleNextClick}>
                <i className="icon slider-right-arrow" />
              </button>
            </div>
            <div className="photo-list">
              {images.map((image: any, index: number) => (
                <div className="photo-item" key={index}>
                  <img
                    src={albumsImages + image.path}
                    width="100px"
                    height="100px"
                    alt={`Image ${index + 1}`}
                  />
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <span className="d-flex justify-content-center no-images">
          {t("no_images_uploaded")}
        </span>
      )}
    </>
  );
};
export default Album;
