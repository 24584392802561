import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Sidebar from "src/components/layouts/sidebar/Sidebar";
import Breadcrumb from "src/components/layouts/Common/Breadcrumb";
import RegistrationRequests from "src/components/panel/registration_requests/RegistrationRequests";
import { RoleCheck } from "src/helpers/RoleCheck";

const RegistrationRequestsView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("unregistration_list_view")}</title>
      </Helmet>
      <Sidebar>
        {RoleCheck("3") || RoleCheck("4") ? (
          <Breadcrumb
            title={t("unregistration_list_title")}
            subtitle={t("unregistration_list_subtitle")}
          />
        ) : (
          <Breadcrumb
            title={t("unregistration_list_title")}
            subtitle={t("unregistration_make_request_subtitle")}
          />
        )}

        <RegistrationRequests />
      </Sidebar>
    </>
  );
};

export default RegistrationRequestsView;
