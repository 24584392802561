import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useNavigate } from "react-router";
import { makeApiCall } from "src/api/apiRequests";
import { RoleCheck } from "src/helpers/RoleCheck";
import { useUserdata } from "src/store/UserData";
import ValidationMessage from "src/helpers/ValidationMessage";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import Select from "react-select";
import Loading from "src/helpers/Loading";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

interface ShowRequestProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  transferId: number;
  setModified: (visible: boolean) => void;
}

const ShowRequest: React.FC<ShowRequestProps> = ({
  onShowModal,
  setShowModal,
  transferId,
  setModified,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useUserdata((state: any) => state.userData);

  const [childName, setChildName] = useState("");
  const [childLastName, setChildLastName] = useState("");
  const [parentName, setParentName] = useState("");
  const [parentLastName, setParentLastName] = useState("");
  const [email, setEmail] = useState("");
  const [sourceKindergarten, setSourceKindergarten] = useState("");
  const [targetKindergarten, setTargetKindergarten] = useState("");
  const [rejectedReason, setRejectedReason] = useState("");
  const [transferReason, setTransferReason] = useState("");
  const [rejectedReasonRefuse, setRejectedReasonRefuse] = useState("");
  const [kindergartenId, setKindergartenId] = useState("");
  const [type, setType] = useState("");
  const [openSpots, setOpenSpots] = useState("");
  const [takenSpots, setTakenSpots] = useState("");
  const [committeeMembers, setCommitteeMembers] = useState<
    Record<string, any>[]
  >([]);
  const [verifiedFolder, setVerifiedFolder] = useState<number>(0);
  const [status, setStatus] = useState<number>(0);
  const [kindergartens, setKindergartens] = useState<Record<string, any>[]>([]);
  const [btnLoadingApprove, setBtnLoadingApprove] = useState<boolean>(false);
  const [btnLoadingApproveCommittee, setBtnLoadingApproveCommittee] =
    useState<boolean>(false);
  const [btnLoadingApproveAnother, setBtnLoadingApproveAnother] =
    useState<boolean>(false);
  const [btnLoadingReject, setBtnLoadingReject] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const onClose = () => {
    setShowModal(false);
  };

  const getShowRequestData = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.showTransfer(transferId),
        "GET",
        API_HEADERS.authenticated
      );
      setLoading(false);

      setChildName(response.transfer?.child?.first_name);
      setChildLastName(response.transfer?.child?.last_name);
      setParentName(response.transfer?.child?.guardian?.first_name);
      setParentLastName(response.transfer?.child?.guardian?.last_name);
      setTransferReason(response.transfer?.transfer_reason);

      setSourceKindergarten(response.transfer?.source_kindergarten?.name);
      setTargetKindergarten(response.transfer?.target_kindergarten?.name);
      setType(response.transfer?.type);
      setStatus(response.transfer?.status_id);
      setEmail(response.transfer?.child?.guardian?.email);
      setOpenSpots(response.nr_of_free_places_in_the_kindergarten);
      setTakenSpots(response.nr_of_children_in_the_kindergarten);

      (RoleCheck("3") || RoleCheck("9")) && verifiedMembersCommittee();
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      onClose();
    }
  };

  useEffect(() => {
    getShowRequestData();
  }, []);

  const approveRequest = async (): Promise<void> => {
    try {
      setBtnLoadingApprove(true);

      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.approveRequest(transferId),
        "POST",
        API_HEADERS.authenticated
      );

      FLASH_MESSAGES.successMsg(response.success);
      onClose();
      setModified(true);
    } catch (error: any) {
      if (error.response && error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
      }
      setBtnLoadingApprove(false);
    }
  };

  const approveRequestAnotherKindergarten = async (): Promise<void> => {
    setBtnLoadingApproveAnother(true);
    var formData = {
      rjected_reason: rejectedReason,
    };
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.approveRequestAnother(transferId),
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);
      onClose();
      setModified(true);
    } catch (error: any) {
      if (error.response && error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
      }
      setBtnLoadingApproveAnother(false);
    }
  };

  const rejectRequestt = async (): Promise<void> => {
    var rejectionData = {
      rejected_reason: rejectedReason,
    };
    try {
      setBtnLoadingReject(true);
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.rejectRequest(transferId),
        "POST",
        API_HEADERS.authenticated,
        rejectionData
      );

      FLASH_MESSAGES.successMsg(response.success);
      onClose();
      setModified(true);
    } catch (error: any) {
      if (error.response && error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
      }
      setBtnLoadingReject(false);
    }
  };

  const getKindergartens = async (): Promise<void> => {
    let request: any = API_PATHS.kindergartensDropdownList;
    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setKindergartens(response.kindergartens);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  const approvedCommittee = async (): Promise<void> => {
    setBtnLoadingApproveCommittee(true);
    var formData = {
      verified_folder: verifiedFolder,
    };
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.approvedByCommitteeTransfer(Number(transferId)),
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);
      onClose();
      setModified(true);
      setBtnLoadingApproveCommittee(false);
    } catch (error: any) {
      if (error.response && error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
      }
    }
  };

  const verifiedMembersCommittee = async (): Promise<void> => {
    var formData = {
      verified_folder: verifiedFolder,
    };
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.verifiedCommitteeMembersTransfer(Number(transferId)),
        "POST",
        API_HEADERS.authenticated,
        formData
      );
      setCommitteeMembers(
        response.committee_members ? response?.committee_members : []
      );
      getKindergartens();
    } catch (error: any) {
      if (error.response && error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        navigate(-1);
      }
    }
  };

  return (
    <Modal
      isOpen={onShowModal && !loading}
      toggle={() => onClose()}
      size="md"
      centered
    >
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("show_request_title")}
      </ModalHeader>
      <ModalBody>
        <div className="modal-note">{t("show_request_details")}</div>

        <div>
          <p className="text-blue show-request">
            {t("show_request_main_info")}
          </p>
        </div>
        <div className="d-flex border-bottom modal-requests">
          <div className="modal-sections mb-4">
            <span className="header-text">{t("show_request_pupil_name")}</span>
            <span className="bold-text">
              {(childName ? childName : "-") +
                " " +
                (childLastName ? childLastName : "-")}
            </span>
          </div>
          <div className="modal-sections">
            <span className="header-text">{t("show_request_guardian")}</span>
            <span className="bold-text">
              {(parentName ? parentName : "-") +
                " " +
                (parentLastName ? parentLastName : "-")}
            </span>
          </div>
          <div className="modal-sections">
            <span className="header-text">
              {t("show_request_guardian_email")}
            </span>
            <span className="bold-text">{email ? email : "-"}</span>
          </div>
        </div>
        <div className="d-flex mt-4 modal-requests">
          <div className="modal-sections">
            <span className="header-text">{t("show_request_source")}</span>
            <span className="bold-text">
              {sourceKindergarten ? sourceKindergarten : "-"}
            </span>
          </div>
          <div className="modal-sections">
            <span className="header-text">{t("show_request_destination")}</span>
            <span className="bold-text">
              {targetKindergarten ? targetKindergarten : "-"}
            </span>
          </div>
          <div className="modal-sections">
            <span className="header-text"></span>
            <span className="bold-text"></span>
          </div>
        </div>
        <div className="mt-4">
          <p className="text-blue show-request">
            {t("show_request_main_info_for_destination")}
          </p>
        </div>
        <div className="d-flex mt-4 mb-4 modal-requests">
          <div className="modal-sections">
            <span className="header-text">
              {t("show_request_nr_free_spots")}
            </span>
            <span className="colored-field green m-auto d-flex justify-content-center">
              {openSpots ? openSpots : "-"}
            </span>
          </div>
          <div className="modal-sections">
            <span className="header-text">
              {t("show_request_occupied_spots")}
            </span>
            <span className="colored-field red m-auto d-flex justify-content-center">
              {takenSpots ? takenSpots : "-"}
            </span>
          </div>
          <div className="form-block mx-auto">
            <div className="modal-sections">
              <span className="header-text">
                {t("show_request_transfer_reason")}
              </span>
            </div>
            <textarea
              name="comments"
              cols={30}
              rows={6}
              className={"form-input"}
              defaultValue={transferReason ? transferReason : "-"}
              readOnly
            />
          </div>
        </div>
        {RoleCheck("9") || RoleCheck("3") ? (
          <span className="text-blue show-request">
            {t("show_application_committe_memebers_votes")}
          </span>
        ) : (
          ""
        )}
        {RoleCheck("9") ? (
          <div className="d-flex flex-wrap mt-4">
            {loading ? (
              <div>
                <div className="text-center">
                  <Loading />
                </div>
              </div>
            ) : (
              committeeMembers
                .filter(
                  (item) =>
                    item.first_name === userData.first_name &&
                    item.last_name === userData.last_name
                )
                .map((member) => (
                  <div
                    className="application-checkbox d-flex justify-content-start mb-3"
                    key={member.id}
                  >
                    <span className="popup_title my-auto me-3">
                      {(member.first_name ? member.first_name : "-") +
                        " " +
                        (member.last_name ? member.last_name : "-")}
                    </span>
                    <input
                      id="checkbox"
                      className="form-check-input me-5"
                      type="checkbox"
                      style={{ width: "35px", height: "35px" }}
                      defaultChecked={
                        member.checked === 1 || verifiedFolder === 1
                      }
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        const updatedValue = isChecked ? 1 : 0;
                        setVerifiedFolder(updatedValue);
                      }}
                    />
                    {validations.verified_folder && (
                      <ValidationMessage
                        message={validations.verified_folder[0]}
                      />
                    )}
                  </div>
                ))
            )}
          </div>
        ) : (
          ""
        )}

        {RoleCheck("3") && status === 1 ? (
          <div className="d-flex flex-wrap w-100 mt-4">
            {loading ? (
              <div>
                <div className="text-center">
                  <Loading />
                </div>
              </div>
            ) : (
              committeeMembers.map((member) => (
                <div
                  className="application-checkbox d-flex justify-content-end mb-1"
                  key={member.id}
                >
                  <span className="popup_title my-auto me-3">
                    {(member.first_name ? member.first_name : "-") +
                      " " +
                      (member.last_name ? member.last_name : "-")}
                  </span>
                  <input
                    id="checkbox"
                    className="form-check-input me-5"
                    type="checkbox"
                    style={{ width: "35px", height: "35px" }}
                    checked={member.checked}
                    readOnly
                  />
                  {validations.verified_folder && (
                    <ValidationMessage
                      message={validations.verified_folder[0]}
                    />
                  )}
                </div>
              ))
            )}
          </div>
        ) : (
          ""
        )}
      </ModalBody>
      {RoleCheck("3") && (
        <ModalFooter>
          <div className="d-flex flex-wrap align-items-baseline justify-content-end mt-2">
            {btnLoadingApprove ? (
              <div className="d-flex my-auto">
                <span className="d-flex popup_title my-2 me-3">
                  {t("approve_transfer_title")}:
                </span>
                <button type="button" className="button-green has-icon">
                  <i className="icon spinner-icon" />
                  {t("requests_approving")}
                </button>
              </div>
            ) : (
              <div className="d-flex my-auto">
                <span className="d-flex popup_title my-2 me-3">
                  {t("approve_transfer_title")}:
                </span>
                <button
                  type="submit"
                  className="button-green has-icon"
                  onClick={(e) => {
                    approveRequest();
                    e.stopPropagation();
                  }}
                >
                  <i className="icon tick-icon" />
                  {t("show_request_approve")}
                </button>
              </div>
            )}
          </div>
          <div className="border-top w-100 mt-2"></div>
          <div className="d-flex flex-wrap align-items-baseline justify-content-center mt-3">
            <div className="row">
              <div className="form-block col-12 d-flex flex-wrap justify-content-center">
                <label>{t("show_request_cancellation_reason")}</label>
                <textarea
                  name="comments"
                  cols={10}
                  rows={6}
                  className={
                    "form-input" +
                    (validations.rejected_reason ? " input-error" : "")
                  }
                  onChange={(e) => setRejectedReason(e.target.value)}
                  value={rejectedReason}
                  placeholder={t(
                    "show_request_cancellation_reason_placeholder"
                  )}
                />

                {validations.rejected_reason && (
                  <ValidationMessage message={validations.rejected_reason[0]} />
                )}
              </div>
              <div className="form-block col-12">
                <label className="required">{t("application_choose_institution")}</label>
                {type == "cerdhe-cerdhe" ? (
                  <Select
                    placeholder={t("first_step_select_kindergarten")}
                    className={
                      "form-react-select" +
                      (validations.kindergarten_id ? " input-error" : "")
                    }
                    classNamePrefix="select_input"
                    name="specification"
                    onChange={(e) =>
                      e ? setKindergartenId(e.value) : setKindergartenId("")
                    }
                    isClearable={true}
                    isSearchable={false}
                    options={kindergartens
                      .filter((item) => item.type === "cerdhe")
                      .map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                  />
                ) : (
                  <label>{t("show_request_your_child_no_kindergarten")}</label>
                )}
                {validations.kindergarten_id && (
                  <ValidationMessage message={validations.kindergarten_id[0]} />
                )}
              </div>
              <div className="d-flex justify-content-end">
                {btnLoadingApproveAnother ? (
                  <div className="d-flex my-auto">
                    <span className="d-flex popup_title my-2 me-3">
                      {t("show_request_approve_another_nursery")}
                    </span>
                    <button
                      type="button"
                      className="button-green has-icon mb-1"
                    >
                      <i className="icon spinner-icon" />
                      {t("show_application_approving")}
                    </button>
                  </div>
                ) : (
                  <div className="d-flex my-auto">
                    <span className="d-flex popup_title my-2 me-3">
                      {t("show_request_approve_another_nursery")}
                    </span>
                    <button
                      type="submit"
                      className="button-green has-icon mb-1"
                      onClick={(e) => {
                        approveRequestAnotherKindergarten();
                        e.stopPropagation();
                      }}
                    >
                      <i className="icon tick-icon" />
                      {t("show_request_approve")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="border-top w-100 mt-2"></div>
          <div className="form-block col-12 d-flex flex-wrap justify-content-center mt-2">
            <label>{t("show_request_cancellation_reason")}</label>
            <textarea
              name="comments"
              cols={20}
              rows={6}
              className={
                "form-input" +
                (validations.rejected_reason ? " input-error" : "")
              }
              onChange={(e) => setRejectedReasonRefuse(e.target.value)}
              value={rejectedReasonRefuse}
              placeholder={t("show_request_cancellation_reason_placeholder")}
            />
            {validations.rejected_reason && (
              <ValidationMessage message={validations.rejected_reason[0]} />
            )}
          </div>
          <div className=" d-flex flex-wrap justify-content-end">
            {btnLoadingReject ? (
              <div className="d-flex my-auto">
                <span className="d-flex popup_title my-2 me-3">
                  {t("reject_transfer_title")}:
                </span>
                <button type="button" className="button-red has-icon">
                  <i className="icon spinner-icon" />
                  {t("show_request_canceling")}
                </button>
              </div>
            ) : (
              <div className="d-flex my-auto">
                <span className="d-flex popup_title my-2 me-3">
                  {t("reject_transfer_title")}:
                </span>
                <button
                  type="submit"
                  className="button-red has-icon"
                  onClick={(e) => {
                    rejectRequestt();
                    e.stopPropagation();
                  }}
                >
                  <i className="icon close-icon" />
                  {t("show_request_cancel")}
                </button>
              </div>
            )}
          </div>
          <div className="border-top w-100 mt-2"></div>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("show_request_close")}
          </button>
        </ModalFooter>
      )}
      {RoleCheck("9") && (
        <ModalFooter>
          {btnLoadingApproveCommittee ? (
            <button type="button" className="button-green has-icon">
              <i className="icon spinner-icon" />
              {t("requests_approving")}
            </button>
          ) : (
            <button
              type="submit"
              className="button-green has-icon"
              onClick={(e) => {
                approvedCommittee();
                e.stopPropagation();
              }}
            >
              <i className="icon tick-icon" />
              {t("show_request_approve")}
            </button>
          )}
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("show_request_close")}
          </button>
        </ModalFooter>
      )}
      {!RoleCheck("3") && !RoleCheck("9") && (
        <ModalFooter>
          <div className="d-flex flex-wrap align-items-baseline justify-content-center mx-auto mt-2">
            <button
              className="button-white"
              type="button"
              onClick={() => onClose()}
            >
              {t("show_request_close")}
            </button>
          </div>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default ShowRequest;
