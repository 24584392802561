import React, { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import i18n from "src/i18n/i18n";
import moment from "moment";
import "moment/locale/sq";
import "moment/locale/fr";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

interface ShowBillProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  invoiceId: number;
}

interface InvoiceDetails {
  loading: boolean;
  title: string;
  billNumber: string;
  billDate: string;
  month: string;
  nurseryKindergarten: string;
  type: string;
  guardianName: string;
  childName: string;
  email: string;
  phone: string;
  institutionName: string;
  institutionBranch: string;
  branchCode: string;
  obligations: string;
  presence: number;
  absence: number;
  kindergartenPrice: number;
  nurseryPrice: number;
}

const initialState: InvoiceDetails = {
  loading: true,
  title: "",
  billNumber: "",
  billDate: "",
  month: "",
  nurseryKindergarten: "",
  type: "",
  guardianName: "",
  childName: "",
  email: "",
  phone: "",
  institutionName: "",
  institutionBranch: "",
  branchCode: "",
  obligations: "",
  presence: 0,
  absence: 0,
  kindergartenPrice: 0,
  nurseryPrice: 0,
};

const reducer = (
  state: InvoiceDetails,
  action: Partial<InvoiceDetails>
): InvoiceDetails => ({
  ...state,
  ...action,
});

const ShowBill: React.FC<ShowBillProps> = ({
  onShowModal,
  setShowModal,
  invoiceId,
}) => {
  const { t } = useTranslation();
  const [invoiceDetails, dispatch] = useReducer(reducer, initialState);

  const fetchInvoiceDetails = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.childrenInvoiceDetails(invoiceId),
        "GET",
        API_HEADERS.authenticated
      );
      const { institution_data, invoice, price_per_day } = response;

      dispatch({
        loading: false,
        title: invoice.invoice_number,
        billNumber: invoice.invoice_number,
        billDate: moment()
          .locale(i18n.language === "al" ? "sq" : "en")
          .month(invoice.created_at)
          .format("DD MMMM, yy"),
        nurseryKindergarten: invoice.kindergarten.name,
        type: invoice.kindergarten.type,
        guardianName: `${invoice.guardian.first_name} ${invoice.guardian.last_name}`,
        childName: `${invoice.child.first_name} ${invoice.child.last_name}`,
        email: invoice.guardian.email,
        phone: invoice.guardian.phone,
        institutionName: institution_data.name,
        institutionBranch: institution_data.code,
        branchCode: institution_data.branch_code,
        month:
          moment()
            .locale(i18n.language === "al" ? "sq" : "en")
            .month(invoice.month - 1)
            .format("MMMM") + `, ${invoice.year}`,
        obligations: invoice.obligations,
        presence: invoice.presence,
        absence: invoice.absence,
        kindergartenPrice: price_per_day.kindergarten,
        nurseryPrice: price_per_day.nursery,
      });
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      onClose();
    }
  };

  useEffect(() => {
    fetchInvoiceDetails();
  }, []);

  const onClose = () => {
    setShowModal(false);
  };

  return (
    <Modal
      isOpen={onShowModal && !invoiceDetails.loading}
      toggle={() => onClose()}
      size="lg"
      centered
    >
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("child_billing_show_title") + invoiceDetails.title}
      </ModalHeader>
      <ModalBody>
        <div className="modal-info-content">
          <div className="modal-info-block">
            <span className="title">
              {t("child_billing_show_bill_details")}:
            </span>
            <div className="row">
              <div className="col-12 col-sm-6">
                <span className="label">
                  {t("child_billing_show_bill_number")}:
                </span>
                <span className="content">{invoiceDetails.billNumber}</span>
              </div>
              <div className="col-12 col-sm-6">
                <span className="label">
                  {t("child_billing_show_bill_date")}:
                </span>
                <span className="content">{invoiceDetails.billDate}</span>
              </div>
              <div className="col-12 col-sm-6">
                <span className="label">
                  {t("child_billing_show_bill_month")}:
                </span>
                <span className="content">{invoiceDetails.month}</span>
              </div>
              <div className="col-12 col-sm-6">
                <span className="label">
                  {t("child_billing_show_bill_nursery_kindergarten")}:
                </span>
                <span className="content">
                  {invoiceDetails.nurseryKindergarten}
                </span>
              </div>
            </div>
          </div>
          <div className="modal-info-block">
            <span className="title">{t("child_billing_show_billed_to")}:</span>
            <div className="row">
              <div className="col-12 col-sm-6">
                <span className="label">
                  {t("child_billing_show_guardian_name")}:
                </span>
                <span className="content">{invoiceDetails.guardianName}</span>
              </div>
              <div className="col-12 col-sm-6">
                <span className="label">{t("child_billing_show_email")}:</span>
                <span className="content">{invoiceDetails.email}</span>
              </div>
              <div className="col-12 col-sm-6">
                <span className="label">
                  {t("child_billing_show_child_name")}:
                </span>
                <span className="content">{invoiceDetails.childName}</span>
              </div>
              <div className="col-12 col-sm-6">
                <span className="label">{t("child_billing_show_phone")}:</span>
                <span className="content">{invoiceDetails.phone}</span>
              </div>
            </div>
          </div>
          <div className="modal-info-block">
            <span className="title">
              {t("child_billing_show_institutional_data")}:
            </span>
            <div className="row">
              <div className="col-12 col-sm-6">
                <span className="label">
                  {t("child_billing_show_name_of_the_institution")}:
                </span>
                <span className="content">
                  {invoiceDetails.institutionName}
                </span>
              </div>
              <div className="col-12 col-sm-6">
                <span className="label">
                  {t("child_billing_show_branch_code")}:
                </span>
                <span className="content">{invoiceDetails.branchCode}</span>
              </div>
              <div className="col-12 col-sm-6">
                <span className="label">
                  {t("child_billing_show_institution_code")}:
                </span>
                <span className="content">
                  {invoiceDetails.institutionBranch}
                </span>
              </div>
            </div>
          </div>
          <div className="row invoice">
            <div className="table-container">
              <div className="row header">
                <div className="cell">
                  {t("child_billing_show_description")}
                </div>
                <div className="cell text-center">
                  {t("child_billing_show_number_of_days")}
                </div>
                <div className="cell text-end">
                  {t("child_billing_show_payment_amount")}
                </div>
              </div>
              <div className="row">
                <div className="cell description">
                  {t("child_billing_show_unpaid_obligation")}
                </div>
                <div className="cell number text-center">
                  {invoiceDetails.obligations}
                </div>
                <div className="cell amount text-end">
                  0 {t("child_billing_show_all_currency")}
                </div>
              </div>
              <div className="row">
                <div className="cell description">
                  {t("child_billing_show_days_attended")}
                </div>
                <div className="cell number text-center">
                  {invoiceDetails.presence}
                </div>
                <div className="cell amount text-end">
                  {invoiceDetails.type === "kopesht"
                    ? invoiceDetails.presence * invoiceDetails.kindergartenPrice
                    : invoiceDetails.presence *
                      invoiceDetails.nurseryPrice}{" "}
                  {t("child_billing_show_all_currency")}
                </div>
              </div>
              <div className="row">
                <div className="cell description">
                  {t("child_billing_show_absences")}
                </div>
                <div className="cell number text-center">
                  {invoiceDetails.absence}
                </div>
                <div className="cell amount text-end">
                  {/* {invoiceDetails.type === "kopesht"
                    ? invoiceDetails.absence * invoiceDetails.kindergartenPrice
                    : invoiceDetails.absence * invoiceDetails.nurseryPrice}{" "}
                  {t("child_billing_show_all_currency")} */}
                </div>
              </div>
              <div className="row total">
                <div className="cell description">
                  {t("child_billing_show_total_amount")}
                </div>
                <div className="cell number text-center"></div>
                <div className="cell amount text-end">
                  {invoiceDetails.type === "kopesht"
                    ? invoiceDetails.presence * invoiceDetails.kindergartenPrice
                    : invoiceDetails.presence *
                      invoiceDetails.nurseryPrice}{" "}
                  {t("child_billing_show_all_currency")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className="button-white"
          type="button"
          onClick={() => onClose()}
        >
          {t("child_billing_show_close")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ShowBill;
