import { useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useParams } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import ValidationMessage from "src/helpers/ValidationMessage";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

interface AddNewProductProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  setModified: (visible: boolean) => void;
}

const AddNewProduct: React.FC<AddNewProductProps> = ({
  onShowModal,
  setShowModal,
  setModified,
}) => {
  const { t } = useTranslation();
  const { Id } = useParams();

  const [name, setName] = useState<string>("");

  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const onClose = () => {
    setShowModal(false);
  };

  const addNewProduct = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setLoadingButton(true);

    const formData = {
      name: name,
      allergic_group_id: Id,
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.allergicProductStore,
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);

      setModified(true);

      onClose();
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        onClose();
      }
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <Modal isOpen={onShowModal} toggle={() => onClose()} size="md" centered>
      <ModalHeader
        className="text-dark blue font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        <i className="icon allergy-icon" />
        {t("meal_allergies_add_new_product_title")}
      </ModalHeader>
      <form onSubmit={(e) => addNewProduct(e)}>
        <ModalBody>
          <div className="modal-note">
            {t("meal_allergies_add_new_product_desc")}
          </div>
          <div className="form-block">
            <label className="required">
              {t("meal_allergies_add_new_product_label")}
            </label>
            <input
              type="text"
              name="name"
              placeholder={t("meal_allergies_add_new_product_placeholder")}
              value={name}
              className={
                "form-input" + (validations.name ? " input-error" : "")
              }
              autoComplete="off"
              onChange={(e) => setName(e.target.value)}
            />
            {validations.name && (
              <ValidationMessage message={validations.name[0]} />
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("meal_allergies_add_new_product_cancel_button")}
          </button>

          {loadingButton ? (
            <button type="button" className="button-blue has-icon">
              <i className="spinner-icon" />
              {t("meal_allergies_add_new_product_adding_button")}
            </button>
          ) : (
            <button type="submit" className="button-blue has-icon">
              <i className="icon add-icon" />
              {t("meal_allergies_add_new_product_add_button")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddNewProduct;
