import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import { LanguageDate } from "src/helpers/CheckLanguageDate";
import CustomPagination from "src/helpers/CustomPaginate";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import SearchFilter from "src/helpers/SearchFilter";
import ListResponse from "src/helpers/ListResponse";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import Select, { SingleValue } from "react-select";
import moment from "moment";
import Loading from "src/helpers/Loading";
import DatePicker from "react-datepicker";
import useSearchSubmit from "src/hooks/useSearchSubmit";

const Logs: React.FC = () => {
  const { t } = useTranslation("");
  const navigate = useNavigate();

  // Logs
  const [logs, setLogs] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);

  // Search
  const {
    searchInputs,
    searchBtnLoading,
    setSearchBtnLoading,
    handleSubmit,
    setPage,
  } = useSearchSubmit();
  const [causerName, setCauserName] = useState<string>("");
  const [objectId, setObjectId] = useState<string>("");
  const [action, setAction] = useState<SingleValue<{ value: string; label: string }>>(null);
  const [object, setObject] = useState<SingleValue<{ value: string; label: string }>>(null);
  const [description, setDescription] = useState("");
  const [from, setFrom] = useState<Date | null>(null);
  const [to, setTo] = useState<Date | null>(null);
  const [logObjects, setLogObjects] = useState<any>([]);



  const getLogs = async (): Promise<void> => {
    const searchParams: any = searchInputs;
    let request: any = API_PATHS.logsList;

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      setLogs(response.activity_logs);
      setLoading(false);
      logObjects.length === 0 && getLogsObject();
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    } finally {
      setSearchBtnLoading(false);
    }
  };

  useEffect(() => {
    getLogs();
  }, [searchInputs]);

  const getLogsObject = async (): Promise<void> => {
    let request: any = API_PATHS.activityLogObjects;
    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setLogObjects(response.objects);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  return (
    <>
      <div className="input-items">
        <form onSubmit={(e) =>
          handleSubmit(e, {
            ...(causerName && { causer_name: causerName }),
            ...(objectId && { object_id: objectId }),
            ...(action && { action: action.value }),
            ...(object && { object: object.value }),
            ...(description && { description: description }),
            ...(from && { from_date: moment(from).format("yyyy-MM-DD") }),
            ...(to && { to_date: moment(to).format("yyyy-MM-DD") }),
          })
        } className="row mb-4">
          <div className="form-block col-12 col-sm-6 col-md-3">
            <label>{t("logs_search_by_subject")}</label>
            <input
              placeholder={t("logs_search")}
              className="form-input"
              name="text"
              type="text"
              value={causerName}
              onChange={(e) => setCauserName(e.target.value)}
            />
          </div>
          <div className="form-block col-12 col-sm-6 col-md-3">
            <label>{t("logs_choose_action")}</label>
            <Select
              placeholder={t("logs_choose")}
              className={"form-react-select"}
              classNamePrefix="select_input"
              name="specification"
              onChange={(e) => setAction(e)}
              isClearable={true}
              isSearchable={false}
              value={action}
              options={[
                { value: "created", label: t("created") },
                { value: "deleted", label: t("deleted") },
                { value: "updated", label: t("updated") },
                { value: "uploaded", label: t("uploaded") },
                { value: "approved", label: t("approved") },
                { value: "rejected", label: t("rejected") },
                { value: "transfered", label: t("transfered") },
                { value: "cancelled", label: t("cancelled") },
                { value: "verified", label: t("verified") },
                { value: "registered", label: t("registered") },
                { value: "downloaded", label: t("downloaded") },
                { value: "exported", label: t("exported") },
              ]}
            />
          </div>
          <div className="form-block col-12 col-sm-6 col-md-3">
            <label>{t("logs_choose_object")}</label>
            <Select
              placeholder={t("logs_choose")}
              className="form-react-select"
              classNamePrefix="select_input"
              name="specification"
              onChange={(e) => setObject(e)}
              isClearable={true}
              isSearchable={false}
              value={object}
              options={logObjects.map((item: any) => ({
                value: item,
                label: item,
              }))}
            />
          </div>
          <div className="form-block col-12 col-sm-6 col-md-3">
            <label>{t("logs_search_by_value")}</label>
            <input
              placeholder={t("logs_search")}
              className="form-input"
              name="text"
              type="text"
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="form-block col-12 col-sm-6 col-md-3">
            <label>{t("logs_search_by_object_id")}</label>
            <input
              placeholder={t("logs_search")}
              className="form-input"
              name="text"
              type="text"
              onChange={(e) => setObjectId(e.target.value)}
            />
          </div>
          <div className="form-block col-12 col-sm-6 col-md-3">
            <label>{t("logs_choose_date_from")}</label>
            <DatePicker
              placeholderText={t("logs_choose_date_from")}
              className="form-datepicker"
              name="from_date"
              showMonthDropdown
              showYearDropdown
              isClearable
              dateFormat="dd-MM-yyyy"
              selected={from}
              onChange={(e) => setFrom(e)}
              locale={LanguageDate.datepicker}
              autoComplete="off"
            />
          </div>
          <div className="form-block col-12 col-sm-6 col-md-3">
            <label>{t("logs_choose_date_to")}</label>
            <DatePicker
              placeholderText={t("logs_choose_date_to")}
              name="to_date"
              className="form-datepicker"
              showMonthDropdown
              showYearDropdown
              isClearable
              locale={LanguageDate.datepicker}
              dateFormat="dd-MM-yyyy"
              selected={to}
              onChange={(e) => setTo(e)}
              autoComplete="off"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-3 mb-1 mt-4">
            {searchBtnLoading ? (
              <button type="button" className="button-orange has-icon">
                <i className="icon spinner-icon" />
                {t("logs_searching")}
              </button>
            ) : (
              <button type="submit" className="button-orange has-icon">
                <i className="icon search-icon" />
                {t("logs_search")}
              </button>
            )}
          </div>
        </form>
      </div>
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                style={{ width: "16%" }}
                role="columnheader"
                className="text-left"
              >
                {t("logs_subject")}
              </th>
              <th
                style={{ width: "16%" }}
                role="columnheader"
                className="text-center"
              >
                {t("logs_action")}
              </th>
              <th
                style={{ width: "16%" }}
                role="columnheader"
                className="text-center"
              >
                {t("logs_object")}
              </th>
              <th
                style={{ width: "16%" }}
                role="columnheader"
                className="text-center"
              >
                {t("logs_value")}
              </th>
              <th
                style={{ width: "16%" }}
                role="columnheader"
                className="text-center"
              >
                {t("logs_object_id")}
              </th>
              <th
                style={{ width: "16%" }}
                role="columnheader"
                className="text-center"
              >
                {t("logs_date")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={6}>
                  <Loading />
                </td>
              </tr>
            ) : (
              logs.data.map((log: any) => (
                <tr key={log.id}>
                  <td
                    role="cell"
                    className="text-left"
                    data-label={t("logs_subject")}
                  >
                    {(log.causer.first_name ? log.causer.first_name : "-") +
                      " " +
                      (log.causer.last_name ? log.causer.last_name : "-")}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("logs_action")}
                  >
                    {log.event ? log.event : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("logs_object")}
                  >
                    {log.object ? log.object : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("logs_value")}
                  >
                    {log.description ? log.description : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("logs_object_id")}
                  >
                    {log.object_id ? log.object_id : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("logs_date")}
                  >
                    {log.created_at
                      ? moment(log.created_at)
                        .locale(LanguageDate.moment)
                        .format("DD MMMM, yyyy")
                      : "-"}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse totalItems={logs?.data?.length} loading={loading} />
      </div>
      <CustomPagination data={logs} setActivePage={(e) => setPage(e)} />
    </>
  );
};

export default Logs;
