import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeApiCall } from "src/api/apiRequests";
import { useNavigate } from "react-router-dom";
import { LanguageDate } from "src/helpers/CheckLanguageDate";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import SearchFilter from "src/helpers/SearchFilter";
import ListResponse from "src/helpers/ListResponse";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import CustomPagination from "src/helpers/CustomPaginate";
import moment from "moment";
import Loading from "src/helpers/Loading";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";

const Backup: React.FC = () => {
  const { t } = useTranslation("");
  const navigate = useNavigate();

  // Backups list
  const [backups, setBackups] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState<number>(1);

  // Generate backup
  const [btnLoading, setBtnLoading] = useState(false);

  const getBackups = async (): Promise<void> => {
    const searchParams: any = {};
    let request: any = API_PATHS.backupList;

    if (activePage > 1) {
      searchParams["page"] = activePage;
    }

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setBackups(response.backups);
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  useEffect(() => {
    getBackups();
  }, [activePage]);

  const generateBackups = async () => {
    setBtnLoading(true);

    let request: any = API_PATHS.backupList + "?generate_backup_now=true";

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      FLASH_MESSAGES.successMsg(response.success);
      getBackups();
      setBtnLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  const downloadBackup = async (backupId: number) => {
    let request: any = API_PATHS.backupList + "?backup_id=" + backupId;

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.blob,
        {},
        "blob"
      );

      const blob = new Blob([response], { type: "text/sql" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `backup_${backupId}.sql`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error: any) {
    } finally {
    }
  };

  const deleteBackups = (backupId: number, backupTitle: string) => {
    ConfirmPopup.deletePopup(
      t("delete_backup_title"),
      t("delete_backup_type"),
      backupTitle
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.deleteBackup(backupId),
            "DELETE",
            API_HEADERS.authenticated
          );
          getBackups();
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  return (
    <>
      <div className="mb-5 d-flex justify-content-end">
        {btnLoading ? (
          <button className="button-blue has-icon" type="button">
            <i className="icon spinner-icon" />
            {t("backup_generating")}
          </button>
        ) : (
          <button
            className="button-blue has-icon"
            type="submit"
            onClick={() => generateBackups()}
          >
            <i className="icon add-icon" />
            {t("backup_generate")}
          </button>
        )}
      </div>
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                style={{ width: "16%" }}
                role="columnheader"
                className="text-left"
              >
                {t("backup_name")}
              </th>
              <th
                style={{ width: "19%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon calender-icon" />
                {t("backup_date")}
              </th>
              <th
                style={{ width: "19%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon actions-icon" />
                {t("backup_actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={3}>
                  <Loading />
                </td>
              </tr>
            ) : (
              backups?.data.map((backup: any) => (
                <tr key={backup.id}>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("backup_name")}
                  >
                    {backup.name ? backup.name : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("backup_date")}
                  >
                    {backup.created_at
                      ? moment(backup.created_at)
                          .locale(LanguageDate.moment)
                          .format("DD MMMM, yyyy")
                      : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("backup_actions")}
                  >
                    <div className="actions">
                      <button
                        type="button"
                        className="button-unstyled-icon"
                        onClick={() => downloadBackup(backup.id)}
                      >
                        <i className="icon download-icon" />
                      </button>
                      <button
                        type="button"
                        className="button-unstyled-icon"
                        key={backup.id}
                        onClick={(e) => {
                          deleteBackups(backup.id, backup.name);
                        }}
                      >
                        <i className="icon delete-icon" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse totalItems={backups?.data?.length} loading={loading} />
      </div>
      <CustomPagination
        data={backups}
        setActivePage={(e) => setActivePage(e)}
      />
    </>
  );
};

export default Backup;
