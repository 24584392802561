import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { forwardRef } from "react";
import { LanguageDate } from "src/helpers/CheckLanguageDate";

interface CalendarHeaderProps {
  setNavigate: (direction: "prev" | "next") => void;
  setDate: (date: Date) => void;
  date: Date;
}

interface DatePickerInputProps {
  value?: string;
  onClick?: () => void;
}

const DatePickerButton = forwardRef<HTMLButtonElement, DatePickerInputProps>(
  ({ value, onClick }, ref) => (
    <button
      className="datepicker-button button-unstyled"
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  )
);

const CalendarHeader: React.FC<CalendarHeaderProps> = ({
  setNavigate,
  setDate,
  date,
}) => {
  const { t } = useTranslation();

  const handleDate = (date: Date) => {
    if (date !== null) {
      setDate(date);
    } else {
      setDate(new Date());
    }
  };

  return (
    <>
      <div className="fullcalendar-header">
        <div className="header-navigate">
          <div className="toolbar">
            <div className="form-block">
              <DatePicker
                selected={date}
                onChange={handleDate}
                customInput={<DatePickerButton />}
                showMonthYearPicker
                dateFormat="MMM/yyyy"
                placeholderText={t("child_participation_date_placeholder")}
                className="form-datepicker"
                autoComplete="off"
                name="month_year"
                maxDate={new Date()}
                locale={LanguageDate.datepicker}
                required={true}
              />
            </div>
            <div className="navigate-buttons">
              <button
                className="button-transparent has-icon only"
                onClick={() => setNavigate("prev")}
              >
                <i className="icon left-arrow-icon" />
              </button>
              {date.getMonth() !== new Date().getMonth() && (
                <button
                  className="button-transparent has-icon only"
                  onClick={() => setNavigate("next")}
                >
                  <i className="icon right-arrow-icon" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CalendarHeader;
