import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Sidebar from "src/components/layouts/sidebar/Sidebar";
import Breadcrumb from "src/components/layouts/Common/Breadcrumb";
import FirstStepKindergarten from "src/components/panel/applications_kindergarten/application_first_step/FirstStep";
import * as AppConfig from "../../../../helpers/AppConfig";

const MakeApplicationsFirstStepKindergartenView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("applications_list_view")}</title>
      </Helmet>
      <Sidebar>
        <Breadcrumb title={t("applications_title")} />
        
        <FirstStepKindergarten />
      </Sidebar>
    </>
  );
};

export default MakeApplicationsFirstStepKindergartenView;
