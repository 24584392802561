import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface ShowCategoryCategoryProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  title: string;
  documents: Array<string>;
}

const ShowCategory: React.FC<ShowCategoryCategoryProps> = ({
  onShowModal,
  setShowModal,
  title,
  documents,
}) => {
  const { t } = useTranslation();

  const onClose = () => {
    setShowModal(false);
  };

  const cerdheItems = documents.filter((item: any) => item.type === "cerdhe");
  const kopeshtItems = documents.filter((item: any) => item.type === "kopesht");

  return (
    <Modal isOpen={onShowModal} toggle={() => onClose()} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15 small-text"
        toggle={() => onClose()}
        tag="span"
      >
        {title}
      </ModalHeader>
      <ModalBody>
        <div className="modal-info">
          {cerdheItems.length > 0 ? (
            <>
              <div className="modal-bold-text mb-1">
                {t("childrens_categories_view_nursery")}
              </div>
              <ul>
                {cerdheItems.map((document: any) => (
                  <li key={document.id}>{document.title}</li>
                ))}
              </ul>
              <br />
            </>
          ) : (
            ""
          )}

          {kopeshtItems.length > 0 ? (
            <>
              <div className="modal-bold-text mb-1">
                {t("childrens_categories_view_kindergarten")}
              </div>
              <ul>
                {kopeshtItems.map((document: any) => (
                  <li key={document.id}>{document.title}</li>
                ))}
              </ul>
            </>
          ) : (
            ""
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className="button-white"
          type="button"
          onClick={() => onClose()}
        >
          {t("childrens_categories_view_cancel_button")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ShowCategory;
