import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import L from "leaflet";
import { icon } from "leaflet";
import "leaflet/dist/leaflet.css";

import { Navigate, useNavigate, useParams } from "react-router";
import * as AppConfig from "../../../../helpers/AppConfig";
import { makeApiCall, makeApiCallDownload } from "src/api/apiRequests";
import { RoleCheck } from "src/helpers/RoleCheck";
import API_PATHS from "src/api/apiPaths";
import SearchFilter from "src/helpers/SearchFilter";
import API_HEADERS from "src/api/apiConfig";
import { useUserdata } from "src/store/UserData";
import ValidationMessage from "src/helpers/ValidationMessage";

import CustomPagination from "src/helpers/CustomPaginate";
import ListResponse from "src/helpers/ListResponse";
import Loading from "src/helpers/Loading";
import PATHS from "src/routes/Paths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import moment from "moment";
import { LanguageDate } from "src/helpers/CheckLanguageDate";

const ShowApplication: React.FC = () => {
  const { t } = useTranslation("");
  const navigate = useNavigate();
  const param = useParams();
  const userData = useUserdata((state: any) => state.userData);

  const [applicationDate, setApplicationDate] = useState({});
  const [childAddress, setChildAddress] = useState<string>("");
  const [kindergartenAddress, setKindergartenAddress] = useState<string>("");
  const [selectedKindergarten, setSelectedKindergarten] = useState<string>("");
  const [kindergartenNumber, setKindergartenNumber] = useState<number>(0);
  const [latitude, setLatitude] = useState<number | string>(0);
  const [longitude, setLongitude] = useState<number | string>(0);
  const [verifiedFolder, setVerifiedFolder] = useState<number>(0);
  const [type, setType] = useState("");
  const [cancellationReason, setCancellationReason] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [kindergartenId, setKindergartenId] = useState("");
  const [committeeMembers, setCommitteeMembers] = useState<
    Record<string, any>[]
  >([]);

  const [childrenCategories, setChildrenCategories] = useState("");
  const [childrenCategoriesDocuments, setChildrenCategoriesDocuments] =
    useState<Record<string, any>[]>([]);
  const [numberOfEmptySpots, setNumberOfEmptySpots] = useState("");
  const [totalActualTakenSpots, setTotalActualTakenSpots] = useState("");
  const [numberOfStaff, setNumberOfStaff] = useState("");
  const [childName, setChildName] = useState<string>("");
  const [childLastName, setChildLastName] = useState<string>("");
  const [parentName, setParentName] = useState<string>("");
  const [parentLastName, setParentLastName] = useState<string>("");
  const [status, setStatus] = useState<number>(0);
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [applicationDocuments, setApplicationDocuments] = useState<
    Record<string, any>[]
  >([]);
  const [warranty, setWarranty] = useState<any>({});
  const [kindergartens, setKindergartens] = useState<Record<string, any>[]>([]);

  const [btnLoadingApproveCommittee, setBtnLoadingApproveCommittee] =
    useState<boolean>(false);
  const [modified, setModified] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [btnLoadingApprove, setBtnLoadingApprove] = useState(false);
  const [btnLoadingApproveAnother, setBtnLoadingApproveAnother] =
    useState(false);
  const [btnLoadingReject, setBtnLoadingReject] = useState(false);
  const [btnLoadingConfirm, setBtnLoadingConfirm] = useState(false);
  console.log("Before", verifiedFolder);

  const [validations, setValidations] = useState<Record<string, string>>({});

  const sortingOptions = [
    { value: "newest", label: "Sort By: Newest First" },
    { value: "oldest", label: "Sort By: Oldest First" },
    { value: "popular", label: "Sort By: Popular First" },
  ];

  const [selectedOption, setSelectedOption] = useState(sortingOptions[0]);

  const handleOptionChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
  };

  const showApplication = async (): Promise<void> => {
    const applicationId = param.id;

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.showApplication(Number(applicationId)),
        "GET",
        API_HEADERS.authenticated
      );
      setLoading(false);

      setApplicationDate(response.application.created_at);
      setChildAddress(response.application.child.guardian.address);
      setKindergartenAddress(response.application.kindergarten.address);
      setSelectedKindergarten(
        response.application.kindergarten.name
          ? response.application.kindergarten.name
          : ""
      );
      setKindergartenNumber(response.application.kindergarten.number);

      const latitudeValue =
        Number(response.application.kindergarten.latitude) || 0;
      const longitudeValue =
        Number(response.application.kindergarten.longtitude) || 0;
      setLatitude(latitudeValue);
      setLongitude(longitudeValue);

      setChildName(response.application.child.first_name);
      setChildLastName(response.application.child.last_name);
      setParentName(response.application.child.guardian.first_name);
      setParentLastName(response.application.child.guardian.last_name);

      setMobile(response.application.child.guardian.phone);
      setStatus(response.application.status_id);
      setEmail(response.application.child.guardian.email);

      setChildrenCategories(response.application.children_category.title);
      setChildrenCategoriesDocuments(
        response.application.children_category.children_category_documents
      );
      setApplicationDocuments(response.application.application_documents);
      setWarranty(response.application.warranty_payment);
      setNumberOfEmptySpots(response.nr_of_free_places_in_the_kindergarten);
      setTotalActualTakenSpots(response.nr_of_children_in_the_kindergarten);
      setNumberOfStaff(response.nr_of_staff_in_kindergarten);
      setType(response.application.kindergarten.type);
      {
        (RoleCheck("3") || RoleCheck("9")) && verifiedMembersCommittee();
      }
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  useEffect(() => {
    showApplication();
  }, []);

  const approveApplication = async (): Promise<void> => {
    const applicationId = param.id;

    try {
      setBtnLoadingApprove(true);

      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.approveApplication(Number(applicationId)),
        "POST",
        API_HEADERS.authenticated
      );

      FLASH_MESSAGES.successMsg(response.success);
      if (type == "kopesht") {
        navigate(PATHS.registerApplicationsKindergarten);
      } else if (type == "cerdhe") {
        navigate(PATHS.registerApplicationsNursery);
      }

      setModified(true);
    } catch (error: any) {
      if (error.response && error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
      }
      setBtnLoadingApprove(false);
    }
  };

  const approveAnotherKindergartenApplication = async (): Promise<void> => {
    const applicationId = param.id;

    const formData = new FormData();
    formData.append("kindergarten_id", kindergartenId);
    formData.append("cancellation_reason", cancellationReason);

    try {
      setBtnLoadingApproveAnother(true);

      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.approveAnotherKindergartenApplication(Number(applicationId)),
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);
      if (type == "kopesht") {
        navigate(PATHS.registerApplicationsKindergarten);
      } else if (type == "cerdhe") {
        navigate(PATHS.registerApplicationsNursery);
      }

      setModified(true);
    } catch (error: any) {
      if (error.response && error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
      }
      setBtnLoadingApproveAnother(false);
    }
  };

  const rejectApplication = async (): Promise<void> => {
    const applicationId = param.id;
    var formData = {
      cancellation_reason: rejectReason,
    };
    try {
      setBtnLoadingReject(true);

      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.rejectApplication(Number(applicationId)),
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);
      if (type == "kopesht") {
        navigate(PATHS.registerApplicationsKindergarten);
      } else if (type == "cerdhe") {
        navigate(PATHS.registerApplicationsNursery);
      }
      setModified(true);
    } catch (error: any) {
      if (error.response && error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
      }
      setBtnLoadingReject(false);
    }
  };

  const confirmApplication = async (): Promise<void> => {
    const applicationId = param.id;

    try {
      setBtnLoadingConfirm(true);

      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.registerChild(Number(applicationId)),
        "POST",
        API_HEADERS.authenticated
      );

      FLASH_MESSAGES.successMsg(response.success);
      if (type == "kopesht") {
        navigate(PATHS.registerApplicationsKindergarten);
      } else if (type == "cerdhe") {
        navigate(PATHS.registerApplicationsNursery);
      }

      setModified(true);
    } catch (error: any) {
      if (error.response && error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
      }
      setBtnLoadingConfirm(false);
    }
  };

  const approvedCommittee = async (): Promise<void> => {
    const applicationId = param.id;
    setBtnLoadingApproveCommittee(true);
    var formData = {
      verified_folder: verifiedFolder,
    };
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.approvedByCommittee(Number(applicationId)),
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);
      if (type == "kopesht") {
        navigate(PATHS.registerApplicationsKindergarten);
      } else if (type == "cerdhe") {
        navigate(PATHS.registerApplicationsNursery);
      }
      setBtnLoadingApproveCommittee(false);

      setModified(true);
    } catch (error: any) {
      if (error.response && error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
      }
    }
  };

  const verifiedMembersCommittee = async (): Promise<void> => {
    const applicationId = param.id;
    var formData = {
      verified_folder: verifiedFolder,
    };
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.verifiedCommitteeMembers(Number(applicationId)),
        "POST",
        API_HEADERS.authenticated,
        formData
      );
      setCommitteeMembers(response.committee_members);
      kindergartens.length <= 0 && getKindergartens();
      setModified(true);
    } catch (error: any) {
      if (error.response && error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        navigate(-1);
      }
    }
  };

  const getKindergartens = async (): Promise<void> => {
    setModified(false);

    let request: any = API_PATHS.kindergartensDropdownList;
    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setValidations({});
      setKindergartens(response.kindergartens);
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  const downloadFile = async (documentId: number): Promise<void> => {
    try {
      const response = await makeApiCallDownload<Blob>(
        API_PATHS.downloadDocument(Number(documentId)),
        "GET",
        API_HEADERS.authenticated,
        undefined,
        "blob"
      );

      if (response) {
        const blob = response.data;

        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Document");
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  const downloadWarrantyFile = async (documentId: number): Promise<void> => {
    try {
      const response = await makeApiCallDownload<Blob>(
        API_PATHS.downloadWarrantyDocument(Number(documentId)),
        "GET",
        API_HEADERS.authenticated,
        undefined,
        "blob"
      );

      if (response) {
        const blob = response.data;

        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Document");
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  const openFileInBrowser = async (documentId: number): Promise<void> => {
    try {
      const response = await makeApiCallDownload<any>(
        API_PATHS.showDocument(Number(documentId)),
        "GET",
        API_HEADERS.authenticated
      );

      const contentType = response.headers["content-type"];
      const blob = new Blob([response.data], { type: contentType });
      const fileUrl = URL.createObjectURL(blob);

      window.open(fileUrl);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
    }
  };

  const openFileInBrowserWarranty = async (
    documentId: number
  ): Promise<void> => {
    try {
      const response = await makeApiCallDownload<any>(
        API_PATHS.showWarrantyDocument(Number(documentId)),
        "GET",
        API_HEADERS.authenticated
      );
      const contentType = response.headers["content-type"];
      const blob = new Blob([response.data], { type: contentType });
      const fileUrl = URL.createObjectURL(blob);

      window.open(fileUrl);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
    }
  };

  const markerColors = [
    "#74dde5",
    "#fac86b",
    "#678af7",
    "#6bdaae",
    "#ab56d4",
    "#fd8adc",
    "#70b0ff",
    "#9386f0",
    "#f1c448",
    "#6592f3",
    "#3d6de0",
    "#e3692c",
    "#ff9900",
    "#ff8c00",
    "#f29b30",
    "#ffdeb5",
    "#008000",
    "#ffc0cb",
    "#59bf80",
    "#cb3438",
  ];

  const locations = [{ latitude, longitude, kindergartenNumber }];

  const createMarker = (location: any) => {
    const randomColor =
      markerColors[Math.floor(Math.random() * markerColors.length)];

    const customIcon = icon({
      iconUrl: require("../../../../assets/images/marker-icon.png"),
      iconSize: [20, 20],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      tooltipAnchor: [16, -28],
      className: "custom-marker",
    });

    customIcon.options.className += " marker-color";

    const markerStyle = document.createElement("style");

    markerStyle.innerHTML = `
      .marker-color {
        background: ${randomColor};
      }
    `;
    document.head.appendChild(markerStyle);

    return (
      <Marker
        position={[
          location?.latitude ? location.latitude : 0,
          location?.longitude ? location.longitude : 0,
        ]}
        key={location.kindergartenNumber}
        icon={customIcon}
      ></Marker>
    );
  };

  return (
    <>
      <div className="white">
        <div>
          <p className="text-blue mb-4">
            {t("show_application_important_details")}
          </p>
        </div>
        <div className="show-nursery mb-5">
          <div className="info-section-application">
            <div className="mb-5 me-3">
              <div className="mb-5">
                <span className="show-report-description">
                  <i className="icon calender-icon" />
                  {t("show_application_date")}
                </span>
                <p className="show-application-description mx-1">
                  {applicationDate
                    ? moment(applicationDate)
                        .locale(LanguageDate.moment)
                        .format("DD MMMM yyyy")
                    : "-"}
                </p>
              </div>
              <div>
                <span className="show-report-description">
                  <i className="icon kindergarten-table-icon" />
                  {t("show_application_selected_nursery")}
                </span>
                <p className="show-application-description mx-1">
                  {selectedKindergarten ? selectedKindergarten : "-"}
                </p>
              </div>
            </div>
            <div className="mb-5">
              <div className="mb-5">
                <span className="show-report-description">
                  <i className="icon address-icon" />
                  {t("show_application_child_address")}
                </span>
                <p className="show-application-description mx-1">
                  {childAddress ? childAddress : "-"}
                </p>
              </div>
              <div>
                <span className="show-report-description">
                  <i className="icon kindergarten-table-icon" />
                  {t("show_application_nursery_address")}
                </span>
                <p className="show-application-description mx-1">
                  {kindergartenAddress ? kindergartenAddress : "-"}
                </p>
              </div>
            </div>
            <div></div>
            <div className="section-container d-flex mb-3">
              <div className="nursery-report">
                <div className="circle">
                  <i className="icon-before staff-number-icon" />
                </div>
                <div className="circle-info">
                  <span className="show-report-cards">
                    {t("show_kindergarten_staff_number")}
                  </span>
                  <span>{numberOfStaff ? numberOfStaff : "-"}</span>
                </div>
              </div>
              <div className="nursery-report">
                <div className="circle red">
                  <i className="icon-before kid-icon" />
                </div>
                <div className="circle-info">
                  <span className="show-report-cards">
                    {t("show_kindergarten_vacancies")}
                  </span>
                  <span>{numberOfEmptySpots ? numberOfEmptySpots : "-"}</span>
                </div>
              </div>
              <div className="nursery-report">
                <div className="circle blue">
                  <i className="icon-before kid-icon" />
                </div>
                <div className="circle-info">
                  <span className="show-report-cards">
                    {t("show_kindergarten_children_number")}
                  </span>
                  <span>
                    {totalActualTakenSpots ? totalActualTakenSpots : "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="show-map-application">
            <div id="map">
              <MapContainer
                center={[41.33, 19.82]}
                zoom={13}
                scrollWheelZoom={false}
                attributionControl={false}
                style={{ width: "auto", height: "250px" }}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                  url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
                />
                {locations.map((location) => createMarker(location))}
              </MapContainer>
            </div>
          </div>
        </div>
        <div>
          <p className="text-blue">{t("show_application_child_profile")}</p>
        </div>
        <div className="profile-info-application">
          <div className="profile-initials">
            <div className="avatar-violet size-xl">
              <span className="circle">
                {`${childName ? childName.charAt(0) : ""}${
                  childLastName ? childLastName.charAt(0) : ""
                }`}
              </span>
            </div>
          </div>
          <div className="profile-data">
            <div className="d-flex flex-direction-column-app">
              <div className="info">
                <div>
                  <span className="label">
                    <i className="icon children-name-icon" />
                    {t("show_application_child_name")}
                  </span>
                  <span className="text mx-1">
                    {(childName ? childName : "-") +
                      " " +
                      (childLastName ? childLastName : "-")}
                  </span>
                </div>
                <div>
                  <i className="icon person-icon" />
                  <span className="label">
                    {t("show_application_parent_name")}
                  </span>
                  <span className="text mx-1">
                    {(parentName ? parentName : "-") +
                      " " +
                      (parentLastName ? parentLastName : "-")}
                  </span>
                </div>
                <div>
                  <i className="icon telephone-icon" />

                  <span className="label">{t("show_application_phone")}</span>
                  <span className="text mx-1">{mobile ? mobile : "-"}</span>
                </div>
              </div>
              <div className="info">
                <div>
                  <i className="icon status-icon" />
                  <span className="label">{t("show_application_status")}</span>
                  <span className="text mx-1">
                    {status === 1 ? (
                      <span className="transfer-text">
                        <i className="icon dot-icon me-2 my-auto" />
                        {t("not_reviewed")}
                      </span>
                    ) : status === 2 && type === "cerdhe" ? (
                      <span className="transfer-text">
                        <i className="icon tick-icon my-0 me-2" />
                        {t("approved_in_the_same_nursery")}
                      </span>
                    ) : status === 2 && type === "kopesht" ? (
                      <span className="transfer-text">
                        <i className="icon tick-icon my-0 me-2" />
                        {t("approved_in_the_same_kindergarten")}
                      </span>
                    ) : status === 3 && type === "cerdhe" ? (
                      <span className="transfer-text">
                        <i className="icon tick-icon my-0 me-2" />
                        {t("approved_in_another_nursery")}
                      </span>
                    ) : status === 3 && type === "kopesht" ? (
                      <span className="transfer-text">
                        <i className="icon tick-icon my-0 me-2" />
                        {t("approved_in_another_kindergarten")}
                      </span>
                    ) : status === 4 ? (
                      <span className="transfer-text">
                        <i className="icon close-icon my-0 me-2" />
                        {t("rejected")}
                      </span>
                    ) : status === 5 ? (
                      <span className="transfer-text">
                        <i className="icon dot-icon my-0 me-2" />
                        {t("warrenty_uploaded")}
                      </span>
                    ) : (
                      <span className="transfer-text">
                        <i className="icon tick-icon my-0 me-2" />
                        {t("confirmed")}
                      </span>
                    )}
                  </span>
                </div>
                <div>
                  <i className="icon email-icon" />
                  <span className="label">{t("show_application_email")}</span>
                  <span className="email mx-1">{email ? email : "-"}</span>
                </div>
                <div className="mt-5"></div>
              </div>
              <div className="info">
                <div className="mb-0">
                  <i className="icon category-icon" />
                  <span className="label">
                    {t("show_application_category")}
                  </span>
                  <span className="text sub-category mb-2">
                    {childrenCategories ? childrenCategories : t("show_application_no_category_selected")}
                  </span>
                  <div className="border-bottom"></div>
                </div>
                {childrenCategories ? (
                  <div className="mt-3">
                    {childrenCategoriesDocuments.map((category, index) => (
                      <p className="text-categories">
                        <i className="icon dot-icon"/>
                        {category.title ? category.title : "-"}
                      </p>
                    ))}
                  </div>
                ) : (
                  <div className="mt-3">{" "}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        {applicationDocuments.length === 0 ? (
          ""
        ) : (
          <div className="mt-5">
            <p className="text-blue mb-4">
              {t("show_application_child_documents")}
            </p>
            <div className="table-list-documents mb-5">
              <table role="table" className="w-100">
                <thead>
                  <tr role="row">
                    <th
                      style={{ width: "25%" }}
                      role="columnheader"
                      className="text-left"
                    >
                      {t("show_application_type")}
                    </th>
                    <th
                      style={{ width: "25%" }}
                      role="columnheader"
                      className="text-center"
                    >
                      {t("show_application_file_name")}
                    </th>
                    <th
                      style={{ width: "25%" }}
                      role="columnheader"
                      className="text-center"
                    >
                      {t("show_application_uploaded")}
                    </th>
                    <th
                      style={{ width: "25%" }}
                      role="columnheader"
                      className="text-end"
                    >
                      {t("show_application_actions")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td className="text-center" colSpan={4}>
                        <Loading />
                      </td>
                    </tr>
                  ) : (
                    applicationDocuments.map((document, index) => (
                      <tr>
                        <td
                          role="cell"
                          className="text-left"
                          data-label={t("show_application_type")}
                        >
                          {document.title ? document.title : "-"}
                        </td>
                        <td
                          role="cell"
                          className="text-center"
                          data-label={t("show_application_file_name")}
                        >
                          {document.path ? document.path : "-"}
                        </td>
                        <td
                          role="cell"
                          className="text-center"
                          data-label={t("show_application_uploaded")}
                        >
                          {document.created_at
                            ? moment(document.created_at)
                                .locale(LanguageDate.moment)
                                .format("DD, MMMM yyyy")
                            : "-"}
                        </td>
                        <td
                          role="cell"
                          className="text-center"
                          data-label={t("show_application_actions")}
                        >
                          <div className="actions">
                            <button
                              type="button"
                              className="button-unstyled-icon"
                              onClick={() => openFileInBrowser(document.id)}
                            >
                              <i className="icon show-icon" />
                            </button>
                            <button
                              type="button"
                              className="button-unstyled-icon"
                              onClick={() => downloadFile(document.id)}
                            >
                              <i className="icon download-icon" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {warranty && (
          <div className="mt-5">
            <p className="text-blue mb-4">
              {t("show_application_warranty_document")}
            </p>
            <div className="table-list-documents mb-5">
              <table role="table" className="w-100">
                <thead>
                  <tr role="row">
                    <th
                      style={{ width: "25%" }}
                      role="columnheader"
                      className="text-left"
                    >
                      {t("show_application_type")}
                    </th>
                    <th
                      style={{ width: "25%" }}
                      role="columnheader"
                      className="text-center"
                    >
                      {t("show_application_file_name")}
                    </th>
                    <th
                      style={{ width: "25%" }}
                      role="columnheader"
                      className="text-center"
                    >
                      {t("show_application_uploaded")}
                    </th>
                    <th
                      style={{ width: "25%" }}
                      role="columnheader"
                      className="text-end"
                    >
                      {t("show_application_actions")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      role="cell"
                      className="text-left"
                      data-label={t("show_application_type")}
                    >
                      {t("show_application_warranty")}
                    </td>
                    <td
                      role="cell"
                      className="text-center"
                      data-label={t("show_application_file_name")}
                    >
                      {warranty.path ? warranty.path : "-"}
                    </td>
                    <td
                      role="cell"
                      className="text-center"
                      data-label={t("show_application_uploaded")}
                    >
                      {warranty.created_at
                        ? moment(warranty.created_at)
                            .locale(LanguageDate.moment)
                            .format("DD, MMMM yyyy")
                        : "-"}
                    </td>
                    <td
                      role="cell"
                      className="text-center"
                      data-label={t("show_application_actions")}
                    >
                      <div className="actions">
                        <button
                          type="button"
                          className="button-unstyled-icon"
                          onClick={() => openFileInBrowserWarranty(warranty.id)}
                        >
                          <i className="icon show-icon" />
                        </button>
                        <button
                          type="button"
                          className="button-unstyled-icon"
                          onClick={() => downloadWarrantyFile(warranty.id)}
                        >
                          <i className="icon download-icon" />
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        {(RoleCheck("9") || RoleCheck("3")) &&
        status === 1 &&
        committeeMembers.length > 0 ? (
          <span className="text-blue">
            {t("show_application_committe_memebers_votes")}
          </span>
        ) : (
          ""
        )}
        {RoleCheck("9") ? (
          <div className="d-flex mt-4">
            {committeeMembers
              .filter(
                (item) =>
                  item.first_name === userData.first_name &&
                  item.last_name === userData.last_name
              )
              .map((member) => (
                <div className="application-checkbox d-flex justify-content-start">
                  <span className="popup_title my-auto me-3">
                    {(member.first_name ? member.first_name : "-") +
                      " " +
                      (member.last_name ? member.last_name : "-")}
                  </span>
                  <input
                    id="checkbox"
                    className="form-check-input me-5"
                    type="checkbox"
                    style={{ width: "35px", height: "35px" }}
                    defaultChecked={
                      member.checked === 1 || verifiedFolder === 1
                    }
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      const updatedValue = isChecked ? 1 : 0;
                      setVerifiedFolder(updatedValue);
                    }}
                  />
                </div>
              ))}
          </div>
        ) : (
          ""
        )}

        {RoleCheck("3") && status === 1 ? (
          <div className="d-flex flex-wrap w-100 mt-4">
            {committeeMembers.map((member) => (
              <div
                className="application-checkbox d-flex justify-content-end mb-1"
                key={member.id}
              >
                <span className="popup_title my-auto me-3">
                  {(member.first_name ? member.first_name : "-") +
                    " " +
                    (member.last_name ? member.last_name : "-")}
                </span>
                <input
                  id="checkbox"
                  className="form-check-input me-5"
                  type="checkbox"
                  style={{ width: "35px", height: "35px" }}
                  checked={member.checked}
                />
                {validations.verified_folder && (
                  <ValidationMessage message={validations.verified_folder[0]} />
                )}
              </div>
            ))}
          </div>
        ) : (
          ""
        )}

        {RoleCheck("3") && status === 1 ? (
          <div className="w-100">
            <div className="d-flex flex-wrap align-items-baseline justify-content-end mt-5">
              {btnLoadingApprove ? (
                <button
                  type="button"
                  className="button-green has-icon me-3 mb-4"
                >
                  <i className="icon spinner-icon" />
                  {t("show_application_approving")}
                </button>
              ) : (
                <button
                  type="submit"
                  className="button-green has-icon me-3 mb-4"
                  onClick={() => approveApplication()}
                >
                  <i className="icon tick-icon" />
                  {t("show_application_approve")}
                </button>
              )}
              {btnLoadingReject ? (
                <button
                  type="button"
                  className="button-red has-icon  me-3 mb-4"
                >
                  <i className="icon spinner-icon" />
                  {t("show_application_canceling")}
                </button>
              ) : (
                <button
                  type="submit"
                  className="button-red has-icon me-3 mb-4"
                  onClick={() => rejectApplication()}
                >
                  <i className="icon close-icon" />
                  {t("show_application_cancel")}
                </button>
              )}
            </div>

            <div className="d-flex flex-wrap align-items-baseline justify-content-end border-bottom">
              <div className="row">
                <div className="form-block">
                  <label>{t("show_application_cancellation_reason")}</label>
                  <textarea
                    name="comments"
                    cols={39}
                    rows={6}
                    className={
                      "form-input" +
                      (validations.cancellation_reason ? " input-error" : "")
                    }
                    onChange={(e) => setRejectReason(e.target.value)}
                    value={rejectReason}
                    placeholder={t(
                      "show_application_cancellation_reason_placeholder"
                    )}
                  />
                  {validations.cancellation_reason && (
                    <ValidationMessage
                      message={validations.cancellation_reason[0]}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex flex-wrap align-items-baseline justify-content-end mt-3">
              {btnLoadingApproveAnother ? (
                <div className="d-flex my-auto">
                  <span className="d-flex popup_title my-2 me-3">
                    {t("show_application_approve_another_kindergarten")}
                  </span>
                  <button
                    type="button"
                    className="button-green has-icon me-3 mb-1"
                  >
                    <i className="icon spinner-icon" />
                    {t("show_application_approving")}
                  </button>
                </div>
              ) : (
                <div className="d-flex my-auto">
                  <span className="d-flex popup_title my-2 me-3">
                    {t("show_application_approve_another_kindergarten")}
                  </span>
                  <button
                    type="submit"
                    className="button-green has-icon me-3 mb-1"
                    onClick={() => approveAnotherKindergartenApplication()}
                  >
                    <i className="icon tick-icon" />
                    {t("show_application_approve")}
                  </button>
                </div>
              )}
              <div className="row">
                <div className="form-block">
                  <label>{t("show_application_cancellation_reason")}</label>
                  <textarea
                    name="comments"
                    cols={10}
                    rows={6}
                    className={
                      "form-input" +
                      (validations.cancellation_reason ? " input-error" : "")
                    }
                    onChange={(e) => setCancellationReason(e.target.value)}
                    value={cancellationReason}
                    placeholder={t(
                      "show_application_cancellation_reason_placeholder"
                    )}
                  />
                  {validations.cancellation_reason && (
                    <ValidationMessage
                      message={validations.cancellation_reason[0]}
                    />
                  )}
                </div>
                <div className="form-block col-12">
                  <label className="required">
                    {t("application_choose_institution")}
                  </label>
                  {type == "kopesht" ? (
                    <Select
                      placeholder={t("first_step_select_kindergarten")}
                      className={
                        "form-react-select" +
                        (validations.kindergarten_id ? " input-error" : "")
                      }
                      classNamePrefix="select_input"
                      name="specification"
                      onChange={(e) =>
                        e ? setKindergartenId(e.value) : setKindergartenId("")
                      }
                      isClearable={true}
                      isSearchable={false}
                      options={kindergartens
                        .filter((item) => item.type === "kopesht")
                        .map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))}
                    />
                  ) : (
                    <Select
                      placeholder={t("first_step_select_kindergarten")}
                      className={
                        "form-react-select" +
                        (validations.kindergarten_id ? " input-error" : "")
                      }
                      classNamePrefix="select_input"
                      name="specification"
                      onChange={(e) =>
                        e ? setKindergartenId(e.value) : setKindergartenId("")
                      }
                      isClearable={true}
                      isSearchable={false}
                      options={kindergartens
                        .filter((item) => item.type === "cerdhe")
                        .map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))}
                    />
                  )}
                  {validations.kindergarten_id && (
                    <ValidationMessage
                      message={validations.kindergarten_id[0]}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {RoleCheck("3") && status === 5 && (
          <div className="d-flex flex-wrap align-items-baseline justify-content-end mt-5">
            {btnLoadingConfirm ? (
              <button type="button" className="button-green has-icon me-3 mb-4">
                <i className="icon spinner-icon" />
                {t("show_application_confirming")}
              </button>
            ) : (
              <button
                type="submit"
                className="button-green has-icon me-3 mb-4"
                onClick={() => confirmApplication()}
              >
                <i className="icon tick-icon" />
                {t("show_application_confirm")}
              </button>
            )}
          </div>
        )}

        {RoleCheck("9") && status === 1 && (
          <div className="d-flex flex-wrap align-items-baseline justify-content-end mt-5">
            {btnLoadingApproveCommittee ? (
              <button type="button" className="button-green has-icon">
                <i className="icon spinner-icon" />
                {t("requests_approving")}
              </button>
            ) : (
              <button
                type="submit"
                className="button-green has-icon"
                onClick={(e) => {
                  approvedCommittee();
                  e.stopPropagation();
                }}
              >
                <i className="icon tick-icon" />
                {t("show_request_approve")}
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ShowApplication;
