import { useState, useRef, FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import ValidationMessage from "src/helpers/ValidationMessage";
import EvaluationStars from "../../performance/EvaluationStars";
import Select, { SingleValue } from "react-select";
import { evaluations } from "../../../panel/performance/data";

interface EditTeacherEvaluationProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  evaluationId: number;
  setModified: (visible: boolean) => void;
}

const EditTeacherEvaluation: React.FC<EditTeacherEvaluationProps> = ({
  onShowModal,
  setShowModal,
  evaluationId,
  setModified,
}) => {
  const { t } = useTranslation();

  const [teacherEvaluationDate, setTeacherEvaluationDate] = useState("");
  const [teacherEvaluationComment, setTeacherEvaluationComment] = useState("");
  const [teacherEvaluationScore, setTeacherEvaluationScore] =
    useState<SingleValue<{ value: number; text?: string; icon: JSX.Element }>>(
      null
    );
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const editTeacherEvaluation = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setBtnLoading(true);

    let formData = {
      evaluation_comment: teacherEvaluationComment,
      evaluation_score: teacherEvaluationScore
        ? teacherEvaluationScore.value
        : "",
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.updateTeacherEvaluation(evaluationId),
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);

      setModified(true);

      onClose();
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        onClose();
      }
      setBtnLoading(false);
    }
  };

  const onClose = () => {
    setShowModal(false);
  };

  const getShowTeacherEvaluationData = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.teacherEvaluationDetails(evaluationId),
        "GET",
        API_HEADERS.authenticated
      );
      const { teacher, evaluation_date, evaluation_score, evaluation_comment } =
        response.teacher_evaluation;
      setTeacherEvaluationDate(response.teacher_evaluation.evaluation_date);
      setTeacherEvaluationComment(
        response.teacher_evaluation.evaluation_comment
      );
      setTeacherEvaluationScore({
        value: evaluation_score,
        text: `${evaluation_score} star`,
        icon: <EvaluationStars evaluationNr={evaluation_score} />,
      });
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      onClose();
    }
  };

  useEffect(() => {
    getShowTeacherEvaluationData();
  }, []);

  return (
    <Modal
      isOpen={onShowModal && !loading}
      toggle={() => onClose()}
      size="md"
      centered
    >
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("edit_teacher_evaluation_title")}
      </ModalHeader>
      <form onSubmit={(e) => editTeacherEvaluation(e)}>
        <ModalBody>
          <div className="modal-note mb-4">
            {t("edit_teacher_evaluation_details")}
          </div>
          <div className="d-flex border-bottom modal-requests">
            <div className="col-12 col-md-6 form-block mb-5">
              <label className="required">{t("edit_teacher_evaluation")}</label>
              <span className="header-text">
                <Select
                  placeholder={t(
                    "performance_edit_evaluation_evaluation_placeholder"
                  )}
                  className={
                    "form-react-select" +
                    (validations.evaluation_score ? " input-error" : "")
                  }
                  classNamePrefix="select_input"
                  name="evaluation_score"
                  onChange={(e) => setTeacherEvaluationScore(e)}
                  isClearable={true}
                  isSearchable={false}
                  options={evaluations}
                  value={teacherEvaluationScore}
                  getOptionLabel={(e: any) => e.icon}
                />
                {validations.evaluation_score && (
                  <ValidationMessage
                    message={validations.evaluation_score[0]}
                  />
                )}
              </span>
            </div>
          </div>
          <div className="d-flex mt-4 mb-4 modal-requests">
            <div className="modal-sections">
              <div className="form-block">
                <label>{t("edit_teacher_evaluation_comment")}</label>
                <textarea
                  name="comments"
                  cols={50}
                  rows={6}
                  className={
                    "form-input" +
                    (validations.evaluation_comment ? " input-error" : "")
                  }
                  onChange={(e) => setTeacherEvaluationComment(e.target.value)}
                  value={teacherEvaluationComment}
                  placeholder={t("edit_teacher_evaluation_comment")}
                />
                {validations.evaluation_comment && (
                  <ValidationMessage
                    message={validations.evaluation_comment[0]}
                  />
                )}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("edit_teacher_evaluation_close")}
          </button>
          {btnLoading ? (
            <button className="button-blue has-icon" type="button">
              <i className="icon spinner-icon" />
              {t("edit_teacher_evaluation_saving")}
            </button>
          ) : (
            <button className="button-blue" type="submit">
              {t("edit_teacher_evaluation_save")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditTeacherEvaluation;
