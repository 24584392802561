import { ApexOptions } from "apexcharts";
import React from "react";
import ReactApexChart from "react-apexcharts";

type FlexibleObject = {
  [key: string]: any;
};

interface ReactChartProps {
  type:
    | "line"
    | "area"
    | "bar"
    | "pie"
    | "donut"
    | "radialBar"
    | "scatter"
    | "bubble"
    | "heatmap"
    | "candlestick"
    | "boxPlot"
    | "radar"
    | "polarArea"
    | "rangeBar"
    | "rangeArea"
    | "treemap"
    | undefined;
  otherOptions?: FlexibleObject;
  series: any[];
  height?: number;
}

const ReactChart: React.FC<ReactChartProps> = ({
  type,
  otherOptions,
  series,
  height,
}) => {
  const options: ApexOptions = {
    ...otherOptions,
    chart: {
      type: "line",
      height: height ? height : 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    tooltip: {
      enabled: true,
    },
  };

  return (
    <ReactApexChart
      type={type}
      options={options}
      series={series}
      height={350}
      className="apex-charts"
    />
  );
};

export default ReactChart;
