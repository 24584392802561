import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import CustomPagination from "src/helpers/CustomPaginate";
import API_PATHS from "src/api/apiPaths";
import PATHS from "src/routes/Paths";
import API_HEADERS from "src/api/apiConfig";
import SearchFilter from "src/helpers/SearchFilter";
import ListResponse from "src/helpers/ListResponse";
import Loading from "src/helpers/Loading";
import useSearchSubmit from "src/hooks/useSearchSubmit";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

const Teachers: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Teachers
  const [teachers, setTeachers] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // Search
  const {
    searchInputs,
    searchBtnLoading,
    setSearchBtnLoading,
    handleSubmit,
    setPage,
  } = useSearchSubmit();
  const [term, setTerm] = useState<string>("");

  const getTeachers = async (): Promise<void> => {
    const searchParams: any = searchInputs;
    let request: any = API_PATHS.teachersList;

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      setTeachers(response.teachers);
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1)
    } finally {
      setSearchBtnLoading(false);
    }
  };

  useEffect(() => {
    getTeachers();
  }, [searchInputs]);

  return (
    <>
      <div className="input-items">
        <form onSubmit={(e) =>
          handleSubmit(e, {
            ...(term && { term: term }),
          })
        }>
          <div className="row mb-1">
            <div className="form-block col-12 col-sm-6 col-md-2">
              <label>{t("teachers_term")}</label>
              <input
                placeholder={t("teachers_search_by_term")}
                className="form-input"
                name="text"
                type="text"
                onChange={(e) => setTerm(e.target.value)}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-3 mb-1 mt-4">
              {searchBtnLoading ? (
                <button type="button" className="button-orange has-icon">
                  <i className="icon spinner-icon" />
                  {t("teachers_searching")}
                </button>
              ) : (
                <button type="submit" className="button-orange has-icon">
                  <i className="icon search-icon" />
                  {t("teachers_search")}
                </button>
              )}
            </div>
          </div>
          <p className="filter-explain-text mb-5">
            {t("teachers_filter_explain")}
          </p>
        </form>
      </div>
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                style={{ width: "16%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon table-teachers-icon" />
                {t("teachers_name_lastname")}
              </th>
              <th
                style={{ width: "19%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon address-icon" />
                {t("teachers_address")}
              </th>
              <th
                style={{ width: "19%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon telephone-icon" />
                {t("teachers_mobile")}
              </th>
              <th
                style={{ width: "19%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon email-icon" />
                {t("teachers_email")}
              </th>
              <th
                style={{ width: "19%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon actions-icon" />
                {t("teachers_actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={5}>
                  <Loading />
                </td>
              </tr>
            ) : (
              teachers.data.map((teacher: any) => (
                <tr key={teacher.id}>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("teachers_name_lastname")}
                  >
                    {(teacher.first_name ? teacher.first_name : "-") +
                      " " +
                      (teacher.last_name ? teacher.last_name : "-")}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("teachers_address")}
                  >
                    {teacher.address ? teacher.address : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("teachers_mobile")}
                  >
                    {teacher.phone ? teacher.phone : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-center blue-text"
                    data-label={t("teachers_email")}
                  >
                    {teacher.email ? teacher.email : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("teachers_actions")}
                  >
                    <div className="actions">
                      <button
                        type="button"
                        className="button-unstyled-icon"
                        onClick={() => navigate(PATHS.showTeacher + teacher.id)}
                      >
                        <i className="icon show-icon" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={teachers?.data?.length}
          loading={loading}
        />
      </div>
      <CustomPagination
        data={teachers}
        setActivePage={(e) => setPage(e)}
      />
    </>
  );
};

export default Teachers;
