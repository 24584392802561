import React from "react";
import { RoleCheck } from "src/helpers/RoleCheck";
import TeacherPerformance from "./TeacherPerformance";
import DirectorPsychologistPerformance from "./DirectorPsychologistPerformance";

const Performance: React.FC = () => {
  return (
    <>
      {RoleCheck("2") ? (
        <TeacherPerformance />
      ) : (
        <DirectorPsychologistPerformance />
      )}
    </>
  );
};

export default Performance;
