import { useState, FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import ValidationMessage from "src/helpers/ValidationMessage";
import { useParams } from "react-router-dom";
import SearchFilter from "src/helpers/SearchFilter";

interface EditResponsiblePersonProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  responsiblePersonId: number;
  setModified: (visible: boolean) => void;
}

const EditResponsiblePerson: React.FC<EditResponsiblePersonProps> = ({
  onShowModal,
  setShowModal,
  responsiblePersonId,
  setModified,
}) => {
  const { t } = useTranslation();
  const { childId } = useParams();

  const [loading, setLoading] = useState<boolean>(true);

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [relationToChild, setRelationToChild] = useState<string>("");

  const [loadingButton, setLoadingButton] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const onClose = () => {
    setShowModal(false);
  };

  const fetchResponsiblePersonData = async (): Promise<void> => {
    const searchParams: any = {
      child_id: childId,
    };

    let request: any = SearchFilter(
      searchParams,
      API_PATHS.getChildResponsiblePerson(responsiblePersonId)
    );

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      const { responsiblePerson } = response;

      setLoading(false);
      setFirstName(responsiblePerson.first_name);
      setLastName(responsiblePerson.last_name);
      setPhone(responsiblePerson.phone ? responsiblePerson.phone : "");
      setEmail(responsiblePerson.email ? responsiblePerson.email : "");
      setRelationToChild(responsiblePerson.relation_to_child);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      onClose();
    }
  };

  useEffect(() => {
    fetchResponsiblePersonData();
  }, []);

  const updateResponsiblePerson = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setLoadingButton(true);

    const formData = {
      first_name: firstName,
      last_name: lastName,
      relation_to_child: relationToChild,
      child_id: childId,
      phone: phone,
      email: email,
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.childUpdateResponsiblePerson(responsiblePersonId),
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);

      setModified(true);

      onClose();

      setLoadingButton(false);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        onClose();
      }
      setLoadingButton(false);
    }
  };

  return (
    <Modal
      isOpen={onShowModal && !loading}
      toggle={() => onClose()}
      size="md"
      centered
    >
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("child_responsible_persons_edit_modal_title")}
      </ModalHeader>
      <form onSubmit={(e) => updateResponsiblePerson(e)}>
        <ModalBody>
          <div className="modal-note">
            {t("child_responsible_persons_edit_modal_desc")}
          </div>
          <div className="row">
            <div className="form-block col-12 col-md-6">
              <label className="required">
                {t("child_responsible_persons_edit_modal_name_label")}
              </label>
              <input
                type="text"
                name="first_name"
                placeholder={t(
                  "child_responsible_persons_edit_modal_name_placeholder"
                )}
                value={firstName}
                className={
                  "form-input" + (validations.first_name ? " input-error" : "")
                }
                autoComplete="off"
                onChange={(e) => setFirstName(e.target.value)}
              />
              {validations.first_name && (
                <ValidationMessage message={validations.first_name[0]} />
              )}
            </div>
            <div className="form-block col-12 col-md-6">
              <label className="required">
                {t("child_responsible_persons_edit_modal_lastname_label")}
              </label>
              <input
                type="text"
                name="last_name"
                placeholder={t(
                  "child_responsible_persons_edit_modal_lastname_placeholder"
                )}
                value={lastName}
                className={
                  "form-input" + (validations.last_name ? " input-error" : "")
                }
                autoComplete="off"
                onChange={(e) => setLastName(e.target.value)}
              />
              {validations.last_name && (
                <ValidationMessage message={validations.last_name[0]} />
              )}
            </div>
            <div className="form-block col-12 col-md-6">
              <label>
                {t("child_responsible_persons_edit_modal_phone_label")}
              </label>
              <input
                type="text"
                name="phone"
                placeholder={t(
                  "child_responsible_persons_edit_modal_phone_placeholder"
                )}
                value={phone}
                className={
                  "form-input" + (validations.phone ? " input-error" : "")
                }
                autoComplete="off"
                onChange={(e) => setPhone(e.target.value)}
              />
              {validations.phone && (
                <ValidationMessage message={validations.phone[0]} />
              )}
            </div>
            <div className="form-block col-12 col-md-6">
              <label>
                {t("child_responsible_persons_edit_modal_email_label")}
              </label>
              <input
                type="email"
                name="email"
                placeholder={t(
                  "child_responsible_persons_edit_modal_email_placeholder"
                )}
                value={email}
                className={
                  "form-input" + (validations.email ? " input-error" : "")
                }
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
              />
              {validations.email && (
                <ValidationMessage message={validations.email[0]} />
              )}
            </div>
            <div className="form-block col-12 col-md-6">
              <label className="required">
                {t("child_responsible_persons_edit_modal_relation_label")}
              </label>
              <input
                type="text"
                name="relation_to_child"
                placeholder={t(
                  "child_responsible_persons_edit_modal_relation_placeholder"
                )}
                value={relationToChild}
                className={
                  "form-input" +
                  (validations.relation_to_child ? " input-error" : "")
                }
                autoComplete="off"
                onChange={(e) => setRelationToChild(e.target.value)}
              />
              {validations.relation_to_child && (
                <ValidationMessage message={validations.relation_to_child[0]} />
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("child_responsible_persons_edit_modal_close_button")}
          </button>

          {loadingButton ? (
            <button type="button" className="button-blue has-icon">
              <i className="spinner-icon" />
              {t("child_responsible_persons_edit_modal_editing_button")}
            </button>
          ) : (
            <button type="submit" className="button-blue has-icon">
              <i className="icon add-icon" />
              {t("child_responsible_persons_edit_modal_edit_button")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditResponsiblePerson;
