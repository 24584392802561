import moment from "moment";
import React, { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { SingleValue } from "react-select";
import { makeApiCall } from "src/api/apiRequests";
import { useNavigate } from "react-router-dom";
import { LanguageDate } from "src/helpers/CheckLanguageDate";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import SearchFilter from "src/helpers/SearchFilter";
import DatePicker from "react-datepicker";
import CustomPagination from "src/helpers/CustomPaginate";
import Loading from "src/helpers/Loading";
import ListResponse from "src/helpers/ListResponse";
import AddAbsence from "./AddAbsence";
import ValidationMessage from "src/helpers/ValidationMessage";
import useSearchSubmit from "src/hooks/useSearchSubmit";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";

interface DatePickerInputProps {
  value?: string;
  onClick?: () => void;
}

const Participation: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Children list
  const [listOfChildren, setListOfChildren] = useState<any>([]);
  const [listOfGroups, setListOfGroups] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // Search
  const {
    searchInputs,
    searchBtnLoading,
    setSearchBtnLoading,
    handleSubmit,
    setPage,
  } = useSearchSubmit();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchGroup, setSearchGroup] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [searchDate, setSearchDate] = useState<any>(new Date());
  const [validations, setValidations] = useState<Record<string, string>>({});

  const DatePickerButton = forwardRef<HTMLButtonElement, DatePickerInputProps>(
    ({ value, onClick }, ref) => (
      <button
        type="button"
        className={
          "datepicker-button simple-input" +
          (validations.date_of_participation ? " input-error" : "")
        }
        onClick={onClick}
        ref={ref}
      >
        {value}
      </button>
    )
  );

  // Add absence modal
  const [showModal, setShowModal] = useState<boolean>(false);
  const [childId, setChildId] = useState<number | null>(null);

  const fetchChildren = async (): Promise<void> => {
    const searchParams: any = searchInputs;
    let request: any = API_PATHS.participationChildrenList;

    searchParams.date_of_participation = moment(searchDate).format("yyyy-MM-DD");

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setListOfChildren(response.children);
      setListOfGroups(
        response.kindergarten_groups.map((group: any) => ({
          value: group.id,
          label: group.name,
        }))
      );
      setLoading(false);
      setValidations({});
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
        setListOfChildren([]);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        navigate(-1);
      }
    } finally {
      setSearchBtnLoading(false);
    }
  };

  const deleteAbsence = (absenceId: string, childName: string) => {
    ConfirmPopup.deletePopup(
      t("participation_list_delete_absence_title"),
      t("participation_list_delete_absence_desc"),
      childName
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.deleteAbsence(absenceId),
            "DELETE",
            API_HEADERS.authenticated
          );

          fetchChildren();
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  useEffect(() => {
    fetchChildren();
  }, [searchInputs]);

  const handleChange = (child: any) => {
    if (child.absence === null) {
      setShowModal(true);
      setChildId(child.id);
    } else {
      deleteAbsence(child.absence.id, `${child.first_name} ${child.last_name}`);
    }
  };

  return (
    <>
      <form onSubmit={(e) =>
        handleSubmit(e, {
          ...(searchTerm && { term: searchTerm }),
          ...(searchGroup && { group: searchGroup.value }),
        })
      } className="row">
        <div className="form-block col-12 col-sm-6 col-md-3 col-xxl-2">
          <label>{t("participation_list_search_term_label")}</label>
          <input
            type="text"
            name="term"
            className="form-input"
            placeholder={t("participation_list_search_term_placeholder")}
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
        </div>
        <div className="form-block col-12 col-sm-6 col-md-3 col-xxl-2">
          <label>{t("participation_list_search_group_label")}</label>
          <Select
            placeholder={t("participation_list_search_group_placeholder")}
            className="form-react-select"
            classNamePrefix="select_input"
            name="program_age_group_id"
            onChange={(e) => setSearchGroup(e)}
            isClearable={true}
            isSearchable={false}
            value={searchGroup}
            options={listOfGroups}
          />
        </div>
        <div className="form-block col-12 col-sm-6 col-md-3 col-xxl-2">
          <label>{t("participation_list_search_date_label")}</label>
          <DatePicker
            selected={searchDate}
            onChange={(e) => setSearchDate(e)}
            customInput={<DatePickerButton />}
            showMonthDropdown
            showYearDropdown
            dateFormat="dd MMM, yyyy"
            isClearable
            placeholderText={t("participation_list_search_date_placeholder")}
            name="date_of_participation"
            className={
              "form-datepicker" +
              (validations.date_of_participation ? " input-error" : "")
            }
            locale={LanguageDate.datepicker}
            autoComplete="off"
          />
          {validations.date_of_participation && (
            <ValidationMessage message={validations.date_of_participation[0]} />
          )}
        </div>
        <div className="col-12 col-sm-6 col-md-3 col-xxl-2 mb-1">
          {searchBtnLoading ? (
            <button type="button" className="mt-4 button-orange has-icon">
              <i className="icon spinner-icon" />
              {t("participation_list_searching_button")}
            </button>
          ) : (
            <button type="submit" className="mt-4 button-orange has-icon">
              <i className="icon search-icon" />
              {t("participation_list_search_button")}
            </button>
          )}
        </div>
      </form>
      {showModal && (
        <AddAbsence
          onShowModal={showModal}
          setShowModal={(e) => setShowModal(e)}
          setModified={() => fetchChildren()}
          setChildId={(e) => setChildId(e)}
          childId={childId}
          date={searchDate}
        />
      )}
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon children-name-icon" />
                {t("participation_list_table_child_name")}
              </th>
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon guardian-icon" />
                {t("participation_list_table_guardian")}
              </th>
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon group-icon" />
                {t("participation_list_table_group")}
              </th>
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon telephone-icon" />
                {t("participation_list_table_contact")}
              </th>
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon calender-icon" />
                {t("participation_list_table_participation")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={5}>
                  <Loading />
                </td>
              </tr>
            ) : (
              listOfChildren?.data?.map((child: any) => (
                <tr key={child.id}>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("participation_list_table_child_name")}
                  >
                    {`${child.first_name} ${child.last_name}`}
                  </td>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("participation_list_table_guardian")}
                  >
                    {`${child.guardian.first_name} ${child.guardian.last_name}`}
                  </td>
                  <td
                    role="cell"
                    className="text-left"
                    data-label={t("participation_list_table_group")}
                  >
                    {child.kindergarten_group
                      ? child.kindergarten_group.name
                      : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-left"
                    data-label={t("participation_list_table_contact")}
                  >
                    <div className="d-flex flex-column">
                      <span>{child.guardian.phone}</span>
                      <span className="blue-text">{child.guardian.email}</span>
                    </div>
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("participation_list_table_participation")}
                  >
                    <div className="actions">
                      <div className="form-block mb-0">
                        <div className="form-switch">
                          <div className="switch">
                            <input
                              type="checkbox"
                              checked={child.absence === null}
                              onChange={() => handleChange(child)}
                            />
                            <span className="slider round"></span>
                          </div>
                          {child.absence === null
                            ? t(
                              "participation_list_table_participation_present"
                            )
                            : t(
                              "participation_list_table_participation_absent"
                            )}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={
            listOfChildren.length === 0 ? 0 : listOfChildren?.data?.length
          }
          loading={loading}
        />
      </div>

      <CustomPagination
        data={listOfChildren}
        setActivePage={(e) => setPage(e)}
      />
    </>
  );
};

export default Participation;
