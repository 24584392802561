import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import CustomPagination from "src/helpers/CustomPaginate";
import EditKindergarten from "./EditKindergarten";
import PATHS from "src/routes/Paths";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import SearchFilter from "src/helpers/SearchFilter";
import ListResponse from "src/helpers/ListResponse";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import Loading from "src/helpers/Loading";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";

const KindergartenGrid: React.FC<{
  searchInputs: Record<string, string>;
  modified: boolean,
  filter: "newest_first" | "oldest_first" | undefined;
  searched: boolean,
  page: number,
  setPage: (nr: number) => void;
  setSearchBtnLoading: (value: boolean) => void;
  setModified: (value: boolean) => void;
}> = ({
  searchInputs,
  modified,
  searched,
  filter,
  page,
  setPage,
  setSearchBtnLoading,
  setModified
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Kindergarten
    const [kindergartens, setKindergartens] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);


    // Modals
    const [editKindergartenModal, setEditKindergartenModal] = useState<boolean>(false);
    const [modalId, setModalId] = useState<number | null>(null);

    const getKindergartens = async (): Promise<void> => {
      var searchParams: any = searchInputs
      let request: any = API_PATHS.listKindergartens;

      searchParams.type = "kopesht";
      searchParams.pagination = "yes";
      searchParams.filter = filter;

      request = SearchFilter(searchParams, request);

      try {
        const response: any = await makeApiCall<ResponseType>(
          request,
          "GET",
          API_HEADERS.authenticated
        );
        setKindergartens(response.kindergartens);
        setLoading(false);
        setModified(false);
      } catch (error: any) {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        navigate(-1);
      } finally {
        setSearchBtnLoading(false)
      }
    };

    useEffect(() => {
      getKindergartens();
    }, [filter, searchInputs]);

    useEffect(() => {
      modified && getKindergartens();
    }, [modified]);

    const deleteKindergarten = (
      kindergartenId: number,
      kindergartenTitle: string
    ) => {
      ConfirmPopup.deleteKindergartenPopup(
        t("delete_kindergarten_title"),
        t("delete_kindergarten_type"),
        kindergartenTitle
      ).then(async (response) => {
        if (response.isConfirmed) {
          try {
            const response: any = await makeApiCall<ResponseType>(
              API_PATHS.deleteKindergarten(kindergartenId),
              "DELETE",
              API_HEADERS.authenticated
            );
            if (kindergartens.data.length === 1 && page > 1) {
              setPage(page - 1);
            } else {
              getKindergartens();
            }
            FLASH_MESSAGES.successMsg(response.success);
          } catch (error: any) {
            if (error.response.data.error) {
              FLASH_MESSAGES.errorMsg(error.response.data.error);
            }
          }
        }
      });
    };

    return (
      <>
        {loading ? (
          <div className="text-center">
            <Loading />
          </div>
        ) : (
          <div className="grid-container">
            {kindergartens.data.map((kindergarten: any) => (
              <div className="grid-item" key={kindergarten.id}>
                <div className="grid-header">
                  <div className="grid-number">
                    {kindergarten.number ? kindergarten.number : "-"}
                  </div>
                  {kindergarten.name ? kindergarten.name : "-"}
                </div>
                <div className="grid-content">
                  <div className="grid-title mt-2">
                    <i className="icon address-icon" />
                    {t("kindergarten_address")}
                  </div>
                  <div className="grid-subtitle">
                    {kindergarten.address ? kindergarten.address : "-"}
                  </div>

                  <div className="grid-title">
                    <i className="icon telephone-icon" />
                    {t("kindergarten_mobile")}
                  </div>
                  <div className="grid-subtitle">
                    {kindergarten.phone ? kindergarten.phone : "-"}
                  </div>
                  <div className="grid-title">
                    <i className="icon email-icon" />
                    {t("kindergarten_email")}
                  </div>
                  <div className="grid-subtitle text-blue">
                    {kindergarten.email ? kindergarten.email : "-"}
                  </div>
                  <div className="grid-title">
                    <i className="icon website-icon" />
                    {t("kindergarten_website")}
                  </div>
                  <div className="grid-subtitle">
                    {kindergarten.website ? (
                      <a
                        href={kindergarten.website}
                        className="text-decoration-none"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {kindergarten.website}
                      </a>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
                <div className="grid-actions">
                  <div className="mt-2">
                    <button
                      type="button"
                      className="button-unstyled-icon"
                      onClick={(e) => {
                        setEditKindergartenModal(true);
                        setModalId(kindergarten.id);
                        e.stopPropagation();
                      }}
                    >
                      <i className="icon edit-icon" />
                    </button>
                    {editKindergartenModal && modalId === kindergarten.id && (
                      <EditKindergarten
                        onShowModal={editKindergartenModal}
                        setKindergartenVisible={(e) => {
                          setEditKindergartenModal(e);
                          setModalId(null);
                        }}
                        kindergartenId={kindergarten.id}
                        setModified={(e) => setModified(e)}
                      />
                    )}

                    <button
                      type="button"
                      className="button-unstyled-icon"
                      onClick={() =>
                        navigate(PATHS.showKindergarten + kindergarten.id)
                      }
                    >
                      <i className="icon show-icon" />
                    </button>
                    <button
                      className="button-unstyled-icon"
                      key={kindergarten.id}
                      onClick={() => {
                        deleteKindergarten(kindergarten.id, kindergarten.name);
                      }}
                    >
                      <i className="icon delete-icon" />
                    </button>
                  </div>
                </div>
              </div>
            ))
            }
          </div>
        )}

        <ListResponse
          totalItems={kindergartens?.data?.length}
          loading={loading}
          searched={searched}
        />
        <CustomPagination
          data={kindergartens}
          setActivePage={(e) => setPage(e)}
        />
      </>
    );
  };
export default KindergartenGrid;
