import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Breadcrumb from "src/components/layouts/Common/Breadcrumb";
import Sidebar from "src/components/layouts/sidebar/Sidebar";
import Reports from "src/components/panel/reports/Reports";

const ReportsView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("reports_tab")}</title>
      </Helmet>
      <Sidebar>
        <Breadcrumb title={t("reports_title")} />
        <Reports />
      </Sidebar>
    </>
  );
};

export default ReportsView;
