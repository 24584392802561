import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ShowNurseryStaff from "./ShowNurseryStaff";
import ShowNurseryChildren from "./ShowNurseryChildren";
import ShowNurseryImages from "./ShowNurseryImages";
import EditNursery from "../EditNursery";

import { makeApiCall } from "src/api/apiRequests";
import ValidationMessage from "src/helpers/ValidationMessage";
import CustomPagination from "src/helpers/CustomPaginate";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import L from "leaflet";
import { icon } from "leaflet";
import "leaflet/dist/leaflet.css";

import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import SearchFilter from "src/helpers/SearchFilter";
import ListResponse from "src/helpers/ListResponse";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

import Loading from "src/helpers/Loading";

const ShowNursery: React.FC = () => {
  const { t } = useTranslation("");
  const navigate = useNavigate();
  const param = useParams();
  const [tab, setTab] = useState("staff");
  const [editNurseryModal, setEditNurseryModal] = useState(false);
  const [modalId, setModalId] = useState<number | null>(null);

  const [nurseryId, setNurseryId] = useState<number>(0);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [latitude, setLatitude] = useState<number | string>(0);
  const [longitude, setLongitude] = useState<number | string>(0);
  const [modified, setModified] = useState(false);
  const [type, setType] = useState("cerdhe");
  const [numberOfEmptySpots, setNumberOfEmptySpots] = useState("");
  const [totalActualTakenSpots, setTotalActualTakenSpots] = useState("");
  const [numberOfStaff, setNumberOfStaff] = useState("");
  const [maxNumberChildren, setMaxNumberChildren] = useState("");
  const [loading, setLoading] = useState<boolean>(true);
  const [images, setImages] = useState<File[]>([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const showNursery = async (): Promise<void> => {
    const kindergartenId = param.id;

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.showKindergarten(Number(kindergartenId)),
        "GET",
        API_HEADERS.authenticated
      );
      setLoading(false);

      setNurseryId(response.kindergarten.id);
      setName(response.kindergarten.name);
      setNumber(response.kindergarten.number);
      setAddress(response.kindergarten.address);
      setPostalCode(response.kindergarten.postal_code);
      setPhone(response.kindergarten.phone);
      setEmail(response.kindergarten.email);
      setWebsite(response.kindergarten.website);
      const latitudeValue = Number(response.kindergarten.latitude) || 0;
      const longitudeValue = Number(response.kindergarten.longtitude) || 0;
      setLatitude(latitudeValue);
      setLongitude(longitudeValue);
      setMaxNumberChildren(response.kindergarten.max_number_children);
      setNumberOfEmptySpots(response.nr_of_free_places_in_the_kindergarten);
      setTotalActualTakenSpots(response.nr_of_children_in_the_kindergarten);
      setNumberOfStaff(response.nr_of_staff_in_kindergarten);
      setImages(response.kindergarten.images);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
    }
  };

  useEffect(() => {
    showNursery();
  }, []);

  const markerColors = [
    "#74dde5",
    "#fac86b",
    "#678af7",
    "#6bdaae",
    "#ab56d4",
    "#fd8adc",
    "#70b0ff",
    "#9386f0",
    "#f1c448",
    "#6592f3",
    "#3d6de0",
    "#e3692c",
    "#ff9900",
    "#ff8c00",
    "#f29b30",
    "#ffdeb5",
    "#008000",
    "#ffc0cb",
    "#59bf80",
    "#cb3438",
  ];

  const locations = [{ latitude, longitude, name }];

  const createMarker = (location: any) => {
    const randomColor =
      markerColors[Math.floor(Math.random() * markerColors.length)];

    const customIcon = icon({
      iconUrl: require("../../../../assets/images/marker-icon.png"),
      iconSize: [20, 20],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      tooltipAnchor: [16, -28],
      className: "custom-marker",
    });

    customIcon.options.className += " marker-color";

    const markerStyle = document.createElement("style");

    markerStyle.innerHTML = `
      .marker-color {
        background: ${randomColor};
      }
    `;
    document.head.appendChild(markerStyle);

    return (
      <Marker
        position={[
          location?.latitude ? location.latitude : 0,
          location?.longitude ? location.longitude : 0,
        ]}
        key={location.name}
        icon={customIcon}
      >
        <Popup className="leaflet-popup-content">
          <div className="grid-container1">
            <div className="grid-item">
              <div className="grid-header">
                <div className="grid-number">{number ? number : "-"}</div>
                {name ? name : "-"}
              </div>
              <div className="grid-content">
                <div className="grid-title mt-2">
                  <i className="icon address-icon" />
                  {t("nursery_address")}
                </div>
                <div className="grid-subtitle">{address ? address : "-"}</div>
                <div className="grid-title">
                  <i className="icon telephone-icon" />
                  {t("nursery_mobile")}
                </div>
                <div className="grid-subtitle">{phone ? phone : "-"}</div>
                <div className="grid-title">
                  <i className="icon email-icon" />
                  {t("nursery_email")}
                </div>
                <div className="grid-subtitle">{email ? email : "-"}</div>
                <div className="grid-title">
                  <i className="icon website-icon" />
                  {t("nursery_website")}
                </div>
                <div className="grid-subtitle">
                  {website ? (
                    <a
                      href={website}
                      className="text-decoration-none"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {website}
                    </a>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </div>
          </div>
        </Popup>
      </Marker>
    );
  };

  return (
    <>
      <div className="show-nursery mb-5">
        <div className="show-map">
          <div id="map">
            <MapContainer
              center={[41.33, 19.82]}
              zoom={13}
              scrollWheelZoom={false}
              attributionControl={false}
              style={{ width: "640", height: "370px" }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
              />
              {locations.map((location) => createMarker(location))}
            </MapContainer>
          </div>
        </div>
        <div className="info-section">
          <div className="mb-5 me-3">
            <div className="mb-5">
              <span className="show-report-title">
                <i className="icon address-icon" />
                {t("show_nursery_address")}
              </span>
              <p className="show-report-description mx-1">
                {address ? address : "-"}
              </p>
            </div>
            <div>
              <span className="show-report-title">
                <i className="icon telephone-icon" />
                {t("show_nursery_mobile")}
              </span>
              <p className="show-report-description mx-1">
                {phone ? phone : "-"}
              </p>
            </div>
          </div>
          <div className="mb-5">
            <div className="mb-5">
              <span className="show-report-title">
                <i className="icon website-icon" />
                {t("show_nursery_website")}
              </span>
              <p className="show-report-description">
                {website ? (
                  <a
                    href={website}
                    className="text-decoration-none"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {website}
                  </a>
                ) : (
                  "-"
                )}
              </p>
            </div>
            <div>
              <span className="show-report-title">
                <i className="icon email-icon" />
                {t("show_nursery_email")}
              </span>
              <p className="show-report-description blue-text">
                {email ? email : "-"}
              </p>
            </div>
          </div>
          <div>
            <div className="mb-5">
              <button
                type="button"
                className="has-icon button-blue"
                onClick={() => {
                  setEditNurseryModal(true);
                  setModalId(nurseryId);
                }}
              >
                <i className="icon-before edit-icon" />
                {t("show_nursery_edit")}
              </button>
              {editNurseryModal && modalId === nurseryId && (
                <EditNursery
                  onShowModal={editNurseryModal}
                  setNurseryVisible={(e) => {
                    setEditNurseryModal(e);
                    setModalId(null);
                  }}
                  kindergartenId={nurseryId}
                  setModified={(e) => setModified(e)}
                />
              )}
            </div>
          </div>
          <div className="section-container">
            <div className="nursery-report">
              <div className="circle">
                <i className="icon-before staff-number-icon" />
              </div>
              <div className="circle-info">
                <span className="show-report-cards">
                  {t("show_nursery_staff_number")}
                </span>
                <span>{numberOfStaff ? numberOfStaff : "-"}</span>
              </div>
            </div>
            <div className="nursery-report">
              <div className="circle red">
                <i className="icon-before kid-icon" />
              </div>
              <div className="circle-info">
                <span className="show-report-cards">
                  {t("show_nursery_vacancies")}
                </span>
                <span>{numberOfEmptySpots ? numberOfEmptySpots : "-"}</span>
              </div>
            </div>
            <div className="nursery-report">
              <div className="circle blue">
                <i className="icon-before kid-icon" />
              </div>
              <div className="circle-info">
                <span className="show-report-cards">
                  {t("show_nursery_children_number")}
                </span>
                <span>
                  {totalActualTakenSpots ? totalActualTakenSpots : "-"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="tab-sort">
          <div
            className={`div ${tab === "staff" ? "selected" : ""}`}
            onClick={(e) => {
              e ? setTab("staff") : setTab("");
            }}
          >
            {t("show_nursery_staff")}
          </div>

          <div
            className={`div ${tab === "children" ? "selected" : ""}`}
            onClick={(e) => {
              e ? setTab("children") : setTab("");
            }}
          >
            {t("show_nursery_children")}
          </div>

          <div
            className={`div ${tab === "images" ? "selected" : ""}`}
            onClick={(e) => {
              e ? setTab("images") : setTab("");
            }}
          >
            {t("show_nursery_images")}
          </div>
        </div>
        {tab === "staff" ? (
          <ShowNurseryStaff />
        ) : tab === "children" ? (
          <ShowNurseryChildren />
        ) : (
          <ShowNurseryImages />
        )}
      </div>
    </>
  );
};
export default ShowNursery;
