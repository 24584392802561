import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

interface ViewFoodMenuProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  foodMenuId: number;
}

const ViewFoodMenu: React.FC<ViewFoodMenuProps> = ({
  onShowModal,
  setShowModal,
  foodMenuId,
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState<string>("");
  const [ageGroup, setAgeGroup] = useState<string>("");
  const [season, setSeason] = useState<string>("");
  const [age, setAge] = useState<string>("");
  const [file, setFile] = useState<string>("");
  const [link, setLink] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(true);

  const onClose = () => {
    setShowModal(false);
  };

  const getFoodData = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.foodMenuShow(foodMenuId),
        "GET",
        API_HEADERS.authenticated
      );

      setName(response.menu.name);
      setAgeGroup(response.menu.age_group.name);
      setSeason(response.menu.age_group.season_translated);
      setAge(response.menu.age_group.age_translated);
      setFile(response.menu.path);
      setLink(response.menu.file_url);

      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      onClose();
    }
  };

  useEffect(() => {
    getFoodData();
  }, []);

  const viewFoodMenuDocument = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        link,
        "GET",
        API_HEADERS.blob,
        {},
        "blob"
      );

      const blob = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      window.open(url, "_blank");
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      onClose();
    }
  };

  return (
    <Modal
      isOpen={onShowModal && !loading}
      toggle={() => onClose()}
      size="md"
      centered
    >
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {name}
      </ModalHeader>
      <ModalBody>
        <div className="modal-note">
          {t("meal_view_food_menu_desc") + " " + name}
        </div>
        <div className="modal-info-block">
          <div className="row">
            <div className="info col-12 col-sm-6">
              <span className="label">{t("meal_view_food_menu_name")}</span>
              <span className="item">{name}</span>
            </div>
            <div className="info col-12 col-sm-6">
              <span className="label">
                {t("meal_view_food_menu_age_group")}
              </span>
              <span className="item">{ageGroup}</span>
            </div>
            <div className="info col-12 col-sm-6">
              <span className="label">{t("meal_view_food_menu_season")}</span>
              <span className="item">{season}</span>
            </div>
            <div className="info col-12 col-sm-6">
              <span className="label">{t("meal_view_food_menu_age")}</span>
              <span className="item">{age}</span>
            </div>
            <div className="info col-12">
              <span className="label">{t("meal_view_food_menu_document")}</span>
              <span className="item">{file}</span>
            </div>
          </div>
          <div className="row">
            <div className="info col-12">
              <span className="label">{t("meal_view_food_menu_link")}</span>
              <span
                className="item link"
                onClick={() => viewFoodMenuDocument()}
              >
                {t("meal_view_food_menu_link_click_here")}
              </span>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className="button-white"
          type="button"
          onClick={() => onClose()}
        >
          {t("meal_view_food_menu_cancel_button")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ViewFoodMenu;
