import DatePicker from "react-datepicker";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { forwardRef } from "react";
import i18n from "src/i18n/i18n";
import { LanguageDate } from "src/helpers/CheckLanguageDate";

interface CalendarHeaderProps {
  setView: (view: "dayGridMonth" | "dayGridWeek") => void;
  setNavigate: (direction: "prev" | "next") => void;
  setDate: (date: Date) => void;
  view: "dayGridMonth" | "dayGridWeek";
  date: Date;
  totalAbsence: number | null;
}

interface DatePickerInputProps {
  value?: string;
  onClick?: () => void;
}

const DatePickerButton = forwardRef<HTMLButtonElement, DatePickerInputProps>(
  ({ value, onClick }, ref) => (
    <button
      className="datepicker-button button-unstyled"
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  )
);

const CalendarHeader: React.FC<CalendarHeaderProps> = ({
  setView,
  setNavigate,
  setDate,
  view,
  date,
  totalAbsence,
}) => {
  const { t } = useTranslation();

  const checkActive = (acitve: "dayGridMonth" | "dayGridWeek") => {
    if (acitve === view) {
      return "button-white";
    } else {
      return "button-transparent";
    }
  };

  const checkDate = (newDate: Date) => {
    const todaysDate = moment(newDate).format("DD, MMMM yyyy");
    const filteredDate = moment(date).format("DD, MMMM yyyy");
    if (todaysDate === filteredDate) {
      return " active";
    }

    return "";
  };

  const setTodayDate = () => {
    const todaysDate = moment(new Date()).format("DD, MMMM yyyy");
    const filteredDate = moment(date).format("DD, MMMM yyyy");
    if (todaysDate !== filteredDate) {
      setDate(new Date());
    }
  };

  const handleDate = (date: Date) => {
    if (date !== null) {
      setDate(date);
    } else {
      setDate(new Date());
    }
  };

  const formattedMonth = moment(date)
    .locale(i18n.language === "al" ? "sq" : "en")
    .format("MMMM");

  return (
    <>
      <div className="fullcalendar-header">
        <div className="header-navigate">
          <div className="toolbar">
            <div className="form-block">
              <DatePicker
                selected={date}
                onChange={handleDate}
                customInput={<DatePickerButton />}
                showMonthYearPicker
                dateFormat="MMM/yyyy"
                placeholderText={t("child_participation_date_placeholder")}
                className="form-datepicker"
                autoComplete="off"
                name="month_year"
                maxDate={new Date()}
                locale={LanguageDate.datepicker}
                required={true}
              />
            </div>
            <div className="navigate-buttons">
              <button
                className="button-transparent has-icon only"
                onClick={() => setNavigate("prev")}
              >
                <i className="icon left-arrow-icon" />
              </button>
              {date.getMonth() !== new Date().getMonth() && (
                <button
                  className="button-transparent has-icon only"
                  onClick={() => setNavigate("next")}
                >
                  <i className="icon right-arrow-icon" />
                </button>
              )}
            </div>
          </div>

          <div className="toolbar">
            <div className="grid-view">
              <button
                className={checkActive("dayGridMonth")}
                onClick={() => setView("dayGridMonth")}
              >
                {t("child_participation_month_view")}
              </button>
              {/* <button
                className={checkActive("dayGridWeek")}
                onClick={() => setView("dayGridWeek")}
              >
                {t("child_participation_week_view")}
              </button> */}
              <div className="today">
                <button
                  className={"button-transparent" + checkDate(new Date())}
                  onClick={() => setTodayDate()}
                  type="button"
                >
                  {t("child_participation_today_date")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="header-info">
          {t("child_participation_in_month_of")} {formattedMonth},{" "}
          {totalAbsence !== 1
            ? t("child_participation_there_are")
            : t("child_participation_there_is")}{" "}
          <span>{totalAbsence !== null ? totalAbsence : "-"}</span>{" "}
          {totalAbsence !== 1
            ? t("child_participation_absences")
            : t("child_participation_absence")}
        </div>
      </div>
    </>
  );
};

export default CalendarHeader;
