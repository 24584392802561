import React, { useState } from "react";
import Select, { SingleValue } from "react-select";
import { useTranslation } from "react-i18next";
import Map from "./Map";
import CreateKindergarten from "./CreateKindergarten";
import KindergartenGrid from "./KindergartenGrid";
import Kindergarten from "./KindergartenList";
import useSearchSubmit from "src/hooks/useSearchSubmit";

const KindergartenOverview: React.FC = () => {
  const { t } = useTranslation();

  // View
  const [view, setView] = useState<"list" | "grid" | "map">("list");

  const [modified, setModified] = useState(false);

  // Search
  const {
    searchInputs,
    searchBtnLoading,
    searched,
    page,
    setSearchBtnLoading,
    handleSubmit,
    setPage,
  } = useSearchSubmit();
  const [text, setText] = useState<string>("");
  const [specification, setSpecification] = useState<SingleValue<{ value: string, label: string }>>(null);
  const [filter, setFilter] = useState<SingleValue<{ value: "newest_first" | "oldest_first", label: string }> | null>({
    value: "newest_first",
    label: t("kindergarten_sort_by_newest_first"),
  });

  // Modal
  const [createKindergartenModal, setCreateKindergartenModal] = useState<boolean>(false);

  return (
    <>
      <div className="input-items">
        <form onSubmit={(e) =>
          handleSubmit(e, {
            ...(text && { text: text }),
            ...(specification && { specification: specification.value }),
          })
        } className="row">
          <div className="form-block col-12 col-sm-6 col-md-3">
            <label>{t("kindergarten_search_by_term")}</label>
            <input
              placeholder={t("kindergarten_search")}
              className="form-input"
              name="text"
              type="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <p className="filter-explain-text red mt-2 mb-3">
              {t("kindergarten_filter_explain")}
            </p>
          </div>
          <div className="form-block col-12 col-sm-6 col-md-3">
            <label>{t("kindergarten_choose_option")}</label>
            <Select
              placeholder={t("kindergarten_choose")}
              className="form-react-select"
              classNamePrefix="select_input"
              name="specification"
              onChange={(e) => setSpecification(e)}
              value={specification}
              isClearable={true}
              isSearchable={false}
              options={[
                { value: "address", label: t("nursery_address") },
                { value: "name", label: t("nursery_name") },
              ]}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-3 mb-1 mt-4">
            {searchBtnLoading ? (
              <button type="button" className="button-orange has-icon">
                <i className="icon spinner-icon" />
                {t("kindergarten_searching")}
              </button>
            ) : (
              <button
                type="submit"
                className="button-orange has-icon"
              >
                <i className="icon search-icon" />
                {t("kindergarten_search")}
              </button>
            )}
          </div>
          <div className="col-12 col-sm-6 col-md-3 d-flex align-items-baseline justify-content-end mt-4">
            <button
              type="button"
              className="button-blue has-icon"
              onClick={() => setCreateKindergartenModal(true)}
            >
              <i className="icon plus-icon" />
              {t("kindergarten_add_a_kindergarten")}
            </button>
            {createKindergartenModal && (
              <CreateKindergarten
                onShowModal={createKindergartenModal}
                setShowModal={setCreateKindergartenModal}
                setModified={(e) => setModified(e)}
              />
            )}
          </div>
        </form>
      </div>
      <div className="input-items">
        <div className="row">
          <div className="form-block col-12 col-sm-6 col-md-3 pb-4">
            {(view === "list" || view === "grid") && (
              <>
                <label>{t("kindergarten_filter")}</label>
                <Select
                  className={"select-sort"}
                  classNamePrefix="select_input"
                  value={filter}
                  options={[
                    {
                      value: "newest_first",
                      label: t("kindergarten_sort_by_newest_first"),
                    },
                    {
                      value: "oldest_first",
                      label: t("kindergarten_sort_by_oldest_first"),
                    },
                  ]}
                  onChange={(e) => setFilter(e)}
                />
              </>
            )}
          </div>
          <div
            className={`form-block col-12 col-sm-6 col-md-9 pb-4 organize-sort${view !== "map" ? " mt-4" : ""}`}
          >
            <div
              className={`div${view === "list" ? " selected" : ""}`}
              onClick={() => setView("list")}
            >
              {t("kindergarten_list")} <i className="icon list-icon" />
            </div>

            <div
              className={`div${view === "grid" ? " selected" : ""}`}
              onClick={() => setView("grid")}
            >
              {t("kindergarten_grid")} <i className="icon grid-icon" />
            </div>

            <div
              className={`div${view === "map" ? " selected" : ""}`}
              onClick={() => setView("map")}
            >
              {t("kindergarten_map")} <i className="icon map-icon" />
            </div>
          </div>
        </div>
      </div>
      {view === "list" && (
        <Kindergarten
          searchInputs={searchInputs}
          modified={modified}
          filter={filter?.value}
          searched={searched}
          page={page}
          setPage={(e) => setPage(e)}
          setSearchBtnLoading={(e) => setSearchBtnLoading(e)}
          setModified={(e) => setModified(e)}
        />
      )}
      {view === "grid" && (
        <KindergartenGrid
          searchInputs={searchInputs}
          modified={modified}
          filter={filter?.value}
          searched={searched}
          page={page}
          setPage={(e) => setPage(e)}
          setSearchBtnLoading={(e) => setSearchBtnLoading(e)}
          setModified={(e) => setModified(e)}
        />
      )}
      {view === "map" && (
        <Map
          searchInputs={searchInputs}
          modified={modified}
          setSearchBtnLoading={(e) => setSearchBtnLoading(e)}
          setModified={(e) => setModified(e)}
        />
      )}
    </>
  );
};
export default KindergartenOverview;
