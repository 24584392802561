import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Sidebar from "src/components/layouts/sidebar/Sidebar";
import Breadcrumb from "src/components/layouts/Common/Breadcrumb";
import Album from "src/components/panel/gallery/Album";

const AlbumView: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
     
      <Helmet>
        <title>{t("album_title_view")}</title>
      </Helmet>
      <Sidebar>
        <Breadcrumb title={t("album_title")} 
        // subtitle={t("gallery_subtitle")}
        />
        <Album />
      </Sidebar>
    </>
  );
};

export default AlbumView;
