import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Sidebar from "src/components/layouts/sidebar/Sidebar";
import Breadcrumb from "src/components/layouts/Common/Breadcrumb";
import Backup from "src/components/panel/backup/Backup";


const BackupView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("backup_list_view")}</title>
      </Helmet>
      <Sidebar>
        <Breadcrumb title={t("backup_list_title")} 
        subtitle={t("backup_list_subtitle")}/>
        <Backup/>
      </Sidebar>
    </>
  );
};

export default BackupView;
