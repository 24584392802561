import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Breadcrumb from "src/components/layouts/Common/Breadcrumb";
import Sidebar from "src/components/layouts/sidebar/Sidebar";
import Performance from "src/components/panel/performance/Performance";
import { accessData } from "src/helpers/AppConfig";
import { RoleCheck } from "src/helpers/RoleCheck";

const PerformanceView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("performance_tab")}</title>
      </Helmet>
      <Sidebar>
        <Breadcrumb
          title={t("performance_title")}
          subtitle={
            RoleCheck("2")
              ? t("teacher_performance_subtitle")
              : t("performance_subtitle")
          }
        />
        <Performance />
      </Sidebar>
    </>
  );
};

export default PerformanceView;
