import { useState, FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import ValidationMessage from "src/helpers/ValidationMessage";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

interface EditEvaluationProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  setModified: (visible: boolean) => void;
  evaluationId: number;
  childName: string;
}

const EditEvaluation: React.FC<EditEvaluationProps> = ({
  onShowModal,
  setShowModal,
  setModified,
  evaluationId,
  childName,
}) => {
  const { t } = useTranslation();

  const [evaluation, setEvaluation] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const [loadingButton, setLoadingButton] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const onClose = () => {
    setShowModal(false);
  };

  const fetchEvaluation = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.childGetEvaluation(evaluationId),
        "GET",
        API_HEADERS.authenticated
      );

      setEvaluation(response.evaluation.evaluation);

      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      onClose();
    }
  };

  useEffect(() => {
    fetchEvaluation();
  }, []);

  const editEvaluation = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setLoadingButton(true);

    let formData = {
      evaluation: evaluation,
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.childUpdateEvaluation(evaluationId),
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);

      setModified(true);

      onClose();
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        onClose();
      }
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <Modal
      isOpen={onShowModal && !loading}
      toggle={() => onClose()}
      size="md"
      centered
    >
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("evaluations_edit_modal_title")}
      </ModalHeader>
      <form onSubmit={(e) => editEvaluation(e)}>
        <ModalBody>
          <div className="modal-note">
            {t("evaluations_edit_modal_desc") + " " + childName}
          </div>
          <div className="row">
            <div className="form-block col-12">
              <label className="required">
                {t("evaluations_edit_modal_evaluation_label")}
              </label>
              <textarea
                className={
                  "form-input" + (validations.evaluation ? " input-error" : "")
                }
                placeholder={t("evaluations_edit_modal_evaluation_placeholder")}
                onChange={(e) => setEvaluation(e.target.value)}
                name="evaluation"
                rows={3}
                value={evaluation}
              />
              {validations.evaluation && (
                <ValidationMessage message={validations.evaluation[0]} />
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("evaluations_edit_modal_close_button")}
          </button>

          {loadingButton ? (
            <button type="button" className="button-blue has-icon">
              <i className="spinner-icon" />
              {t("evaluations_edit_modal_adding_button")}
            </button>
          ) : (
            <button type="submit" className="button-blue has-icon">
              <i className="icon add-icon" />
              {t("evaluations_edit_modal_add_button")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditEvaluation;
