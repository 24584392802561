import React, {  useState } from "react";
import Applications from "./Applications";
import Transfers from "./Transfers";

const Reports: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <div className="reports">
      <Applications setLoading={setLoading} />
      {!loading && <Transfers/>}
    </div>
  );
};

export default Reports;
