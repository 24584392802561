import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationAl from "./al/translation.json";
import translationEn from "./en/translation.json";

// The translations for the English and Albanian languages
const resources = {
  al: {
    translation: translationAl,
  },
  en: {
    translation: translationEn,
  },
};

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const currentLanguage = localStorage.getItem("i18nextLng") || "en";

i18n.use(initReactI18next).init({
  returnNull: false,
  resources,
  lng: currentLanguage,
  fallbackLng: "en",

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
