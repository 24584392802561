import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

interface ShowStaffProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  setModalVisible: (visible: boolean) => void;
  staffId: number;
}

const ShowStaff: React.FC<ShowStaffProps> = ({
  onShowModal,
  setShowModal,
  setModalVisible,
  staffId,
}) => {
  const { t } = useTranslation("");

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [role, setRole] = useState("");
  const [institutions, setInstitutions] = useState<Record<string, any>[]>([]);

  const [loading, setLoading] = useState<boolean>(true);

  const showStaff = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.showStaff(staffId),
        "GET",
        API_HEADERS.authenticated
      );
      setLoading(false);
      setFirstName(response.staff_member?.first_name);
      setLastName(response.staff_member?.last_name);
      setAddress(response.staff_member?.address);
      setRole(response.staff_member?.role);
      setPhone(response.staff_member?.phone);
      setInstitutions(response.staff_member?.kindergarten);
      setEmail(response.staff_member?.email);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      onClose();
    }
  };

  useEffect(() => {
    showStaff();
  }, []);

  const onClose = () => {
    setModalVisible(false);
    setShowModal(false);
  };

  return (
    <Modal
      isOpen={onShowModal && !loading}
      toggle={() => onClose()}
      size="md"
      centered
    >
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("show_staff_title")}
      </ModalHeader>
      <ModalBody>
        <div className="modal-note">{t("show_staff_details")}</div>
        <div className="row border-bottom modal-requests">
          <div className="col-12 col-md-4 modal-sections mb-3">
            <span className="header-text">{t("show_staff_name_lastname")}</span>
            <span className="bold-text">
              {(firstName ? firstName : "-") +
                " " +
                (lastName ? lastName : "-")}
            </span>
          </div>
          <div className="col-12 col-md-4 modal-sections mb-3">
            <span className="header-text">{t("show_staff_email")}</span>
            <span className="bold-text">{email ? email : "-"}</span>
          </div>
          <div className="col-12 col-md-4 modal-sections mb-3">
            <span className="header-text">{t("show_staff_mobile")}</span>
            <span className="bold-text">{phone ? phone : "-"}</span>
          </div>
        </div>
        <div className="row mt-3 modal-requests border-bottom">
          <div className="col-12 modal-sections mb-3">
            <span className="header-text">{t("show_staff_address")}</span>
            <span className="bold-text">{address ? address : "-"}</span>
          </div>
        </div>
        <div className="row mt-3 modal-requests">
          <div className="col-12 col-md-4 modal-sections mb-3">
            <span className="header-text">{t("show_staff_role")}</span>
            <span className="bold-text">{role ? role : "-"}</span>
          </div>
          <div className="col-12 col-md-8 modal-sections">
            <span className="header-text">{t("show_staff_institution")}</span>
            <span className="bold-text">
              {institutions.length > 0
                ? institutions.map((kindergarten: any, index: number) => (
                    <span key={index}>
                      {kindergarten.name +
                        (index === institutions.length - 1 ? "" : ", ")}
                    </span>
                  ))
                : "-"}
            </span>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className="button-white"
          type="button"
          onClick={() => onClose()}
        >
          {t("show_staff_close")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ShowStaff;
