import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";
import ValidationMessage from "src/helpers/ValidationMessage";
import CustomPagination from "src/helpers/CustomPaginate";
import * as AppConfig from "../../../helpers/AppConfig";
import { RoleCheck } from "src/helpers/RoleCheck";

import API_PATHS from "src/api/apiPaths";
import PATHS from "src/routes/Paths";
import API_HEADERS from "src/api/apiConfig";
import SearchFilter from "src/helpers/SearchFilter";
import ListResponse from "src/helpers/ListResponse";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

import Loading from "src/helpers/Loading";

const ShowRegistrationRequest: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const param = useParams();

  const [childName, setChildName] = useState("");
  const [childLastName, setChildLastName] = useState("");
  const [parentName, setParentName] = useState("");
  const [email, setEmail] = useState("");
  const [kindergarten, setKindergarten] = useState("");
  const [phone, setPhone] = useState("");
  const [directorName, setDirectorName] = useState("");
  const [directorLastName, setDirectorLastName] = useState("");
  const [reason, setReason] = useState("");
  const [status, setStatus] = useState("");

  const [approvedByDirector, setApprovedByDirector] = useState("1");
  const [approvedByAdmin, setApprovedByAdmin] = useState("1");
  const [checkApprovedDirector, setCheckApprovedDirector] = useState("");
  const [unregisterDate, setUnregisterDate] = useState("");
  const [childrenCategoriesDocuments, setChildrenCategoriesDocuments] =
    useState<Record<string, any>[]>([]);
  const [childrenCategories, setChildrenCategories] = useState("");

  const [loading, setLoading] = useState(true);
  const [modified, setModified] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [paginate, setPaginate] = useState<any>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const getCurrentDate = (): string => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");

    return `${yyyy}-${mm}-${dd}`;
  };

  const getShowUnregistration = async (): Promise<void> => {
    const unregistrationId = param.id;

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.showUnregistration(Number(unregistrationId)),
        "GET",
        API_HEADERS.authenticated
      );
      setLoading(false);

      setChildName(response.child?.first_name);
      setChildLastName(response.child?.last_name);
      setParentName(response.child?.fathers_name);

      setKindergarten(response.kindergarten?.name);
      setPhone(response.guardian?.phone);
      setDirectorName(response.nr_of_free_places_in_the_kindergarten);
      setDirectorLastName(response.nr_of_children_in_the_kindergarten);
      setReason(response.reason);
      setStatus(response.child?.status);
      setCheckApprovedDirector(response.approved_by_director);
      setChildrenCategories(response.children_category.title);
      setChildrenCategoriesDocuments(
        response.children_category.children_category_documents
      );
      setEmail(response.guardian?.email);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
    }
  };

  useEffect(() => {
    getShowUnregistration();
  }, []);

  const approveUnregister = async (): Promise<void> => {
    const unregistrationId = param.id;

    const formData = new FormData();
    if (RoleCheck("3")) {
      formData.append("approved_by_admin", approvedByAdmin);
    } else {
      formData.append("approved_by_director", approvedByDirector);
    }

    formData.append("unregister_date", getCurrentDate());

    try {
      setBtnLoading(true);

      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.approveUnregistration(Number(unregistrationId)),
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);
      navigate(PATHS.unregistration);
      setModified(true);
    } catch (error: any) {
      if (error.response && error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
      }
      setBtnLoading(false);
    }
  };

  return (
    <>
      <div className="white">
        <div>
          <p className="text-blue">{t("show_unregistration_important_info")}</p>
        </div>
        <div className="profile-info-application unresgistration-responsive d-flex border-bottom mb-5">
          <div className="profile-initials">
            <div className="avatar-violet size-xl">
              <span className="circle">
                {`${childName ? childName.charAt(0) : ""}${
                  childLastName ? childLastName.charAt(0) : ""
                }`}
              </span>
            </div>
          </div>
          <div className="profile-data">
            <div className="d-flex flex-direction-column-app">
              <div className="info">
                <div className="mb-1">
                  <span className="label">
                    <i className="icon children-name-icon" />
                    {t("show_unregistration_child_name")}
                  </span>
                  <span className="text mx-1">
                    {(childName ? childName : "-") +
                      " " +
                      (childLastName ? childLastName : "-")}
                  </span>
                </div>
                <div className="mb-5">
                  <i className="icon person-icon" />
                  <span className="label">
                    {t("show_unregistration_parent_name")}
                  </span>
                  <span className="text mx-1">
                    {(parentName ? parentName : "-") +
                      " " +
                      (childLastName ? childLastName : "-")}
                  </span>
                </div>
              </div>
              <div className="info">
                <div className="mb-1">
                  <i className="icon telephone-icon" />

                  <span className="label">
                    {t("show_unregistration_phone")}
                  </span>
                  <span className="text mx-1">{phone ? phone : "-"}</span>
                </div>
                <div className="mb-5">
                  <i className="icon email-icon" />
                  <span className="label">
                    {t("show_unregistration_email")}
                  </span>
                  <span className="email mx-1">{email ? email : "-"}</span>
                </div>
              </div>
              <div className="info">
                <div className="mb-1">
                  <i className="icon telephone-icon" />

                  <span className="label">
                    {t("show_unegistration_kindergarten")}
                  </span>
                  <span className="text mx-1">
                    {kindergarten ? kindergarten : "-"}
                  </span>
                </div>
                <div className="mb-5">
                  <i className="icon email-icon" />
                  <span className="label">
                    {t("show_unregistration_director")}
                  </span>
                  <span className="text mx-1">
                    {(parentName ? parentName : "-") +
                      " " +
                      (childLastName ? childLastName : "-")}{" "}
                  </span>
                </div>
              </div>
              <div className="info category">
                <div className="mb-0">
                  <i className="icon category-icon" />
                  <span className="label">
                    {t("show_unregistration_category")}
                  </span>
                  <span className="text sub-category mb-2">
                    {childrenCategories
                      ? childrenCategories
                      : t("show_application_no_category_selected")}
                  </span>
                  <div className="border-bottom"></div>
                </div>
                {childrenCategories ? (
                  <div className="mt-3">
                    {childrenCategoriesDocuments.map((category, index) => (
                      <p className="text-categories">
                        <i className="icon dot-icon" />
                        {category.title ? category.title : "-"}
                      </p>
                    ))}
                  </div>
                ) : (
                  <div className="mt-3">{" "}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className="text-blue">{t("show_unregistration_reason")}</p>
        </div>
        <div className="title-text mb-3">
          <i className="icon person-icon me-2" />
          <span className="me-2">{t("show_unregistration_parent_name")}:</span>
          <span className="text-bold">
            {" "}
            {(parentName ? parentName : "-") +
              " " +
              (childLastName ? childLastName : "-")}
          </span>
        </div>
        <div>
          <p className="text-description mb-5">{reason ? reason : "-"}</p>
        </div>
        {RoleCheck("3") && status === "active" ? (
          <div className="d-flex align-items-baseline justify-content-end">
            {btnLoading ? (
              <button type="button" className="button-red has-icon mb-1">
                <i className="icon spinner-icon" />
                {t("show_unregistration_unregistering")}
              </button>
            ) : (
              <button
                type="submit"
                className="button-red has-icon mb-1"
                onClick={() => approveUnregister()}
              >
                <i className="icon close-icon" />
                {t("show_unregistration_unregister")}
              </button>
            )}
          </div>
        ) : RoleCheck("4") && checkApprovedDirector == "0" ? (
          <>
            {/* <div className="title-text mb-2">
              <span className="me-2">{t("show_unregistration_contact")}:</span>
            </div> */}
            <div className="d-flex align-items-baseline">
              {/* <button type="button" className="button-blue has-icon mb-1 me-2">
                <i className="icon email-icon" />
                {t("show_unregistration_email")}
              </button>
              <button type="button" className="button-white has-icon mb-1 me-2">
                <i className="icon message-icon" />
                {t("show_unregistration_chat")}
              </button> */}

              <div className="button-position">
                {btnLoading ? (
                  <button type="button" className="button-green has-icon mb-1">
                    <i className="icon spinner-icon" />
                    {t("show_unregistration_approving")}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="button-green has-icon mb-1"
                    onClick={() => approveUnregister()}
                  >
                    <i className="icon tick-icon" />
                    {t("show_unregistration_approve")}
                  </button>
                )}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default ShowRegistrationRequest;
