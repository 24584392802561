export const evaluations = [
  { value: 1, text: "1 star", icon: generateStars(1) },
  { value: 2, text: "2 stars", icon: generateStars(2) },
  { value: 3, text: "3 stars", icon: generateStars(3) },
  { value: 4, text: "4 stars", icon: generateStars(4) },
  { value: 5, text: "5 stars", icon: generateStars(5) },
];

function generateStars(num: number) {
  const stars = Array.from({ length: 5 }, (_, index) => (
    <i
      key={index}
      className={`icon evaluation-icon ${index < num ? "green" : ""}`}
    />
  ));
  return <div className="evaluations-stars">{stars}</div>;
}
