import React, { useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { makeApiCall } from "src/api/apiRequests";
import API_PATHS from "src/api/apiPaths";
import SearchFilter from "src/helpers/SearchFilter";
import API_HEADERS from "src/api/apiConfig";
import CustomPagination from "src/helpers/CustomPaginate";
import ListResponse from "src/helpers/ListResponse";
import Loading from "src/helpers/Loading";
import PATHS from "src/routes/Paths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import useSearchSubmit from "src/hooks/useSearchSubmit";

const ApplicationsKindergarten: React.FC = () => {
  const { t } = useTranslation("");
  const navigate = useNavigate();

  // Applications
  const [applications, setApplications] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // Search
  const {
    searchInputs,
    searchBtnLoading,
    searched,
    setSearchBtnLoading,
    handleSubmit,
    setPage,
  } = useSearchSubmit();
  const [kindergartens, setKindergartens] = useState<Record<string, any>[]>([]);
  const [statusId, setStatusId] = useState<SingleValue<{ value: string; label: string }>>(null);
  const [referenceNumber, setReferenceNumber] = useState<string>("");
  const [kindergartenId, setKindergartenId] = useState<SingleValue<{ value: string; label: string }>>(null);


  const getApplications = async (): Promise<void> => {
    const searchParams: any = searchInputs;
    let request: any = API_PATHS.listApplications;
    searchParams.type = "kopesht";

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      setApplications(response.applications);
      setLoading(false);
      kindergartens.length === 0 && getKindergartens();
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    } finally {
      setSearchBtnLoading(false)
    }
  };

  useEffect(() => {
    getApplications();
  }, [searchInputs]);

  const getKindergartens = async (): Promise<void> => {
    let request: any = API_PATHS.kindergartensDropdownList;
    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setKindergartens(response.kindergartens);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  return (
    <>
      <>
        <div className="input-items">
          <form onSubmit={(e) =>
            handleSubmit(e, {
              ...(kindergartenId && { kindergarten_id: kindergartenId.value }),
              ...(referenceNumber && { reference_number: referenceNumber }),
              ...(statusId && { status_id: statusId.value }),
            })
          } className="row">
            <div className="form-block col-12 col-sm-6 col-md-3">
              <label>{t("application_institution")}</label>
              <Select
                placeholder={t("application_choose_institution")}
                className="form-react-select"
                classNamePrefix="select_input"
                name="kindergarten"
                onChange={(e) => setKindergartenId(e)}
                isClearable
                isSearchable
                value={kindergartenId}
                options={kindergartens.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </div>
            <div className="form-block col-12 col-sm-6 col-md-3">
              <label>{t("applications_reference_number")}</label>
              <input
                placeholder={t("applications_reference_number_search_by")}
                className="form-input"
                name="text"
                type="text"
                value={referenceNumber}
                onChange={(e) => setReferenceNumber(e.target.value)}
              />
            </div>
            <div className="form-block col-12 col-sm-6 col-md-3">
              <label>{t("application_status")}</label>
              <Select
                placeholder={t("application_choose_status")}
                className={"form-react-select"}
                classNamePrefix="select_input"
                name="specification"
                onChange={(e) => setStatusId(e)}
                isClearable
                isSearchable={false}
                options={[
                  { value: "1", label: t("not_reviewed") },
                  {
                    value: "2",
                    label: t("approved_in_the_same_kindergarten"),
                  },
                  {
                    value: "3",
                    label: t("approved_in_another_kindergarten"),
                  },
                  { value: "4", label: t("rejected") },
                  { value: "5", label: t("warrenty_uploaded") },
                  { value: "6", label: t("confirmed") },
                ]}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-3 mt-4 mb-4">
              {searchBtnLoading ? (
                <button type="button" className="button-orange has-icon">
                  <i className="icon spinner-icon" />
                  {t("application_searching")}
                </button>
              ) : (
                <button
                  type="submit"
                  className="button-orange has-icon"
                >
                  <i className="icon search-icon" />
                  {t("application_search")}
                </button>
              )}
            </div>
          </form>
          <p className="filter-explain-text mb-5">
            {t("application_filter_explain")}
          </p>
        </div>
        <div className="table-list mb-5">
          <table role="table" className="w-100 mb-4">
            <thead>
              <tr role="row">
                <th
                  style={{ width: "16%" }}
                  role="columnheader"
                  className="text-left"
                >
                  <i className="icon children-name-icon" />
                  {t("application_child")}
                </th>
                <th
                  style={{ width: "19%" }}
                  role="columnheader"
                  className="text-center"
                >
                  <i className="icon guardian-icon" />
                  {t("application_guardian")}
                </th>
                <th
                  style={{ width: "19%" }}
                  role="columnheader"
                  className="text-center"
                >
                  <i className="icon kindergarten-table-icon" />
                  {t("application_nursery_kindergarten")}
                </th>
                <th
                  style={{ width: "19%" }}
                  role="columnheader"
                  className="text-center"
                >
                  <i className="icon status-icon" />
                  {t("application_status")}
                </th>
                <th
                  style={{ width: "19%" }}
                  role="columnheader"
                  className="text-center"
                >
                  <i className="icon actions-icon" />
                  {t("application_actions")}
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td className="text-center" colSpan={6}>
                    <Loading />
                  </td>
                </tr>
              ) : (
                applications.data.map((application: any) => (
                  <tr>
                    <td
                      role="cell"
                      className="text-left bold-text"
                      data-label={t("application_child")}
                    >
                      {(application.child.first_name ? application.child.first_name : "-") +
                        " " +
                        (application.child.last_name ? application.child.last_name : "-")}
                    </td>
                    <td
                      role="cell"
                      className="text-center bold-text"
                      data-label={t("application_guardian")}
                    >
                      {(application.child.guardian.first_name ? application.child.guardian.first_name : "-") +
                        " " +
                        (application.child.guardian.last_name ? application.child.guardian.last_name : "-")}
                    </td>
                    <td
                      role="cell"
                      className="text-center"
                      data-label={t("application_nursery_kindergarten")}
                    >
                      {application.kindergarten.name ? application.kindergarten.name : "-"}
                    </td>
                    <td
                      role="cell"
                      className="text-center"
                      data-label={t("application_status")}
                    >
                      {application.status_id === 1 ? (
                        <span className="transfer-text">
                          <i className="icon dot-icon my-1 me-2" />
                          {t("not_reviewed")}
                        </span>
                      ) : application.status_id === 2 ? (
                        <span className="transfer-text">
                          <i className="icon tick-icon my-0 me-2" />
                          {t("approved_in_the_same_kindergarten")}
                        </span>
                      ) : application.status_id === 3 ? (
                        <span className="transfer-text">
                          <i className="icon tick-icon my-0 me-2" />
                          {t("approved_in_another_kindergarten")}
                        </span>
                      ) : application.status_id === 4 ? (
                        <span className="transfer-text">
                          <i className="icon close-icon my-0 me-2" />
                          {t("rejected")}
                        </span>
                      ) : application.status_id === 5 ? (
                        <span className="transfer-text">
                          <i className="icon dot-icon my-0 me-2" />
                          {t("warrenty_uploaded")}
                        </span>
                      ) : (
                        <span className="transfer-text">
                          <i className="icon tick-icon my-0 me-2" />
                          {t("confirmed")}
                        </span>
                      )}
                    </td>
                    <td
                      role="cell"
                      className="text-center"
                      data-label={t("application_actions")}
                    >
                      <div className="actions">
                        <button
                          type="button"
                          className="button-unstyled-icon"
                          onClick={() =>
                            navigate(PATHS.showApplicationKindergarten + application.id)
                          }
                        >
                          <i className="icon show-icon" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <ListResponse
            totalItems={applications?.data?.length}
            loading={loading}
            searched={searched}
          />
          <CustomPagination
            data={applications}
            setActivePage={(e) => setPage(e)}
          />
        </div>
      </>
    </>
  );
};

export default ApplicationsKindergarten;
