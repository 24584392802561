import React from "react";

interface EvaluationStarsProps {
  evaluationNr: number;
}

const EvaluationStars: React.FC<EvaluationStarsProps> = ({ evaluationNr }) => {
  if (evaluationNr) {
    const starElements = [];

    for (let i = 0; i < 5; i++) {
      const starColorClass = i < evaluationNr ? "green" : "";
      starElements.push(
        <i key={i} className={`icon evaluation-icon ${starColorClass}`} />
      );
    }

    return <div>{starElements}</div>;
  } else {
    return null;
  }
};

export default EvaluationStars;
