import { useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import ValidationMessage from "src/helpers/ValidationMessage";

interface EditNoteProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  foodNote: string;
  noteId: number;
  setModified: (visible: boolean) => void;
}

const EditNote: React.FC<EditNoteProps> = ({
  onShowModal,
  setShowModal,
  noteId,
  foodNote,
  setModified,
}) => {
  const { t } = useTranslation();

  const [note, setNote] = useState<string>(foodNote);
  const [loadingButton, setLoadingButton] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const onClose = () => {
    setShowModal(false);
  };

  const editNote = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setLoadingButton(true);

    let formData = {
      comment: note,
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.updateChildFoodNote(noteId),
        "POST",
        API_HEADERS.authenticated,
        formData
      );
      FLASH_MESSAGES.successMsg(response.success);
      setModified(true);
      setLoadingButton(false);
      onClose();
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        onClose();
      }
      setLoadingButton(false);
    }
  };

  return (
    <Modal isOpen={onShowModal} toggle={() => onClose()} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("child_food_note_edit_modal_title")}
      </ModalHeader>
      <form onSubmit={(e) => editNote(e)}>
        <ModalBody>
          <div className="modal-note">
            {t("child_food_note_edit_modal_desc")}
          </div>
          <div className="form-block">
            <label className="required">
              {t("child_food_note_edit_modal_comment_label")}
            </label>
            <textarea
              name="comment"
              rows={3}
              placeholder={t("child_food_note_edit_modal_comment_placeholder")}
              defaultValue={note}
              className={
                "form-input" + (validations.comment ? " input-error" : "")
              }
              autoComplete="off"
              onChange={(e) => setNote(e.target.value)}
            />
            {validations.comment && (
              <ValidationMessage message={validations.comment[0]} />
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("child_food_note_edit_modal_close_button")}
          </button>

          {loadingButton ? (
            <button type="button" className="button-blue has-icon">
              <i className="spinner-icon" />
              {t("child_food_note_edit_modal_saving_button")}
            </button>
          ) : (
            <button type="submit" className="button-blue has-icon">
              <i className="icon edit-icon" />
              {t("child_food_note_edit_modal_save_button")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditNote;
