import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { SingleValue } from "react-select";
import { makeApiCall } from "src/api/apiRequests";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import ReactChart from "src/components/common/ReactChart";
import moment from "moment";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import SearchFilter from "src/helpers/SearchFilter";
import Loading from "src/helpers/Loading";
import ExportApplications from "./ExportApplications";
import { LanguageDate } from "src/helpers/CheckLanguageDate";

interface ApplicationsProps {
  setLoading: (state: boolean) => void;
}

const Applications: React.FC<ApplicationsProps> = ({ setLoading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Search
  const [dateStart, setDateStart] = useState<Date | null>(null);
  const [dateEnd, setDateEnd] = useState<Date | null>(null);
  const [kindergartenId, setKindergartenId] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [statusId, setStatusId] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [childrenCategory, setChildrenCategory] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [type, setType] =
    useState<SingleValue<{ value: string; label: string }>>(null);

  // Applications data
  const [applicationsData, setApplicationsData] = useState<any[]>([]);
  const [applicationFiltersData, setApplicationFiltersData] = useState<any>({});
  const [applicationLoading, setApplicationLoading] = useState<boolean>(true);
  const [searchBtnLoading, setSearchBtnLoading] = useState<boolean>(false);

  // Export modal
  const [exportModal, setExportModal] = useState<boolean>(false);

  const fetchReport = async (e?: any): Promise<void> => {
    let searchParams: any = {};

    if (e) {
      e.preventDefault();
      setSearchBtnLoading(true);

      dateStart &&
        (searchParams["date_start"] = moment(dateStart).format("yyy-MM-DD"));
      dateEnd &&
        (searchParams["date_end"] = moment(dateEnd).format("yyy-MM-DD"));
      kindergartenId &&
        (searchParams["kindergarten_id"] = kindergartenId.value);
      statusId && (searchParams["status_id"] = statusId.value);
      childrenCategory &&
        (searchParams["children_category"] = childrenCategory.value);
      type && (searchParams["type"] = type.value);
    }

    let request: any = SearchFilter(
      searchParams,
      API_PATHS.reportsApplicationsList
    );
    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setApplicationsData(response.application);
      setApplicationFiltersData(response.filters);
      setApplicationLoading(false);
      setLoading(false);
    } catch (error: any) {
      if (error.response.status === 404) {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        navigate(-1);
      }
    } finally {
      setSearchBtnLoading(false);
    }
  };

  useEffect(() => {
    fetchReport();
  }, []);

  const fillOptions = (filter: string, label: string) => {
    const options: any[] = [];

    applicationFiltersData[filter] &&
      applicationFiltersData[filter].forEach((option: any) => {
        option[label] !== "other" &&
          options.push({
            value: option.id,
            label: option[label],
          });
      });
    return options;
  };

  const fullYearData = (type: string) => {
    let chartData: any[] = [];
    applicationsData.forEach((application) => {
      chartData.push(
        type === "data"
          ? {
              x: `${application.month}/${application.year}`,
              y: application.nr_applications,
            }
          : `${application.month}/${application.year}`
      );
    });
    return chartData;
  };

  return (
    <>
      {applicationLoading ? (
        <div className="text-center">
          <Loading />
        </div>
      ) : (
        <div className="card">
          <div className="card-body">
            <div className="card-title">{t("reports_application_title")}</div>
            <form
              onSubmit={(e) => fetchReport(e)}
              className="row reports-search"
            >
              <div className="form-block">
                <label>{t("reports_application_date_start_label")}</label>
                <DatePicker
                  selected={dateStart}
                  onChange={(e) => setDateStart(e)}
                  showMonthDropdown
                  showYearDropdown
                  dateFormat="dd-MM-yyyy"
                  placeholderText={t(
                    "reports_application_date_start_placeholder"
                  )}
                  className="form-datepicker"
                  autoComplete="off"
                  name="date_start"
                  maxDate={new Date()}
                  locale={LanguageDate.datepicker}
                />
              </div>
              <div className="form-block">
                <label>{t("reports_application_date_end_label")}</label>
                <DatePicker
                  selected={dateEnd}
                  onChange={(e) => setDateEnd(e)}
                  showMonthDropdown
                  showYearDropdown
                  dateFormat="dd-MM-yyyy"
                  placeholderText={t(
                    "reports_application_date_end_placeholder"
                  )}
                  className="form-datepicker"
                  autoComplete="off"
                  name="date_end"
                  maxDate={new Date()}
                  locale={LanguageDate.datepicker}
                />
              </div>
              <div className="form-block">
                <label>{t("reports_application_kindergarten_label")}</label>
                <Select
                  placeholder={t(
                    "reports_application_kindergarten_placeholder"
                  )}
                  className="form-react-select"
                  classNamePrefix="select_input"
                  name="kindergarten_id"
                  onChange={(e) => setKindergartenId(e)}
                  isClearable={true}
                  isSearchable={false}
                  options={fillOptions("kindergartens", "name")}
                  value={kindergartenId}
                />
              </div>
              <div className="form-block">
                <label>{t("reports_application_status_label")}</label>
                <Select
                  placeholder={t("reports_application_status_placeholder")}
                  className="form-react-select"
                  classNamePrefix="select_input"
                  name="status_id"
                  onChange={(e) => setStatusId(e)}
                  isClearable={true}
                  isSearchable={false}
                  options={fillOptions("statuses", "name")}
                  value={statusId}
                />
              </div>
              <div className="form-block">
                <label>
                  {t("reports_application_children_category_label")}
                </label>
                <Select
                  placeholder={t(
                    "reports_application_children_category_placeholder"
                  )}
                  className="form-react-select"
                  classNamePrefix="select_input"
                  name="children_category"
                  onChange={(e) => setChildrenCategory(e)}
                  isClearable={true}
                  isSearchable={false}
                  options={fillOptions("children_category", "title")}
                  value={childrenCategory}
                />
              </div>
              <div className="form-block">
                <label>{t("reports_application_type_label")}</label>
                <Select
                  placeholder={t("reports_application_type_placeholder")}
                  className="form-react-select"
                  classNamePrefix="select_input"
                  name="type"
                  onChange={(e) => setType(e)}
                  isClearable={true}
                  isSearchable={false}
                  options={[
                    {
                      value: "cerdhe",
                      label: t("reports_application_type_nursery"),
                    },
                    {
                      value: "kopesht",
                      label: t("reports_application_type_kindergarten"),
                    },
                  ]}
                  value={type}
                />
              </div>
              <div className="d-flex">
                {searchBtnLoading ? (
                  <button
                    type="button"
                    className="button-orange has-icon my-auto"
                  >
                    <i className="icon spinner-icon" />
                    {t("reports_application_searching_button")}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="button-orange has-icon my-auto"
                  >
                    <i className="icon search-icon" />
                    {t("reports_application_search_button")}
                  </button>
                )}
                <button
                  type="button"
                  className="button-blue ms-4 has-icon my-auto"
                  onClick={() => setExportModal(true)}
                >
                  <i className="icon export-icon" />
                  {t("reports_application_export_type_button")}
                </button>
                {exportModal && (
                  <ExportApplications
                    filterData={{
                      dateStart: dateStart,
                      dateEnd: dateEnd,
                      kindergartenId: kindergartenId,
                      statusId: statusId,
                      childrenCategory: childrenCategory,
                      type: type,
                    }}
                    onShowModal={exportModal}
                    setShowModal={setExportModal}
                  />
                )}
              </div>
            </form>
            <div className="row">
              <ReactChart
                type="line"
                series={[
                  {
                    name: t("reports_application_chart_title"),
                    color: "#ffb020",
                    data: fullYearData("data"),
                  },
                ]}
                otherOptions={{
                  xaxis: {
                    type: "numeric",
                    categories: fullYearData("categories"),
                    labels: {
                      rotate: -45,
                    },
                    tickPlacement: "between",
                  },
                  yaxis: {
                    title: {
                      text: t("reports_application_chart_title"),
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Applications;
