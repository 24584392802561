import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import ListResponse from "src/helpers/ListResponse";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import Loading from "src/helpers/Loading";
import SearchFilter from "src/helpers/SearchFilter";
import CustomPagination from "src/helpers/CustomPaginate";

import * as ConfirmPopup from "../../../../../../helpers/ConfirmPopup";
import ViewEvaluation from "./ViewEvaluation";
import EditEvaluation from "./EditEvaluation";
import moment from "moment";
import CalendarHeader from "./CalendarHeader";
import AddNewEvaluation from "./AddNewEvaluation";
import { RoleCheck } from "src/helpers/RoleCheck";

const EvaluationList: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { childId } = useParams();

  // Search date
  const [selectedDate, setSelectedDate] = useState<any>(new Date());

  const [tab, setTab] = useState("teacher");

  const [evaluations, setEvaluations] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [activePage, setActivePage] = useState<number>(1);

  // Evaluiation modals
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [modalId, setModalId] = useState<number | null>(null);

  const [modified, setModified] = useState(false);

  const fetchEvaluations = async (): Promise<void> => {
    setModified(false);
    setLoading(true);
    const monthNumber = selectedDate.getMonth() + 1;
    const year = selectedDate.getFullYear();

    let searchParams: any = {
      month: monthNumber,
      year: year,
    };

    let request: any = API_PATHS.childEvaluationList(childId);

    if (activePage > 1) {
      searchParams["page"] = activePage;
    }

    if (RoleCheck("1")) {
      searchParams["role"] = tab;
    }

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setEvaluations(response.evaluations);
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvaluations();
  }, [modified, activePage, selectedDate, tab]);

  const deleteEvaluation = (evaluationId: number, guardianTitle: string) => {
    ConfirmPopup.deletePopup(
      t("child_evaluation_delete_title"),
      t("child_evaluation_delete_type"),
      guardianTitle
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.deleteChildEvaluation(evaluationId),
            "DELETE",
            API_HEADERS.authenticated
          );
          setModified(true);
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  const calendarNavigate = (direction: "prev" | "next") => {
    const currentYear = selectedDate.getFullYear();
    const currentMonth = selectedDate.getMonth();

    let newDate: Date;

    newDate = new Date(
      currentYear,
      direction === "prev" ? currentMonth - 1 : currentMonth + 1,
      1
    );

    setSelectedDate(newDate);
  };

  const checkActive = (acitve: "teacher" | "psychologist") => {
    if (acitve === tab) {
      return "button-white";
    } else {
      return "button-transparent";
    }
  };

  const truncateText = (text: string) => {
    const words = text.split(" ");

    let formatedText = text;
    if (words.length > 10) {
      formatedText = words.slice(0, 10).join(" ") + "...";
    }

    return formatedText;
  };

  return (
    <div className="child-evaluation">
      <CalendarHeader
        setNavigate={(e) => (loading ? "" : calendarNavigate(e))}
        setDate={(e) => (loading ? "" : setSelectedDate(e))}
        date={selectedDate}
      />
      {RoleCheck("2") ? (
        <div className="w-100 mb-3 d-flex justify-content-end">
          <button
            className="button-blue has-icon"
            onClick={() => setShowAddModal(true)}
          >
            <i className="icon add-icon" />
            {t("child_evaluation_add_evaluation_button")}
          </button>
          {showAddModal && (
            <AddNewEvaluation
              onShowModal={showAddModal}
              setShowModal={(e) => setShowAddModal(e)}
              setModified={(e) => setModified(e)}
            />
          )}
        </div>
      ) : (
        ""
      )}
      <div className="table-list mb-5">
        {RoleCheck("1") ? (
          <div className="tab-buttons">
            <button
              onClick={() => (!loading ? setTab("teacher") : "")}
              className={checkActive("teacher")}
            >
              Teacher
            </button>
            <button
              onClick={() => (!loading ? setTab("psychologist") : "")}
              className={checkActive("psychologist")}
            >
              Psychologist
            </button>
          </div>
        ) : (
          ""
        )}
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-left"
              >
                {t("child_evaluation_table_teacher")}
              </th>
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-left"
              >
                {t("child_evaluation_table_date_of_evaluation")}
              </th>
              <th
                style={{ width: "45%" }}
                role="columnheader"
                className="text-left"
              >
                {t("child_evaluation_table_comment")}
              </th>
              <th
                style={{ width: "15%" }}
                role="columnheader"
                className="text-center"
              >
                {t("child_evaluation_table_action")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={4}>
                  <Loading />
                </td>
              </tr>
            ) : (
              evaluations?.data?.map((evaluation: any) => (
                <tr key={evaluation.id}>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("child_evaluation_table_teacher")}
                  >
                    {evaluation.author.first_name +
                      " " +
                      evaluation.author.last_name}
                  </td>
                  <td
                    role="cell"
                    className="text-left"
                    data-label={t("child_evaluation_table_date_of_evaluation")}
                  >
                    {moment(evaluation.evaluation_date).format("DD-MM-YYYY")}
                  </td>
                  <td
                    role="cell"
                    className="text-left"
                    data-label={t("child_evaluation_table_comment")}
                  >
                    {evaluation.evaluation
                      ? truncateText(evaluation.evaluation)
                      : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("child_evaluation_table_action")}
                  >
                    <div className="actions">
                      {RoleCheck("2") ? (
                        <>
                          <button
                            className="button-unstyled-icon"
                            onClick={() => {
                              setShowEditModal(true);
                              setModalId(evaluation.id);
                            }}
                          >
                            <i className="icon edit-icon" />
                          </button>
                          {showEditModal && modalId === evaluation.id && (
                            <EditEvaluation
                              onShowModal={showEditModal}
                              setShowModal={(e) => {
                                setShowEditModal(e);
                                setModalId(null);
                              }}
                              evaluationId={evaluation.id}
                              setModified={(e) => setModified(e)}
                            />
                          )}
                        </>
                      ) : (
                        ""
                      )}

                      <button
                        className="button-unstyled-icon"
                        onClick={() => {
                          setShowViewModal(true);
                          setModalId(evaluation.id);
                        }}
                      >
                        <i className="icon show-icon" />
                      </button>
                      {showViewModal && modalId === evaluation.id && (
                        <ViewEvaluation
                          onShowModal={showViewModal}
                          setShowModal={(e) => {
                            setShowViewModal(e);
                            setModalId(null);
                          }}
                          evaluationId={evaluation.id}
                        />
                      )}
                      {RoleCheck("2") ? (
                        <button
                          className="button-unstyled-icon"
                          onClick={() =>
                            deleteEvaluation(
                              evaluation.id,
                              `${evaluation.author.first_name} ${evaluation.author.last_name}`
                            )
                          }
                        >
                          <i className="icon delete-icon" />
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={evaluations?.data?.length}
          loading={loading}
        />
      </div>
      <CustomPagination
        data={evaluations}
        setActivePage={(e) => setActivePage(e)}
      />
    </div>
  );
};

export default EvaluationList;
