import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeApiCall } from "src/api/apiRequests";
import { useNavigate } from "react-router-dom";
import { RoleCheck } from "src/helpers/RoleCheck";
import ReactChart from "src/components/common/ReactChart";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import Loading from "src/helpers/Loading";
import GenerateInvoice from "./GenerateInvoice";

const AdminDashboard: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [totalChildren, setTotalChildren] = useState<number>(0);
  const [childrenGender, setChildrenGender] = useState<any>({});
  const [totalKindergartens, setTotalKindergartens] = useState<number>(0);
  const [totalNurseries, setTotalNurseries] = useState<number>(0);
  const [totalChildrenAgeGroup, setTotalChildrenAgeGroup] = useState<any>({});
  const [totalMembers, setTotalMembers] = useState<any>({});

  const [dashboardLoading, setDashboardLoading] = useState<boolean>(true);

  // Invoices modal
  const [invoiceModal, setInvoiceModal] = useState<boolean>(false);

  const fetchReport = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.adminDashboard,
        "GET",
        API_HEADERS.authenticated
      );

      setTotalChildren(response.total_children);
      setChildrenGender(response.children_gender);
      setTotalKindergartens(response.total_kindergartens);
      setTotalNurseries(response.total_nurseries);
      setTotalChildrenAgeGroup(response.age_group_children);
      setTotalMembers(response.total_members_nr);
      setDashboardLoading(false);
    } catch (error: any) {
      if (error.response.status === 404) {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    RoleCheck("3") && fetchReport();
  }, []);

  return (
    <>
      {dashboardLoading ? (
        <div className="text-center">
          <Loading />
        </div>
      ) : (
        <>
          <div className="row">
            <div className="w-100 mb-3 d-flex justify-content-end">
              <button
                className="button-blue"
                onClick={() => setInvoiceModal(true)}
              >
                {t("dashboard_generate_invoices_button")}
              </button>
              {invoiceModal && (
                <GenerateInvoice
                  onShowModal={invoiceModal}
                  setShowModal={(e) => setInvoiceModal(e)}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-4">
              <div className="card second mb-4">
                <div className="card-body">
                  <div className="general-info">
                    <span className="gen-title">
                      {t("dashboard_total_number_of_children")}
                    </span>
                    <span className="info">{totalChildren}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-4">
              <div className="card second mb-4">
                <div className="card-body">
                  <div className="general-info">
                    <span className="gen-title">
                      {t("dashboard_total_number_of_nurseries")}
                    </span>
                    <span className="info">{totalNurseries}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-4">
              <div className="card second mb-4">
                <div className="card-body">
                  <div className="general-info">
                    <span className="gen-title">
                      {t("dashboard_total_number_of_kindergardens")}
                    </span>
                    <span className="info">{totalKindergartens}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="card second mb-4">
                <div className="card-body">
                  <div className="card-title">
                    {t("dashboard_children_gender_pie")}
                  </div>
                  <ReactChart
                    type="donut"
                    series={[childrenGender.boys, childrenGender.girls]}
                    otherOptions={{
                      legend: {
                        position: "bottom",
                      },
                      labels: [
                        t("dashboard_children_gender_boys"),
                        t("dashboard_children_gender_girls"),
                      ],
                      colors: ["#3d6de0", "#ab56d4"],
                      chart: {
                        height: 450,
                        id: "basic-bar",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-8">
              <div className="card second mb-4">
                <div className="card-body">
                  <div className="card-title">
                    {t("dashboard_children_age_goups_number")}
                    <ReactChart
                      type="bar"
                      series={[
                        {
                          name: "Total",
                          color: "#ffb020",
                          data: [
                            totalChildrenAgeGroup["6-8"],
                            totalChildrenAgeGroup["8-10"],
                            totalChildrenAgeGroup["10-12"],
                            totalChildrenAgeGroup["12-36"],
                            totalChildrenAgeGroup["36-72"],
                          ],
                        },
                      ]}
                      otherOptions={{
                        chart: {
                          id: "basic-bar",
                          zoom: {
                            enabled: false,
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        legend: {
                          show: false,
                        },
                        xaxis: {
                          categories: [
                            `6-8 ${t("dashboard_children_age_goup_months")}`,
                            `8-10 ${t("dashboard_children_age_goup_months")}`,
                            `10-12 ${t("dashboard_children_age_goup_months")}`,
                            `1-3 ${t("dashboard_children_age_goup_years")}`,
                            `3-6 ${t("dashboard_children_age_goup_years")}`,
                          ],
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card second mb-4">
            <div className="card-body">
              <div className="card-title">{t("dashboard_number_of_staff")}</div>
              <div className="row">
                <ReactChart
                  type="bar"
                  series={[
                    {
                      name: "Total",
                      color: "#3d6de0",
                      data: [
                        totalMembers.teacher ? totalMembers.teacher : 0,
                        totalMembers.director ? totalMembers.director : 0,
                        totalMembers.psychologist
                          ? totalMembers.psychologist
                          : 0,
                        totalMembers.hr ? totalMembers.hr : 0,
                        totalMembers.it ? totalMembers.it : 0,
                        totalMembers.cooker ? totalMembers.cooker : 0,
                        totalMembers.committeemember
                          ? totalMembers.committeemember
                          : 0,
                      ],
                    },
                  ]}
                  otherOptions={{
                    chart: {
                      id: "basic-bar",
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    legend: {
                      show: false,
                    },
                    xaxis: {
                      categories: [
                        t("dashboard_staff_teacher"),
                        t("dashboard_staff_director"),
                        t("dashboard_staff_psychologist"),
                        [
                          t("dashboard_staff_human"),
                          t("dashboard_staff_resourses"),
                        ],
                        t("dashboard_staff_it"),
                        t("dashboard_staff_cooker"),
                        [
                          t("dashboard_staff_committe"),
                          t("dashboard_staff_member"),
                        ],
                      ],
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AdminDashboard;
