import { useState, FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import Select from "react-select";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import ValidationMessage from "src/helpers/ValidationMessage";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

interface EditTransferPopupKindergartenProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  setModified: (visible: boolean) => void;
  transferId: number;
}

const EditTransferPopupKindergarten: React.FC<EditTransferPopupKindergartenProps> = ({
  onShowModal,
  setShowModal,
  setModified,
  transferId
}) => {
  const { t } = useTranslation("");

  const [childId, setChildId] = useState("");
  const [targetKindergartenId, setTargetKindergartenId] = useState("");
  const [transferReason, setTransferReason] = useState("");
  const [kindergartens, setKindergartens] = useState<Record<string, any>[]>([]);
  const [transferChildFirstName, setTransferChildFirstName] = useState("");
  const [transferChildFLastName, setTransferChildLastName] = useState("");
  const [transferNumber, setTransferNumber] = useState("");
  const [transferSource, setTransferSource] = useState("");
  const [transferTarget, setTransferTarget] = useState("");

  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const editTransfer = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setBtnLoading(true);

    const formData = new FormData();
    formData.append("child_id", childId);
    formData.append("target_kindergarten_id", targetKindergartenId);
    formData.append("transfer_reason", transferReason);

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.updateTransfer(Number(transferId)),
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);
      onClose();
      setModified(true);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
      }
    } finally {
      setBtnLoading(false);
    }
  };

  const getKindergartens = async (): Promise<void> => {
    let request: any = API_PATHS.kindergartensDropdownList;
    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setKindergartens(response.kindergartens);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      onClose();
    }
  };

  const onClose = () => {
    setShowModal(false);
  };

  const showTransfer = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.showTransfer(Number(transferId)),
        "GET",
        API_HEADERS.authenticated
      );
      setChildId(response.transfer.child.id);
      setTransferChildFirstName(response.transfer.child.first_name);
      setTransferChildLastName(response.transfer.child.last_name);
      setTransferNumber(response.transfer.transfer_number);
      setTransferSource(response.transfer.source_kindergarten.name);
      setTransferReason(response.transfer.transfer_reason);
      setTargetKindergartenId(response.transfer.target_kindergarten.id);
      setTransferTarget(response.transfer.target_kindergarten.name);
      setLoading(false);
      getKindergartens();
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      onClose();
    }
  };

  useEffect(() => {
    showTransfer();
  }, []);

  const handleChildSelection = (childId: string): void => {
    setChildId(childId);
  };

  return (
    <Modal isOpen={!loading && onShowModal} toggle={() => onClose()} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        <p className="application-formular-title">
          {t("edit_transfer_popup_form")}{" "}
          {transferNumber ? transferNumber : "_"}
        </p>
      </ModalHeader>
      <form onSubmit={(e) => editTransfer(e)}>
        <ModalBody>
          <p className="popup_title mb-4">
            {t("edit_transfer_popup_description")}
          </p>
          <div className="form-block">
            <div className="form-radio">
              <div className="radio-button">
                <input
                  className={validations.child_id ? "input-error" : ""}
                  id={`child_${childId ? childId : null}`}
                  name="pending"
                  type="radio"
                  value={`child_${childId ? childId : null}`}
                  onChange={() => handleChildSelection(childId)}
                  checked={childId !== ""}
                />
                <label htmlFor={`child_${childId}`}>
                  {(transferChildFirstName ? transferChildFirstName : "-") +
                    " " +
                    (transferChildFLastName ? transferChildFLastName : "-")}
                </label>
              </div>
            </div>
            {validations.child_id && (
              <ValidationMessage message={validations.child_id[0]} />
            )}
          </div>
          <div className="row">
            <div className="form-block col-12 col-md-6 col-sm-6">
              <label>{t("edit_transfer_popup_source")}</label>
              <input
                className="form-input"
                type="text"
                name="name"
                value={transferSource ? transferSource : "-"}
                readOnly
              />
            </div>

            <div className="form-block col-12 col-md-6 col-sm-6">
              <label className="required">
                {t("edit_transfer_popup_destination")}
              </label>
              <Select
                className={
                  "form-react-select" +
                  (validations.target_kindergarten_id ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="specification"
                onChange={(e) =>
                  e
                    ? (setTargetKindergartenId(e.value),
                      setTransferTarget(e.label))
                    : (setTargetKindergartenId(""), setTransferTarget(""))
                }
                value={{ value: targetKindergartenId, label: transferTarget }}
                isClearable={true}
                isSearchable={false}
                options={kindergartens
                  .filter((item: any) => item.type === "kopesht")
                  .map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
              />
              {validations.target_kindergarten_id && (
                <ValidationMessage
                  message={validations.target_kindergarten_id[0]}
                />
              )}
            </div>
          </div>
          <div className="form-block col-12">
            <label className="required">
              {t("edit_transfer_popup_reason")}
            </label>
            <textarea
              name="comment"
              className={
                "form-input" +
                (validations.transfer_reason ? " input-error" : "")
              }
              value={transferReason}
              placeholder={t("edit_transfer_popup_reason_of_application")}
              cols={50}
              rows={6}
              onChange={(e) => setTransferReason(e.target.value)}
            />
            {validations.transfer_reason && (
              <ValidationMessage message={validations.transfer_reason[0]} />
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("edit_transfer_popup_close")}
          </button>
          {btnLoading ? (
            <button className="button-blue has-icon" type="button">
              <i className="icon spinner-icon" />
              {t("edit_transfer_popup_transfering")}
            </button>
          ) : (
            <button className="button-blue has-icon" type="submit">
              <i className="icon transfer-arrows-icon" />
              {t("edit_transfer_popup_transfer")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditTransferPopupKindergarten;
