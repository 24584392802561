import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import Select, { SingleValue } from "react-select";
import { RoleCheck } from "src/helpers/RoleCheck";
import AddNewMenu from "./AddNewMenu";
import EditFoodMenu from "./EditFoodMenu";
import ViewFoodMenu from "./ViewFoodMenu";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import ListResponse from "src/helpers/ListResponse";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import Loading from "src/helpers/Loading";
import SearchFilter from "src/helpers/SearchFilter";
import CustomPagination from "src/helpers/CustomPaginate";
import useSearchSubmit from "src/hooks/useSearchSubmit";
import * as ConfirmPopup from "../../../../helpers/ConfirmPopup";

const Menu: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Food menu list
  const [foodMenus, setFoodMenus] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // Search
  const {
    searchInputs,
    searchBtnLoading,
    searched,
    page,
    setSearchBtnLoading,
    handleSubmit,
    setPage,
  } = useSearchSubmit();
  const [ageGroupsList, setFoodAgeGroupsList] = useState<any>([]);
  const [termSearch, setTermSearch] = useState<string>("");
  const [ageGroupSearch, setAgeGroupSearch] =
    useState<SingleValue<{ value: string; label: string }>>(null);

  // Modal
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showViewModal, setShowViewModal] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number | null>(null);

  const getFoodMenus = async (): Promise<void> => {
    var searchParams: any = searchInputs;
    let request: any = API_PATHS.foodMenuList;

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setFoodMenus(response.menus);
      setLoading(false);
      setSearchBtnLoading(false);
      page === 0 && !searched && getFoodAgeGroupData();
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  const getFoodAgeGroupData = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.foodAgeGroupsNames,
        "GET",
        API_HEADERS.authenticated
      );

      const ageGroupsArr = [];
      for (const [key, value] of Object.entries(response.foodAgeGroup)) {
        ageGroupsArr.push({
          label: value,
          value: key,
        });
      }

      setFoodAgeGroupsList(ageGroupsArr);
    } catch (error: any) {}
  };

  useEffect(() => {
    getFoodMenus();
  }, [searchInputs]);

  const deleteFoodMenu = (foodMenuId: number, foodMenuTitle: string) => {
    ConfirmPopup.deletePopup(
      t("meal_food_menu_delete_title"),
      t("meal_food_menu_delete_type"),
      foodMenuTitle
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.foodMenuDelete(foodMenuId),
            "DELETE",
            API_HEADERS.authenticated
          );
          if (foodMenus.data.length === 1 && page > 1) {
            setPage(page - 1);
          } else {
            getFoodMenus();
          }
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  return (
    <>
      <form
        onSubmit={(e) =>
          handleSubmit(e, {
            ...(termSearch && { term: termSearch }),
            ...(ageGroupSearch && { food_age_group_id: ageGroupSearch.value }),
          })
        }
        className="row"
      >
        <div className="form-block col-12 col-sm-6 col-md-3 col-xxl-2">
          <label>{t("meal_food_menu_term_label")}</label>
          <input
            name="term"
            placeholder={t("meal_food_menu_term_placeholder")}
            className="form-input"
            onChange={(e) => setTermSearch(e.target.value)}
            value={termSearch}
          />
        </div>
        <div className="form-block col-12 col-sm-6 col-md-3 col-xxl-2">
          <label>{t("meal_food_menu_age_group_label")}</label>
          <Select
            placeholder={t("meal_food_menu_age_group_placeholder")}
            className="form-react-select"
            classNamePrefix="select_input"
            name="day"
            onChange={(e) => setAgeGroupSearch(e)}
            isClearable={true}
            isSearchable={false}
            options={ageGroupsList}
            value={ageGroupSearch}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-3 col-xxl-2 mb-1">
          {searchBtnLoading ? (
            <button type="button" className="mt-4 button-orange has-icon">
              <i className="icon spinner-icon" />
              {t("meal_food_menu_searching_button")}
            </button>
          ) : (
            <button type="submit" className="mt-4 button-orange has-icon">
              <i className="icon search-icon" />
              {t("meal_food_menu_search_button")}
            </button>
          )}
        </div>
      </form>
      {RoleCheck("3") && (
        <div className="w-100 mb-3 d-flex justify-content-end">
          <button
            className="button-blue has-icon"
            onClick={() => setShowAddModal(true)}
          >
            <i className="icon add-icon" />
            {t("meal_food_menu_add_new_button")}
          </button>
          {showAddModal && (
            <AddNewMenu
              onShowModal={showAddModal}
              setShowModal={(e) => setShowAddModal(e)}
              setModified={() => getFoodMenus()}
            />
          )}
        </div>
      )}
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                style={{ width: "25%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon assortment-icon" />
                {t("meal_food_menu_table_name")}
              </th>
              <th
                style={{ width: "25%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon document-icon" />
                {t("meal_food_menu_table_path")}
              </th>
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon age-group-icon" />
                {t("meal_food_menu_table_age_group")}
              </th>
              <th
                style={{ width: "15%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon season-icon" />
                {t("meal_food_menu_season")}
              </th>
              <th
                style={{ width: "15%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon actions-icon" />
                {t("meal_food_menu_actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={5}>
                  <Loading />
                </td>
              </tr>
            ) : (
              foodMenus?.data?.map((foodMenu: any) => (
                <tr key={foodMenu.id}>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("meal_food_menu_table_name")}
                  >
                    {foodMenu.name}
                  </td>
                  <td
                    role="cell"
                    className="text-left"
                    data-label={t("meal_food_menu_table_path")}
                  >
                    {foodMenu.path}
                  </td>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("meal_food_menu_table_age_group")}
                  >
                    {foodMenu.age_group.name}
                  </td>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("meal_food_menu_season")}
                  >
                    {foodMenu.age_group.season_translated}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("meal_food_menu_actions")}
                  >
                    <div className="actions">
                      {RoleCheck("3") && (
                        <>
                          <button
                            className="button-unstyled-icon"
                            onClick={() => {
                              setShowEditModal(true);
                              setModalId(foodMenu.id);
                            }}
                          >
                            <i className="icon edit-icon" />
                          </button>
                          {showEditModal && modalId === foodMenu.id && (
                            <EditFoodMenu
                              onShowModal={showEditModal}
                              setShowModal={(e) => {
                                setShowEditModal(e);
                                setModalId(null);
                              }}
                              foodMenuId={foodMenu.id}
                              setModified={() => getFoodMenus()}
                            />
                          )}
                        </>
                      )}

                      <button
                        className="button-unstyled-icon"
                        onClick={() => {
                          setShowViewModal(true);
                          setModalId(foodMenu.id);
                        }}
                      >
                        <i className="icon show-icon" />
                      </button>
                      {showViewModal && modalId === foodMenu.id && (
                        <ViewFoodMenu
                          onShowModal={showViewModal}
                          setShowModal={(e) => {
                            setShowViewModal(e);
                            setModalId(null);
                          }}
                          foodMenuId={foodMenu.id}
                        />
                      )}
                      {RoleCheck("3") && (
                        <button
                          className="button-unstyled-icon"
                          onClick={() =>
                            deleteFoodMenu(foodMenu.id, foodMenu.name)
                          }
                        >
                          <i className="icon delete-icon" />
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse totalItems={foodMenus?.data?.length} loading={loading} />
      </div>
      <CustomPagination data={foodMenus} setActivePage={(e) => setPage(e)} />
    </>
  );
};

export default Menu;
