import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import Select, { SingleValue } from "react-select";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import ListResponse from "src/helpers/ListResponse";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import PATHS from "src/routes/Paths";
import SearchFilter from "src/helpers/SearchFilter";
import CustomPagination from "src/helpers/CustomPaginate";
import Loading from "src/helpers/Loading";
import useSearchSubmit from "src/hooks/useSearchSubmit";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";

const Groups: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [listOfGroups, setListOfGroups] = useState<any>([]);
  const [teachersList, setTeachersList] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  // Search
  const {
    searchInputs,
    searchBtnLoading,
    page,
    setSearchBtnLoading,
    handleSubmit,
    setPage,
  } = useSearchSubmit();
  const [groupNameSearch, setGroupNameSearch] = useState<string>("");
  const [teacherSearch, setTeacherSearch] =
    useState<SingleValue<{ value: string; label: string }>>(null);


  const fetchGroups = async (): Promise<void> => {
    var searchParams: any = searchInputs;
    let request: any = API_PATHS.kindergartenGrouplist;

    searchParams.pagination = 'yes';

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      setListOfGroups(response.kindergartensgroups);
      setTeachersList(
        response.teachersList.map((teacher: any) => ({
          value: teacher.id,
          label: `${teacher.first_name} ${teacher.last_name}`,
        }))
      );
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    } finally {
      setSearchBtnLoading(false);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, [searchInputs]);

  const deleteGroup = (groupId: number, groupTitle: string) => {
    ConfirmPopup.deletePopup(
      t("groups_list_delete_title"),
      t("groups_list_delete_type"),
      groupTitle
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.deleteKindergartenGroup(groupId),
            "DELETE",
            API_HEADERS.authenticated
          );
          if (listOfGroups.data.length === 1 && page > 1) {
            setPage(page - 1);
          } else {
            fetchGroups();
          }
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  return (
    <>
      <form onSubmit={(e) =>
        handleSubmit(e, {
          ...(groupNameSearch && { name: groupNameSearch }),
          ...(teacherSearch && { teacher_id: teacherSearch.value }),
        })
      } className="row">
        <div className="form-block col-12 col-sm-6 col-md-3 col-xxl-2">
          <label>{t("groups_list_search_group_name_label")}</label>
          <input
            type="text"
            name="name"
            placeholder={t("groups_list_search_group_name_placeholder")}
            value={groupNameSearch}
            className="form-input"
            autoComplete="off"
            onChange={(e) => setGroupNameSearch(e.target.value)}
          />
        </div>
        <div className="form-block col-12 col-sm-6 col-md-3 col-xxl-2">
          <label>{t("groups_list_search_teacher_label")}</label>
          <Select
            placeholder={t("groups_list_search_teacher_placeholder")}
            className="form-react-select"
            classNamePrefix="select_input"
            name="program_age_group_id"
            onChange={(e) => setTeacherSearch(e)}
            isClearable={true}
            isSearchable={false}
            value={teacherSearch}
            options={teachersList}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-3 col-xxl-2 mb-1">
          {searchBtnLoading ? (
            <button type="button" className="mt-4 button-orange has-icon">
              <i className="icon spinner-icon" />
              {t("groups_list_searching_button")}
            </button>
          ) : (
            <button
              type="submit"
              className="mt-4 button-orange has-icon"
            >
              <i className="icon search-icon" />
              {t("groups_list_search_button")}
            </button>
          )}
        </div>
      </form>
      <div className="w-100 mb-3 d-flex justify-content-end">
        <button
          className="button-blue has-icon"
          onClick={() => navigate(PATHS.addGroup)}
        >
          <i className="icon add-icon" />
          {t("groups_list_add_group_button")}
        </button>
      </div>
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                style={{ width: "30%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon sidebar-groups-icon" />
                {t("groups_list_table_group_name")}
              </th>
              <th
                style={{ width: "30%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon sidebar-teachers-icon" />
                {t("groups_list_table_teacher")}
              </th>
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon sidebar-children-icon" />
                {t("groups_list_table_child_number")}
              </th>
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon actions-icon" />
                {t("groups_list_table_actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={4}>
                  <Loading />
                </td>
              </tr>
            ) : (
              listOfGroups.data.map((group: any) => (
                <tr key={group.id}>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("groups_list_table_group_name")}
                  >
                    {group.name}
                  </td>
                  <td
                    role="cell"
                    className="text-left"
                    data-label={t("groups_list_table_teacher")}
                  >
                    {group.teachers.length > 0
                      ? group.teachers.map(
                        (teacher: any, index: number) =>
                          teacher.first_name +
                          " " +
                          teacher.last_name +
                          (index === group.teachers.length - 1 ? "" : ", ")
                      )
                      : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-left"
                    data-label={t("groups_list_table_child_number")}
                  >
                    {group.count_children}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("groups_list_table_actions")}
                  >
                    <div className="actions">
                      <button
                        className="button-unstyled-icon"
                        onClick={() => navigate(PATHS.editGroup(group.id))}
                      >
                        <i className="icon edit-icon" />
                      </button>
                      <button
                        className="button-unstyled-icon"
                        onClick={() => deleteGroup(group.id, group.name)}
                      >
                        <i className="icon delete-icon" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={listOfGroups?.data?.length}
          loading={loading}
        />
      </div>
      <CustomPagination
        data={listOfGroups}
        setActivePage={(e) => setPage(e)}
      />
    </>
  );
};

export default Groups;
