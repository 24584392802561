import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import { LanguageDate } from "src/helpers/CheckLanguageDate";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import moment from "moment";

interface ShowApprovedTransferProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  transferId: number;
}

const ShowApprovedTransfer: React.FC<ShowApprovedTransferProps> = ({
  onShowModal,
  setShowModal,
  transferId,
}) => {
  const { t } = useTranslation();

  const [childName, setChildName] = useState<string | null>("");
  const [childLastName, setChildLastName] = useState<string | null>("");
  const [parentName, setParentName] = useState<string | null>("");
  const [parentLastName, setParentLastName] = useState<string | null>("");
  const [email, setEmail] = useState<string | null>("");
  const [sourceKindergarten, setSourceKindergarten] = useState<string | null>("");
  const [targetKindergarten, setTargetKindergarten] = useState<string | null>("");
  const [approvalDate, setApprovalDate] = useState<Date | null>(null);

  const [loading, setLoading] = useState<boolean>(true);

  const onClose = () => {
    setShowModal(false);
  };

  const getShowApprovedTransferData = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.showTransfer(transferId),
        "GET",
        API_HEADERS.authenticated
      );
      setLoading(false);

      setChildName(response.transfer?.child?.first_name);
      setChildLastName(response.transfer?.child?.last_name);
      setParentName(response.transfer?.child?.guardian?.first_name);
      setParentLastName(response.transfer?.child?.guardian?.last_name);
      setSourceKindergarten(response.transfer?.source_kindergarten?.name);
      setTargetKindergarten(response.transfer?.target_kindergarten?.name);
      setApprovalDate(response.transfer?.approved_date)
      setEmail(response.transfer?.child?.guardian?.email);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      onClose();
    }
  };

  useEffect(() => {
    getShowApprovedTransferData();
  }, []);


  return (
    <Modal isOpen={!loading && onShowModal} toggle={() => onClose()} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("show_approved_transfer_title")}
      </ModalHeader>
      <ModalBody>
        <div className="modal-note">{t("show_approved_transfer_details")}</div>
        <div><p className="text-blue show-request">{t("show_approved_transfer_main_info")}</p></div>
        <div className="d-flex border-bottom modal-requests flex-wrap">
          <div className="modal-sections mb-4 me-2">
            <span className="header-text">{t("show_approved_transfer_pupil_name")}</span>
            <span className="bold-text"> {(childName ? childName : "-") +
              " " +
              (childLastName ? childLastName : "-")}</span>
          </div>
          <div className="modal-sections me-2">
            <span className="header-text">{t("show_approved_transfer_guardian")}</span>
            <span className="bold-text"> {(parentName ? parentName : "-") +
              " " +
              (parentLastName ? parentLastName : "-")}</span>
          </div>
          <div className="modal-sections">
            <span className="header-text">{t("show_approved_transfer_guardian_email")}</span>
            <span className="bold-text">{email ? email : "-"}</span>
          </div>
        </div>
        <div className="d-flex mt-4 modal-requests flex-wrap">
          <div className="modal-sections me-2">
            <span className="header-text">{t("show_approved_transfer_source")}</span>
            <span className="bold-text">{sourceKindergarten ? sourceKindergarten : "-"}</span>
          </div>
          <div className="modal-sections me-2">
            <span className="header-text">{t("show_approved_transfer_destination")}</span>
            <span className="bold-text">{targetKindergarten ? targetKindergarten : "-"}</span>
          </div>
          <div className="modal-sections">
            <span className="header-text">{t("show_approved_transfer_approval_date")}</span>
            <span className="bold-text"> {approvalDate
              ? moment(approvalDate).locale(LanguageDate.moment).format("DD, MMMM yyyy")
              : "-"}</span>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className="button-white"
          type="button"
          onClick={() => onClose()}
        >
          {t("show_approved_transfer_close")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ShowApprovedTransfer;
