import { useState } from "react";
import { useTranslation } from "react-i18next";
import DaysContent from "./DaysContent";

type TabsContentState = {
  activeTab: number;
};

interface DayTabsProps {
  week: number;
}

const DayTabs: React.FC<DayTabsProps> = ({ week }) => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<TabsContentState["activeTab"]>(1);

  const days = [1, 2, 3, 4, 5];

  const handleTabClick = (day: number) => {
    setActiveTab(day);
  };

  return (
    <div className="day-tabs mt-4">
      <div className="tab-buttons">
        {days.map((day) => (
          <button
            key={day}
            className={`tab-button${activeTab === day ? " active" : ""}`}
            onClick={() => handleTabClick(day)}
          >
            {t("education_program_view_day_" + day)}
          </button>
        ))}
      </div>

      <div className="tab-content">
        {days.map(
          (day) =>
            activeTab === day && (
              <div key={day}>
                <DaysContent week={week} selectedDay={day} />
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default DayTabs;
