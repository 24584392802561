import { create } from "zustand";
import { AddEducationProgram } from "./AddEducationProgram";
import { t } from "i18next";

export const EditEducationProgramStore = create((set) => ({
  success: false,

  submited: {
    loading: false,
    form: "",
  },

  validations: {},

  tabs: {
    weeks: {
      active: 1,
      clicked: 1,
    },
    days: {
      active: 1,
      clicked: 1,
    },
  },

  setValidations: (data: any) => set(() => ({ validations: data })),
  resetValidations: () =>
    set(() => ({
      validations: {},
    })),

  changeTab: (tab: string, number: number, success?: boolean) => {
    const educationProgramEdit = (AddEducationProgram as any).getState()
      .educationProgramEdit;

    const storeState = (EditEducationProgramStore as any).getState();

    const { week_topic, date, day_topic, day_activity, day_tools } =
      educationProgramEdit;
    if (!success) {
      if (!week_topic && !day_topic && !date && !day_activity && !day_tools) {
        if (tab === "weeks") {
          set(() => ({
            tabs: {
              weeks: {
                active: number,
                clicked: number,
              },
              days: {
                active: 1,
                clicked: 1,
              },
            },
          }));
        } else {
          set((state: any) => ({
            tabs: {
              ...state.tabs,
              [tab]: {
                ...state.tabs[tab],
                active: number,
                clicked: number,
              },
            },
          }));
        }
        storeState.resetValidations();
      } else {
        storeState.setSubmited(true, tab);

        set((state: any) => ({
          tabs: {
            ...state.tabs,
            [tab]: {
              ...state.tabs[tab],
              clicked: number,
            },
          },
        }));
      }
    } else {
      if (tab === "weeks") {
        set(() => ({
          tabs: {
            weeks: {
              active: number,
              clicked: number,
            },
            days: {
              active: 1,
              clicked: 1,
            },
          },
        }));
      } else {
        set((state: any) => ({
          tabs: {
            ...state.tabs,
            [tab]: {
              ...state.tabs[tab],
              active: number,
              clicked: number,
            },
          },
        }));
      }
    }
  },

  setSuccess: (status: boolean) => set(() => ({ success: status })),

  setSubmited: (loading: boolean, form: string) => {
    const educationProgramEdit = (AddEducationProgram as any).getState()
      .educationProgramEdit;

    const { week_topic, date, day_topic, day_activity, day_tools } =
      educationProgramEdit;

    if (!week_topic) {
      if (day_topic || date || day_activity || day_tools) {
        set(() => ({
          validations: {
            week_topic: [t("education_program_edit_week_topic_message")],
          },
        }));
      }
    } else {
      set(() => ({
        submited: {
          loading: loading,
          form: form,
        },
      }));
    }
  },
}));
