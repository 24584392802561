import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import { RoleCheck } from "src/helpers/RoleCheck";
import AddNewResponsiblePerson from "./AddResponsiblePerson";
import EditResponsiblePerson from "./EditResponsiblePerson";
import ViewResponsiblePerson from "./ViewResponsiblePerson";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import ListResponse from "src/helpers/ListResponse";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import Loading from "src/helpers/Loading";

import * as ConfirmPopup from "../../../../../../helpers/ConfirmPopup";

const ResponsiblePersons: React.FC = () => {
  const { t } = useTranslation();
  const { childId } = useParams();
  const navigate = useNavigate();

  const [responsiblePersons, setResponsiblePersons] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [modalId, setModalId] = useState<number | null>(null);
  const [modified, setModified] = useState(false);

  const fetchResponsiblePersons = async (): Promise<void> => {
    setModified(false);

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.childResponsiblePersonsList(childId),
        "GET",
        API_HEADERS.authenticated
      );
      setResponsiblePersons(response.responsiblePersons);
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  useEffect(() => {
    fetchResponsiblePersons();
  }, [modified]);

  const deleteResponsiblePerson = (
    responsiblePersonId: number,
    responsiblePersonTitle: string
  ) => {
    ConfirmPopup.deletePopup(
      t("child_responsible_persons_delete_title"),
      t("child_responsible_persons_delete_type"),
      responsiblePersonTitle
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.deleteChildResponsiblePersons(
              responsiblePersonId,
              childId
            ),
            "DELETE",
            API_HEADERS.authenticated
          );
          setModified(true);
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  return (
    <>
      {RoleCheck("1") ? (
        <div className="w-100 mb-3 d-flex justify-content-end">
          <button
            className="button-blue has-icon"
            onClick={() => setShowAddModal(true)}
          >
            <i className="icon add-icon" />
            {t("child_responsible_persons_add_new_button")}
          </button>
          {showAddModal && (
            <AddNewResponsiblePerson
              onShowModal={showAddModal}
              setShowModal={(e) => setShowAddModal(e)}
              setModified={(e) => setModified(e)}
            />
          )}
        </div>
      ) : (
        ""
      )}
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                style={{ width: "30%" }}
                role="columnheader"
                className="text-left"
              >
                {t("child_responsible_persons_table_full_name")}
              </th>
              <th
                style={{ width: "30%" }}
                role="columnheader"
                className="text-left"
              >
                {t("child_responsible_persons_table_phone")}
              </th>
              <th
                style={{ width: "25%" }}
                role="columnheader"
                className="text-left"
              >
                {t("child_responsible_persons_table_relation_to_child")}
              </th>
              <th
                style={{ width: "15%" }}
                role="columnheader"
                className="text-center"
              >
                {t("child_responsible_persons_table_actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={4}>
                  <Loading />
                </td>
              </tr>
            ) : (
              responsiblePersons.map((responsiblePerson: any) => (
                <tr key={responsiblePerson.id}>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("child_responsible_persons_table_full_name")}
                  >
                    {responsiblePerson.first_name +
                      " " +
                      responsiblePerson.last_name}
                  </td>
                  <td
                    role="cell"
                    className="text-left"
                    data-label={t("child_responsible_persons_table_phone")}
                  >
                    {responsiblePerson.phone ? responsiblePerson.phone : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-left"
                    data-label={t(
                      "child_responsible_persons_table_relation_to_child"
                    )}
                  >
                    {responsiblePerson.relation_to_child}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("child_responsible_persons_table_actions")}
                  >
                    <div className="actions">
                      {RoleCheck("1") ? (
                        <>
                          <button
                            className="button-unstyled-icon"
                            onClick={() => {
                              setShowEditModal(true);
                              setModalId(responsiblePerson.id);
                            }}
                          >
                            <i className="icon edit-icon" />
                          </button>
                          {showEditModal &&
                            modalId === responsiblePerson.id && (
                              <EditResponsiblePerson
                                onShowModal={showEditModal}
                                setShowModal={(e) => {
                                  setShowEditModal(e);
                                  setModalId(null);
                                }}
                                responsiblePersonId={responsiblePerson.id}
                                setModified={(e) => setModified(e)}
                              />
                            )}
                        </>
                      ) : (
                        ""
                      )}
                      <button
                        className="button-unstyled-icon"
                        onClick={() => {
                          setShowViewModal(true);
                          setModalId(responsiblePerson.id);
                        }}
                      >
                        <i className="icon show-icon" />
                      </button>
                      {showViewModal && modalId === responsiblePerson.id && (
                        <ViewResponsiblePerson
                          onShowModal={showViewModal}
                          setShowModal={(e) => {
                            setShowViewModal(e);
                            setModalId(null);
                          }}
                          responsiblePersonId={responsiblePerson.id}
                        />
                      )}
                      {RoleCheck("1") ? (
                        <button
                          className="button-unstyled-icon"
                          onClick={() =>
                            deleteResponsiblePerson(
                              responsiblePerson.id,
                              responsiblePerson.first_name +
                                " " +
                                responsiblePerson.last_name
                            )
                          }
                        >
                          <i className="icon delete-icon" />
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={responsiblePersons?.length}
          loading={loading}
        />
      </div>
    </>
  );
};

export default ResponsiblePersons;
