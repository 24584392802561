import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

interface ShowTransferNurseryProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  transferId: number;
}

const ShowTransferNursery: React.FC<ShowTransferNurseryProps> = ({
  onShowModal,
  setShowModal,
  transferId,
}) => {
  const { t } = useTranslation();

  const [childName, setChildName] = useState("");
  const [childLastName, setChildLastName] = useState("");
  const [parentName, setParentName] = useState("");
  const [parentLastName, setParentLastName] = useState("");
  const [email, setEmail] = useState("");
  const [sourceKindergarten, setSourceKindergarten] = useState("");
  const [targetKindergarten, setTargetKindergarten] = useState("");
  const [openSpots, setOpenSpots] = useState("");
  const [takenSpots, setTakenSpots] = useState("");

  const [loading, setLoading] = useState<boolean>(true);

  const onClose = () => {
    setShowModal(false);
  };

  const getShowRequestData = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.showTransfer(transferId),
        "GET",
        API_HEADERS.authenticated
      );
      setLoading(false);

      setChildName(response.transfer?.child?.first_name);
      setChildLastName(response.transfer?.child?.last_name);
      setParentName(response.transfer?.child?.guardian?.first_name);
      setParentLastName(response.transfer?.child?.guardian?.last_name);

      setSourceKindergarten(response.transfer?.source_kindergarten?.name);
      setTargetKindergarten(response.transfer?.target_kindergarten?.name);
      setOpenSpots(response.nr_of_free_places_in_the_kindergarten);
      setTakenSpots(response.nr_of_children_in_the_kindergarten);
      setEmail(response.transfer?.child?.guardian?.email);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      onClose();
    }
  };

  useEffect(() => {
    getShowRequestData();
  }, []);

  return (
    <Modal
      isOpen={onShowModal && !loading}
      toggle={() => onClose()}
      size="md"
      centered
    >
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("show_request_title")}
      </ModalHeader>
      <ModalBody>
        <div className="modal-note">{t("show_request_details")}</div>

        <div>
          <p className="text-blue show-request">
            {t("show_request_main_info")}
          </p>
        </div>
        <div className="d-flex border-bottom modal-requests flex-wrap">
          <div className="modal-sections mb-4 me-2">
            <span className="header-text">{t("show_request_pupil_name")}</span>
            <span className="bold-text">
              {(childName ? childName : "-") +
                " " +
                (childLastName ? childLastName : "-")}
            </span>
          </div>
          <div className="modal-sections me-2">
            <span className="header-text">{t("show_request_guardian")}</span>
            <span className="bold-text">
              {(parentName ? parentName : "-") +
                " " +
                (parentLastName ? parentLastName : "-")}
            </span>
          </div>
          <div className="modal-sections">
            <span className="header-text">
              {t("show_request_guardian_email")}
            </span>
            <span className="bold-text">{email ? email : "-"}</span>
          </div>
        </div>
        <div className="d-flex mt-4 modal-requests flex-wrap">
          <div className="modal-sections me-2">
            <span className="header-text">{t("show_request_source")}</span>
            <span className="bold-text">
              {sourceKindergarten ? sourceKindergarten : "-"}
            </span>
          </div>
          <div className="modal-sections">
            <span className="header-text">{t("show_request_destination")}</span>
            <span className="bold-text">
              {targetKindergarten ? targetKindergarten : "-"}
            </span>
          </div>
          <div className="modal-sections">
            <span className="header-text"></span>
            <span className="bold-text"></span>
          </div>
        </div>
        <div className="mt-4">
          <p className="text-blue show-request">
            {t("show_request_main_info_for_destination")}
          </p>
        </div>
        <div className="d-flex mt-4 mb-4 modal-requests flex-wrap">
          <div className="modal-sections me-2">
            <span className="header-text">
              {t("show_request_nr_free_spots")}
            </span>
            <span className="colored-field green m-auto d-flex justify-content-center">
              {openSpots ? openSpots : "-"}
            </span>
          </div>
          <div className="modal-sections">
            <span className="header-text">
              {t("show_request_occupied_spots")}
            </span>
            <span className="colored-field red m-auto d-flex justify-content-center">
              {takenSpots ? takenSpots : "-"}
            </span>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className="button-white"
          type="button"
          onClick={() => onClose()}
        >
          {t("show_request_close")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ShowTransferNursery;
