import { useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import ValidationMessage from "src/helpers/ValidationMessage";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

interface AddNewCategoryProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  setModified: (visible: boolean) => void;
}

type Item = {
  title: string;
  type: string;
};

const AddNewCategory: React.FC<AddNewCategoryProps> = ({
  onShowModal,
  setShowModal,
  setModified,
}) => {
  const { t } = useTranslation();

  const [title, setTitle] = useState("");

  const [documents, setDocuments] = useState<Item[]>([
    {
      title: "",
      type: "cerdhe",
    },
    {
      title: "",
      type: "kopesht",
    },
  ]);

  const [loadingButton, setLoadingButton] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const nurseryDocuments = documents.filter(
    (item: any) => item.type === "cerdhe"
  );
  const kindergartenDocuments = documents.filter(
    (item: any) => item.type === "kopesht"
  );

  // Find last indices safely
  const findLastIndexOfType = (type: string) => {
    const filteredDocs = documents.filter((doc) => doc.type === type);
    if (filteredDocs.length === 0) return -1;
    const lastItem = filteredDocs.pop();
    return documents.lastIndexOf(lastItem as any);
  };

  const lastCerdheIndex = findLastIndexOfType("cerdhe");
  const lastKopeshtIndex = findLastIndexOfType("kopesht");

  const addNewDocument = (type: string) => {
    const newItems: Item[] = [...documents, { title: "", type: type }];
    setDocuments(newItems);
  };

  const removeDocument = (type: string, index: number) => {
    setDocuments((prevItems) => prevItems.filter((item, idx) => idx !== index));
  };

  const onClose = () => {
    setShowModal(false);
  };

  const addButton = (type: string) => {
    let addRowButton = (
      <button
        type="button"
        className="button-white has-icon only"
        onClick={() => addNewDocument(type)}
      >
        <i className="icon simple-plus-icon"></i>
      </button>
    );

    return addRowButton;
  };

  // This function generates a button component that, when clicked, removes a document of a specified type at a given index.
  const removeButton = (type: string, index: number) => {
    let removeRowButton = (
      <button
        type="button"
        className="button-white has-icon only"
        onClick={() => removeDocument(type, index)}
      >
        <i className="icon minus-icon"></i>
      </button>
    );
    return removeRowButton;
  };

  const handleChange = (value: any, i: number) => {
    let targetArray = documents;
    const updatedArray = [...targetArray];
    updatedArray[i].title = value;

    setDocuments(updatedArray);
  };

  const addCategory = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setLoadingButton(true);

    const categoryData = {
      title: title,
      document: { ...documents },
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.categoryStore,
        "POST",
        API_HEADERS.authenticated,
        categoryData
      );

      FLASH_MESSAGES.successMsg(response.success);

      setModified(true);

      onClose();

      setLoadingButton(false);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        onClose();
      }
      setLoadingButton(false);
    }
  };

  return (
    <Modal isOpen={onShowModal} toggle={() => onClose()} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("childrens_categories_new_title")}
      </ModalHeader>
      <form onSubmit={(e) => addCategory(e)}>
        <ModalBody>
          <div className="modal-note">{t("childrens_categories_new_desc")}</div>
          <div className="form-block">
            <label className="required">
              {t("childrens_categories_new_name_label")}
            </label>
            <input
              type="text"
              name="title"
              placeholder={t("childrens_categories_new_name_placeholder")}
              value={title}
              className={
                "form-input" + (validations.title ? " input-error" : "")
              }
              autoComplete="off"
              onChange={(e) => setTitle(e.target.value)}
            />
            {validations.title && (
              <ValidationMessage message={validations.title[0]} />
            )}
          </div>
          <div className="form-block mb-0">
            <label className="required">
              {t("childrens_categories_nursery_label")}
            </label>
          </div>
          {documents.map(
            (document, index) =>
              document.type === "cerdhe" && (
                <div key={index} className="documents">
                  <div className="form-block">
                    <div className="input-row">
                      <input
                        type="text"
                        name="title"
                        placeholder={t(
                          "childrens_categories_document_name_placeholder"
                        )}
                        value={document.title ? document.title : ""}
                        className={
                          "form-input" +
                          (validations[`document.${index}.title`]
                            ? " input-error"
                            : "")
                        }
                        autoComplete="off"
                        onChange={(e) => handleChange(e.target.value, index)}
                      />
                      {removeButton("cerdhe", index)}
                    </div>
                    {validations[`document.${index}.title`] && (
                      <ValidationMessage
                        message={validations[`document.${index}.title`]}
                      />
                    )}
                  </div>
                  <div className="add-remove-buttons">
                    {index === lastCerdheIndex && addButton("cerdhe")}
                  </div>
                </div>
              )
          )}
          {nurseryDocuments.length === 0 && (
            <div className="documents">
              <div className="add-remove-buttons">{addButton("cerdhe")}</div>
            </div>
          )}

          <div className="break-line"></div>
          <div className="form-block mb-0">
            <label className="required">
              {t("childrens_categories_kindergarten_label")}
            </label>
          </div>
          {documents.map(
            (document, index) =>
              document.type === "kopesht" && (
                <div key={index} className="documents">
                  <div className="form-block">
                    <div className="input-row">
                      <input
                        type="text"
                        name="title"
                        placeholder={t(
                          "childrens_categories_document_name_placeholder"
                        )}
                        value={document.title ? document.title : ""}
                        className={
                          "form-input" +
                          (validations[`document.${index}.title`]
                            ? " input-error"
                            : "")
                        }
                        autoComplete="off"
                        onChange={(e) => handleChange(e.target.value, index)}
                      />
                      {removeButton("kopesht", index)}
                    </div>
                    {validations[`document.${index}.title`] && (
                      <ValidationMessage
                        message={validations[`document.${index}.title`]}
                      />
                    )}
                  </div>
                  <div className="add-remove-buttons">
                    {index === lastKopeshtIndex && addButton("kopesht")}
                  </div>
                </div>
              )
          )}
          {kindergartenDocuments.length === 0 && (
            <div className="documents">
              <div className="add-remove-buttons">{addButton("kopesht")}</div>
            </div>
          )}
          <div className="form-block">
            {validations.document && (
              <ValidationMessage message={validations.document[0]} />
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("childrens_categories_new_cancel")}
          </button>

          {loadingButton ? (
            <button type="button" className="button-blue has-icon">
              <i className="spinner-icon" />
              {t("childrens_categories_new_adding_button")}
            </button>
          ) : (
            <button type="submit" className="button-blue has-icon">
              <i className="icon add-icon" />
              {t("childrens_categories_new_add_button")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddNewCategory;
