import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import AddNewCategory from "./AddNewCategory";
import EditCategory from "./EditCategory";
import ShowCategory from "./ShowCategory";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import ListResponse from "src/helpers/ListResponse";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import Loading from "src/helpers/Loading";
import CustomPagination from "src/helpers/CustomPaginate";
import SearchFilter from "src/helpers/SearchFilter";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";

const ChildrenCategories: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Children categories list
  const [childrenCategories, setChildrenCategories] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [activePage, setActivePage] = useState<number>(1);

  // Modals
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number | null>(null);

  const getCategories = async (): Promise<void> => {
    let request: any = API_PATHS.categoriesList + "?list=y";

    var searchParams = {};

    if (activePage > 1) {
      searchParams = {
        page: activePage,
      };
    }

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setChildrenCategories(response.childrenCategories);
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  useEffect(() => {
    getCategories();
  }, [activePage]);

  const deleteCategory = (categoryId: number, categoryTitle: string) => {
    ConfirmPopup.deletePopup(
      t("delete_category_title"),
      t("delete_category_type"),
      categoryTitle
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.deleteCategory(categoryId),
            "DELETE",
            API_HEADERS.authenticated
          );

          getCategories();
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  return (
    <>
      <div className="w-100 mb-3 d-flex justify-content-end">
        <button
          className="button-blue has-icon"
          onClick={() => setShowAddModal(true)}
        >
          <i className="icon add-icon" />
          {t("childrens_categories_add_new_category")}
        </button>
        {showAddModal && (
          <AddNewCategory
            onShowModal={showAddModal}
            setShowModal={(e) => setShowAddModal(e)}
            setModified={() => getCategories()}
          />
        )}
      </div>
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                style={{ width: "85%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon category-icon" />
                {t("childrens_categories_table_category")}
              </th>
              <th
                style={{ width: "15%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon actions-icon" />
                {t("childrens_categories_table_actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={2}>
                  <Loading />
                </td>
              </tr>
            ) : (
              childrenCategories?.data?.map((category: any) => (
                <tr key={category.id}>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("childrens_categories_table_category")}
                  >
                    {category.title}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("childrens_categories_table_actions")}
                  >
                    <div className="actions">
                      <button
                        className="button-unstyled-icon"
                        onClick={() => {
                          setShowEditModal(true);
                          setModalId(category.id);
                        }}
                      >
                        <i className="icon edit-icon" />
                      </button>
                      {showEditModal && modalId === category.id && (
                        <EditCategory
                          onShowModal={showEditModal}
                          setShowModal={(e) => {
                            setShowEditModal(e);
                            setModalId(null);
                          }}
                          categoryId={category.id}
                          setModified={() => getCategories()}
                        />
                      )}
                      <button
                        className="button-unstyled-icon"
                        onClick={() => {
                          setShowModal(true);
                          setModalId(category.id);
                        }}
                      >
                        <i className="icon show-icon" />
                      </button>
                      {showModal && modalId === category.id && (
                        <ShowCategory
                          onShowModal={showModal}
                          setShowModal={(e) => {
                            setShowModal(e);
                            setModalId(null);
                          }}
                          title={category.title}
                          documents={category.children_category_documents}
                        />
                      )}
                      <button
                        className="button-unstyled-icon"
                        onClick={() =>
                          deleteCategory(category.id, category.title)
                        }
                      >
                        <i className="icon delete-icon" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={childrenCategories?.data?.length}
          loading={loading}
        />
      </div>
      <CustomPagination
        data={childrenCategories}
        setActivePage={(e) => setActivePage(e)}
      />
    </>
  );
};

export default ChildrenCategories;
