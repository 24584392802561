import React, { useState, useEffect, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { makeApiCall } from "src/api/apiRequests";
import { LanguageDate } from "src/helpers/CheckLanguageDate";
import ShowEvaluationChild from "./show_evaluation_popups/ShowEvaluationChild";
import EditEvaluationPopupChild from "./edit_evaluations/EditEvaluationPopupChild";
import EvaluationPopupChild from "./evaluation_popups/EvaluationPopup";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import SearchFilter from "src/helpers/SearchFilter";
import ListResponse from "src/helpers/ListResponse";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import CustomPagination from "src/helpers/CustomPaginate";
import ValidationMessage from "src/helpers/ValidationMessage";
import Loading from "src/helpers/Loading";
import moment from "moment";
import DatePicker from "react-datepicker";
import * as ConfirmPopup from "../../../../helpers/ConfirmPopup";
import useSearchSubmit from "src/hooks/useSearchSubmit";

interface DatePickerInputProps {
  value?: string;
  onClick?: () => void;
  errorClass?: string;
}

const ChildrenList: React.FC = () => {
  const { t } = useTranslation("");
  const { id } = useParams();

  // Children list
  const [children, setChildren] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // Search
  const {
    searchInputs,
    searchBtnLoading,
    setSearchBtnLoading,
    handleSubmit,
    setPage,
  } = useSearchSubmit();
  const [term, setTerm] = useState<string>("");
  const [dateOfEvaluation, setDateOfEvaluation] = useState<Date | null>(
    new Date()
  );
  const [validations, setValidations] = useState<Record<string, string>>({});

  // Modals
  const [addEvaluationModal, setAddEvaluationModal] = useState<boolean>(false);
  const [showChildEvaluationModal, setShowChildEvaluationModal] =
    useState<boolean>(false);
  const [editChildEvaluationModal, setEditChildEvaluationModal] =
    useState<boolean>(false);
  const [modalId, setModalId] = useState<number | null>(null);

  const DatePickerButton = forwardRef<HTMLButtonElement, DatePickerInputProps>(
    ({ errorClass, value, onClick }, ref) => (
      <button
        type="button"
        className={
          "datepicker-button simple-input" + (errorClass ? " input-error" : "")
        }
        onClick={onClick}
        ref={ref}
      >
        {value}
      </button>
    )
  );

  const getChildren = async (): Promise<void> => {
    let searchParams: any = searchInputs;
    let request: any = API_PATHS.evaluationsChildrenList;

    searchParams.date_of_evaluation = moment(dateOfEvaluation).format("yyyy-MM-DD");
    searchParams.kindergarten_id = id;

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      setChildren(response.children);
      setLoading(false);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      }
    } finally {
      setSearchBtnLoading(false);
    }
  };

  useEffect(() => {
    getChildren();
  }, [searchInputs]);


  const deleteEvaluation = (evaluationId: number, evaluationTitle: string) => {
    ConfirmPopup.deletePopup(
      t("delete_evaluation_title"),
      t("delete_evaluation_type"),
      evaluationTitle
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.deleteChildEvaluation(evaluationId),
            "DELETE",
            API_HEADERS.authenticated
          );
          getChildren();
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  return (
    <>
      <div className="input-items">
        <form onSubmit={(e) =>
          handleSubmit(e, {
            ...(term && { term: term }),
          })
        }>
          <div className="row mb-1">
            <div className="form-block col-12 col-sm-6 col-md-2">
              <label>{t("children_search_term_label")}</label>
              <input
                name="term"
                value={term}
                className="form-input"
                placeholder={t("children_search_term_placeholder")}
                onChange={(e) => setTerm(e.target.value)}
              />
            </div>
            <div className="form-block col-12 col-sm-6 col-md-2">
              <label>{t("performance_add_new_evaluation_date_label")}</label>
              <DatePicker
                selected={dateOfEvaluation}
                onChange={(e) => setDateOfEvaluation(e)}
                customInput={<DatePickerButton
                  errorClass={
                    validations.date_of_evaluation ? " input-error" : ""
                  }
                />}
                showMonthDropdown
                showYearDropdown
                dateFormat="dd MMM, yyyy"
                isClearable
                placeholderText={t(
                  "participation_list_search_date_placeholder"
                )}
                name="date_of_participation"
                className={
                  "form-datepicker" +
                  (validations.date_of_evaluation ? " input-error" : "")
                }
                locale={LanguageDate.datepicker}
                autoComplete="off"
              />
              {validations.date_of_evaluation && (
                <ValidationMessage
                  message={validations.date_of_evaluation[0]}
                />
              )}
            </div>
            <div className="col-12 col-sm-6 col-md-3 col-xxl-2 mb-1">
              {searchBtnLoading ? (
                <button type="button" className="mt-4 button-orange has-icon">
                  <i className="icon spinner-icon" />
                  {t("participation_list_searching_button")}
                </button>
              ) : (
                <button type="submit" className="mt-4 button-orange has-icon">
                  <i className="icon search-icon" />
                  {t("participation_list_search_button")}
                </button>
              )}
            </div>
          </div>
          <p className="filter-explain-text">
            {t("unregistration_filter_explain")}
          </p>
        </form>
      </div>
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                style={{ width: "17%" }}
                role="columnheader"
                className="text-left"
              >
                <i className="icon children-name-icon" />
                {t("children_list_name_lastname")}
              </th>
              <th
                style={{ width: "17%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon guardian-icon" />
                {t("children_list_guardian")}
              </th>
              <th
                style={{ width: "17%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon telephone-icon" />
                {t("children_list_mobile")}
              </th>
              <th
                style={{ width: "15%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon calender-icon" />
                {t("children_list_evaluation_date")}
              </th>
              <th
                style={{ width: "17%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon evaluation-icon" />
                {t("children_list_evaluations")}
              </th>
              <th
                style={{ width: "20%" }}
                role="columnheader"
                className="text-center"
              >
                <i className="icon actions-icon" />
                {t("children_list_actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan={6}>
                  <Loading />
                </td>
              </tr>
            ) : (
              children.data.map((child: any) => (
                <tr key={child.id}>
                  <td
                    role="cell"
                    className="text-left bold-text"
                    data-label={t("children_list_name_lastname")}
                  >
                    {(child.first_name ? child.first_name : "-") +
                      " " +
                      (child.last_name ? child.last_name : "-")}
                  </td>
                  <td
                    role="cell"
                    className="text-center bold-text"
                    data-label={t("children_list_guardian")}
                  >
                    {(child.guardian.first_name
                      ? child.guardian.first_name
                      : "-") +
                      " " +
                      (child.guardian.last_name
                        ? child.guardian.last_name
                        : "-")}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("children_list_mobile")}
                  >
                    <div className="d-flex flex-column">
                      <span>{child.guardian.phone}</span>
                      <span className="blue-text">{child.guardian.email}</span>
                    </div>
                  </td>
                  <td
                    role="cell"
                    className="text-center dark-text"
                    data-label={t("children_list_evaluation_date")}
                  >
                    {child.evaluation
                      ? moment(child.evaluation.evaluation_date)
                        .locale(LanguageDate.moment)
                        .format("DD MMMM, yyyy")
                      : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("children_list_evaluations")}
                  >
                    {child.evaluation && child.evaluation.evaluation
                      ? child.evaluation.evaluation.length > 100
                        ? child.evaluation.evaluation.substring(0, 100) + "..."
                        : child.evaluation.evaluation
                      : "-"}
                  </td>
                  <td
                    role="cell"
                    className="text-center blue-text"
                    data-label={t("children_list_actions")}
                  >
                    <div className="actions">
                      {child.evaluation ? (
                        <>
                          <button
                            type="button"
                            className="button-unstyled-icon"
                            onClick={(e) => {
                              setEditChildEvaluationModal(true);
                              setModalId(child.evaluation.id);
                            }}
                          >
                            <i className="icon edit-icon" />
                          </button>
                          {editChildEvaluationModal &&
                            modalId === child.evaluation.id && (
                              <EditEvaluationPopupChild
                                onShowModal={editChildEvaluationModal}
                                setShowModal={(e) => {
                                  setEditChildEvaluationModal(e);
                                  setModalId(null);
                                }}
                                evaluationId={child.evaluation.id}
                                setModified={() => getChildren()}
                              />
                            )}

                          <button
                            type="button"
                            className="button-unstyled-icon"
                            onClick={(e) => {
                              setShowChildEvaluationModal(true);
                              setModalId(child.evaluation.id);
                            }}
                          >
                            <i className="icon show-icon" />
                          </button>
                          {showChildEvaluationModal &&
                            modalId === child.evaluation.id && (
                              <ShowEvaluationChild
                                onShowModal={showChildEvaluationModal}
                                setShowModal={(e) => {
                                  setShowChildEvaluationModal(e);
                                  setModalId(null);
                                }}
                                evaluationId={child.evaluation.id}
                              />
                            )}

                          <button
                            className="button-unstyled-icon"
                            onClick={(e) => {
                              deleteEvaluation(
                                child.evaluation.id,
                                child.first_name + " " + child.last_name
                              );
                            }}
                          >
                            <i className="icon delete-icon" />
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="button-unstyled-icon"
                            onClick={() => {
                              setAddEvaluationModal(true);
                              setModalId(child.id);
                            }}
                          >
                            <i className="icon plus-bordered-icon" />
                          </button>
                          {addEvaluationModal && modalId === child.id && (
                            <EvaluationPopupChild
                              onShowModal={addEvaluationModal}
                              setShowModal={(e) => {
                                setAddEvaluationModal(e);
                                setModalId(null);
                              }}
                              setModified={() => getChildren()}
                              childId={child.id}
                              dateOfEvaluation={dateOfEvaluation}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse totalItems={children?.data?.length} loading={loading} />
      </div>
      <CustomPagination
        data={children}
        setActivePage={(e) => setPage(e)}
      />
    </>
  );
};
export default ChildrenList;
