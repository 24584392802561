import { useState, useRef, FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import ValidationMessage from "src/helpers/ValidationMessage";
import { Navigate, useNavigate, useParams } from "react-router";
import * as AppConfig from "../../../helpers/AppConfig";
import { makeApiCall, makeApiCallDownload } from "src/api/apiRequests";
import API_PATHS from "src/api/apiPaths";
import SearchFilter from "src/helpers/SearchFilter";
import API_HEADERS from "src/api/apiConfig";

import CustomPagination from "src/helpers/CustomPaginate";
import ListResponse from "src/helpers/ListResponse";
import Loading from "src/helpers/Loading";
import PATHS from "src/routes/Paths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import moment from "moment";

interface UploadWarrantyPopupProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  applicationId: number;
  setModified: (visible: boolean) => void;
}

const UploadWarrantyPopup: React.FC<UploadWarrantyPopupProps> = ({
  onShowModal,
  setShowModal,
  applicationId,
  setModified,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [warrantyPayment, setWarrantyPayment] = useState<File | null>(null);

  const [loading, setLoading] = useState<boolean>(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const onClose = () => {
    setShowModal(false);
    setModified(false);
  };

  const uploadWarranty = async (): Promise<void> => {
    setBtnLoading(true);

    const formData = new FormData();
    formData.append("application_id", applicationId.toString());
    formData.append("warranty_payment", warrantyPayment as Blob);
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.uploadWarrantyPayment,
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);
      navigate(PATHS.applicationsKindergarten);
      setModified(true);
    } catch (error: any) {
      if (error.response && error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        navigate(-1);
      }
      setBtnLoading(false);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setWarrantyPayment(file);
    }
  };

  const deleteDocument = () => {
    setWarrantyPayment(null);
  };

  return (
    <Modal isOpen={onShowModal} toggle={() => onClose()} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("upload_warranty_title")}
      </ModalHeader>
      <ModalBody>
        <div className="upload-documents">
          <div className="upload-file-box">
            <div
              className={
                "form-input" +
                (validations.warranty_payment ? " input-error" : "")
              }
            >
              <>
                <input
                  className="upload-input"
                  accept="image/*,application/pdf"
                  name="image"
                  type="file"
                  onChange={(e) => handleFileChange(e)}
                />
                <div className="upload-input-placeholder">
                  <i className="icon upload-documents-icon" />
                  <div className="subicon">
                    <i className="icon  upload-documents-subicon mx-1" />
                  </div>
                </div>
                <span className="popup_title text-center">
                  {t("third_step_upload_instructions")}
                </span>
                <span className="popup_description text-center">
                  {t("third_step_limitations")}
                </span>
              </>
            </div>
          </div>
          {validations.warranty_payment && (
            <ValidationMessage message={validations.warranty_payment} />
          )}
          {warrantyPayment && (
            <>
              <div className="download-zone mt-4">
                <div className="document-type">
                  <span>
                    <i className="icon pdf-icon mx-4 me-3"></i>
                  </span>
                  <span className="document-text">
                    {warrantyPayment.name ? warrantyPayment.name : "-"}
                  </span>
                </div>
                <div className="document-info">
                  <span className="document-text my-1 me-4">
                    {warrantyPayment.size
                      ? (warrantyPayment.size / (1024 * 1024)).toFixed(1)
                      : "-"}{" "}
                    MB
                  </span>
                  <span onClick={() => deleteDocument()}>
                    <i className="icon close-icon me-2" />
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className="button-white"
          type="button"
          onClick={() => onClose()}
        >
          {t("upload_warranty_close")}
        </button>
        {btnLoading ? (
          <button type="button" className="button-green has-icon">
            <i className="icon spinner-icon" />
            {t("upload_warranty_saving")}
          </button>
        ) : (
          <button
            type="submit"
            className="button-green has-icon"
            onClick={(e) => {
              uploadWarranty();
              e.stopPropagation();
            }}
          >
            <i className="icon tick-icon" />
            {t("upload_warranty_save")}
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default UploadWarrantyPopup;
