import { useState, FormEvent, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { makeApiCall } from "src/api/apiRequests";
import { LanguageDate } from "src/helpers/CheckLanguageDate";
import DatePicker from "react-datepicker";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import ValidationMessage from "src/helpers/ValidationMessage";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import moment from "moment";
interface GenerateInvoiceProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
}
interface DatePickerInputProps {
  placeholder?: string;
  value?: string;
  onClick?: () => void;
  errorClass?: string;
}

const DatePickerButton = forwardRef<HTMLButtonElement, DatePickerInputProps>(
  ({ errorClass, placeholder, value, onClick }, ref) => (
    <button
      type="button"
      className={
        "datepicker-button simple-input" + (errorClass ? " input-error" : "")
      }
      onClick={onClick}
      ref={ref}
    >
      {value ? value : placeholder}
    </button>
  )
);

const GenerateInvoice: React.FC<GenerateInvoiceProps> = ({
  onShowModal,
  setShowModal,
}) => {
  const { t } = useTranslation();

  const [date, setDate] = useState<Date | null>(null);

  const [loadingButton, setLoadingButton] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const onClose = () => {
    setShowModal(false);
  };

  const generateInvoice = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setLoadingButton(true);

    let formData: any = {
      month: date ? Number(moment(date).format("MM")) : "",
      year: date ? moment(date).format("YYYY") : "",
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.generateInvoice,
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);

      onClose();
      setLoadingButton(false);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        onClose();
      }
      setLoadingButton(false);
    }
  };

  return (
    <Modal isOpen={onShowModal} toggle={() => onClose()} size="sm" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("dashboard_generate_invoices_modal_tile")}
      </ModalHeader>
      <form onSubmit={(e) => generateInvoice(e)}>
        <ModalBody>
          <div className="modal-note">
            {t("dashboard_generate_invoices_modal_desc")}
          </div>
          <div className="form-block">
            <label className="required">
              {t("dashboard_generate_invoices_modal_label")}
            </label>
            <DatePicker
              selected={date}
              onChange={setDate}
              customInput={
                <DatePickerButton
                  errorClass={
                    validations.month || validations.year ? " input-error" : ""
                  }
                />
              }
              showMonthYearPicker
              dateFormat="MMMM/yyyy"
              placeholderText={t(
                "dashboard_generate_invoices_modal_placeholder"
              )}
              className="form-datepicker"
              autoComplete="off"
              name="month_year"
              maxDate={new Date()}
              locale={LanguageDate.datepicker}
              required={true}
            />
            {(validations.month || validations.year) && (
              <ValidationMessage
                message={
                  validations.month ? validations.month[0] : validations.year[0]
                }
              />
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("dashboard_generate_invoices_modal_close")}
          </button>

          {loadingButton ? (
            <button type="button" className="button-blue has-icon">
              <i className="spinner-icon" />
              {t("dashboard_generate_invoices_modal_generating")}
            </button>
          ) : (
            <button type="submit" className="button-blue has-icon">
              {t("dashboard_generate_invoices_modal_generate")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default GenerateInvoice;
