import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";
import ValidationMessage from "src/helpers/ValidationMessage";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import API_PATHS from "src/api/apiPaths";
import SearchFilter from "src/helpers/SearchFilter";
import API_HEADERS from "src/api/apiConfig";
import CustomPagination from "src/helpers/CustomPaginate";

import StoreAlbumPopup from "./StoreAlbumPopup";
import EditAlbumPopup from "./EditAlbum";

import PATHS from "src/routes/Paths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import ListResponse from "src/helpers/ListResponse";
import Loading from "src/helpers/Loading";
import { RoleCheck } from "src/helpers/RoleCheck";

const Gallery: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [createAlbumPopup, setCreateAlbumPopup] = useState(false);
  const [editAlbumPopup, setEditAlbumPopup] = useState(false);

  const [albums, setAlbums] = useState<Record<string, any>[]>([]);
  const [title, setTitle] = useState("");
  const [order, setOrder] = useState("desc");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [searched, setSearched] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [paginate, setPaginate] = useState<any>([]);
  const [validations, setValidations] = useState<Record<string, string>>({});
  const [modalId, setModalId] = useState<number | null>(null);
  const [dropdownId, setDropdownId] = useState<number | null>(null);
  const [modified, setModified] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const getAlbums = async (hasSearch: any | undefined): Promise<void> => {
    setModified(false);

    var searchParams: any = {
      order: order,
    };

    let request: any = API_PATHS.albumList;

    if (hasSearch && searched) {
      setBtnLoading(true);
      hasSearch.preventDefault();
    }

    if (searched) {
      if (title) {
        searchParams["title"] = title;
      }
    }

    if (activePage > 1) {
      searchParams["page"] = activePage;
    }

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      setAlbums(response.albums.data);
      setPaginate(response.albums);
      setBtnLoading(false);
      setLoading(false);
    } catch (error: any) {
    } finally {
      setBtnLoading(false);
    }
  };

  useEffect(() => {
    getAlbums("");
  }, [modified, activePage, searched, order]);

  const deleteAlbums = (albumId: number, albumTitle: string) => {
    ConfirmPopup.deletePopup(
      t("delete_album_title"),
      t("delete_album_type"),
      albumTitle
    ).then(async (response) => {
      if (response.isConfirmed) {
        try {
          const response: any = await makeApiCall<ResponseType>(
            API_PATHS.deleteAlbum(albumId),
            "DELETE",
            API_HEADERS.authenticated
          );

          setModified(true);
          FLASH_MESSAGES.successMsg(response.success);
        } catch (error: any) {
          if (error.response.data.error) {
            FLASH_MESSAGES.errorMsg(error.response.data.error);
          }
        }
      }
    });
  };

  return (
    <>
      <div className="input-items">
        <form id="request">
          <div className="row">
            <div className="form-block col-12 col-sm-6 col-md-3">
              <label>{t("album_title")}</label>
              <input
                placeholder={t("album_title_search_by")}
                className={
                  "form-input" + (validations.title ? " input-error" : "")
                }
                name="title"
                type="text"
                onChange={(e) => setTitle(e.target.value)}
              />
              {validations.title && (
                <ValidationMessage message={validations.title[0]} />
              )}
            </div>
            <div className="col-12 col-sm-6 col-md-3 mt-4 mb-4">
              {btnLoading === true ? (
                <button type="button" className="button-orange has-icon">
                  <i className="icon spinner-icon" />
                  {t("albums_searching")}
                </button>
              ) : (
                <button
                  type="submit"
                  className="button-orange has-icon"
                  onClick={(e) => {
                    setSearched(true);
                    setActivePage(1);
                    getAlbums(e);
                    e.preventDefault();
                  }}
                >
                  <i className="icon search-icon" />
                  {t("albums_search")}
                </button>
              )}
            </div>
          </div>
        </form>
        <p className="filter-explain-text">{t("requests_filter_explain")}</p>
      </div>
      {RoleCheck("2") && (
      <div className="d-flex align-items-baseline justify-content-end">
        <button
          type="button"
          className="button-blue has-icon mb-1"
          onClick={() => setCreateAlbumPopup(true)}
        >
          <i className="icon plus-icon" />
          {t("album_add_album")}
        </button>
        {createAlbumPopup && (
          <StoreAlbumPopup
            onShowModal={createAlbumPopup}
            setShowModal={setCreateAlbumPopup}
            setModified={(e) => setModified(e)}
          />
        )}
      </div>
      )}
      <label>{t("gallery_filter")}</label>
      <div className="row mb-5">
        <div className="form-block col-12 col-sm-6 col-md-3 pb-4">
          <Select
            className={"select-sort"}
            classNamePrefix="select_input"
            value={
              order
                ? {
                    value: order,
                    label: `Sort By: ${
                      order === "desc" ? "Newest" : "Oldest"
                    } First`,
                  }
                : null
            }
            options={[
              {
                value: "desc",
                label: t("gallery_sort_by_newest_first"),
              },
              {
                value: "asc",
                label: t("gallery_sort_by_oldest_first"),
              },
            ]}
            onChange={(e) => (e ? setOrder(e.value) : setOrder("desc"))}
          />
        </div>
      </div>
      <div>
        <div className="gallery-container mb-5">
          {loading ? (
            <div>
              <div className="text-center m-auto">
                <Loading />
              </div>
            </div>
          ) : (
            albums.map((album, index) => (
              <div className="grid-item">
                <div
                  className="grid-content"
                  onClick={() => navigate(PATHS.showAlbum + album.id)}
                >
                  <i className="icon album-icon" />
                </div>
                <div className="section-border"></div>
                <div className="d-flex justify-content-between">
                  <div className="grid-actions">
                    <div className="">
                      <span
                        onClick={() => navigate(PATHS.showAlbum + album.id)}
                      >
                        {" "}
                        <i className="icon file-icon" /> {" "}
                        {album.name}
                      </span>
                    </div>
                  </div>
                  {RoleCheck("2") && (
                  <div className="mx-3">
                    <Dropdown
                      isOpen={dropdownId === album.id ? true : false}
                      toggle={() => setDropdownId(null)}
                    >
                      <DropdownToggle caret={false} tag="span">
                        <span
                          className="d-inline-flex"
                          onClick={(e) => {
                            e.stopPropagation();
                            setDropdownId(album.id);
                          }}
                        >
                          <i className="icon three-dots-icon" />
                        </span>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem
                          key={album.id}
                          onClick={(e) => {
                            setEditAlbumPopup(true);
                            setModalId(album.id);
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                        >
                          <i className="icon edit-icon me-2" />
                          <span>{t("gallery_edit")}</span>
                        </DropdownItem>
                        {editAlbumPopup && modalId === album.id && (
                          <EditAlbumPopup
                            onShowModal={editAlbumPopup}
                            setModified={(e) => {
                              setEditAlbumPopup(e);
                              setModalId(null);
                            }}
                            albumId={album.id}
                            setShowModal={(e) => setShowModal(e)}
                          />
                        )}
                        <DropdownItem
                          key={album.id}
                          onClick={(e) => {
                            deleteAlbums(album.id, album.name);
                          }}
                        >
                          <i className="icon delete-icon me-2" />
                          <span>{t("gallery_delete")}</span>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
        <ListResponse
          totalItems={albums.length}
          loading={loading}
          searched={searched}
        />
        <CustomPagination
          data={paginate}
          setActivePage={(e) => setActivePage(e)}
        />
      </div>
    </>
  );
};

export default Gallery;
