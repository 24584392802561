import { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { get, map } from "lodash";

//i18n
import i18n from "../../../i18n/i18n";
import languages from "../../../i18n/languages";

const LanguageDropdown = () => {
  const [selectedLang, setSelectedLang] = useState<string>(
    localStorage.getItem("i18nextLng") || "en"
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const changeLanguageAction = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
    setSelectedLang(lang ? lang : "");
    window.location.reload();
  };

  return (
    <Dropdown
      isOpen={isDropdownOpen}
      toggle={() => setIsDropdownOpen(!isDropdownOpen)}
      className="language-dropdown d-none d-sm-block"
    >
      <DropdownToggle className="button-transparent" tag="button">
        <span
          className="d-flex align-items-center"
          style={{ width: "24px", height: "24px" }}
        >
          <img
            src={get(languages, `${selectedLang}.flag`, "") as string}
            alt="Flag"
            height="16"
          />
        </span>
      </DropdownToggle>
      <DropdownMenu className="language-switch dropdown-menu-end">
        {map(Object.keys(languages), (key) => (
          <DropdownItem
            key={key}
            onClick={() => changeLanguageAction(key)}
            className={`notify-item ${
              selectedLang === key ? "active" : "none"
            }`}
          >
            <img
              src={get(languages, `${key}.flag`, "") as string}
              alt="Flag"
              className="me-1"
              height="12"
            />
            <span className="align-middle">
              {get(languages, `${key}.label`, "") as string}
            </span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default LanguageDropdown;
