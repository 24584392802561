import { useState, FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Select, { MultiValue, SingleValue } from "react-select";
import { StaffRoles } from "./StaffRoles";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import ValidationMessage from "src/helpers/ValidationMessage";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

type SelectedKindergartensObject = {
  value: string;
  label: string;
};

interface AddStaffProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  setModified: (visible: boolean) => void;
}

const AddStaff: React.FC<AddStaffProps> = ({
  onShowModal,
  setShowModal,
  setModified,
}) => {
  const { t } = useTranslation("");

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [roleId, setRoleId] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [selectedKindergatens, setSelectedKindergatens] = useState<
    SelectedKindergartensObject[]
  >([]);

  const [validations, setValidations] = useState<Record<string, string>>({});
  const [btnLoading, setBtnLoading] = useState(false);

  const [kindergartens, setKindergartens] = useState<Record<string, any>[]>([]);

  const onClose = () => {
    setShowModal(false);
  };

  const storeStaffMemeber = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setBtnLoading(true);

    let formData = {
      role_id: roleId ? roleId.value : "",
      first_name: firstName,
      last_name: lastName,
      email: email,
      kindergartens: selectedKindergatens
        ? selectedKindergatens.map((kindergarten: any) => kindergarten.value)
        : [],
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.storeStaff,
        "POST",
        API_HEADERS.authenticated,
        formData
      );

      FLASH_MESSAGES.successMsg(response.success);

      onClose();
      setModified(true);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
      }
    } finally {
      setBtnLoading(false);
    }
  };

  const getKindergartens = async (): Promise<void> => {
    setModified(false);
    let request: any = API_PATHS.kindergartensDropdownList;
    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setKindergartens(response.kindergartens);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      setBtnLoading(false);
      onClose();
    }
  };

  useEffect(() => {
    getKindergartens();
  }, []);

  const handleChange = (
    e:
      | SingleValue<SelectedKindergartensObject>
      | MultiValue<SelectedKindergartensObject>
  ) => {
    if (Array.isArray(e)) {
      setSelectedKindergatens(e as SelectedKindergartensObject[]);
    } else if (e !== null) {
      setSelectedKindergatens([e as any]);
    } else {
      setSelectedKindergatens([]);
    }
  };

  return (
    <Modal isOpen={onShowModal} toggle={() => onClose()} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("add_staff_modal_title")}
      </ModalHeader>
      <form onSubmit={(e) => storeStaffMemeber(e)}>
        <ModalBody>
          <p className="popup_subtitle mb-4">{t("add_staff_description")}</p>
          <p className="popup_title mb-4">{t("add_staff_subtitle")}</p>
          <div className="row">
            <div className="form-block col-12 col-md-6">
              <label className="required">{t("add_staff_first_name")}</label>
              <input
                type="text"
                name="first_name"
                className={
                  "form-input" + (validations.first_name ? " input-error" : "")
                }
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                placeholder={t("add_staff_first_name")}
              />
              {validations.first_name && (
                <ValidationMessage message={validations.first_name[0]} />
              )}
            </div>
            <div className="form-block col-12 col-md-6">
              <label className="required">{t("add_staff_last_name")}</label>
              <input
                type="text"
                name="last_name"
                className={
                  "form-input" + (validations.last_name ? " input-error" : "")
                }
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                placeholder={t("add_staff_last_name")}
              />
              {validations.last_name && (
                <ValidationMessage message={validations.last_name[0]} />
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-block col-12">
              <label className="required">{t("add_staff_email")}</label>
              <input
                type="email"
                name="email"
                className={
                  "form-input" + (validations.email ? " input-error" : "")
                }
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder={t("add_staff_email")}
              />
              {validations.email && (
                <ValidationMessage message={validations.email[0]} />
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-block col-12 col-md-6">
              <label className="required">{t("add_staff_role")}</label>
              <Select
                placeholder={t("add_staff_select_role")}
                className={
                  "form-react-select" +
                  (validations.role_id ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="specification"
                onChange={(e) => {
                  setRoleId(e);
                  setSelectedKindergatens([]);
                }}
                isClearable={true}
                isSearchable={false}
                options={StaffRoles}
                value={roleId}
              />
              {validations.role_id && (
                <ValidationMessage message={validations.role_id[0]} />
              )}
            </div>
            {(roleId?.value === "2" ||
              roleId?.value === "4" ||
              roleId?.value === "5" ||
              roleId?.value === "8") && (
              <div className="form-block col-12 col-md-6">
                <label>{t("add_staff_institution")}</label>
                <Select
                  placeholder={t("add_staff_select_institution")}
                  className={
                    "form-react-select" +
                    (validations.kindergartens ? " input-error" : "")
                  }
                  classNamePrefix="select_input"
                  name="specification"
                  onChange={handleChange}
                  isClearable
                  isSearchable
                  isMulti={roleId?.value === "5"}
                  options={kindergartens.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  value={selectedKindergatens}
                />
                {validations.kindergartens && (
                  <ValidationMessage message={validations.kindergartens[0]} />
                )} 
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("add_staff_close")}
          </button>
          {btnLoading ? (
            <button className="button-blue has-icon" type="button">
              <i className="icon spinner-icon" />
              {t("add_staff_saving")}
            </button>
          ) : (
            <button className="button-blue has-icon" type="submit">
              <i className="icon plus-icon" />
              {t("add_staff_save")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddStaff;
