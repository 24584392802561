import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeApiCall } from "src/api/apiRequests";
import API_PATHS from "src/api/apiPaths";
import API_HEADERS from "src/api/apiConfig";
import PATHS from "src/routes/Paths";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import ValidationMessage from "src/helpers/ValidationMessage";

const ResetPassword = (): JSX.Element => {
  const { t } = useTranslation("");
  const { token } = useParams();
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: any = await makeApiCall<ResponseType>(
          API_PATHS.getPasswordData(token),
          "GET",
          API_HEADERS.unauthenticated
        );

        setUserName(response.user.email);
      } catch (error: any) {
        navigate(PATHS.login);
      }
    };

    fetchData();
  }, []);

  const resetPassword = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setButtonIsLoading(true);

    const passwordData = {
      password: password,
      password_confirmation: confirmPassword,
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.resetPassword(token),
        "POST",
        API_HEADERS.unauthenticated,
        passwordData
      );
      FLASH_MESSAGES.successMsg(response.success);
      setButtonIsLoading(false);
      navigate(PATHS.login);
    } catch (error: any) {
      if (error.response.status === 404 || error.response.status === 422) {
        setValidations(error.response.data);
        setButtonIsLoading(false);
      }
    }
  };

  return (
    <>
      <h3 className="auth-title">
        {t("auth_reset_password_title")} {userName}!
      </h3>
      <div className="auth-card">
        <p className="auth-note">{t("auth_reset_password_note")}</p>
        <form className="w-100" onSubmit={(e) => resetPassword(e)}>
          <div className="form-block">
            <label className="required">
              {t("auth_reset_password_password_label")}
            </label>
            <div className="password-input">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder={t("auth_reset_password_passwonrd_placeholder")}
                className={
                  "form-input" +
                  (validations.password || validations.error
                    ? " input-error"
                    : "")
                }
                autoComplete="off"
                onChange={(e) => setPassword(e.target.value)}
              />
              <div
                className="show-hide"
                onClick={(e) => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <i className="icon show-password-icon" />
                ) : (
                  <i className="icon hide-password-icon" />
                )}
              </div>
            </div>

            {(validations.password || validations.error) && (
              <ValidationMessage
                message={
                  validations.password
                    ? validations.password[0]
                    : validations.error
                }
              />
            )}
          </div>
          <div className="form-block">
            <label className="required">
              {t("auth_reset_password_confirm_password_button")}
            </label>
            <div className="password-input">
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="password"
                placeholder={t(
                  "auth_reset_password_confirm_password_placeholder"
                )}
                className={
                  "form-input" +
                  (validations.password || validations.error
                    ? " input-error"
                    : "")
                }
                autoComplete="off"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <div
                className="show-hide"
                onClick={(e) => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <i className="icon show-password-icon" />
                ) : (
                  <i className="icon hide-password-icon" />
                )}
              </div>
            </div>
          </div>

          {buttonIsLoading ? (
            <button type="button" className="button-blue w-100 has-icon">
              <i className="spinner-icon" />
              {t("auth_reset_password_sending_button")}
            </button>
          ) : (
            <button type="submit" className="button-blue w-100">
              {t("auth_reset_password_send_button")}
            </button>
          )}
        </form>
      </div>
    </>
  );
};

export default ResetPassword;
