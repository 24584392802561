import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
// import { tinyMCEApiKey } from "src/helpers/AppConfig";

interface CustomEditorProps {
  onSubmit: (content: string) => void;
  initialValue: string | undefined;
}

const CustomEditor: React.FC<CustomEditorProps> = ({
  onSubmit,
  initialValue,
}) => {
  const editorRef = useRef<any>(null);

  const handleInit = (evt: any, editor: any) => {
    editorRef.current = editor;
  };

  const myObject = {
    images_upload_handler: (blobInfo: any, success: any, failure: any) => {
      setTimeout(function () {
        /* no matter what you upload, we will turn it into TinyMCE logo :) */
        success("http://moxiecode.cachefly.net/tinymce/v9/images/logo.png");
      }, 2000);
    },
  };

  return (
    <Editor
      onChange={() => onSubmit(editorRef.current.getContent())}
      onInit={handleInit}
      initialValue={initialValue}
      apiKey="w6fjufda87q0fb0a0m7v2dd529ley6zg2u4s333ubr0rk75l"
      init={{
        height: 400,
        menubar: true,
        plugins:
          "powerpaste casechange searchreplace autolink directionality advcode visualblocks visualchars image link media mediaembed codesample table charmap pagebreak nonbreaking anchor tableofcontents insertdatetime advlist lists checklist wordcount tinymcespellchecker editimage formatpainter permanentpen charmap tinycomments linkchecker emoticons advtable export print autosave",
        toolbar:
          "undo redo print spellcheckdialog formatpainter | blocks fontfamily fontsize | bold italic underline forecolor backcolor | link image addcomment showcomments  | alignleft aligncenter alignright alignjustify lineheight | checklist bullist numlist indent outdent | removeformat",
        toolbar_sticky: true,
        images_upload_url: "postAcceptor.php",
        myObject,
        icons: "thin",
        skin: "material-classic",
        content_style: "material-classic",
      }}
    />
  );
};

export default CustomEditor;
