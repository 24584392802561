import React, { useState, useEffect } from "react";
import Select, { SingleValue } from "react-select";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import { RoleCheck } from "src/helpers/RoleCheck";
import CustomPagination from "src/helpers/CustomPaginate";
import RegistrationRequestPopup from "./RegistrationRequestPopup";
import API_PATHS from "src/api/apiPaths";
import PATHS from "src/routes/Paths";
import API_HEADERS from "src/api/apiConfig";
import SearchFilter from "src/helpers/SearchFilter";
import ListResponse from "src/helpers/ListResponse";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import moment from "moment";
import Loading from "src/helpers/Loading";
import useSearchSubmit from "src/hooks/useSearchSubmit";

const RegistrationRequests: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Unregistration list
  const [unregistrations, setUnregistrations] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // Search
  const {
    searchInputs,
    searchBtnLoading,
    searched,
    page,
    setSearchBtnLoading,
    handleSubmit,
    setPage,
  } = useSearchSubmit();
  const [term, setTerm] = useState<string>("");
  const [kindergartenId, setKindergartenId] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [status, setStatus] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [type, setType] = useState<
    SingleValue<{ value: "cerdhe" | "kopesht"; label: string }>
  >({ value: "cerdhe", label: t("unregistration_nursery") });

  const [kindergartens, setKindergartens] = useState<Record<string, any>[]>([]);

  const [makeRequestPopup, setMakeRequestPopup] = useState(false);

  const getUnregistertationList = async (): Promise<void> => {
    const searchParams: any = searchInputs;
    let request: any = API_PATHS.unregistrationList;

    request = SearchFilter(searchParams, request);

    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );

      setUnregistrations(response.children);
      setLoading(false);
      page === 0 && getKindergartens();
    } catch (error: any) {
      navigate(-1);
    } finally {
      setSearchBtnLoading(false);
    }
  };

  useEffect(() => {
    getUnregistertationList();
  }, [searchInputs]);

  const getKindergartens = async (): Promise<void> => {
    let request: any = API_PATHS.kindergartensDropdownList;
    try {
      const response: any = await makeApiCall<ResponseType>(
        request,
        "GET",
        API_HEADERS.authenticated
      );
      setKindergartens(response.kindergartens);
      setLoading(false);
    } catch (error: any) {
      FLASH_MESSAGES.errorMsg(error.response.data.error);
      navigate(-1);
    }
  };

  return (
    <>
      {RoleCheck("3") || RoleCheck("4") ? (
        <>
          <div className="input-items">
            <form
              onSubmit={(e) =>
                handleSubmit(e, {
                  ...(term && { term: term }),
                  ...(kindergartenId && {
                    kindergarten_id: kindergartenId.value,
                  }),
                  ...(status && { status: status.value }),
                })
              }
            >
              <div className="row mb-1">
                <div className="form-block col-12 col-sm-6 col-md-2">
                  <label>{t("unregistration_search_by_term")}</label>
                  <input
                    placeholder={t("unregistration_search")}
                    className="form-input"
                    name="term"
                    type="text"
                    onChange={(e) => setTerm(e.target.value)}
                  />
                </div>
                <div className="form-block col-12 col-sm-6 col-md-2">
                  <label>{t("unregistration_choose_option_type")}</label>
                  <Select
                    placeholder={t("unregistration_choose")}
                    className="form-react-select"
                    classNamePrefix="select_input"
                    name="status"
                    onChange={(e) => setType(e)}
                    isClearable={false}
                    isSearchable={false}
                    value={type}
                    options={[
                      { value: "cerdhe", label: t("unregistration_nursery") },
                      {
                        value: "kopesht",
                        label: t("unregistration_kindergarten"),
                      },
                    ]}
                  />
                </div>
                <div className="form-block col-12 col-sm-6 col-md-2">
                  <label>{t("unregistration_choose_option_institucion")}</label>
                  <Select
                    placeholder={t("unregistration_choose")}
                    className="form-react-select"
                    classNamePrefix="select_input"
                    name="kindergarten_id"
                    onChange={(e) => setKindergartenId(e)}
                    isClearable={true}
                    isSearchable={false}
                    value={kindergartenId}
                    options={kindergartens
                      .filter((item: any) => item.type === type?.value)
                      .map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                  />
                </div>
                <div className="form-block col-12 col-sm-6 col-md-2">
                  <label>{t("unregistration_choose_option_status")}</label>
                  <Select
                    placeholder={t("unregistration_choose")}
                    className="form-react-select"
                    classNamePrefix="select_input"
                    name="status"
                    onChange={(e) => setStatus(e)}
                    isClearable={true}
                    isSearchable={false}
                    value={status}
                    options={[
                      { value: "active", label: t("unregistration_active") },
                      {
                        value: "inactive",
                        label: t("unregistration_inactive"),
                      },
                    ]}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-3 mb-1 mt-4">
                  {searchBtnLoading ? (
                    <button type="button" className="button-orange has-icon">
                      <i className="icon spinner-icon" />
                      {t("unregistration_searching")}
                    </button>
                  ) : (
                    <button type="submit" className="button-orange has-icon">
                      <i className="icon search-icon" />
                      {t("unregistration_search")}
                    </button>
                  )}
                </div>
              </div>
              <p className="filter-explain-text mb-5">
                {t("unregistration_filter_explain")}
              </p>
            </form>
          </div>
          <div className="table-list mb-5">
            <table role="table" className="w-100">
              <thead>
                <tr role="row">
                  <th
                    style={{ width: "10%" }}
                    role="columnheader"
                    className="text-left"
                  >
                    <i className="icon children-name-icon" />
                    {t("unregistration_child")}
                  </th>
                  <th
                    style={{ width: "20%" }}
                    role="columnheader"
                    className="text-center"
                  >
                    <i className="icon guardian-icon" />
                    {t("unregistration_guardian")}
                  </th>
                  <th
                    style={{ width: "25%" }}
                    role="columnheader"
                    className="text-center"
                  >
                    <i className="icon information-icon" />
                    {t("unregistration_important_information")}
                  </th>
                  <th
                    style={{ width: "20%" }}
                    role="columnheader"
                    className="text-center"
                  >
                    <i className="icon kindergarten-table-icon" />
                    {t("unregistration_nursery_kindergarten")}
                  </th>
                  <th
                    style={{ width: "25%" }}
                    role="columnheader"
                    className="text-center"
                  >
                    <i className="icon actions-icon" />
                    {t("unregistration_actions")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan={5}>
                      <Loading />
                    </td>
                  </tr>
                ) : (
                  unregistrations.data.map((unregister: any) => (
                    <tr key={unregister.id}>
                      <td
                        role="cell"
                        className="text-left bold-text"
                        data-label={t("unregistration_child")}
                      >
                        {(unregister.child.first_name
                          ? unregister.child.first_name
                          : "-") +
                          " " +
                          (unregister.child.last_name
                            ? unregister.child.last_name
                            : "-")}
                      </td>
                      <td
                        role="cell"
                        className="text-center bold-text"
                        data-label={t("unregistration_guardian")}
                      >
                        {(unregister.guardian.first_name
                          ? unregister.guardian.first_name
                          : "-") +
                          " " +
                          (unregister.guardian.last_name
                            ? unregister.guardian.last_name
                            : "-")}
                      </td>
                      <td
                        role="cell"
                        className="text-center"
                        data-label={t("unregistration_important_information")}
                      >
                        <div className="d-flex flex-column">
                          <span>
                            {unregister.guardian.phone
                              ? unregister.guardian.phone
                              : "-"}
                          </span>
                          <span className="blue-text">
                            {unregister.guardian.email
                              ? unregister.guardian.email
                              : "-"}
                          </span>
                        </div>
                      </td>
                      <td
                        role="cell"
                        className="text-center"
                        data-label={t("unregistration_nursery_kindergarten")}
                      >
                        {unregister.kindergarten.name
                          ? unregister.kindergarten.name
                          : "-"}
                      </td>
                      {unregister.approved_by_admin == "0" ? (
                        <td
                          role="cell"
                          className="text-center"
                          data-label={t("unregistration_actions")}
                        >
                          <div className="actions-buttons">
                            <button
                              type="button"
                              className="button-yellow-border has-icon"
                              onClick={() =>
                                navigate(
                                  PATHS.showUnregistration + unregister.id
                                )
                              }
                            >
                              <i className="icon show-icon" />
                              {t("unregistration_show")}
                            </button>
                          </div>
                        </td>
                      ) : (
                        <td
                          role="cell"
                          className="text-center"
                          data-label={t("unregistration_actions")}
                        >
                          {unregister.child.status === "active" ? (
                            <span className="transfer-text">
                              <i className="icon green dot-icon my-1 me-2" />
                              {t("unregistration_active")}
                            </span>
                          ) : (
                            <span className="transfer-text">
                              <i className="icon red dot-icon my-1 me-2" />
                              {t("unregistration_inactive")}
                            </span>
                          )}
                        </td>
                      )}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <ListResponse
              totalItems={unregistrations?.data?.length}
              loading={loading}
              searched={searched}
            />
          </div>
          <CustomPagination
            data={unregistrations}
            setActivePage={(e) => setPage(e)}
          />
        </>
      ) : (
        <>
          <div className="d-flex align-items-baseline justify-content-end mb-5">
            <button
              type="button"
              className="button-blue has-icon mb-1"
              onClick={() => setMakeRequestPopup(true)}
            >
              <i className="icon plus-icon" />
              {t("registration_requests_make_a_request")}
            </button>
            {makeRequestPopup && (
              <RegistrationRequestPopup
                onShowModal={makeRequestPopup}
                setShowModal={setMakeRequestPopup}
                setModified={() => getUnregistertationList()}
              />
            )}
          </div>
          <div className="transfer-page">
            <div className="table-list-transfers1 mb-5">
              <table role="table" className="w-100 transfer-head">
                <thead>
                  {loading ? (
                    <tr>
                      <td className="text-center" colSpan={3}>
                        <Loading />
                      </td>
                    </tr>
                  ) : (
                    unregistrations.data.map((reg: any) => (
                      <tr role="row" key={reg.id}>
                        <th
                          style={{ width: "50%" }}
                          role="columnheader"
                          className="text-left p-4"
                        >
                          <i className="icon dropdown-time-icon" />
                          <span className="popup_description">
                            {reg.unregister_date
                              ? moment(reg.unregister_date).format(
                                  "DD, MM, yyyy"
                                )
                              : "-"}
                          </span>
                          <span className="d-flex mt-3">
                            {t("registration_request_unregistration_request")}
                            <span className="transfer-text mx-2">
                              {(reg.child.first_name
                                ? reg.child.first_name
                                : "-") +
                                " " +
                                (reg.child.last_name
                                  ? reg.child.last_name
                                  : "-")}
                            </span>
                          </span>
                        </th>
                        <th
                          style={{ width: "50%" }}
                          role="columnheader"
                          className="text-end"
                        >
                          {reg.child.status === "active" ? (
                            <span className="transfer-text">
                              <i className="icon green dot-icon my-1 me-2" />
                              {t("registration_request_registred")}
                            </span>
                          ) : (
                            <span className="transfer-text">
                              <i className="icon red dot-icon my-1 me-2" />
                              {t("registration_request_unregistred")}
                            </span>
                          )}
                        </th>
                      </tr>
                    ))
                  )}
                </thead>
              </table>
              <ListResponse
                totalItems={unregistrations?.data?.length}
                loading={loading}
                searched={searched}
              />
            </div>
            <CustomPagination
              data={unregistrations}
              setActivePage={(e) => setPage(e)}
            />
          </div>
        </>
      )}
    </>
  );
};

export default RegistrationRequests;
