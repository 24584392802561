import { useState, FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Select, { SingleValue } from "react-select";
import { days, meals } from "../data";
import { makeApiCall } from "src/api/apiRequests";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import ValidationMessage from "src/helpers/ValidationMessage";
import FLASH_MESSAGES from "src/helpers/FlashMessages";

interface AddNewAssortmentProps {
  onShowModal: boolean;
  setShowModal: (visible: boolean) => void;
  setModified: (visible: boolean) => void;
}

const AddNewAssortment: React.FC<AddNewAssortmentProps> = ({
  onShowModal,
  setShowModal,
  setModified,
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [day, setDay] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [meal, setMeal] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [foodAgeGroup, setFoodAgeroup] =
    useState<SingleValue<{ value: string; label: string }>>(null);
  const [calories, setCalories] = useState("");
  const [ingredients, setIngredients] = useState("");
  const [ageGroupsList, setFoodAgeGroupsList] = useState<any>([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [validations, setValidations] = useState<Record<string, string>>({});

  const onClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    getFoodAgeGroupData();
  }, []);

  const getFoodAgeGroupData = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.foodAgeGroupsNames,
        "GET",
        API_HEADERS.authenticated
      );

      const ageGroupsArr = [];
      for (const [key, value] of Object.entries(response.foodAgeGroup)) {
        ageGroupsArr.push({
          label: value,
          value: key,
        });
      }

      setFoodAgeGroupsList(ageGroupsArr);
    } catch (error: any) {}
  };

  const addAssortment = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setLoadingButton(true);

    const assortmentData = {
      name: name,
      day: day?.value,
      meal: meal?.value,
      food_age_group_id: foodAgeGroup?.value,
      calories: calories,
      ingredients: ingredients,
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.assortmentStore,
        "POST",
        API_HEADERS.authenticated,
        assortmentData
      );

      FLASH_MESSAGES.successMsg(response.success);

      setModified(true);

      onClose();

      setLoadingButton(false);
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidations(error.response.data);
      } else {
        FLASH_MESSAGES.errorMsg(error.response.data.error);
        onClose();
      }
      setLoadingButton(false);
    }
  };

  return (
    <Modal isOpen={onShowModal} toggle={() => onClose()} size="lg" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onClose()}
        tag="span"
      >
        {t("meal_add_new_assortment_title")}
      </ModalHeader>
      <form onSubmit={(e) => addAssortment(e)}>
        <ModalBody>
          <div className="modal-note">{t("meal_add_new_assortment_desc")}</div>
          <div className="row">
            <div className="form-block col-12 col-sm-6 col-lg-4">
              <label className="required">
                {t("meal_add_new_assortment_name_label")}
              </label>
              <input
                type="text"
                name="name"
                placeholder={t("meal_add_age_groups_age_group_placeholder")}
                value={name}
                className={
                  "form-input" + (validations.name ? " input-error" : "")
                }
                autoComplete="off"
                onChange={(e) => setName(e.target.value)}
              />
              {validations.name && (
                <ValidationMessage message={validations.name[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-lg-4">
              <label className="required">
                {t("meal_add_new_assortment_day_label")}
              </label>
              <Select
                placeholder={t("meal_add_new_assortment_day_placeholder")}
                className={
                  "form-react-select" + (validations.day ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="day"
                onChange={(e) => setDay(e)}
                isClearable={true}
                isSearchable={false}
                options={days}
                value={day}
              />
              {validations.day && (
                <ValidationMessage message={validations.day[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-lg-4">
              <label className="required">
                {t("meal_add_new_assortment_meal_label")}
              </label>
              <Select
                placeholder={t("meal_add_new_assortment_meal_placeholder")}
                className={
                  "form-react-select" + (validations.meal ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="meal"
                onChange={(e) => setMeal(e)}
                isClearable={true}
                isSearchable={false}
                options={meals}
                value={meal}
              />
              {validations.meal && (
                <ValidationMessage message={validations.meal[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-lg-4">
              <label className="required">
                {t("meal_add_new_assortment_age_group_label")}
              </label>
              <Select
                placeholder={t("meal_add_new_assortment_age_group_placeholder")}
                className={
                  "form-react-select" +
                  (validations.food_age_group_id ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="food_age_group_id"
                onChange={(e) => setFoodAgeroup(e)}
                isClearable={true}
                isSearchable={false}
                options={ageGroupsList}
                value={foodAgeGroup}
              />
              {validations.food_age_group_id && (
                <ValidationMessage message={validations.food_age_group_id[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-lg-4">
              <label className="required">
                {t("meal_add_new_assortment_calories_label")}
              </label>
              <input
                type="number"
                name="calories"
                placeholder={t("meal_add_new_assortment_calories_placeholder")}
                value={calories}
                min={0}
                className={
                  "form-input" + (validations.calories ? " input-error" : "")
                }
                autoComplete="off"
                onChange={(e) => setCalories(e.target.value)}
              />
              {validations.calories && (
                <ValidationMessage message={validations.calories[0]} />
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-block col-12 col-lg-8">
              <label>{t("meal_add_new_assortment_ingredients_label")}</label>
              <textarea
                name="description"
                className={
                  "form-input" + (validations.ingredients ? " input-error" : "")
                }
                value={ingredients}
                placeholder={t(
                  "meal_add_new_assortment_ingredients_placeholder"
                )}
                rows={3}
                onChange={(e) => setIngredients(e.target.value)}
              />
              {validations.ingredients && (
                <ValidationMessage message={validations.ingredients[0]} />
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button-white"
            type="button"
            onClick={() => onClose()}
          >
            {t("meal_add_new_assortment_cancel_button")}
          </button>

          {loadingButton ? (
            <button type="button" className="button-blue has-icon">
              <i className="spinner-icon" />
              {t("meal_add_new_assortment_adding_button")}
            </button>
          ) : (
            <button type="submit" className="button-blue has-icon">
              <i className="icon add-icon" />
              {t("meal_add_new_assortment_add_button")}
            </button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddNewAssortment;
