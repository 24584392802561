import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "src/api/apiRequests";
import { useUserdata } from "src/store/UserData";
import Select, { SingleValue } from "react-select";
import API_HEADERS from "src/api/apiConfig";
import API_PATHS from "src/api/apiPaths";
import CheckGender from "src/helpers/Checkgender";
import FLASH_MESSAGES from "src/helpers/FlashMessages";
import ValidationMessage from "src/helpers/ValidationMessage";
import PATHS from "src/routes/Paths";

const EditProfile = (): JSX.Element => {
  const { t } = useTranslation("");
  const navigate = useNavigate();

  interface UserData {
    role: string;
    // Other properties...
  }
  type AddUserData = (userData: UserData) => void;

  const addLoggedUserData: AddUserData = useUserdata(
    (state: any) => state.addUserData
  );
  const userData = useUserdata((state: any) => state.userData);

  const [fistName, setFirstName] = useState(
    userData ? userData.first_name : ""
  );
  const [lastName, setLastName] = useState(userData ? userData.last_name : "");
  const [email] = useState(userData ? userData.email : "");
  const [phone, setPhone] = useState(userData?.phone ? userData.phone : "");
  const [gender, setGender] = useState<
    SingleValue<{ value: string; label: string }>
  >(
    userData
      ? {
          value: userData.gender,
          label: t("edit_profile_gender_" + userData.gender),
        }
      : null
  );
  const [city, setCity] = useState(userData?.city ? userData.city : "");
  const [postalCode, setPostalCode] = useState(
    userData?.postal_code ? userData.postal_code : ""
  );
  const [address, setAddress] = useState(
    userData?.address ? userData.address : ""
  );

  const [validations, setValidations] = useState<Record<string, string>>({});

  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  const updateUserData = async (): Promise<void> => {
    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.profile,
        "POST",
        API_HEADERS.authenticated
      );

      addLoggedUserData(response);
    } catch (error: any) {}
  };

  const editProfile = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setButtonIsLoading(true);

    const updateProfileData = {
      first_name: fistName,
      last_name: lastName,
      phone: phone,
      address: address,
      postal_code: postalCode,
      city: city,
      gender: gender ? gender.value : null,
    };

    try {
      const response: any = await makeApiCall<ResponseType>(
        API_PATHS.updateProfile,
        "POST",
        API_HEADERS.authenticated,
        updateProfileData
      );
      FLASH_MESSAGES.successMsg(response.success);
      updateUserData();
      navigate(PATHS.myProfile);
    } catch (error: any) {
      if (error.response.status === 404 || error.response.status === 422) {
        setValidations(error.response.data);
      }
    } finally {
      setButtonIsLoading(false);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-10 col-lg-12 col-xl-10 col-xxl-6">
          <div className="card">
            <div className="card-header">{t("edit_profile_card_title")}</div>
            <div className="card-body">
              <div className="edit-profile">
                <form onSubmit={(e) => editProfile(e)}>
                  <div className="form-block">
                    <label className="required">
                      {t("edit_profile_name_label")}
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      value={fistName}
                      placeholder={t("edit_profile_name_placeholder")}
                      className={
                        "form-input" +
                        (validations.first_name ? " input-error" : "")
                      }
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    {validations.first_name && (
                      <ValidationMessage message={validations.first_name[0]} />
                    )}
                  </div>
                  <div className="form-block">
                    <label className="required">
                      {t("edit_profile_lastname_label")}
                    </label>
                    <input
                      type="text"
                      name="last_name"
                      value={lastName}
                      placeholder={t("edit_profile_lastname_placeholder")}
                      className={
                        "form-input" +
                        (validations.last_name ? " input-error" : "")
                      }
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    {validations.last_name && (
                      <ValidationMessage message={validations.last_name[0]} />
                    )}
                  </div>
                  <div className="form-block">
                    <label>{t("edit_profile_email_label")}</label>
                    <input
                      type="text"
                      name="email"
                      value={email}
                      placeholder={t("edit_profile_email_placeholder")}
                      className="form-input"
                      readOnly
                    />
                  </div>
                  <div className="form-block">
                    <label className="required">
                      {t("edit_profile_phone_label")}
                    </label>
                    <input
                      type="text"
                      name="phone"
                      value={phone}
                      placeholder={t("edit_profile_phone_placeholder")}
                      className={
                        "form-input" + (validations.phone ? " input-error" : "")
                      }
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    {validations.phone && (
                      <ValidationMessage message={validations.phone[0]} />
                    )}
                  </div>
                  <div className="form-block">
                    <label className="required">
                      {t("edit_profile_gender_label")}
                    </label>
                    <Select
                      placeholder={t("edit_profile_gender_placeholder")}
                      className={
                        "form-react-select w-100" +
                        (validations.gender ? " input-error" : "")
                      }
                      classNamePrefix="select_input"
                      name="gender"
                      onChange={(e) => setGender(e)}
                      value={gender}
                      isClearable={true}
                      isSearchable={false}
                      options={[
                        {
                          value: "male",
                          label: t("create_new_account_male_option"),
                        },
                        {
                          value: "female",
                          label: t("create_new_account_female_option"),
                        },
                      ]}
                    />
                    {validations.gender && (
                      <ValidationMessage message={validations.gender[0]} />
                    )}
                  </div>
                  <div className="form-block">
                    <label>{t("edit_profile_city_label")}</label>
                    <input
                      type="text"
                      name="city"
                      value={city}
                      placeholder={t("edit_profile_city_placeholder")}
                      className={
                        "form-input" + (validations.city ? " input-error" : "")
                      }
                      onChange={(e) => setCity(e.target.value)}
                    />
                    {validations.city && (
                      <ValidationMessage message={validations.city[0]} />
                    )}
                  </div>
                  <div className="form-block">
                    <label>{t("edit_profile_postal_code_label")}</label>
                    <input
                      type="text"
                      name="postal_code"
                      value={postalCode}
                      placeholder={t("edit_profile_postal_code_placeholder")}
                      className={
                        "form-input" +
                        (validations.postal_code ? " input-error" : "")
                      }
                      onChange={(e) => setPostalCode(e.target.value)}
                    />
                    {validations.postal_code && (
                      <ValidationMessage message={validations.postal_code[0]} />
                    )}
                  </div>
                  <div className="form-block">
                    <label>{t("edit_profile_address_label")}</label>
                    <input
                      type="text"
                      name="address"
                      value={address}
                      placeholder={t("edit_profile_address_placeholder")}
                      className={
                        "form-input" +
                        (validations.address ? " input-error" : "")
                      }
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    {validations.address && (
                      <ValidationMessage message={validations.address[0]} />
                    )}
                  </div>
                  <div className="edit-profile-buttons">
                    <button
                      className="button-white"
                      type="button"
                      onClick={() => navigate(-1)}
                    >
                      {t("edit_profile_go_back_button")}
                    </button>
                    {buttonIsLoading ? (
                      <button type="button" className="button-blue has-icon">
                        <i className="spinner-icon" />
                        {t("edit_profile_changing_button")}
                      </button>
                    ) : (
                      <button type="submit" className="button-blue has-icon">
                        <i className="icon edit-icon" />
                        {t("edit_profile_change_button")}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
